import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  nodeWrapper: {
    marginTop: 16,
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap'
  },
  input: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    paddingLeft: 15,
    paddingRight: 10,
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        height: 35
      },
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    }
  },
}))