import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  indicator: {
    background: theme.palette.background.default,
    width: 120,
    minHeight: 400,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,
    position: 'relative',

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 85,
      bottom: 85,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 1,
      borderLeft: '2px dotted #333333'
    }
  },
  indicatorItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 10,

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 70,
      height: 70,
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: theme.palette.background.default,
    }
  },
  indicatorNumber: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    width: 32,
    height: 32,
    borderRadius: 16,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    boxShadow: '0px 4px 12px rgba(35, 39, 85, 0.1)',
    zIndex: 20,
  },
  indicatorActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  indicatorPassed: {
    backgroundColor: theme.palette.text.primary,
    color: 'white'
  }
}));
