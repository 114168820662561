import {
  Button,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import useStyles from "./LabelList.styles";
import { iLabel } from "@gloow/apiconsumer";
import SearchField from "@common/components/Form/SearchField/SearchField";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import Colors from "@common/constants/Colors";
import IconMenu from '@assets/images/icons-menu.svg';
import Spinner from "@common/components/Spinner/Spinner";

export interface iLabelListProps {
  item: iLabel[];
  loading?: boolean,
  disableSearch?: boolean;
  onClick?: (labelId: number | string) => void;
  filter?: string
  hideTitle?: boolean
  activeId?: number | string
  allowAdd?: boolean,
  onAdd?: () => void
}

export const LabelList = ({ item, loading, onClick, disableSearch, filter, allowAdd, activeId, onAdd }: iLabelListProps) => {
  const classes = useStyles();
  const [active, setActive] = useState<number | string>();
  const [searchText, setSearchText] = useState<string>("");
  const { t } = useTranslation()
  useEffect(() => {
    setActive(activeId)
    return () => { }
  }, [activeId])

  useEffect(() => {
    setSearchText(filter ?? '')
  }, [filter])

  const __onClick = (item: iLabel) => {
    onClick && onClick(item.id)
  };

  const withFilter = (item: iLabel[]) => {
    return item.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  const __renderItem = (item: iLabel[], asChild = false) => {
    return item.map((item) => (
      <Button
        key={item.id}
        className={cx(classes.item, {
          active: active?.toString() === item.id.toString(),
          children: asChild,
        })}
        onClick={() => __onClick(item)}
      >
        <div className={classes.labelIcon} style={{ backgroundColor: item.color ?? Colors.default() }} />
        <Typography variant="body2">{item.name}</Typography>
      </Button>
    ));
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerWrapper}>
        <Typography variant="body2" style={{ fontWeight: "600" }}>
          {t('common.labels', 'Labels')}
        </Typography>
      </div>
      {!disableSearch && (
        <SearchField
          className={classes.searchBar}
          text={searchText}
          onChange={setSearchText}
        />
      )}

      {allowAdd && (
        <Button onClick={onAdd} className={classes.addButton}>
          <Add className={classes.addIcon} />
          <Typography variant='body2' >{t('common.new_label', 'New label')}</Typography>
        </Button>
      )}


      <div className={classes.listWrapper}>
        <Button
          className={cx(classes.addButton, { active: active?.toString() === 'all', })}
          onClick={() => {
            onClick && onClick('all')
          }}
        >
          <img alt='all-entities' src={IconMenu} className={classes.menuIcon} />
          <Typography variant="body2">{t('entities.all_entities', 'All entities')}</Typography>
        </Button>
        {loading && <div className={classes.emptyWrapper}><Spinner size="sm" /></div>}
        {__renderItem(withFilter(item))}
      </div>
    </div >
  );
};
