import { AppBar, AppSwitcher } from '@common/components'
import { useAppSelector } from '@store/hooks'
import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import useStyles from './BaseLayout.styles'
interface iBaseLayout extends React.HTMLAttributes<HTMLDivElement> {
  onSearch?: (text) => void,
  headerRightComponent?: ReactElement | JSX.Element,
  title?: string
}

const BaseLayout = ({
  onSearch,
  headerRightComponent,
  title,
  ...props
}: iBaseLayout) => {
  const classes = useStyles()
  // @ts-ignore
  const { slug } = useParams()
  const [openDomain, domainPermissions] = useAppSelector(state => [state.domain.openDomain, state.domain.openDomainPermissions])
  return (
    <>
      <AppBar title={title ?? ''} domain={openDomain} renderActions={headerRightComponent} onSearch={onSearch} />
      {!!slug && <AppSwitcher domainPermissions={domainPermissions!} />}
      <div className={!!slug ? classes.contentContainer : classes.contentContainerFull}>
        {props.children}
      </div>
    </>
  )
}

export default BaseLayout
