import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    background: 'white',
    overflow: 'hidden'
  },
  contentContainer: {
    minHeight: 'calc(100vh - 80px)',
    width: 'calc(100vw - 72px)',
    marginTop: 80,
    marginLeft: 72,
  },
  contentContainerFull: {
    minHeight: 'calc(100vh - 80px)',
    marginTop: 80,
    width: '100%',
    background: 'white'
  }
}))