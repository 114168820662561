import { Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './OnboardingV2.styles'
import ItemSearchButton from '@common/components/ItemSearchButton/ItemSearchButton'
import { Skeleton } from '@material-ui/lab'
const ChooseMember = ({ title = '', description = '', loading, ontologies, selected, onClick }) => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.dialogTitle} variant="h3">
        {title}
      </Typography>
      <p className="mb-4">{description}</p>
      <div className={classes.labels}>
        {ontologies.length === 0 && <>
          <Skeleton width={100} height={32} style={{ borderRadius: 12 }} animation='pulse' variant='rect' />
          <Skeleton width={100} height={32} style={{ borderRadius: 12 }} animation='pulse' variant='rect' />
          <Skeleton width={100} height={32} style={{ borderRadius: 12 }} animation='pulse' variant='rect' />
          <Skeleton width={100} height={32} style={{ borderRadius: 12 }} animation='pulse' variant='rect' />
          <Skeleton width={100} height={32} style={{ borderRadius: 12 }} animation='pulse' variant='rect' />
          <Skeleton width={100} height={32} style={{ borderRadius: 12 }} animation='pulse' variant='rect' />
        </>}
        {ontologies.map(d => <ItemSearchButton
          disabled={loading}
          active={Boolean(selected.find(s => s.id === d.id))}
          onClick={() => onClick(d)}
          name={d.name}
        />)}
      </div>
    </div>
  )
}

export default ChooseMember