import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

const headerHeight = 80
const sideBarWidth = 392
const sideBarWidthSM = 292

export default makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    paddingBottom: 0
  },
  contentContainer: {
    height: '100%',
    padding: '12px 24px'
  },
  contentContainerTable: {
    height: 'calc(100vh - 135px)',
    padding: '12px 24px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 32px',
    paddingBottom: 0
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  actionIcon: {

  },
  actionButton: {
    width: 34,
    height: 34,
    position: 'relative'
  },
  filterSidebar: {
    marginTop: headerHeight,
    paddingBottom: headerHeight,
    width: sideBarWidth,
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: sideBarWidthSM,
    },
    boxShadow: '0px 12px 12px rgba(0,0,0,0.1)',
    '& .resource-content-form': {
      overflow: 'auto',
      height: 'calc(100vh - 152px)'
    }
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24
  },
  filterHeaderTitle: {
    fontWeight: 600,
    fontSize: 24,
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  filterCloseButton: {
    height: 30,
    width: 30,
    transform: 'translateX(5px)'
  },
  filterCloseIcon: {
    fontSize: 24,
    color: Colors.primary
  },
  filterContainer: {
    overflow: 'hidden',
    '& .filter-form': {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 152px)'
    }
  },
  filterCount: {
    position: 'absolute',
    background: Colors.red,
    color: 'white',
    fontSize: 10,
    fontWeight: 600,
    padding: '1px 3px',
    minWidth: 17,
    borderRadius: '50%',
    top: 0,
    right: 0,
    transition: 'transform ease 0.2s'
  },
  filterCountShow: {
    transform: 'scale(1)'
  },
  filterCountHide: {
    transform: 'scale(0)'
  },
  closeResource: {
    position: 'absolute',
    top: 22,
    right: 16
  },
  addButton: {
    marginRight: 8
  },
  resourceImg: {
    width: 44,
    height: 44,
    borderRadius: 12,
    objectFit: 'cover'
  },
  resourceName: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  resourceRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.lightBlue2
    }
  },
  delete: {
    color: Colors.redError,
  },
  more: {
    color: Colors.primary
  },
  menu: {
    marginTop: 8,
    '& li img': {
      marginRight: 8
    }
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 0px'
  }
}))
