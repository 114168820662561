export enum RouteURI {
  MIND_MAPS = 'mind-maps',
  ASSISTANT = 'assistant',
  ENTITIES = 'entities',
  DOCUMENTS = 'docs-media',
  GRID = 'grid',
  FOCUS_MODE = 'focus-mode',
  ACCOUNT = 'account',
  DOMAINS = 'domains',
  AUTH = 'auth',
  NODE_INFO = 'node-info',
  SEARCH = 'search',
  DOMAIN_SETTING = 'domain-setting'
}
