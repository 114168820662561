import { iDomainNew, iLabel, iNode, iRelation, iResource, iResourceMapping } from "@gloow/apiconsumer";
import { VertexType, VisualisationMode } from '@gloow/navimator';

export interface iActiveVertex {
  type: VertexType,
  data: iNode | iLabel | iResource | iDomainNew | undefined,
  mode?: VisualisationMode
}

export interface iDomainPermissions {
  domainUuid: string,
  exp: number,
  expand: boolean,
  iat: number,
  modify: boolean,
  owner: boolean,
  remove: boolean,
  the_rest_of_the_world: boolean,
  view: boolean
}


export interface iDomainData {
  nodes: iNode[],
  resources: iResource[],
  relations: iRelation[],
  resourceMappings: iResourceMapping[],
  // activeNode: iNode | undefined,
  // activeResource: iResource | undefined,
  // activeLabel: iLabel | undefined,
  activeVertex: iActiveVertex,
  openDomain: iDomainNew | undefined,
  domainPermissions: iDomainPermissions | undefined,
  connectedNodes: iNode[],
  connectedResources: iResource[],
  // nodeLabels: iLabel[],
  lastUpdate: number,
  labels: iLabel[],
}


export interface iDomainState {
  open: boolean,
  openDomain: iDomainNew | undefined,
  domains: iDomainNew[],
  openDomainPermissions: iDomainPermissions | undefined,
  publicDomains: iDomainNew[],
  collaborationDomains: iDomainNew[],
  lastUpdate: number
}

export enum domainProp {
  createdUsingOnboardingVersion = 'createdUsingOnboardingVersion',
  createdOnApp = 'createdOnApp'
}
