import { createSlice } from '@reduxjs/toolkit'
import { iLabel, iNode } from "@gloow/apiconsumer"
import { iEntityProperty } from './entities.interfaces'


const initialState: {
  labels: iLabel[],
  columns: iEntityProperty[],
  rows: Partial<iNode>[]
} = {
  labels: [],
  columns: [],
  rows: [],
}

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState: initialState,
  reducers: {
    setColumns: (state, action) => {
      state.columns = action.payload
    },
    setRows: (state, action) => {
      state.rows = action.payload
    },
    setLabels: (state, action) => {
      state.labels = action.payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    },
    addLabel: (state, action) => {
      state.labels = [action.payload, ...state.labels]
    },
    removeLabel: (state, action) => {
      const labels = state.labels
      state.labels = labels.filter(d => d.id !== action.payload)
    },
    updateLabel: (state, action) => {
      state.labels = state.labels.map(d => {
        if (d.id === action.payload.id) return { ...d, ...action.payload }
        return d
      })
    }
  }
})

export const { setColumns, setRows, setLabels, addLabel, removeLabel, updateLabel } = entitiesSlice.actions
export default entitiesSlice.reducer
