import { iProperty, PropertyValueTypes } from "@gloow/apiconsumer";

export interface iEntityProperty extends iProperty {
  editMode: boolean,
  editable: boolean
}

export enum RowActionTypes {
  VIEW_ON_MINDMAP = 'view_on_mindmap',
  VIEW_DOCS_MEDIA = 'view_docs_media',
  DELETE = 'delete',
}

export const newProperty: iEntityProperty = {
  id: null,
  key: '',
  value: '',
  valueType: PropertyValueTypes.STRING,
  order: 0,
  nodeId: null,
  userId: null,
  domainId: null,
  labelId: null,
  ontologyId: null,
  updatedAt: new Date(),
  createdAt: new Date(),
  editable: true,
  editMode: true,
}

export enum EntityRouteURI {
  import = 'import',
  grid = 'grid'
}

export enum EntityDisplayMode {
  grid = 'grid',
  table = 'table'
}
