import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  mapConfiguratorBtn: {
    position: "absolute",
    bottom: 20,
    right: 25,
  },
  emptyContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "100%"
  }
}));
