import React from 'react'
import { SearchResultType } from '@gloow/apiconsumer'
import { connectToNodes, connectToResources, removeConnectedNodes, removeConnectedResources } from '@services/DomainDataService'
import { applyLabelsToNode, removeLabelsFromNode } from '@services/LabelService'
import { getUniqueMention, getUniqueResource } from '../common/utils'
import _ from 'lodash'
import { useAppDispatch } from '@store/hooks'
import { setLastUpdate } from '@store/domain/domainSlice'
import { isDev } from '@helpers/utils'

const useContentRelation = ({ nodeId }: { nodeId?: number }) => {
  const dispatch = useAppDispatch()
  const [relations, setRelations] = React.useState<{ uuid: string, id: number, type: string }[]>([])
  const setContentRelations = (relations: { uuid: string, id: number, type: string }[]) => setRelations(relations)
  const removeRelations = async (removedMentions) => {
    if (!nodeId || !removedMentions.length) return
    const groupedMentions = _.groupBy(removedMentions, 'type')
    const keys = Object.keys(groupedMentions)
    for (const entries of keys) {
      const ids = groupedMentions[entries].map(d => d.id)
      if (entries === SearchResultType.Label) {
        console.log('remove connected labels')
        await removeLabelsFromNode(ids, nodeId)
        continue
      }
      if (entries === SearchResultType.Node) {
        console.log('remove connected nodes')
        await removeConnectedNodes(ids, nodeId)
        continue
      }
      if (entries === SearchResultType.Resource) {
        console.log('remove connected resources')
        await removeConnectedResources(ids, nodeId)
        continue
      }
    }
  }

  const addRelations = async (addedMentions) => {
    if (!nodeId || !addedMentions.length) return
    const groupedMentions = _.groupBy(addedMentions, 'type')
    const keys = Object.keys(groupedMentions)
    for (const entries of keys) {
      const ids = groupedMentions[entries].map(d => d.id)
      if (entries === SearchResultType.Label) {
        console.log('connect labels')
        await applyLabelsToNode(ids, nodeId)
        continue
      }
      if (entries === SearchResultType.Node) {
        console.log('connect nodes')
        await connectToNodes(ids, nodeId)
        continue
      }
      if (entries === SearchResultType.Resource) {
        console.log('connect resources')
        await connectToResources(ids, nodeId)
        continue
      }
    }
  }

  const getUniqueRelations = (value) => {
    const currentRelations = [...getUniqueResource(value), ...getUniqueMention(value)]
    const uniqueUuids = Array.from(new Set(currentRelations.map(d => d.uuid)))
    const uniqueRelations: { uuid: string, id: number, type: string }[] = []
    for (let i = 0; i < uniqueUuids.length; i++) {
      const data = currentRelations.find(d => d.uuid === uniqueUuids[i])
      if (data) uniqueRelations.push(data)
    }
    return uniqueRelations
  }

  const checkRelations = async (value) => {
    if (!nodeId) return
    const uniqueRelations = getUniqueRelations(value)
    const removedRelations = relations.filter(d => !uniqueRelations.map(u => u.uuid).includes(d.uuid))
    const addedRelations = uniqueRelations.filter(d => !relations.map(m => m.uuid).includes(d.uuid))
    if (isDev) console.log('[DEV]', 'relations:', uniqueRelations.length, 'removed:', removedRelations.length, 'added:', addedRelations.length)
    setRelations(uniqueRelations)
    await removeRelations(removedRelations)
    // handler for undo/redo stuff while we still have the add process when user choose node 
    await addRelations(addedRelations)
    const keys = Object.keys(_.groupBy([...removedRelations, ...addedRelations], 'type'))
    setRelations(uniqueRelations)
    // when there are removed/added nodes or labels, update navimator
    if (keys.includes(SearchResultType.Label) || keys.includes(SearchResultType.Node)) dispatch(setLastUpdate(Date.now()))
  }

  return {
    relations,
    checkRelations,
    removeRelations,
    setContentRelations,
    addRelations,
    getUniqueRelations
  }
}

export default useContentRelation