import React, { useEffect, useState } from 'react'
import { Button, IconButton, TableCell, TextField, Tooltip } from '@material-ui/core'
import useStyles from './Column.styles'
import IconDelete from '@assets/images/delete.svg'
import IconThreeDots from '@assets/images/icons-three-dots.svg'
import {
  Error,
  // KeyboardArrowDownOutlined
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { iEntityProperty } from '@store/entities/entities.interfaces'
import { useDrag, useDrop } from 'react-dnd'

const Column = ({
  data,
  error = false,
  errorMessage = '',
  disabled = false,
  onEdit = (e) => { },
  onClick = (e) => { },
  onDelete = () => { },
  onChange = (e) => { },
  onOrderChange = (source, target) => { }
}:
  {
    data: iEntityProperty,
    error?: boolean,
    errorMessage?: string,
    disabled: boolean,
    onEdit?: (e) => void,
    onClick: (e) => void,
    onDelete?: (e) => void,
    onChange?: (e) => void,
    onOrderChange?: (current: iEntityProperty, target: iEntityProperty) => void
  }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(data.editMode)
  const [value, setValue] = useState(data.key)
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: data.editable ? 'drag-column' : 'drop-disabled',
      drop: (_item: iEntityProperty) => onOrderChange(_item, data),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [data, disabled]
  )
  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: data.editable ? 'drag-column' : 'drag-disabled',
      item: data,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    [data, disabled]
  )

  useEffect(() => {
    setEditMode(data.editMode)
    return () => { }
  }, [data.editMode])

  return (
    <TableCell
      ref={(node: any) => preview(drop(node))}
      className={`${classes.container} ${data.editable ? classes.editable : ''} ${isOver ? classes.over : ''} column-${data.key.toLowerCase()}`}
      style={{
        zIndex: data.order === -2 ? 3 : 1,
        opacity
      }}
    >
      <div className={`${classes.content} ${error ? classes.error : ''}`}>
        {editMode ?
          <>
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={classes.textfield}
            />
            <Button color="primary"
              disabled={value.length === 0 || disabled}
              variant="contained"
              className={classes.save}
              onClick={() => onChange({ ...data, key: value })} >{t('common.save')}
            </Button>
          </>
          :
          <>
            {data.editable && !disabled && <div className={`${classes.dragContainer} drag-controller`} ref={drag}><img alt='drag' src={IconThreeDots} /></div>}
            <Tooltip title={error ? errorMessage : data.key}>
              <div className={classes.row}>
                <div className={`${classes.name}`} onClick={() => onEdit(data)}>
                  {data.key}
                </div>
                {error && <Error className={classes.errorIcon} />}
              </div>
            </Tooltip>
          </>
        }
        <div className={classes.row}>
          {data.editable && !disabled && (
            <>
              <IconButton size='small' onClick={() => onDelete(data)} className={classes.delete}>
                <img alt='delete' src={IconDelete} className={classes.icon} />
              </IconButton>
              {/* {!editMode && (
                      <IconButton size='small' onClick={(e) => {
                        if (!data.editable || disabled) return
                        return onClick(e)
                      }} className={classes.action}><KeyboardArrowDownOutlined />
                      </IconButton>
                    )} */}
            </>
          )}
        </div>
      </div>
    </TableCell>
  )
}

export default Column