import React from 'react'
import { Menu, MenuItem, Typography } from '@material-ui/core'
import IconDelete from '@assets/images/delete.svg'
import useStyles from './RowMenu.styles'
import { RowActionTypes } from '@store/entities/entities.interfaces'
import { useTranslation } from 'react-i18next'
import { iDomainPermissions } from '@store/domain/domainSlice'
import IconMindMap from '@assets/images/icons-mind-maps.svg'
import IconResources from '@assets/images/icons-docs-media.svg'

const RowMenu = ({
  anchorEl,
  bulk = false,
  domainPermissions,
  onClose,
  onClick = (action) => { }
}: {
  anchorEl: any,
  bulk: boolean,
  domainPermissions?: iDomainPermissions,
  onClose: () => void,
  onClick: (action: RowActionTypes) => void
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const menus = [
    {
      title: t('common.view_on_mindmap', 'View on mindmap'),
      img: <img alt='node-mindmap' src={IconMindMap} className={classes.addColumnImg} />,
      onClick: () => onClick(RowActionTypes.VIEW_ON_MINDMAP),
      show: !bulk
    },
    {
      title: t('common.view_docs_media', 'View docs media'),
      img: <img alt='node-resources' src={IconResources} className={classes.addColumnImg} />,
      onClick: () => onClick(RowActionTypes.VIEW_DOCS_MEDIA),
      show: !bulk
    },
    {
      title: t('common.delete', 'View on mindmap'),
      img: <img alt='node-delete' src={IconDelete} className={classes.addColumnImg} />,
      onClick: () => onClick(RowActionTypes.DELETE),
      show: domainPermissions?.remove
    },
  ]
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {menus.filter(d => d.show).map((d, idx) => <MenuItem className={classes.menuContainer} onClick={d.onClick} key={idx.toString()}>
        {d.img}
        <Typography variant='body2'>{d.title}</Typography>
      </MenuItem>)}
    </Menu>
  )
}

export default RowMenu