import React from 'react'
import useStyles from './index.styles'
import { Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LanguageDropdown from '@common/components/LanguageDropdown/LanguageDropdown'

const Mobile = () => {
  const classes = useStyles()
  const iosUrl = "https://apps.apple.com/us/app/concept-mapping-gloow/id1487797118"
  const androidUrl = "https://play.google.com/store/apps/details?id=be.nebulae.gloow"
  const { t } = useTranslation()

  return (
    <div className={classes.mobile}>
      <LanguageDropdown className={classes.languageDropdown} />
      <Typography variant={'h3'} className={classes.title}>{t('common.welcome_to_gloow')}</Typography>
      <p className={classes.desc}>{t('mobile.gloow_for_mobile')}</p>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        className={classes.downloadBtn}
        onClick={() => window.location.href = iosUrl}
      >
        {t('mobile.install_gloow_for_ios')}
      </Button>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        onClick={() => window.location.href = androidUrl}
      >
        {t('mobile.install_gloow_for_android')}
      </Button>
    </div>
  );
}

export default Mobile
