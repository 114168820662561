import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(createStyles({
  container: {
    padding: '16px 32px'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  color: {
    width: 48,
    height: 48,
  },
  avatar: {
    marginTop: 12
  },
  name: {
    width: '100%',
    marginLeft: 12
  },
  info: {
    marginTop: 12
  }
}))