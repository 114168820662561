import { makeStyles } from '@material-ui/core/styles'
import mobileBg from '@assets/images/mobile-bg.png'

export default makeStyles((theme) => ({
  mobile: {
    height: '100vh',
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundImage: `url(${mobileBg})`,
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  },
  title: {
    fontWeight: 600,
    fontSize: 40,
    marginBottom: 10
  },
  desc: {
    opacity: 0.8,
    marginBottom: 25
  },
  downloadBtn: {
    marginBottom: 15
  },
  languageDropdown: {
    position: 'absolute',
    top: 24,
    right: 12
  }
}));
