import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Editable, withReact, Slate, ReactEditor } from 'slate-react'
import { createEditor, Editor, Location, Transforms, Range, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import cx from 'classnames'

import { Element, Leaf } from './components/Common'
import useStyles from './NodeContent.styles'
import { decorate, toggleMark } from './common/utils'
import { SearchAutocomplete } from '../SearchAutocomplete/SearchAutoComplete'
import { withImages, onDropImage } from './hooks/withImage'
import { withHtml } from './hooks/withHtml'
import Toolbar from './components/Toolbar'
import { debounce, Portal, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { Node } from "@gloow/apiconsumer"
import { useAppDispatch } from '@store/hooks'
import { updateNodeField } from '@store/nodes/nodesSlice'
import { SearchResultType } from '@gloow/apiconsumer'
import isHotkey from 'is-hotkey'
import { defaultEntityContents } from '@common/constants/Constants'
import { createNodeFromMention, insertMention, withMentions } from './hooks/withMention'
import Spinner from '../Spinner/Spinner'
import AnalyticsService from '@services/AnalyticsService'
import useContentRelation from './hooks/useContentRelation'
import { HOTKEYS } from './common/config'
import { iNodeContentState } from './interface/NodeContent.interface'
import { selectionString, withOverrides } from './hooks/withOverrides'
import { isDev } from '@helpers/utils'

interface iNodeContent {
  id: string,
  className?: string,
  toolbarClassName?: string,
  nodeId?: number,
  initialValue: any[],
  autoSaveDebounceMs?: number,
  onChange?: (value: any[]) => void,
  readOnly?: boolean,
  onMentionClick?: (type: SearchResultType, id: number) => void,
}
const NodeContentInitState: iNodeContentState = {
  target: null,
  search: '',
  isAt: false,
  atRange: null,
}

// @TODO: Create NodeContent hooks!!
export const NodeContent = ({
  id,
  className = '',
  toolbarClassName = '',
  initialValue = defaultEntityContents,
  onChange,
  readOnly = false,
  autoSaveDebounceMs = 0,
  nodeId,
  onMentionClick,
}: iNodeContent) => {
  const classes = useStyles()
  const mentionBoxRef = useRef<any>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [renderSlate, setRenderSlate] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { relations, checkRelations, getUniqueRelations, setContentRelations } = useContentRelation({ nodeId })
  const [state, setState] = useState<iNodeContentState>(NodeContentInitState)

  const renderElement = useCallback(props => <Element {...props} readOnly={readOnly} />, [readOnly])
  const renderLeaf = useCallback(props => <Leaf {...props} readOnly={readOnly} />, [readOnly])

  const editor: ReactEditor = useMemo(() =>
    withOverrides(
      withHistory(
        withHtml(
          withImages(
            withReact(
              withMentions(
                createEditor()
              )
            )
          ), nodeId
        ),
      ),
      // eslint-disable-next-line
    ), [nodeId])

  const [slateValue, setSlateValue] = useState<Descendant[]>(defaultEntityContents)

  // mention box
  useEffect(() => {
    if (state.target) {
      const el = mentionBoxRef.current
      if (!el) return
      const domRange = ReactEditor.toDOMRange(editor, state.target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      // page overflow handling
      if (rect.left + window.pageXOffset + 150 > window.innerWidth) {
        el.style.right = `32px`
        el.style.left = 'unset'
      }
      else {
        el.style.right = 'unset'
        el.style.left = `${rect.left + window.pageXOffset}px`
      }

      if (rect.bottom + window.pageYOffset + 150 > window.innerHeight) {
        el.style.bottom = `64px`
        el.style.top = `unset`
      }
    }
    return () => { }
    // eslint-disable-next-line
  }, [editor, state])

  const __onCreateNewNode = useCallback(async (name: string, onDone?: () => void) => {
    // keep editor on focus, duplicate mention issue
    Transforms.select(editor, state.target as Location)
    console.log(`Creating node with name: ${name}`, nodeId)
    await createNodeFromMention(editor, nodeId, state.search)
    setState(NodeContentInitState)
    if (onDone) onDone()
    // eslint-disable-next-line
  }, [state.search, nodeId])

  // eslint-disable-next-line
  const autoSaveCallback = useCallback(debounce((value, initialValue) => onUpdate(value, initialValue), autoSaveDebounceMs), [nodeId, autoSaveDebounceMs, relations])

  const onUpdate = async (value, initialValue) => {
    try {
      if (isEqual(value, initialValue) || !nodeId) return setLoading(false)
      setLoading(true)
      await checkRelations(value)
      if (isDev) console.log('[DEV] auto save', selectionString(editor), value)
      const ns = new Node()
      const result = await ns.update({ id: nodeId, contents: value })
      if (!result) return setLoading(false)
      dispatch(updateNodeField({ id: nodeId, contents: value, updatedAt: result.updatedAt }))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('node-contents-update', { e, value: JSON.stringify(value) })
    }
  }

  const normalizeValue = () => {
    editor.children = Array.isArray(initialValue) && initialValue?.length > 0 ? initialValue : defaultEntityContents;
    Editor.normalize(editor, { force: true });
    if (isDev) console.log(`[DEV]`, 'normalized content', editor.children)
    setSlateValue(editor.children)
    setContentRelations(getUniqueRelations(editor.children))
  }

  useEffect(() => {
    // rerender slate when nodeId changed
    setLoading(false)
    setRenderSlate(false)
    normalizeValue()
    setTimeout(() => {
      setRenderSlate(true)
    }, 150)
    return () => { }
    // eslint-disable-next-line
  }, [nodeId])

  if (!renderSlate) return <div className={classes.loaderContainer}><Spinner size='md' /></div>

  if (readOnly && initialValue === defaultEntityContents) return <Typography variant='body2'>{t('common.no_description', 'No description')}</Typography>

  const onMention = () => {
    const { selection } = editor
    const { isAt, atRange } = state;

    if (selection && Range.isCollapsed(selection)) {
      if (!isAt) {
        const [start] = Range.edges(selection);
        const wordBefore = Editor.before(editor, start, { unit: "word" });
        const before = wordBefore && Editor.before(editor, wordBefore);
        const beforeRange = before && Editor.range(editor, before, start);
        const beforeText = beforeRange && Editor.string(editor, beforeRange);
        const beforeMatch = beforeText && beforeText.match(/(?<=\s|^)@(\w+)$/);

        if (beforeMatch && beforeRange) {
          setState({
            target: beforeRange,
            search: beforeMatch[1],
            isAt: true,
            atRange: beforeRange
          })
          return;
        }
      }

      if (atRange) {
        const [start, end] = [atRange.anchor, selection];
  
        const atText = Editor.string(
          editor,
          Editor.range(editor, start, end)
        );

        const atTextMatch = atText?.match(/(?<=\s|^)@(\w+( |\w+)*)$/);

        if (atTextMatch) {
          // console.log("Searching for: ", atTextMatch[1]);
          setState({
            ...state,
            search: atTextMatch[1],
            target: Editor.range(editor, start, end)
          })
          return;
        } else {
          setState({
            ...state,
            isAt: false,
            atRange: null,
          })
        }
      }
    }

    setState({
      ...state,
      isAt: false,
      atRange: null,
      target: null
    })
  }

  const onMentionItemClick = async (e) => {
    Transforms.select(editor, state.target as Location)
    if (nodeId) {
      const value = e.node ?? e.resource ?? e.label
      if (!value?.uuid || !value?.id) return
      await insertMention(editor, e, nodeId)
      setContentRelations([...relations, { uuid: value.uuid, id: value.id, type: e.type }])
    }
    setState(NodeContentInitState)
  }

  const onKeyDown = (event) => {
    if (state.target) {
      // do prevent default for mention, we are handling keyboard action on SearchAutoComplete.tsx 
      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowUp':
        case 'Tab':
        case 'Enter':
        case 'Space':
          return event.preventDefault()
        case 'Escape':
          event.preventDefault()
          setState(NodeContentInitState)
          break;
      }
    }
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(editor, mark)
      }
    }
  }

  return (
    <Slate
      editor={editor}
      value={slateValue}
      onChange={(value) => {
        onChange && onChange(value)
        onMention()
        if (Boolean(autoSaveDebounceMs)) autoSaveCallback(value, initialValue)
      }}
    >
      <div className={classes.container}>
        {!readOnly && <Toolbar className={toolbarClassName} loading={loading} nodeId={nodeId} />}
        <Editable
          id={id}
          decorate={(props) => decorate(props, editor)}
          className={cx(classes.editor, { [classes.readonly]: readOnly }) + ` ${className} contents-editor`}
          renderElement={(props) => renderElement({ ...props, onMentionClick })}
          renderLeaf={renderLeaf}
          placeholder={!readOnly ? t('common.type_something', 'Type something') : undefined}
          spellCheck
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          onDrop={(event) => onDropImage(event, editor, nodeId)}
        />
      </div>

      {state.target && state.search.trim() !== '' && (
        <Portal>
          <div ref={mentionBoxRef} data-cy="mentions-portal" className={`${classes.mentionBox}`}>
            <SearchAutocomplete
              term={state.search}
              disableRedirection={true}
              onItemClick={onMentionItemClick}
              onCreateNode={__onCreateNewNode}
            />
          </div>
        </Portal>
      )}
    </Slate>
  )
}
