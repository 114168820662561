import Colors from '@common/constants/Colors';
import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  connectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .filterTitle': {
      margin: '0px 24px',
    },
    '& .node-card': {
      margin: '12px 0px',
      padding: '12px 24px'
    },
    '& .resource-card': {
      margin: '12px 0px',
      padding: '12px 24px'
    },
    '& .node-autocomplete': {
      padding: '12px 24px',
    }
  },
  emptyConnection: {
    margin: '24px',
  },
  emptyText: {
    fontSize: 14
  },
  labelContainer: {
    display: 'flex',
    paddingTop: 12,
    flexDirection: 'column',
    margin: '0px 24px',
    paddingBottom: 24,
    borderWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.greyBorderBottom,
    borderStyle: 'solid'
  },
  filterTitle: {
    marginTop: 12,
    marginBottom: 18,
    fontSize: 14,
    fontWeight: 600,
  },
  labelCheckbox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '0px !important',
    '& .MuiCheckbox-root': {
      padding: 8
    }
  },
  clearAll: {
    fontSize: 16,
    borderBottomWidth: 1,
    borderColor: Colors.greyBorderBottom,
    margin: '24px 14px',
    boxShadow: 'none !important'
  }
}))