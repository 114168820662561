import React, { useEffect, useState } from 'react'
import AppLayout from '@common/components/AppLayout/AppLayout'
import { useHistory, useParams } from 'react-router-dom'
import { Entity, LabelListV2 } from '@common/components'
import useDomain from '@hooks/useDomain'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import AnalyticsService from '@services/AnalyticsService'
import { RouteURI } from '@common/constants/Routes'
import { setConnectionView } from '@store/filters/filtersSlice'
import { getAllNodeLabels } from '@services/LabelService'
import { iInteractionDialog } from '@common/constants/Constants'

const Entities = ({ setNodeMenu }: { setNodeMenu: (props: iInteractionDialog) => void }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { nodes, relations, domainPermissions, labels, resources} = useDomain()
  const { slug, labelId } = useParams<{ slug?: string, labelId?: string }>()
  const [loading, setLoading] = useState(false)
  const entityDisplayMode = useAppSelector(state => state.filters.entityDisplayMode)

  const loadLabels = async (id?: number) => {
    try {
      const nodeLabels = await getAllNodeLabels()
      // case after adding/updating new label: directly push route to new/updated label
      if (id) history.push(`/${slug}/${RouteURI.ENTITIES}/${id}`)
      // case when loading page at the first time: push route to first label if exist
      else if (!labelId && nodeLabels?.length > 0) history.push(`/${slug}/${RouteURI.ENTITIES}/${nodeLabels[0].id}`)
    } catch (e) {
      AnalyticsService.logError('entity-load-labels', { e })
    }
  }
  const onAdd = async () => {
    return history.push(`/${slug}/${RouteURI.ENTITIES}`)
  }

  const onClick = async (labelId) => {
    dispatch(setConnectionView(undefined))
    return history.push(`/${slug}/${RouteURI.ENTITIES}/${labelId}`)
  }

  useEffect(() => {
    (async () => {
      if (labels.length === 0) setLoading(true)
      await loadLabels()
      if (labels.length === 0) setLoading(false)
    })()
    return () => { }
    // eslint-disable-next-line
  }, []);

  return (
    <AppLayout
      renderLeftContent={<LabelListV2 loading={loading} activeId={labelId} item={labels} allowAdd={domainPermissions?.modify} onAdd={onAdd} onClick={onClick} />}
      renderContent={<Entity nodes={nodes} relations={relations} labels={labels} resources={resources} displayMode={entityDisplayMode} setNodeMenu={setNodeMenu} />}
    />
  )
}

export default Entities
