
import React, { useEffect, useRef, useState } from 'react'

import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { AddWebLinkDialog, BaseLayout, BulkNodeDialog, ConfirmDialog, ConnectNodesResourcesDialog, DomainDialog, NodeMenuDialog, TheLoader, UploadFileDialog, UserPermissionsDialog } from '@common/components'

import Entities from "@pages/Domain/Entities"
import Assistant from "@pages/Domain/Assistant"
import MindMaps from '@pages/Domain/MindMaps'
import { iRoute } from '@routes/index.interfaces'

import { setLoading } from '@store/helpers/helpersSlice'
import { addNewNode, clearDomainData, deleteDomain, DomainDataLoader } from '@services/DomainDataService'
import useDomain from '@hooks/useDomain'
import { generateDetailURL } from '@helpers/utils'
import { addExistingItemTypes, contentType, iInteractionDialog } from '@common/constants/Constants'
import { useTranslation } from 'react-i18next'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { Add, ExpandMore } from '@material-ui/icons'
import { RouteURI } from '@common/constants/Routes'

import addIcon from '@assets/images/icons-add-blue.png'
import webIcon from '@assets/images/icons-web.png'
import addBulkIcon from '@assets/images/icons-add-bulk.svg'
import uploadIcon from '@assets/images/icons-upload.png'

import Account from '@common/components/Account'
import Search from './Search'
import DomainSetting from './Setting'
import DocsMedia from './DocsMedia'
// import NodeInformation from './NodeInformation'
import FocusMode from './FocusMode'

import useStyles from './index.styles'
import { setLastUpdate } from '@store/domain/domainSlice'

const Domain = () => {
  const { slug } = useParams<any>()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const domainData = useDomain()
  const { t } = useTranslation()
  const classes = useStyles()
  const intervalDataLoader = useRef<any>(null)

  const [loading, user] = useAppSelector(state => [state.helpers.loading, state.user.user])

  const id = new URLSearchParams(history.location.search).get('id') ?? ''
  const explorerView = history.location.pathname.includes(RouteURI.MIND_MAPS) ?? false
  const focusView = history.location.pathname.includes(RouteURI.FOCUS_MODE) ?? false

  const [addMenuAnchor, setAddMenuAnchor] = useState<any>(null)
  const [addNodeBulkDialog, setAddNodeBulkDialog] = useState<iInteractionDialog>({ open: false, connectTo: undefined })
  const [addWebLinkDialog, setAddWebLinkDialog] = useState<iInteractionDialog>({ open: false, connectTo: undefined })
  const [addFileDialog, setAddFileDialog] = useState<iInteractionDialog>({ open: false, connectTo: undefined })
  const [addExistingItemDialog, setAddExistingItemDialog] = useState<iInteractionDialog>({
    open: false, tab: addExistingItemTypes.NODES,
    connectTo: undefined
  })
  const [nodeMenu, setNodeMenu] = useState<iInteractionDialog>({
    open: false,
    connectTo: undefined,
    redirectWhenDone: true
  })
  const [deleteDomainDialog, setDeleteDomainDialog] = useState(false)
  const [userPermissionsDialog, setUserPermissionsDialog] = useState(false)
  const [showAddDomain, setShowAddDomain] = useState(false)

  const DomainRoutes: iRoute[] = [
    {
      name: 'Mind Maps',
      children: <MindMaps
        setNodeMenu={setNodeMenu}
        setAddExistingItemDialog={setAddExistingItemDialog}
      />,
      path: `/:slug/${RouteURI.MIND_MAPS}`,
      allowGuest: true
    },
    {
      name: 'Assistant',
      children: <Assistant
        setDeleteDomainDialog={setDeleteDomainDialog}
        setShowAddDomain={setShowAddDomain}
        setUserPermissionsDialog={setUserPermissionsDialog}
      />,
      path: `/:slug/${RouteURI.ASSISTANT}`
    },
    {
      name: 'Entities',
      children: <Entities setNodeMenu={setNodeMenu} />,
      path: `/:slug/${RouteURI.ENTITIES}/:labelId?/:route?`
    },
    {
      name: 'Focus Mode',
      children: <FocusMode
        setNodeMenu={setNodeMenu}
        setAddExistingItemDialog={setAddExistingItemDialog}
      />,
      path: `/:slug/${RouteURI.FOCUS_MODE}/:nodeId?`
    },
    {
      name: 'Docs & Media',
      children: <DocsMedia
        setAddFileDialog={setAddFileDialog}
        setAddWebLinkDialog={setAddWebLinkDialog}
        setAddExistingItemDialog={setAddExistingItemDialog}
      />,
      path: `/:slug/${RouteURI.DOCUMENTS}/:resourceId?/:route?`
    },
    {
      name: 'Account',
      children: <Account />,
      path: `/:slug/${RouteURI.ACCOUNT}`
    },
    {
      name: 'Domain Setting',
      children: <DomainSetting />,
      path: `/:slug/${RouteURI.DOMAIN_SETTING}`,
      ownerOnly: true
    },
    {
      name: 'Search',
      children: <Search />,
      path: `/:slug/${RouteURI.SEARCH}/:searchType?`
    },
  ]

  useEffect(() => {
    (async () => {
      try {
        dispatch(setLoading(true))
        await DomainDataLoader(slug)
        dispatch(setLoading(false))
        if (!intervalDataLoader.current) {
          // load domain data every 5 minute
          intervalDataLoader.current = setInterval(() => DomainDataLoader(slug, false), 300 * 1000)
        }
      } catch (error) {
        dispatch(setLoading(false))

        if (!user) {
          return history.replace('/auth', { referer: location.pathname })
        }

        return history.push('/domains')
      }
    })()
    return () => {
      if (intervalDataLoader.current) clearInterval(intervalDataLoader.current)
      intervalDataLoader.current = null
      clearDomainData()
    }
    // eslint-disable-next-line
  }, [slug]);

  if (loading || !domainData.openDomain) return <TheLoader />

  const _addNewNode = async () => {
    const newNode = await addNewNode()
    if (!newNode) return
    dispatch(setLastUpdate(Date.now()))
    return history.push(generateDetailURL(`/${slug}/${RouteURI.MIND_MAPS}`, { id: newNode.id, type: contentType.NODE }))
  }

  const renderAddMenu = (
    <Menu
      className={classes.menu}
      anchorEl={addMenuAnchor}
      id={'1'}
      open={Boolean(addMenuAnchor)}
      onClose={() => setAddMenuAnchor(null)}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClick={() => setAddMenuAnchor(null)}
    >
      <MenuItem onClick={() => _addNewNode()}>
        <img src={addIcon} alt="create new entity" width="20" /> {t('common.add_new_node', 'Add new node')}
      </MenuItem>
      <MenuItem onClick={() => setAddNodeBulkDialog({ open: true })}>
        <img src={addBulkIcon} alt="create multiple nodes" width="20" /> {t('common.add_multiple_nodes', 'Add multiple nodes')}
      </MenuItem>
      <MenuItem onClick={() => setAddFileDialog({ open: true })}>
        <img src={uploadIcon} alt="create new note" width="20" /> {t('common.upload_a_file', 'Upload a file')}
      </MenuItem>
      <MenuItem onClick={() => setAddWebLinkDialog({ open: true })}>
        <img src={webIcon} alt="create new note" width="20" /> {t('common.add_a_web_link', 'Add a web link')}
      </MenuItem>
    </Menu >
  );

  const renderAddButton = () => {
    return <div className='mx-4'>
      <Button
        variant="contained"
        color="primary"
        className={'add-button-header'}
        startIcon={<Add />}
        onClick={(e) => setAddMenuAnchor(e.currentTarget)}
        endIcon={<ExpandMore className={'add-button-icon-more'} style={{ fontSize: 16 }} />}>
        {t('common.add')}
      </Button>
    </div>
  }

  return (
    <BaseLayout
      title={domainData.openDomain.name}
      headerRightComponent={domainData.domainPermissions?.modify ? renderAddButton() : undefined}
    >
      <Switch>
        {DomainRoutes.filter(d => d.ownerOnly ? Boolean(domainData.domainPermissions?.owner) : true).map(route => <Route key={route.name} {...route} />)}
      </Switch>
      {renderAddMenu}

      {/* @todo make this popup accesible to all component using hook so we dont need to pass it over and over  */}
      {domainData.domainPermissions?.modify &&
        <>
          <ConnectNodesResourcesDialog
            nodes={domainData.nodes}
            resources={domainData.resources}
            connectedNodes={domainData.connectedNodes}
            connectedResources={domainData.connectedResources}
            dialogProps={addExistingItemDialog}
            handleClose={() => setAddExistingItemDialog({ open: false, tab: addExistingItemTypes.NODES, connectTo: undefined })}
          />
          <AddWebLinkDialog
            dialogProps={addWebLinkDialog}
            handleClose={(resourceId) => {
              setAddWebLinkDialog({ open: false, connectTo: undefined })
              if (!resourceId) return
              if (explorerView || focusView) {
                return history.push(generateDetailURL(history.location.pathname,
                  { id: resourceId, type: contentType.RESOURCE, ref: id }
                ))
              }
              return history.push(`/${slug}/${RouteURI.DOCUMENTS}/${resourceId}`)
            }}
          />
          <UploadFileDialog
            dialogProps={addFileDialog}
            handleClose={(resourceId, redirectWhenDone) => {
              setAddFileDialog({ open: false, connectTo: undefined })
              if (!resourceId || !redirectWhenDone) return
              if (explorerView || focusView) {
                return history.push(
                  generateDetailURL(history.location.pathname, {
                    id: resourceId,
                    type: contentType.RESOURCE,
                    ref: id
                  })
                )
              }
              return history.push(`/${slug}/${RouteURI.DOCUMENTS}/${resourceId}`)
            }}
          />
          <DomainDialog
            editing={true}
            domain={domainData.openDomain}
            open={showAddDomain}
            onClose={() => setShowAddDomain(false)}
          />
          <UserPermissionsDialog
            open={userPermissionsDialog}
            domain={domainData.openDomain}
            onClose={() => setUserPermissionsDialog(false)}
          />
          <NodeMenuDialog
            dialogProps={nodeMenu}
            setAddNodeBulkDialog={setAddNodeBulkDialog}
            setAddWebLinkDialog={setAddWebLinkDialog}
            setAddFileDialog={setAddFileDialog}
            setAddExistingItemDialog={setAddExistingItemDialog}
            handleClose={() => setNodeMenu({ open: false, connectTo: undefined, tab: undefined })}
          />
          <BulkNodeDialog
            dialogProps={addNodeBulkDialog}
            openDomain={domainData.openDomain}
            onClose={(newNodes) => {
              setAddNodeBulkDialog({ open: false, connectTo: undefined })
              if (!newNodes || addNodeBulkDialog.redirectWhenDone === false) return
              if (explorerView) return history.push(generateDetailURL(history.location.pathname,
                { id: newNodes[0].id, type: contentType.NODE, ref: id }
              ), { ignoreRecenter: true })
            }}
          />
        </>
      }
      {domainData?.domainPermissions?.remove &&
        <ConfirmDialog
          open={deleteDomainDialog}
          onClose={() => setDeleteDomainDialog(false)}
          onConfirm={() => deleteDomain(domainData.openDomain!, () => history.replace(`/domains`))}
          text={t('common.are_you_sure_want_to_delete_this_domain')}
          confirmText={t('common.delete')}
          cancelText={t('common.cancel')}
        />
      }
    </BaseLayout>
  )
}

export default Domain
