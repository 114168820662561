import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";
const width = 'auto'
export default makeStyles((theme) => ({
  autocomplete: {
    borderRadius: 12,
    boxShadow: `0px 5px 12px 0 ${Colors.shadowColor} !important`,
    paddingBottom: 16,
    width: width,
    backgroundColor: 'white'
  },
  iconSearch: {
    width: 16,
    height: 16
  },
  inputRoot: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    width: width,
    padding: '9.5px 15px !important',
    '&:before': {
      margin: '0px 16px !important',
    },
    '&:after': {
      margin: '0px 16px !important',
    },
    backgroundColor: 'white !important'
  },
  input: {
    fontSize: 14,
    padding: '4px 0px !important',
  },
  endAdornment: {
    display: 'none'
  },
  paper: {
    width: width,
    margin: 0,
    boxShadow: `0px 12px 12px 0 ${Colors.shadowColor} !important`,
    border: 0,
    borderRadius: 0,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginTop: -1,
  },
  values: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
    width: width,
  },
  value: {
    cursor: 'pointer',
    display: 'flex',
    gap: 4,
    color: Colors.primary,
    padding: 4,
    paddingRight: 6,
    borderRadius: 24,
    backgroundColor: Colors.lightBlue2,
    fontWeight: 500,
    transition: 'all ease 0.2s',
    '& p': {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
      alignItems: "center",
    },
    '&:hover': {
      backgroundColor: `${Colors.primary}66`,
    }
  },
  menuPaper: {
    boxShadow: 'none !important',
    width: 300,
    padding: 16,
    backgroundColor: 'transparent',
  }
}))
