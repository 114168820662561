import Colors from '@common/constants/Colors';
import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  node: {
    width: '100%',
    display: 'flex',
    boxShadow: 'none !important',
    padding: '8px 50px',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  nodeContainer: {
    position: 'relative',
  },
  nodesContainer: { overflow: 'auto' },
  actionContainer: { margin: 16 },
  nodeName: {},
  nodeTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 8
  },
  labels: {
    color: Colors.grey,
    fontSize: 12,
    alignSelf: 'flex-start',
    textAlign: 'left'
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    right: 16,
    bottom: 0,
    margin: 'auto 0px',
    width: 24,
    height: 24,
    backgroundColor: 'white',
  },
  removeIcon: {
    width: 14,
    height: 14,
  }
}))