import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { contentType } from '@common/constants/Constants'
import { Labels, NodePicker, Avatar } from '@common/components'
import { iLabel, Node, iNode, iDomainNew, Relation, iOntology, iLabelType } from "@gloow/apiconsumer"
import { assignRandomColor } from '@common/constants/Colors'
import { useAppDispatch } from '@store/hooks'
import { addNodes } from '@store/nodes/nodesSlice'
import { addRelations } from '@store/relations/relationsSlice'
import { completeOnboardingEvent } from '@store/user/userSlice'
import { OnboardingEvents } from '@common/constants/Constants'
import { setLastUpdate } from '@store/domain/domainSlice'
import { useTranslation } from 'react-i18next'
import { generateDetailURL } from "@helpers/utils";
import { useHistory } from "react-router-dom";
import { isOntology } from '@helpers/labels'
import { applyOntologiesToNodes } from '@services/LabelService'
import AnalyticsService from '@services/AnalyticsService'
import useStyles from './NodeBulkForm.styles'

const NodeBulkForm = ({
  onCancel = () => { },
  onDone = (newNodes: iNode[]) => { },
  openDomain,
  connectTo,
  connectToLabel
}: {
  onCancel: () => void
  onDone: (newNodes: iNode[]) => void
  openDomain: iDomainNew | undefined
  connectTo?: iNode,
  connectToLabel?: iLabel
}
) => {
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const [labels, setLabels] = useState<(iLabel | iOntology)[]>(connectToLabel ? [connectToLabel] : [])
  const [nodes, setNodes] = useState<Partial<iNode>[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const onDeleteLabel = (label) => {
    setLabels(labels.filter((item) => item !== label))
  }

  const onSelectLabel = (label) => {
    setLabels([...labels, label])
  }

  const onDeleteNodes = (node) => {
    setNodes(nodes.filter((item) => item !== node))
  }

  const onNodesSelected = (node) => {
    setNodes([...nodes, node])
  }

  const onSubmit = async () => {
    try {

      setLoading(true)
      const nodeService = new Node()
      let assignedLabels = labels.filter(d => !isOntology(d))

      // @ts-ignore
      const nodeColor = (labels.length > 0 && !isOntology(labels[0]) && labels[0].color) ? labels[0].color : assignRandomColor();

      const nodesToCreate = nodes.map(node => ({
        name: node.name,
        info: "",
        labels: assignedLabels.map(l => l.id),
        color: nodeColor
      }));

      let newNodes = await nodeService.createMany(nodesToCreate);
      // check ontology
      const ontologies = labels.filter(d => isOntology(d)).filter(d => !!d)
      if (ontologies?.length > 0) {
        const nodeIds = newNodes.map(d => d.id)
        const ontologyLabels = await applyOntologiesToNodes(nodeIds, ontologies as iOntology[])
        if (ontologyLabels?.length > 0) {
          assignedLabels = [...assignedLabels, ...ontologyLabels]
          newNodes = newNodes.map(node => ({
            ...node,
            labels: assignedLabels as iLabel[]
          }))
        }
      }

      dispatch(addNodes(newNodes))
      dispatch(completeOnboardingEvent(OnboardingEvents.CreateNode))

      if (connectTo?.id) {
        const relationService = new Relation()

        const relationsToCreate = newNodes.map(node => ({
          sourceNode: connectTo?.id,
          targetNode: node?.id
        }));

        const newRelations = await relationService.createMany(relationsToCreate)

        dispatch(addRelations(newRelations))
        dispatch(completeOnboardingEvent(OnboardingEvents.ConnectNode))

      }
      setLoading(false)
      dispatch(setLastUpdate(Date.now()))
      onDone(newNodes)
      if (connectTo || connectToLabel) {
        return history.push(generateDetailURL(history.location.pathname, {
          id: newNodes[0].id,
          type: contentType.NODE,
          ref: connectTo?.id.toString(),
        }), {
          ignoreRecenter: true
        })
      }
      // try to set the label where multiple nodes where added as central vertex
      if (assignedLabels.length > 0) {
        return history.push(generateDetailURL(history.location.pathname, {
          id: assignedLabels[0].id,
          type: contentType.LABEL
        }))
      }
    } catch (e) {
      AnalyticsService.logError('node-bulk-form-submit', { e })
      setLoading(false)
    }
  }

  return (
    <>
      {connectTo &&
        <div className={classes.connectingContainer}>
          <div className={classes.connectingTitle}>{t('common.connecting_to')}</div>
          <div className={classes.connectingNode}>
            <Avatar name={connectTo?.name} src={connectTo?.thumbnailUrl} color={connectTo?.color} size={"24"} textSize='sm' />
            <span className={classes.connectingNodeName}>{connectTo?.name}</span>
          </div>
        </div>
      }
      <div className={classes.my8}>
        <label>{t('common.nodes')}</label>
      </div>
      <NodePicker
        selectedNodes={nodes}
        onDeleted={onDeleteNodes}
        onSelected={onNodesSelected}
        placeholder={t('common.type_and_press_enter_to_add')}
      />
      {nodes.length > 0 && <div className='mb-4'></div>}
      <div className={classes.my8}>
        <label>{t('common.labels')}</label>
      </div>
      <Labels
        labels={labels as iLabel[]}
        disabled={loading}
        editable={true}
        domainUuid={openDomain?.uuid!}
        labelType={iLabelType.NODE}
        onSelected={onSelectLabel}
        onDeleted={onDeleteLabel}
      />
      <div className={classes.actionContainer}>
        <Button id="cancel" disabled={loading} className={classes.cancelButton} variant="contained" color="secondary" fullWidth onClick={onCancel}>{t('common.cancel')}</Button>
        <Button id="submit" disabled={!nodes.length || loading} fullWidth variant="contained" color="primary" onClick={() => onSubmit()}>{t('common.save')}</Button>
      </div>
    </>
  );
}

export default NodeBulkForm;
