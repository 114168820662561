import React from 'react'
import Colors from '@common/constants/Colors'
import { BaseEditor, EditorMarks } from 'slate'
import { isMarkActive, toggleMark } from '../common/utils'
import useStyles from '../NodeContent.styles'
import { IconButton } from '@material-ui/core'
const ButtonMark = ({ editor, mark, icon }: { editor: BaseEditor, mark: EditorMarks, icon: JSX.Element }) => {
  const active = isMarkActive(editor, mark) ? true : false
  const classes = useStyles()
  return (
    <IconButton
      size='small'
      className={classes.toolbarButton}
      style={{ backgroundColor: active ? `${Colors.primary}66` : 'transparent', }}
      type='button'
      onClick={() => toggleMark(editor, mark)}
    >
      {icon}
    </IconButton>
  )
}

export default ButtonMark
