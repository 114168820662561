import { ButtonBase, Menu, MenuItem, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { iDomainNew } from "@gloow/apiconsumer"
import { FetchCollaborationDomains, FetchDomains, FetchPublicDomains } from '@services/DomainDataService'
import { useAppSelector } from '@store/hooks'
import { KeyboardArrowDown } from '@material-ui/icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TheTitle from '../TheTitle/TheTitle'
import useStyles from './DomainSwitcher.styles'
import emptyDomain from '@assets/images/icons-empty-domain.png'
import { useHistory } from 'react-router-dom'

const DomainSwitcher = ({ domain }: { domain: iDomainNew }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const domains = useAppSelector(state => state.domain.domains)
  const user = useAppSelector(state => state.session.user)
  const collabDomains = useAppSelector(state => state.domain.collaborationDomains)
  const publicDomains = useAppSelector(state => state.domain.publicDomains)
  const [anchorEl, setAnchorEl] = useState<any>(undefined)
  const mounted = useRef<any>(null)

  const getDomains = async () => {
    try {
      if (!loading) {
        setLoading(true)
        if (!user?.guest) {
          if (domains.length === 0) await FetchDomains(false)
          if (collabDomains.length === 0) await FetchCollaborationDomains(false)
        }
        if (publicDomains.length === 0) await FetchPublicDomains(false)
        if (mounted.current) setLoading(false)
      }
    } catch (error) {
      if (mounted.current) setLoading(false)
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (anchorEl) getDomains()
    return () => { }
    // eslint-disable-next-line
  }, [anchorEl])

  const renderLoading = useCallback(
    () => {
      return <div style={{ margin: '0px 32px' }}>
        <Skeleton style={{ height: 35, borderRadius: 12 }}></Skeleton>
        <Skeleton style={{ height: 35, borderRadius: 12 }}></Skeleton>
        <Skeleton style={{ height: 35, borderRadius: 12 }}></Skeleton>
        <Skeleton style={{ height: 35, borderRadius: 12 }}></Skeleton>
        <Skeleton style={{ height: 35, borderRadius: 12 }}></Skeleton>
        <Skeleton style={{ height: 35, borderRadius: 12 }}></Skeleton>
      </div>
    },
    // eslint-disable-next-line
    [],
  )

  const renderEmptyDomain = useCallback(() => {
    return <div className={classes.emptyDomain}>
      <img alt='empty-domain' src={emptyDomain} className={classes.emptyDomainImg} />
    </div>
    // eslint-disable-next-line
  }, [])

  const renderMenuItem = (domain: iDomainNew) => {
    return <MenuItem key={domain.uuid} className={classes.menuItem} onClick={() => {
      const currentLocation = history.location.pathname.split('/').filter(d => d)
      if (currentLocation.length > 0) {
        // @ts-ignore
        currentLocation[0] = domain.slug ?? domain.uuid
        if ('/' + currentLocation.join('/') === history.location.pathname) return
        return history.push(`/${currentLocation.join('/')}`)
      }
    }}>
      <>
        <Typography variant='h5' className={classes.title}>{domain.name}</Typography>
        <div className={classes.labels}>{domain.labels?.length > 0 ? domain.labels?.map(d => d.name).join(', ') : t('common.no_labels')}</div>
      </>
    </MenuItem>
  }

  return (
    <>
      <ButtonBase className={classes.button} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <TheTitle title={domain.name} />
        <KeyboardArrowDown className={classes.chevron} />
      </ButtonBase>
      <Menu
        classes={{
          paper: classes.menu,
          list: classes.list
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderMenuItem(domain)}
        <Typography className={classes.switchTo} variant='body2'>{t('common.switch_to', 'Switch to')}</Typography>
        {loading ? renderLoading() :
          <div>
            {domains.filter(d => d.uuid !== domain.uuid).map(renderMenuItem)}
            {collabDomains.map(renderMenuItem)}
            {publicDomains.map(renderMenuItem)}
          </div>
        }
        {!loading &&
          domains.length === 0 &&
          collabDomains.length === 0 &&
          publicDomains.length === 0 &&
          renderEmptyDomain()
        }
      </Menu>
    </>
  )
}

export default DomainSwitcher
