import { EntityDisplayMode } from "@store/entities/entities.interfaces"

export const migrations = {
  0: (state: any) => {
    return state
  },
  1: (state: any) => {
    return {
      ...state,
      leftContentWidth: 223,
      rightContentWidth: 400,
      entityDisplayMode: EntityDisplayMode.table
    }
  }
}
