import React, { ReactElement, useState } from "react";
import {
  AppBar as MUIAppBar,
  ClickAwayListener,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import useStyles from "./AppBar.styles";
import {
  LanguageDropdown,
  SearchField,
  DomainSwitcher,
  TheTitle,
} from "@common/components";

import IconLogo from "@assets/images/logo.svg";
import accountIcon from "@assets/images/icons-account.svg";
import domainIcon from "@assets/images/icons-map.svg";
import logoutIcon from "@assets/images/icons-logout.svg";
import subscriptionIcon from "@assets/images/icons-subscription.svg";

import { useAppDispatch, useAppSelector } from "@store/hooks";
import { logout } from "@services/AuthService";
import { useTranslation } from "react-i18next";
import { iDomainNew } from "@gloow/apiconsumer";
import { setPayWallModal } from "@store/paywall/stripe";
import { RouteURI } from "@common/constants/Routes";
// import Colors from '@common/constants/Colors';
import { SearchAutocomplete } from "../SearchAutocomplete/SearchAutoComplete";
import GuestModeDialog from "../Dialog/GuestModeDialog/GuestModeDialog";
import TryGloowDialog from "../Dialog/TryGloowDialog/TryGloowDialog";

const version = process.env.REACT_APP_GRAVITY_VERSION ?? "0.0.0"
const isProduction = process.env.REACT_APP_ENV === 'production'

const AppBar = ({
  title = "",
  domain,
  onSearch,
  renderActions = <></>,
}:
  {
    title?: string,
    domain?: iDomainNew
    onSearch?: (text) => void,
    renderActions?: JSX.Element | ReactElement,
  }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [subscriptionStatus, openDomain, user] = useAppSelector(state => [state.user.subscriptionStatus, state.domain.openDomain, state.session.user, state.filters.searchText])
  const { t } = useTranslation()
  const [profileAnchor, setProfileAnchor] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [showAutocomplete, setShowAutoComplete] = useState<boolean>(false);
  const [tryGloow, setTryGloow] = useState<boolean>(true)

  const explorerView = history.location.pathname.includes(RouteURI.MIND_MAPS) ?? false
  const { slug } = useParams<{ slug: string }>();

  const renderLimit = () => {
    if (
      !subscriptionStatus.subscribed &&
      subscriptionStatus.totalUsage !== undefined &&
      subscriptionStatus.totalUsage > 20
    ) {
      return (
        <div className={classes.subLimit}>
          {t("subscription.almost_reached_limit")}{" "}
          <span onClick={() => dispatch(setPayWallModal(true))}>
            {t("subscription.upgrade_account")}
          </span>
        </div>
      );
    }
    return <></>;
  };

  const renderLimitNodes = () => {
    const limitUsage = 30;
    const limitPercentage =
      ((subscriptionStatus.totalUsage ? subscriptionStatus.totalUsage : 1) /
        limitUsage) *
      100;

    return (
      <div className={classes.limitNodes}>
        <Typography variant='body2'>
          {t("subscription.total_usage", {
            totalUsage: subscriptionStatus.totalUsage,
            limitUsage,
          })}
        </Typography>
        {/* eslint-disable-next-line */}
        <a>{t("subscription.upgrade_to_unlimited_access")}</a>

        <LinearProgress
          variant="determinate"
          value={limitPercentage > 100 ? 100 : limitPercentage}
          className={classes.limitNodesProgress}
        />
      </div>
    );
  };

  const renderProfileMenu = () => {
    return <Menu
      className={classes.menu}
      anchorEl={profileAnchor}
      id={'primary-search-account-menu'}
      keepMounted
      open={Boolean(profileAnchor)}
      onClose={(e) => setProfileAnchor(null)}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {!subscriptionStatus.subscribed && <MenuItem onClick={() => dispatch(setPayWallModal(true))}>{renderLimitNodes()}</MenuItem>}
      <MenuItem onClick={() => history.push(`/${RouteURI.DOMAINS}`)}>
        <img src={domainIcon} className='your-domain' alt="your domains" />
        {t('common.your_domains')}
      </MenuItem>
      <MenuItem onClick={() => history.push(`/${slug ? slug + '/' : ''}${RouteURI.ACCOUNT}`)}>
        <img src={accountIcon} alt="account" />
        {t('common.account')}
      </MenuItem>
      <MenuItem onClick={() => {
        if (!subscriptionStatus.subscribed) dispatch(setPayWallModal(true))
        else history.push(`/${slug ? slug + '/' : ''}${RouteURI.ACCOUNT}/subscription`)
      }}>
        <img src={subscriptionIcon} alt="subscription" />
        {t('common.subscription')}
      </MenuItem>
      <MenuItem onClick={() => {
        logout()
        return history.push(`/${RouteURI.AUTH}`)
      }}>
        <img src={logoutIcon} alt="logout" />
        {t('common.logout')}
      </MenuItem>
    </Menu >
  }

  return (
    <>
      <MUIAppBar
        position="fixed"
        color="secondary"
        className={classes.appbar}
        elevation={0}
      >
        <div className={classes.flexCenter}>
          <IconButton
            size="small"
            className={classes.iconLogoButton}
            onClick={() => history.push("/domains")}
            color="primary"
          >
            <img src={IconLogo} className={classes.iconLogo} alt="logo" />
          </IconButton>

          {!domain && title && <TheTitle title={title} />}
          {domain && <DomainSwitcher domain={domain} />}
          {isProduction ? <></> : <span className={classes.appVersion}>DEVELOPMENT v{version}</span>}
        </div>

        <ClickAwayListener
          onClickAway={() => {
            return setShowAutoComplete(false);
          }}
        >
          <div style={{ position: "relative" }}>
            <SearchField
              className={classes.searchBar}
              text={search}
              onChange={(text) => {
                setSearch(text);
                setShowAutoComplete(true);
                onSearch && onSearch(text);
              }}
              onFocus={(e) => {
                setShowAutoComplete(search !== "");
              }}
              redirectResultOnEnter={!!openDomain}
            />

            {showAutocomplete && !!openDomain && (
              <div className={`search-component ${classes.searchContainer}`}>
                <SearchAutocomplete
                  term={search}
                  onItemClick={(item) => {
                    setSearch('')
                    setShowAutoComplete(false)
                  }}
                />
              </div>
            )}
          </div>
        </ClickAwayListener>

        <div className={`${classes.flexCenter} ${classes.rightSide}`}>
          {renderLimit()}
          {renderActions}
          <LanguageDropdown />
          {!user?.guest && (
            <>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                onClick={(e) => setProfileAnchor(e.target)}
                color="primary"
                className={classes.avatarButton}
              >
                <div className={classes.avatarThumbnail}>
                  <img
                    className={classes.avatarThumbnailImage}
                    src={user?.user_metadata?.picture ?? user?.picture}
                    alt="avatar"
                  />
                </div>
              </IconButton>
              {renderProfileMenu()}
            </>
          )}
        </div>
      </MUIAppBar>

      {user.guest &&
        <>
          {explorerView && (
            <GuestModeDialog
              onConfirm={() => {
                logout()
                return history.push('/auth')
              }}
            />
          )}
          {!explorerView && (
            <TryGloowDialog
              open={tryGloow}
              onClose={() => setTryGloow(false)}
              onConfirm={() => {
                logout()
                return history.push('/auth')
              }} />
          )}
        </>
      }
    </>
  );
};

export default AppBar;
