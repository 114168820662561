import React from 'react'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { iLabel } from "@gloow/apiconsumer"
import useStyles from './FilterForm.styles'

const LabelCheckbox = ({
  active = false,
  label,
  onClick = () => { }
}: {
  label: iLabel,
  active?: boolean,
  onClick: () => void
}) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.labelCheckbox}
      onChange={() => onClick()}
      checked={active}
      value={label.id}
      control={<Checkbox color="primary" />}
      label={label.name}
      labelPlacement="start"
    />
  )
}

export default LabelCheckbox
