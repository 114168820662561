import {updateUserMetaData} from "@services/AuthService";
import AnalyticsService from "@services/AnalyticsService";
import {analyticsEventCategory} from "@gloow/apiconsumer";

export const analytics = storeAPI => next => action => {

    const usefulEvents = [
        "nodes/addNode",
        "relations/addRelation",
        "resources/addResource",
        "domain/addDomain",
        "domain/openDomain",
        "filters/setLabelsFilter",
        "nodes/updateNode"
    ]

    if ( usefulEvents.includes(action.type) && process.env.REACT_APP_ENV !== 'development' ) {
        // @ts-ignore
        dataLayer.push({
            "event": "gravitationalEvent",
            "eventType": action.type,
        })

        AnalyticsService.createUserEvent(analyticsEventCategory.USER_EVENT, action.type)

    }

    if (action.type === 'user/completeOnboardingEvent') {
        const currentState = storeAPI.getState().user.onboardingStatus

        if (currentState[action.payload] === true) {
            console.log('onboarding event already completed, skipping update')
            return next(action)
        }

        AnalyticsService.createUserEvent(analyticsEventCategory.ONBOARDING_EVENT, action.payload)

        // maybe also not the best place for this
        updateUserMetaData({
            onboardingStatus: {
                ...currentState,
                [action.payload]: true
            }
        });
    }

    return next(action)
}
