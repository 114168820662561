import AnalyticsService from "./AnalyticsService"
import { store } from '@store/index'
import { addLabel, setColumns, setRows, updateLabel as _updateLabel } from "@store/entities/entitiesSlice"
import { iNode, iProperty, iPropertyCreate, Label, Node, Property, PropertyValueTypes, iLabel, iLabelType } from "@gloow/apiconsumer"
import { addNode, setNodes, updateNode } from "@store/nodes/nodesSlice"
import { iEntityProperty } from "@store/entities/entities.interfaces"
import { addNodeLabel, updateNodeLabel } from "@store/labels/labelsSlice"

const sortColumn = (a, b) => a.order - b.order || a.key - b.key

export const getColumns = async (id: number) => {
  try {
    const LS = new Label()
    const entity = await LS.get(id)
    const columnsData = [
      ...entity.properties.sort(sortColumn).map((d, index) => ({ ...d, order: index, editable: true, editMode: false }))
    ]
    store.dispatch(setColumns(columnsData))
  } catch (e) {
    AnalyticsService.logError('entity-get-columns', { e })
  }
}

export const deleteColumn = async (id: number) => {
  try {
    const PS = new Property()
    await PS.delete(id)
    const data = store.getState().entities.columns.filter(d => d.id !== id)
    store.dispatch(setColumns(data))
  } catch (e) {
    AnalyticsService.logError('entity-delete-column', { e })
  }
}

export const createOrUpdateCell = async (nodeId: number, newProperty: iEntityProperty) => {
  try {
    const PS = new Property()
    const newProp = {
      key: newProperty.key,
      value: newProperty.value,
      valueType: newProperty.valueType,
      nodeId: nodeId,
      metaData: newProperty.metaData ?? {}
    }
    let result: iProperty
    if (newProperty.nodeId && newProperty.id) {
      // @ts-ignore
      delete newProp.key
      result = await PS.update(newProperty.id, newProp)
    }
    else {
      result = await PS.create(newProp)
    }
    if (result) {
      const rows = store.getState().entities.rows
      const nodes = store.getState().nodes.nodes
      let selectedNode = nodes.find(d => d.id === nodeId)
      if (selectedNode) {
        const currentProp = selectedNode.properties.find(d => d.key === newProperty.key)
        selectedNode = {
          ...selectedNode,
          properties:
            currentProp ?
              selectedNode.properties?.map(prop => {
                if (prop.key === newProperty.key) return result
                return prop
              })
              :
              [...selectedNode.properties, result]
        }
        const updatedRows = rows.map(d => {
          if (d.id === selectedNode?.id) return selectedNode
          return d
        })
        store.dispatch(updateNode(selectedNode))
        store.dispatch(setRows(updatedRows))
      }
      return result
    }
    return false
  } catch (e) {
    AnalyticsService.logError('entity-change-cell', { e })
    return false
  }
}

export const createOrUpdateColumn = async (property: Partial<iEntityProperty>) => {
  try {
    const PS = new Property()
    const LS = new Label()
    let payload: iPropertyCreate = {
      key: String(property.key ?? ''),
      value: String(property.value ?? ''),
      labelId: property.labelId!,
      valueType: property.valueType ?? PropertyValueTypes.STRING,
      order: property.order ?? new Date().getTime()
    }

    let result
    const columns = store.getState().entities.columns
    // add column
    if (!property.id) {
      // create proxy label
      if (property.valueType === PropertyValueTypes.RELATION) {
        const label = await LS.create({
          name: property.key,
          labelType: iLabelType.RELATION,
        })

        payload = {
          ...payload,
          // @ts-ignore
          metaData: {
            relationLabel: label.id
          }
        }
      }

      result = await PS.create(payload)
      const updatedColumns = columns.filter(d => d.key !== '')
      updatedColumns.push({ ...result, editMode: false, editable: true })
      store.dispatch(setColumns(updatedColumns))
    }
    // currently its impossible to update update column
    // else if (current.id) {
    //   result = await PS.update(current.id, payload)
    //   store.dispatch(setColumns([...columns, result]))
    // }
  } catch (e) {
    AnalyticsService.logError('entity-create-or-update-column', { e })
  }
}

export const createOrUpdateRow = async (node: Partial<iNode>) => {
  try {
    const NS = new Node()
    if (!node.id) {
      const newNode = await NS.create({ ...node, properties: [] })
      store.dispatch(addNode(newNode))
      return newNode
    }
    else {
      let nodeChanged: Partial<iNode> = { id: node?.id, name: node.name }
      if (node.labels && node.labels?.length > 0) nodeChanged = { ...nodeChanged, labels: node.labels as number[] }
      const updatedNode = await NS.update(nodeChanged)
      store.dispatch(updateNode(updatedNode))
      return updatedNode
    }
  } catch (e) {
    AnalyticsService.logError('entity-node-change', { e })
  }
}

export const changeColumnOrder = async (current: iEntityProperty, target: iEntityProperty) => {
  try {
    const columns = store.getState().entities.columns
    let updatedColumns = [...columns.filter(d => d.order !== current.order && d.order >= 0)]
    updatedColumns.splice(target.order, 0, { ...current, order: target.order })
    updatedColumns = updatedColumns.map((d, index) => ({ ...d, order: index })).sort(sortColumn)
    const PS = new Property()
    await PS.updateMany(updatedColumns.map(d => ({ id: d.id, order: d.order })))
    store.dispatch(setColumns([...updatedColumns]))
  } catch (e) {
    AnalyticsService.logError('entity-column-order-change', { e })
  }
}

export const importNodes = (newNodes: iNode[]) => {
  const nodes = store.getState().nodes.nodes
  // new nodes
  const newUniqueNodes = newNodes.filter(node => {
    return nodes.find(d => d.id === node.id) === undefined
  }).map(d => ({
    ...d,
    thumbnailUrl: ''
  }))
  // remap labels of existing nodes
  const updatedNodes = nodes.map(node => {
    let data = { ...node }
    const importedNode = newNodes.find(nn => nn.id === node.id)
    // @ts-ignore
    if (importedNode) data.labels = [...node.labels, ...importedNode.labels]
    return data
  })
  store.dispatch(setNodes([...newUniqueNodes, ...updatedNodes]))
}

export const createLabel = async (label: Partial<iLabel>) => {
  try {
    const LS = new Label()
    const newLabel = await LS.create({ labelType: iLabelType.NODE, name: label?.name, domainUuid: label?.uuid, color: label.color })
    store.dispatch(setColumns([]))
    store.dispatch(setRows([]))
    store.dispatch(addLabel(newLabel))
    store.dispatch(addNodeLabel(newLabel))
    return newLabel
  } catch (e) {
    AnalyticsService.logError('entity-create-label', { e })
  }
}
export const updateLabel = async (id: number, label: Partial<iLabel>) => {
  try {
    const LS = new Label()
    const updatedLabel = await LS.update(id, { name: label.name, color: label.color })
    store.dispatch(_updateLabel(updatedLabel))
    store.dispatch(updateNodeLabel(updatedLabel))

    const updatedNodes = store.getState().nodes.nodes.map(d => ({
      ...d,
      labels: d.labels.map(d => {
        if (d.id === id) return { ...d, ...updatedLabel }
        return d
      })
    }))
    store.dispatch(setNodes(updatedNodes))
    return updatedLabel
  } catch (e) {
    AnalyticsService.logError('entity-update-label', { e })
  }
}

export const deleteCell = async (id: number, nodeId: number) => {
  try {
    const PS = new Property()
    await PS.delete(id)
    const updatedNode = store.getState().nodes.nodes.find(d => d.id === nodeId)
    if (!updatedNode) return false
    store.dispatch(updateNode({ ...updatedNode, properties: updatedNode.properties.filter(d => d.id !== id) }))
    return true
  } catch (e) {
    AnalyticsService.logError('entity-delete-property', { e })
    return false
  }
}

export const createOrUpdateProperty = async (property: iPropertyCreate) => {
  try {
    const PS = new Property()
    const res = await PS.create(property)
    if (!res) return false
    if (res.nodeId) {
      // @todo:check why PS.create nodeId returning node object
      // @ts-ignore
      let updatedNode: iNode | undefined = store.getState().nodes.nodes.find(d => d.id === res.nodeId.id)
      if (!updatedNode) return false
      updatedNode = { ...updatedNode, properties: [...updatedNode.properties, res] }
      store.dispatch(updateNode(updatedNode))
      // @ts-ignore
      return { ...res, nodeId: res.nodeId.id }
    }
    return false
  } catch (e) {
    AnalyticsService.logError('entity-create-property', { e })
    return false
  }
}
