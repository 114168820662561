import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography } from '@material-ui/core'
import { NodeBulkForm } from '@common/components'
import { iDomainNew, iLabel, iNode } from "@gloow/apiconsumer"
import { iInteractionDialog } from '@common/constants/Constants'
import { VertexType } from '@gloow/navimator'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    dialogTitle: {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 24,
      width: '80%'
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      minWidth: 500,
      maxWidth: 500
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    }
  }),
);

function BulkNodeDialog(props: {
  dialogProps: iInteractionDialog
  onClose: (newNodes?: iNode[]) => void,
  openDomain: iDomainNew | undefined,
}) {
  const classes = useStyles();
  const { t } = useTranslation()
  const { onClose, dialogProps, openDomain } = props;

  return (
    <Dialog onClose={() => onClose()} open={dialogProps.open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3">
          {t('common.add_multiple_nodes')}
        </Typography>
        <CloseIcon className={classes.dialogBackIcon} onClick={() => onClose()} />
      </div>
      <p className="mb-6">{t('common.you_can_create_multiple_nodes')}</p>

      <NodeBulkForm
        onCancel={() => onClose()}
        onDone={(newNodes) => onClose(newNodes)}
        openDomain={openDomain}
        connectTo={dialogProps?.connectTo?.type === VertexType.Node ? dialogProps?.connectTo?.data as iNode : undefined}
        connectToLabel={dialogProps.connectTo?.type === VertexType.Label ? dialogProps?.connectTo?.data as iLabel : undefined}
      />
    </Dialog>
  );
}

export default BulkNodeDialog;
