import { Menu, MenuItem, Typography } from '@material-ui/core'
import React, { ReactElement, useCallback } from 'react'
import useStyles from './ColumnMenu.styles'
import { TextFormat, Check, Add } from '@material-ui/icons'

// import IconDollar from '@assets/images/icons-dollar.svg'
import IconNumber from '@assets/images/icons-number.svg'
import IconCalendar from '@assets/images/icons-calendar.svg'
import { iProperty, PropertyValueTypes, iLabel } from "@gloow/apiconsumer"
import { useTranslation } from 'react-i18next'
import Colors from '@common/constants/Colors'

const ColumnMenu = ({
  anchorEl,
  labels = [],
  openConnectionMenu,
  onClose,
  onClick = (property: Partial<iProperty>) => { }
}: {
  anchorEl,
  labels: iLabel[],
  openConnectionMenu?: (props) => void,
  onClose: () => void,
  onClick: (property: Partial<iProperty>) => void
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const getIcon = useCallback((type) => {
    switch (type) {
      case PropertyValueTypes.STRING:
        return <TextFormat className={classes.addColumnImg} />
      case PropertyValueTypes.DATETIME:
        return <img alt='calendar' src={IconCalendar} className={classes.addColumnImg} />
      case PropertyValueTypes.INT:
        return <img alt='number' src={IconNumber} className={classes.addColumnImg} />
      case PropertyValueTypes.FLOAT:
        return <img alt='number' src={IconNumber} className={classes.addColumnImg} />
      case PropertyValueTypes.BOOL:
        return <Check className={classes.addColumnImg} />
      default:
        return <TextFormat className={classes.addColumnImg} />
    }
    // eslint-disable-next-line
  }, [])

  const renderMenuItem = ({ icon, text, action = () => { }, index }: { icon: ReactElement, text: string, action: () => void, index: string }) => {
    return <MenuItem key={index} onClick={action}>
      {icon}
      <Typography variant={'body2'} className={classes.addColumnText}>{text}</Typography>
    </MenuItem>
  }

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      classes={{ paper: classes.addColumnMenu }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Typography variant='body2' className={classes.addColumnTitle}>{t('entities.column_type', 'Column Type')}</Typography>
      <div className={classes.menuItemContainer}>
        {Object.keys(PropertyValueTypes).filter(d => PropertyValueTypes[d] !== PropertyValueTypes.RELATION).map((d, index) => renderMenuItem({
          icon: getIcon(PropertyValueTypes[d]),
          text: t(`entities.${PropertyValueTypes[d]}`, PropertyValueTypes[d]),
          action: () => onClick({ valueType: PropertyValueTypes[d] }),
          index: index.toString()
        }))}
      </div>
      {labels.length > 0 && (
        <div>
          <Typography variant='body2' className={classes.addColumnTitle}>
            {t('common.connections', 'Connections')}
          </Typography>
          <div className={classes.menuItemContainer}>
            {labels.map((d, index) => renderMenuItem({
              icon: <div className={classes.color} style={{ backgroundColor: d.color ?? Colors.default() }} />,
              text: d.name,
              action: () => onClick({
                valueType: PropertyValueTypes.RELATION,
                value: String(d.id),
                metaData: { label: d }
              }),
              index: index.toString()
            }))}
            {openConnectionMenu &&
              <MenuItem onClick={openConnectionMenu}>
                <Add className={classes.addIcon} />
                <Typography variant={'body2'} className={classes.addColumnText}>{t('common.connect_nodes')}</Typography>
              </MenuItem>
            }
          </div>
        </div>
      )}
    </Menu>
  )
}

export default ColumnMenu
