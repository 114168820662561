import React, { useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, IconButton, Menu, MenuItem, Button, Snackbar, Grow, TextField } from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import { iLabel, iNode, Relation, PropertyValueTypes } from "@gloow/apiconsumer"
import { useTranslation } from 'react-i18next'
import useStyles from './EntityProperties.styles'
import deleteIcon from '@assets/images/delete.svg'
import { ConfirmDialog, RelationsPicker } from '@common/components'
import Cell from '@common/components/Entities/EntityTable/Cell/Cell'
import { iEntityProperty, newProperty } from '@store/entities/entities.interfaces'
import useDomain from '@hooks/useDomain'
import { createOrUpdateCell, deleteCell } from '@services/EntityService'
import ColumnMenu from '@common/components/Entities/EntityTable/ColumnMenu/ColumnMenu'
import { Alert, Color } from '@material-ui/lab'
import EmptyResourceIcon from '@assets/images/empty-resource.png'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getConnectedNodesForActiveNode } from '@selectors/index'
import { updateNode } from '@store/nodes/nodesSlice'
import _ from 'lodash'
import { removeRelation } from '@store/relations/relationsSlice'
import { addExistingItemTypes, iInteractionDialog } from '@common/constants/Constants'
import { VertexType } from '@gloow/navimator'
import { iConnectedNode } from '@store/nodes/nodes.interfaces'
import { addRelationsLabel } from '@services/DomainDataService'
import { setLastUpdate } from '@store/domain/domainSlice'
import unlinkIcon from '@assets/images/icons-unlink.svg'

const EntityPropertiesTable = (
  { node, openConnectionMenu, onNodeClick }:
    { node: iNode, openConnectionMenu: (props: iInteractionDialog) => void, onNodeClick?: (node: iNode) => void }
) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { domainPermissions, labels, relations, nodes, openDomain, lastUpdate } = useDomain()
  const dispatch = useAppDispatch()
  const [properties, setProperties] = useState<iEntityProperty[]>([])
  const connectedNodes = useAppSelector(state => getConnectedNodesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: node } }))
  const [dialog, setDialog] = useState<{ anchor, id, metaData?, valueType?}>({ anchor: null, id: null, metaData: null, valueType: null })
  const [dialogAdd, setDialogAdd] = useState<any>(null)
  const [dialogConfirm, setDialogConfirm] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [snackBar, setSnackBar] = useState<{ open: boolean, severity: Color | undefined, message: string }>({
    open: false, severity: 'success', message: ''
  })

  const getRelationLabels = () => {
    const labels = connectedNodes?.filter(d => d.id !== node.id)?.map(d => {
      if (!d.relationLabel) return { id: null, name: '', createdAt: new Date(), updatedAt: new Date(), nodeId: d.id }
      return { ...d.relationLabel, nodeId: d.id }
    })
    return _.uniqBy(labels, (d => d.id ? d.id : d))
  }

  const getConnectedNodesByProp = (prop: iEntityProperty) => {
    const relatedNodes = connectedNodes.filter(d => {
      if (prop.metaData?.relationLabel && d.relationLabel?.id) return d.relationLabel?.id === prop.metaData?.relationLabel
      if (prop.metaData?.relatedNode) return [d.relation?.targetNode, d.relation?.sourceNode].includes(prop.metaData?.relatedNode)
      return false
    })
    return relatedNodes
  }

  const initProps = () => {
    const leafProps = node.properties
      .filter(d => d.id && d.valueType !== PropertyValueTypes.RELATION)
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .map(d => ({ ...d, editMode: false, editable: domainPermissions?.modify ?? false })) ?? []
    const relationProps = getRelationLabels().map(d => ({
      id: d.id,
      key: d.name,
      createdAt: d.createdAt ?? new Date(),
      updatedAt: d.updatedAt ?? new Date(),
      value: '',
      valueType: PropertyValueTypes.RELATION,
      metaData: { relationLabel: d.id, relatedNode: !d.id ? d.nodeId : null },
      order: -1,
      nodeId: node.id,
      labelId: null,
      domainId: null,
      ontologyId: null,
      userId: null,
      editable: d.id ? true : false,
      editMode: false
    }))
    setProperties([...relationProps, ...leafProps])
  }

  useEffect(() => {
    initProps()
    return () => { }
    // eslint-disable-next-line
  }, [node.properties, node?.id, lastUpdate])

  const onDelete = async () => {
    try {
      setLoading(true)
      const prop = properties.find(d => d.id === dialog.id && d.metaData === dialog.metaData)
      // if its a type of relation, need to remove all relation
      if (prop?.valueType === PropertyValueTypes.RELATION) {
        // d.relation?.targetNode targetNode will be used if relationLabel is null
        const relationsRemoved = getConnectedNodesByProp(prop).map(d => d.relation)
        if (relationsRemoved.length > 0) {
          const RS = new Relation()
          for (let i = 0; i < relationsRemoved.length; i++) {
            if (!relationsRemoved[i]?.id) continue
            await RS.destroy(relationsRemoved[i]!.id)
            dispatch(removeRelation(relationsRemoved[i]!.id))
          }
        }
      }
      // if leaf props
      else {
        if (dialog.id) {
          let props = properties
          const res = await deleteCell(dialog.id, node.id)
          if (res) props = props.filter(d => d.id !== dialog.id)
          dispatch(updateNode({ ...node, properties: props }))
        }
      }
      setDialog({ anchor: null, id: null, metaData: null })
      setDialogConfirm(false)
      setLoading(false)
      dispatch(setLastUpdate(Date.now()))
    } catch (error) {
      setLoading(false)
      // console.log(error)
    }
  }

  const renderDialog = () => <Menu
    open={Boolean(dialog.anchor)}
    onClose={() => setDialog({ anchor: null, id: null, metaData: null })}
    anchorEl={dialog.anchor}
    keepMounted
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
    transformOrigin={{ vertical: 'top', horizontal: 'center', }}
  >
    <MenuItem onClick={() => setDialogConfirm(true)}>
      <img src={deleteIcon} className={classes.deleteIcon} alt='Delete' />
      <Typography variant='body2'>{t('common.delete', 'Delete')}</Typography>
    </MenuItem>
  </Menu>

  const createOrUpdate = async (prop) => {
    try {
      if (!prop.key || prop.valueType === PropertyValueTypes.RELATION) return
      if (dialogAdd) setDialogAdd(null)
      setLoading(true)
      let newProp = { ...prop, labelId: null, nodeId: node.id }
      const res = await createOrUpdateCell(node.id, newProp)
      setLoading(false)
      if (!res) return setSnackBar({ open: true, severity: 'error', message: t('entities.failed_to_create_or_update_property', 'Failed to create/update property, property name could be already exist') })
      const props = [
        ...properties.filter(d => d.id && d.id !== prop.id),
        { ...res, editMode: false, editable: true }]
        .sort((a, b) => a.order - b.order)
      dispatch(updateNode({ ...node, properties: props }))
    } catch (error) {
      setLoading(false)
      setSnackBar({ open: true, severity: 'error', message: t('common.something_wrong_with_the_server') })
    }
  }

  const _onRelationLabelAdded = async (targetNodes: iConnectedNode[], label: iLabel) => {
    if (!openDomain) return
    const res = await addRelationsLabel(targetNodes, label, openDomain.uuid)
    if (!res) setSnackBar({ open: true, severity: 'error', message: t('common.failed_to_update_relation', 'Failed to update relation') })
  }

  const renderKey = (prop: iEntityProperty) => {
    if (prop.valueType === PropertyValueTypes.RELATION) {
      const relatedNodes = getConnectedNodesByProp(prop)
      const relationLabel = getRelationLabels().find(d => d.id === prop.metaData?.relationLabel)
      return <RelationsPicker
        readOnly={!domainPermissions?.modify && !Boolean(prop.metaData?.label)}
        // @ts-ignore
        relationLabel={prop.metaData?.label?.id ? prop.metaData?.label : (relationLabel?.id ? relationLabel : undefined)}
        activeNode={node}
        targetNodes={relatedNodes}
        onRelationLabelAdded={_onRelationLabelAdded}
      />
    }
    if (prop.id) return <Typography variant='body2'>{prop.key}</Typography>
    return <TextField
      onBlur={() => createOrUpdate(prop)}
      variant='outlined'
      className='text-field-outlined'
      value={prop.key}
      placeholder={t(`entities.describe_your_field`, `Describe your ${prop.valueType}`, { field: prop.valueType })}
      autoFocus
      onChange={(e) => {
        const currentProp = properties.map(p => {
          if (p.id === prop.id) return { ...p, key: e.target.value }
          return p
        })
        setProperties(currentProp)
      }} />
  }

  return (
    <>
      <TableContainer className={`${classes.tableContainer} entity-prop-table`}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='subtitle2'>{t('properties.properties_or_connections', 'Properties or Connections')}</Typography></TableCell>
              <TableCell><Typography variant='subtitle2'>{t('properties.value', 'Value')}</Typography></TableCell>
              {domainPermissions?.remove && <TableCell size='small'></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {properties.map((d, idx) => (
              <TableRow className={classes.row} key={idx.toString()}>
                <TableCell>{renderKey(d)}</TableCell>
                <Cell
                  node={node}
                  nodes={nodes}
                  relations={relations}
                  labels={labels}
                  disabled={loading || !d.editable || !domainPermissions?.modify}
                  property={d}
                  onSave={(props) => createOrUpdate(props)}
                  onNodeClick={onNodeClick}
                />
                {domainPermissions?.remove && (
                  <TableCell size='small'>
                    {d.valueType === PropertyValueTypes.RELATION ?
                      <IconButton disabled={loading} size="small" onClick={() => {
                        setDialog({ id: d.id, metaData: d.metaData, anchor: null, valueType: PropertyValueTypes.RELATION })
                        setDialogConfirm(true)
                      }} className={classes.removeButton}>
                        <img src={unlinkIcon} alt='unlink' />
                      </IconButton>
                      :
                      <IconButton disabled={loading} size="small" className={classes.editIcon} color="primary" onClick={(e) => setDialog({ anchor: e.currentTarget, id: d.id, metaData: d.metaData })}>
                        <MoreVert fontSize="small" />
                      </IconButton>
                    }
                  </TableCell>
                )}
              </TableRow>
            ))}
            {properties.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <img src={EmptyResourceIcon} alt='empty-properties' className={classes.emptyImage} />
                  <Typography variant='body2'>{t('common.no_properties_or_connections', 'No properties or connections')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {domainPermissions?.modify && (
        <div>
          <Button
            startIcon={<Add fontSize={'small'} className={classes.addIcon} />}
            disabled={loading || Boolean(properties?.find(d => {
              if (d.valueType === PropertyValueTypes.RELATION && d.metaData?.label?.id && !d.id) return d
              return d.valueType !== PropertyValueTypes.RELATION && !d.id
            }))}
            className={classes.addButton}
            onClick={(e) => setDialogAdd(e.currentTarget)}
          >
            <Typography variant='body2'>{t('entities.new_property_or_connection', 'New property or connection')}</Typography>
          </Button>
        </div>
      )}
      {renderDialog()}
      <ColumnMenu
        labels={labels}
        anchorEl={dialogAdd}
        onClose={() => setDialogAdd(null)}
        onClick={(target) => {
          setDialogAdd(null)
          setProperties([...properties, { ...newProperty, ...target }])
        }}
        openConnectionMenu={() => {
          setDialogAdd(null)
          openConnectionMenu({
            open: true,
            tab: addExistingItemTypes.NODES,
            connectTo: {
              type: VertexType.Node,
              data: node,
            },
            specificMenu: true,
            connectedNodes: connectedNodes,
            redirectWhenDone: false
          })
        }}
      />
      <ConfirmDialog
        loading={loading}
        open={dialogConfirm}
        onConfirm={() => onDelete()}
        onClose={() => {
          setDialogConfirm(false)
          setDialog({ anchor: null, id: null, metaData: null })
        }}
        text={dialog.valueType === PropertyValueTypes.RELATION ? t('common.are_you_sure_want_to_delete_this_connection', 'Are you sure want to delete this connection?') : t('common.are_you_sure_want_to_delete_this_property', 'Are you sure want to delete this property?')}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
      />

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackBar.open} TransitionComponent={Grow} onClose={() => setSnackBar({ ...snackBar, open: false })} autoHideDuration={3000} >
        <Alert variant={'filled'} onClose={() => setSnackBar({ ...snackBar, open: false })} severity={snackBar.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default EntityPropertiesTable
