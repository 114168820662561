import { Link, makeStyles, Typography } from "@material-ui/core"
import Mention from "./Mention"
import Image from './Image'
import Colors from "@common/constants/Colors"

const useStyles = makeStyles((theme) => ({
  h1: { fontSize: 32, margin: '16px 0px', fontWeight: 600 },
  h2: { fontSize: 24, margin: '16px 0px', fontWeight: 600 },
  h3: { fontSize: 18, margin: '16px 0px', fontWeight: 600 },
  h4: { fontSize: 16, margin: '16px 0px', fontWeight: 600 },
  h5: { fontSize: 14, margin: '16px 0px', fontWeight: 600 },
  h6: { fontSize: 12, margin: '16px 0px', fontWeight: 600 },
  p: { margin: '16px 0px' },
  list: {
    '& li': {
      fontSize: 16,
      marginBottom: 6
    }
  },
  quote: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'italic',
    margin: '0px',
    padding: '10px 16px',
    borderLeft: `2px solid ${Colors.secondaryStroke}`,
  },
}))

export const Element = ({ attributes, children, element, readOnly, onMentionClick }) => {
  const style = { textAlign: element?.align }
  const classes = useStyles()

  switch (element.type) {
    case 'quote':
    case 'block-quote':
      return <blockquote className={classes.quote} {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return (
        <ul className={classes.list} style={style} {...attributes}>{children}</ul>
      )
    case 'heading-one':
      return (
        <Typography variant="h1" className={classes.h1} style={style} {...attributes}>{children}</Typography>
      )
    case 'heading-two':
      return (
        <Typography variant="h2" className={classes.h2} style={style} {...attributes}>{children}</Typography>
      )
    case 'heading-three':
      return (
        <Typography variant="h3" className={classes.h3} style={style} {...attributes}>{children}</Typography>
      )
    case 'heading-four':
      return (
        <Typography variant="h4" className={classes.h4} style={style} {...attributes}>{children}</Typography>
      )
    case 'heading-five':
      return (
        <Typography variant="h5" className={classes.h5} style={style} {...attributes}>{children}</Typography>
      )
    case 'heading-six':
      return (
        <Typography variant="h6" className={classes.h6} style={style} {...attributes}>{children}</Typography>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>{children}</li>
      )
    case 'numbered-list':
      return (
        <ol className={classes.list} style={style} {...attributes}>{children}</ol>
      )
    case 'link':
      return (
        <Link style={style} href={element.url} target='_blank' onClick={() => window.open(element.url, '_blank')} {...attributes}>{children}</Link>
      )
    case 'mention':
      return <Mention onMentionClick={onMentionClick} attributes={attributes} children={children} element={element} style={style} />
    case 'image':
      return <Image attributes={attributes} children={children} element={element} readOnly={readOnly} />
    case 'code':
      return <pre {...attributes}>{children}</pre>
    default:
      return <Typography variant="body1" className={classes.p} style={style} {...attributes}>{children}</Typography>
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.code) {
    children = <code>{children}</code>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.underline) {
    children = <u>{children}</u>
  }
  return <span {...attributes}>{children}</span>
}
