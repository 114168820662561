import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    height: 'calc(100vh - 80px)',
    width: 72,
    backgroundColor: Colors.greyBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    paddingTop: 18
  },
  button: {
    width: 40,
    height: 40,
    padding: 8,
    borderRadius: 12,
    boxShadow: 'none !important',
  }
}))