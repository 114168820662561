import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    paddingTop: 8
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 12,
    padding: 16
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '12px 0px',
    borderRadius: 12,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&.active': {
      textDecoration: 'underline',
      fontWeight: 600
    }
  }
}))