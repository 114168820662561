import { makeStyles } from '@material-ui/core/styles'
export default makeStyles((theme) => ({
  tabTitle: {
    fontWeight: 500,
    fontSize: 24,
    marginBottom: 35
  },
  formContainer: {
    display: 'flex',
    gap: 32
  },
  saveBtn: {
    minWidth: 100
  },
  changePassBtn: {
    marginLeft: 15
  },
  actions: {
    marginTop: 16
  },
  inputContainer: {
    marginBottom: 16
  },
  inputWrapper: {
    flex: 1
  }
}));
