import { createSlice } from '@reduxjs/toolkit'
import { FractalVisualisation } from '@gloow/navimator'

export interface iHelpersState {
  loading: boolean,
  visualisation: FractalVisualisation | null
}

const initialState: iHelpersState = {
  loading: false,
  visualisation: null
}

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setVisualisation: (state, action) => {
      state.visualisation = action.payload
    }
  }
})

export const { setLoading, setVisualisation } = helpersSlice.actions

export default helpersSlice.reducer
