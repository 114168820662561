import { Domain, iDomainNew, iLabel, iNode, iProperty, iResource, Label, Node, Relation, Resource, iLabelType, PropertyValueTypes } from '@gloow/apiconsumer'
import { store } from '@store/index'

// Actions
import {
  closeDomain,
  openDomain,
  setDomains,
  setOpenDomainPermissions,
  setPublicDomains,
  addDomain,
  removeDomain,
  setCollaborationDomains,
  addCollaborationDomain,
  removeCollaborationDomain,
  setLastUpdate
} from '@store/domain/domainSlice'
import { setLoading } from '@store/helpers/helpersSlice'

import AnalyticsService from '@services/AnalyticsService'
import { addResource, setResources } from '@store/resources/resourcesSlice'
import { addNode, addNodes, removeLabelFromNodes, removeNode, removeNodes, setNodes } from '@store/nodes/nodesSlice'
import { addRelation, addRelations, removeAllRelationsConnectedToNode, removeAllRelationsConnectedToNodes, removeRelation, removeRelations, setRelations, updateRelation } from '@store/relations/relationsSlice'
import { addResourceMappings, bulkRemoveResourceMappings, removeSingleMapping, setResourceMappings } from '@store/resourceMappings/resourceMappingsSlice'
import { removeLabel, setNodeLabels } from '@store/labels/labelsSlice'
import { completeOnboardingEvent } from '@store/user/userSlice'
import jwtDecode from 'jwt-decode'
import { defaultEntityContents, OnboardingEvents } from '@common/constants/Constants'
import { setColumns, setLabels, setRows, removeLabel as removeEntityLabel } from '@store/entities/entitiesSlice'
import { resetFilters } from '@store/filters/filtersSlice'
import { iConnectedNode } from '@store/nodes/nodes.interfaces'
import { getWeblinkType } from '@helpers/resource'
import Colors from '@common/constants/Colors'
import { getConnectedNodesForActiveNode, getResourcesForActiveNode } from '@selectors/index'
import { iResourceMapping } from '@gloow/apiconsumer'
import { getAllNodeLabels } from './LabelService'

export async function FetchDomains(preview = true) {
  try {
    store.dispatch(setDomains([]))
    const DomainService = new Domain()
    const domains = await DomainService.listUserDomains()
    if (preview) {
      const domainUuids = domains.map(d => d.uuid)
      const domainPreview = await DomainService.getDomainPreviews(domainUuids)
      for (let i = 0; i < domains.length; i++) {
        if (domainPreview[domains[i].uuid]) {
          domains[i] = {
            ...domains[i],
            // @ts-ignore
            lastOpenedNodes: domainPreview[domains[i].uuid] ?? [] // this one doesn't exist in iDomainNew type
          }
        }
      }
    }
    store.dispatch(setDomains(domains))
  } catch (e) {
    AnalyticsService.logError('domain-fetch', { e })
    console.log(e)
  }
}

export async function FetchPublicDomains(preview = true) {
  try {
    store.dispatch(setPublicDomains([]))
    const DomainService = new Domain()
    const domains = await DomainService.getPublicDomains(0, 20)
    if (preview) {
      const domainUuids = domains.map(d => d.uuid)
      const domainPreview = await DomainService.getDomainPreviews(domainUuids)
      for (let i = 0; i < domains.length; i++) {
        if (domainPreview[domains[i].uuid]) {
          domains[i] = {
            ...domains[i],
            // @ts-ignore
            lastOpenedNodes: domainPreview[domains[i].uuid] ?? [] // this one doesn't exist in iDomainNew type
          }
        }
      }
    }
    store.dispatch(setPublicDomains(domains))
  } catch (e) {
    AnalyticsService.logError('public-domain-fetch', { e })
    console.log(e)
  }
}

export async function FetchCollaborationDomains(preview = true) {
  try {
    store.dispatch(setCollaborationDomains([]))
    const DomainService = new Domain()
    const domains = await DomainService.getDomainCollaboration()
    if (preview) {
      const domainUuids = domains.map(d => d.uuid)
      const domainPreview = await DomainService.getDomainPreviews(domainUuids)
      for (let i = 0; i < domains.length; i++) {
        if (domainPreview[domains[i].uuid]) {
          domains[i] = {
            ...domains[i],
            // @ts-ignore
            lastOpenedNodes: domainPreview[domains[i].uuid] ?? [] // this one doesn't exist in iDomainNew type
          }
        }
      }
    }
    store.dispatch(setCollaborationDomains(domains))
  } catch (e) {
    AnalyticsService.logError('collaboration-domain-fetch', { e })
    console.log(e)
  }
}

export async function createDomain(toCreate: Partial<iDomainNew>) {
  try {
    const DomainService = new Domain()
    const newDomain = await DomainService.createDomain(toCreate)

    newDomain.lastOpenedNodes = []
    // temp code, pre-populate domain for dummy onboarding
    const DS = new Domain();
    await DS.openDomain(newDomain.uuid);
    await DS.prePopulate(newDomain.uuid);
    // end temp code

    const domainPreview = await DomainService.getDomainPreviews([newDomain.uuid])
    newDomain.lastOpenedNodes = domainPreview && domainPreview[newDomain.uuid] ? domainPreview[newDomain.uuid] : []
    newDomain.nodes = domainPreview[newDomain.uuid].length
    newDomain.relations = 1

    store.dispatch(addDomain(newDomain))
    store.dispatch(completeOnboardingEvent(OnboardingEvents.CreateDomain))

    return newDomain
  } catch (e) {
    AnalyticsService.logError('domain-create', { e })
    console.log(e)
  }
}

export async function updateDomain(toUpdate: Partial<iDomainNew>, isCollaborationDomain: boolean = false) {
  const DomainService = new Domain()

  try {
    // open first
    await DomainService.openDomain(toUpdate.uuid)

    const updatedDomain = await DomainService.updateDomain(toUpdate)
    const domainPreview = await DomainService.getDomainPreviews([updatedDomain.uuid])
    // @ts-ignore
    updatedDomain.lastOpenedNodes = domainPreview && domainPreview[updatedDomain.uuid] ? domainPreview[updatedDomain.uuid] : []

    // to update, do delete and add (for now)
    if (!isCollaborationDomain) {
      store.dispatch(removeDomain(updatedDomain))
      store.dispatch(addDomain(updatedDomain))
    } else {
      store.dispatch(removeCollaborationDomain(updatedDomain))
      store.dispatch(addCollaborationDomain(updatedDomain))
    }

    // update domain if its opened
    if (store.getState().domain?.openDomain?.uuid === toUpdate.uuid) {
      store.dispatch(openDomain(updatedDomain))
    }
    return updatedDomain
  } catch (e) {
    AnalyticsService.logError('domain-update', { e })
    console.log(e)
  }
}

export async function DomainDataLoader(slug, clearState = true) {
  return new Promise(async (resolve, reject) => {
    try {
      //we should not clear redux store if loading data in background
      if (clearState) {
        // clear redux store
        store.dispatch(closeDomain())
        store.dispatch(setResources([]))
        store.dispatch(setNodes([]))
        store.dispatch(setRelations([]))
        // store.dispatch(resetFilters())
        store.dispatch(setResourceMappings([]))
        store.dispatch(setNodeLabels([]))
      }

      // open domain
      const DomainService = new Domain()

      const domain = await DomainService.getDomain(slug)
      const domainToken = await DomainService.openDomain(domain.uuid)
      store.dispatch(openDomain(domain))

      // @ts-ignore
      const decodedDomainToken = jwtDecode(domainToken.domainToken)

      store.dispatch(setOpenDomainPermissions(decodedDomainToken))

      const exportedData = await DomainService.exportDomainData()

      exportedData.resources.slice().sort((a, b) => {
        return b.updatedAt - a.updatedAt
      })
      store.dispatch(setResources(exportedData.resources))

      exportedData.nodes.slice().sort((a, b) => {
        return b.updatedAt - a.updatedAt
      })

      store.dispatch(setNodes(exportedData.nodes))
      store.dispatch(setRelations(exportedData.relations))

      // console.log('exported resource mappings', exportedData.resourceMappings)

      store.dispatch(setResourceMappings(exportedData.resourceMappings))

      // @deprecated
      // store.dispatch(setNodeLabels(exportedData.nodeLabels))
      await getAllNodeLabels()
      
      store.dispatch(setLastUpdate(Date.now()))
      resolve(true)
    } catch (e) {
      store.dispatch(setLoading(false))
      AnalyticsService.logError('domain-data-loading', { e, type: 'critical' })
      console.log(e)
      reject(e)
    }
  })
}

export async function getNodeEnrichment(domain: iDomainNew, text: string) {
  // Creating new node (very basic no metadata)
  const nodeService = new Node()
  const newNode = { name: text }
  // ask for suggestions (no created node required, only a query, which represents the node name)
  const suggestions = await nodeService.getSuggestions(newNode.name)
  return suggestions
}


export async function addRelationsLabel(nodes: iConnectedNode[], label: iLabel, domainUuid: string) {
  const _labelSvg = new Label()
  if (!label.id) {
    try {
      label = await _labelSvg.create({
        labelType: iLabelType.RELATION,
        name: label.name,
        domainUuid: domainUuid
      })
    } catch (e) {
      AnalyticsService.logError('create-relation-label', { e })
      return false;
    }
  }

  try {
    const RS = new Relation()
    for (let i = 0; i < nodes.length; i++) {
      if (!nodes[i].relation) continue
      const labelDirection = nodes[i].relation?.targetNode === nodes[i].id
        ? 'outgoingLabel'
        : 'incomingLabel'
      const updated = await RS.update({
        id: nodes[i].relation?.id,
        [labelDirection]: label.id
      })
      store.dispatch(updateRelation(updated))
    }
    store.dispatch(setLastUpdate(Date.now()))
    return true
  } catch (e) {
    AnalyticsService.logError('update-relation-label', { e })
    return false
  }
}

export const removeConnectedNode = async (node: iNode) => {
  const RS = new Relation()
  try {
    // @ts-ignore
    await RS.destroy(node.relation.id)
    // @ts-ignore
    store.dispatch(removeRelation(node.relation.id))
  } catch (error) {
    console.log('failed to remove relation')
  }
}

export const removeConnectedNodes = async (connectedNodeIds: number[], nodeId: number) => {
  const RS = new Relation()
  try {
    const state = store.getState()
    const connectedNodes = getConnectedNodesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: { id: nodeId } } })
    const relationIds = connectedNodes.filter(d => connectedNodeIds.includes(d.id)).map(d => d.relation?.id).filter(d => d)
    // @todo bulk relation delete
    for (let i = 0; i < relationIds.length; i++) {
      await RS.destroy(relationIds[i] as number)
    }
    store.dispatch(removeRelations(relationIds))
  } catch (e) {
    AnalyticsService.logError('remove-connected-nodes', { e })
  }
}

export const removeConnectedResource = async (resource: iResource) => {
  const RS = new Resource()
  try {
    // @ts-ignore
    await RS.removeResourceMapping(resource.resourceMappingId)
    store.dispatch(removeSingleMapping(resource.resourceMappingId))
  } catch (e) {
    console.log('failed to remove mapping')
  }
}

export const removeConnectedResources = async (connectedResourceIds: number[], nodeId) => {
  const RS = new Resource()
  try {

    const state = store.getState()
    const connected = getResourcesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: { id: nodeId } } })
    const ids = connected.filter(d => connectedResourceIds.includes(d.id)).map(d => d.resourceMappingId).filter(d => d)
    // @todo bulk resource mapping delete
    for (let i = 0; i < ids.length; i++) {
      await RS.removeResourceMapping(ids[i] as number)
    }
    store.dispatch(bulkRemoveResourceMappings(ids))
  } catch (e) {
    console.log('failed to remove mapping')
  }
}

export const deleteNode = async (node: iNode) => {
  try {
    const NS: Node = new Node()
    await NS.delete(node?.id!)
    store.dispatch(removeNode(node?.id))
    store.dispatch(removeAllRelationsConnectedToNode(node?.id))
  } catch (e) {
    AnalyticsService.logError('delete-node', { e })
  }
}

export const deleteDomain = async (domain: iDomainNew, onDone?: () => void) => {
  try {
    store.dispatch(setLoading(true))
    const DomainService = new Domain()
    await DomainService.openDomain(domain.uuid)
    await DomainService.deleteDomain(domain)
    store.dispatch(removeDomain(domain))
    onDone && onDone()
    store.dispatch(setLoading(false))
  } catch (e) {
    AnalyticsService.logError('delete-domain', { e })
    console.log(e)
    store.dispatch(setLoading(false))
  }
}

export const deleteNodes = async (nodeIds: number[]) => {
  try {
    const NS: Node = new Node()
    await NS.deleteMany(nodeIds)
    store.dispatch(removeNodes(nodeIds))
    store.dispatch(removeAllRelationsConnectedToNodes(nodeIds))
  } catch (e) {
    AnalyticsService.logError('delete-nodes', { e })
  }
}

export const clearDomainData = async () => {
  store.dispatch(closeDomain())
  store.dispatch(setNodes([]))
  store.dispatch(setResources([]))
  store.dispatch(setResourceMappings([]))
  store.dispatch(setRelations([]))
  store.dispatch(setNodeLabels([]))
  store.dispatch(setLabels([]))
  store.dispatch(setColumns([]))
  store.dispatch(setRows([]))
  store.dispatch(resetFilters())
}


export const deleteLabel = async (labelId: number, nodeIds?: number[]) => {
  try {
    const LS = new Label()
    await LS.delete(labelId)
    store.dispatch(removeLabel(labelId))
    store.dispatch(removeLabelFromNodes({ labelId, nodeIds }))
    store.dispatch(removeEntityLabel(labelId))
  } catch (e) {
    AnalyticsService.logError('entity-delete-label', { e })
  }
}

export const deleteLabelWithNodes = async (labelId: number, nodeIds: number[]) => {
  try {
    if (nodeIds.length > 0) await deleteNodes(nodeIds)
    await deleteLabel(labelId, nodeIds)
  } catch (e) {
    AnalyticsService.logError('entity-delete-label-with-nodes', { e })
  }
}


export async function createManyNodes(
  domain: iDomainNew,
  nodes: Partial<iNode>[]
) {
  try {
    const domainService = new Domain()
    await domainService.openDomain(domain.uuid)
    const ns = new Node()
    const result = await ns.createMany(nodes)
    const openDomain = store.getState().domain.openDomain
    if (openDomain?.uuid === domain.uuid) store.dispatch(addNodes(result))
    return result
  } catch (error) {
    console.log(error)
    return false
  }
}


export async function setDomainProperty(domain: iDomainNew, properties: { key: string, value: any, type: PropertyValueTypes }[]) {
  try {
    const newProps: Partial<iProperty>[] = []
    const ds = new Domain()
    await ds.openDomain(domain.uuid)

    for (let i = 0; i < properties.length; i++) {
      const prop = await ds.setProperty(domain, properties[i].key, properties[i].value, properties[i].type)
      if (typeof prop[properties[i].key] !== 'undefined') newProps.push(properties[i])
    }

    const updatedDomain = {
      ...domain,
      properties: [...domain.properties.filter(d => !newProps.map(d => d.key).includes(d.key)), ...newProps]
    }

    //update domain list
    const domains = store.getState().domain.domains?.map(d => {
      if (d.uuid === domain.uuid) return updatedDomain
      return d
    })
    store.dispatch(setDomains(domains))

    //update open domain
    const updatedOpenDomain = store.getState().domain.openDomain
    if (updatedOpenDomain?.uuid === domain.uuid) {
      store.dispatch(openDomain(domain))
    }

    return updatedDomain
  } catch (e) {
    AnalyticsService.logError('domain-set-property', { e })
    return domain
  }
}

export async function saveOnboardingData(domain: iDomainNew, ontologies: { ontologyId: number, selectedAt: Date }[]) {
  try {
    const ds = new Domain()
    await ds.openDomain(domain.uuid)
    const res = await ds.saveOnboardingData(ontologies)
    if (res?.status === 'ok') return true
    return false
  } catch (e) {
    AnalyticsService.logError('onboarding-save-data', { e })
    return false
  }
}

export async function applyResourceToNode(resourceId: number, nodeId: number) {
  try {
    const rm = store.getState().resourceMappings.resourceMappings
    const exist = rm.find(d => d.node === nodeId && d.resource === resourceId)
    if (exist) return exist
    const rs = new Resource()
    // @ts-ignore
    const result = await rs.createResourceMappings([{ node: nodeId, resource: resourceId }])
    store.dispatch(addResourceMappings(result))
    if (!result || !result.length) return null
    return result[0]
  } catch (e) {
    AnalyticsService.logError('resource-mappings-add', { e })
    return null
  }
}

export async function addWebLink(link: string, nodeId: number | undefined) {
  try {
    const RS = new Resource()
    const resource = await RS.create({
      title: link,
      type: getWeblinkType(link),
      info: '',
      url: link
    })
    if (!resource) return null
    store.dispatch(addResource(resource))
    const response = { resource }
    if (!nodeId) return response
    const rsMap = await applyResourceToNode(resource.id, nodeId)
    return { ...response, resourceMapping: rsMap }
  } catch (e) {
    AnalyticsService.logError('resource-add-web-link', { e })
    return null
  }
}

export async function addNewNode(props?: {
  connectTo?: number,
  labelIds?: number[]
}) {
  try {
    const NS = new Node()
    const node = await NS.create({ name: 'New item', contents: defaultEntityContents, color: Colors.random(), labels: props?.labelIds ?? [] })
    if (!node) return null
    if (props?.connectTo) {
      const RS = new Relation()
      const rel = await RS.create({ sourceNode: node.id, targetNode: props.connectTo })
      store.dispatch(addRelation(rel))
    }
    store.dispatch(addNode(node))
    return node
  } catch (e) {
    AnalyticsService.logError('node-add', { e })
    return null
  }
}

export async function connectToNodes(nodeIds: number[], nodeId: number) {
  try {
    const RS = new Relation()
    const state = store.getState()
    const connectedIds = getConnectedNodesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: { id: nodeId } } }).map(d => d.id)
    const relations = nodeIds.filter(d => !connectedIds.includes(d)).map(d => ({ sourceNode: nodeId, targetNode: d }))
    if (!relations?.length) return
    const result = await RS.createMany(relations)
    if (!result) return
    store.dispatch(addRelations(result))
  } catch (e) {
    AnalyticsService.logError('node-connect', { e })
  }
}

export async function connectToResources(resourceIds: number[], nodeId: number) {
  try {
    const RS = new Resource()
    const state = store.getState()
    const connectedIds = getResourcesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: { id: nodeId } } }).map(d => d.id)
    const rm: Partial<iResourceMapping>[] = resourceIds.filter(d => !connectedIds.includes(d)).map(d => ({ node: nodeId, resource: d }))
    if (!rm?.length) return
    // @ts-ignore
    const result = await RS.createResourceMappings(rm as Partial<iResourceMapping>[])
    if (!result) return
    store.dispatch(addResourceMappings(result))
  } catch (e) {
    AnalyticsService.logError('node-connect', { e })
  }
}
