import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Avatar } from '@common/components';
import { iNode } from "@gloow/apiconsumer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailList: {
      display: 'flex',
    },
    thumbnailItem: {
      width: 32,
      height: 32,
      padding: 10,
      borderRadius: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.info.main,
      color: 'white',
      objectFit: 'cover',
      fontWeight: 600
    },
    stacked: {
      marginLeft: -8
    }
  }),
);

interface INodeThumbnailList {
  nodes?: any,
  noMargin?: boolean
}

const NodeThumbnailList = ({
  nodes = [],
  noMargin = false
}: INodeThumbnailList) => {
  const classes = useStyles();

  if (!nodes.length) {
    return (
      <div className={classes.thumbnailList}>
        <div className={classes.thumbnailItem}>0</div>
      </div>
    )
  }

  return (
    <div className={classes.thumbnailList} style={{ marginLeft: noMargin ? 8 : 0, gap: noMargin ? 0 : 4 }}>
      {nodes.slice(0, 4).map((node: iNode, index) =>
        <div className={noMargin ? classes.stacked : ''} key={index.toString()}>
          <Avatar name={node.name} src={node.thumbnailUrl} color={node.color} textSize={'sm'} size={"32"} />
        </div>
      )}

      {nodes.length > 4 && (
        <div className={classes.thumbnailList}>
          <div className={classes.thumbnailItem} style={{ marginLeft: noMargin ? -8 : 0 }}>
            +{nodes.length - 4}
          </div>
        </div>
      )}
    </div>
  );
}

export default NodeThumbnailList;
