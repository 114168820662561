import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Colors from '@common/constants/Colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    labelContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 8,
      '&:hover': {
        backgroundColor: Colors.lightBlue2
      },
      borderRadius: 10,
      padding: 6,
    },
    addButton: {
      color: Colors.primary,
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      borderRadius: 10,
      '&:hover': {
        backgroundColor: Colors.mediumBlue
      },
    },
    addIcon: {
      fontSize: 18,
      marginRight: 4
    },
    suggestionPaper: {
      maxHeight: 400,
      borderRadius: 10,
      backgroundColor: Colors.white,
      display: 'flex',
      flexDirection: 'column',
      padding: '6px 0px',
      overflow: 'auto'
    },
    suggestionButton: {
      borderRadius: 0,
      justifyContent: 'flex-start',
      padding: '8px 16px',
      boxShadow: 'none !important',
      width: '100%',
      fontSize: 14
    },

    autoComplete: {
      position: 'relative',
      minWidth: 140,
      height: '100% !important',
      minHeight: 34,
      '& .MuiTextField-root': {
        height: '100% !important',
        borderRadius: 10,
        outline: 'none !important',
        border: 'none !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        outline: 'none !important',
        border: 'none !important',
      },
    },
    autoCompleteInput: {
      padding: '6px 12px !important',
      paddingRight: '35px !important',
      fontSize: 14,
      outline: 'none !important',
      border: 'none !important',
      color: Colors.white,
    },
    autoCompleteInputRoot: {
      borderRadius: 10,
      padding: '0 !important',
      height: '100%',
      outline: 'none !important',
      border: 'none !important',
    },
    autoCompleteEndAdornment: {
      display: 'none'
    },
    autoCompletePopper: {
      '& ul': {
        paddingTop: 0,
        paddingBottom: 0
      },
      '& li': {
        fontSize: 14,
        '&.MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: `${Colors.lightBlue2} !important`
        },
        '&.MuiAutocomplete-option[data-focus="true"]': {
          backgroundColor: `${Colors.lightBlue2} !important`
        },
        '&:active': {
          backgroundColor: `${Colors.lightBlue2} !important`
        },
        '&:hover': {
          backgroundColor: `${Colors.lightBlue2} !important`
        },
        '&:focus': {
          backgroundColor: `${Colors.lightBlue2} !important`
        },
        '&:visited': {
          backgroundColor: `${Colors.lightBlue2} !important`
        }
      }
    },
    autoCompleteNoOptions: {
      fontSize: 14,
      padding: '6px 12px !important'
    },
    autoCompleteClose: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      margin: 'auto',
      right: 12,
      width: 16,
      height: 16,
      color: Colors.white,
      backgroundColor: 'transparent !important'
    },
    autoCompleteCloseIcon: {
      padding: 2,
    }
  }),
);