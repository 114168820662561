import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  editIcon: {
    width: 30,
    height: 30
  },
  removeButton: {
    width: 24,
    height: 24,
    '& img': {
      width: 14,
      height: 14
    }
  },
  deleteIcon: {
    marginRight: 8
  },
  row: {
  },
  addIcon: {
    fontSize: 20
  },
  addButton: {
    color: Colors.primary,
    boxShadow: 'none !important',
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: '0px auto',
  },
  tableContainer: {
    '& .relation-picker': {
      margin: '0px !important'
    }
  },
  emptyImage: {
    margin: '0 auto',
    height: 300
  }
}))

