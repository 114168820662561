import { makeStyles } from '@material-ui/core';
import Colors from '@common/constants/Colors';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 60,
  },
  hideContainer: {
    height: 60
  },
  imageContainer: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 12,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: 'white',
    marginRight: 10,
    boxShadow: '0 6px 12px 0 rgba(67, 84, 221, 0.15)',
  },
  imageItem: {
    width: 40,
    height: 40,
    objectFit: 'cover',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    transition: 'all ease 0.3s'
  },
  hideContent: {
    opacity: 0,
    '& .description': {
      whiteSpace: 'nowrap'
    }
  },
  resourceName: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  resourceDesc: {
    color: theme.palette.text.primary,
    fontSize: 12
  },
  resourceDate: {
    color: theme.palette.text.secondary,
    fontSize: 12
  },
  stat: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  statItem: {
    marginRight: 10
  },
  statCount: {
    color: Colors.primary,
    marginRight: 3
  },
  selected: {
    boxShadow: `0 0 0 2px ${Colors.primary} !important`,
  },
  removeButton: {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: Colors.white
  },
  imageContainerFull: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    marginTop: 15,
    marginLeft: 50,

    '& img': {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
    }
  },
  video: {
    width: '100%',
    height: 200
  },
  iframe: {
    width: '100%',
    height: 150,
    borderRadius: 12
  },
}))