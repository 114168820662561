import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography } from '@material-ui/core'
import { DomainForm } from '@common/components'
import { iDomainNew } from "@gloow/apiconsumer"
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    dialogTitle: {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 24,
      width: '80%'
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      minWidth: 420,
      maxWidth: 420
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    }
  }),
);

export interface SimpleDialogProps {
  open: boolean
  onClose: () => void
  editing?: boolean
  domain?: iDomainNew
  onboarding?: boolean
  collaborationsDomain?: boolean
}

function DomainDialog(props: SimpleDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation()
  const { onClose, open, editing = false, domain, onboarding = false, collaborationsDomain = false } = props;
  const [editMode, setEditMode] = useState<boolean>(editing)

  const handleClose = () => {
    setEditMode(false)
    onClose()
  }

  const onDone = (data) => {
    if (!data) return onClose()
    if (!editMode) return setEditMode(true)
    return handleClose()
  }

  useEffect(() => {
    setEditMode(editing)
    return () => { }
  }, [editing, domain])

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3" color={onboarding ? 'primary' : undefined}>
          {!editMode ? (onboarding ? t('onboarding.create_your_first_domain') : t('common.new_domain')) : t('common.edit_domain')}
        </Typography>
        <CloseIcon className={classes.dialogBackIcon} onClick={handleClose} />
      </div>

      {onboarding && <p className="mb-4">{t('onboarding.name_your_domain')}</p>}

      <DomainForm domainData={domain} collaborationsDomain={collaborationsDomain} onDone={onDone} />
    </Dialog>
  );
}

export default DomainDialog;
