import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  list: {
    padding: 0,
    margin: '10px 0px'
  },
  menu: {
    width: 365,
    height: 365,
    padding: 0
  },
  button: {
    padding: '4px 10px',
    borderRadius: 12
  },
  chevron: {
    width: 24,
    height: 24,
    marginLeft: 4,
    color: Colors.primary
  },
  title: {
    color: Colors.primary,
    fontSize: 18,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  menuItem: {
    display: "block",
    padding: '12px 32px',
  },
  switchTo: {
    fontWeight: 600,
    padding: '4px 32px'
  },
  labels: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 4,
  },
  emptyDomain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200
  },
  emptyDomainImg: {
    width: 130
  }
}))