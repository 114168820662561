import { withStyles } from '@material-ui/core'
import { Switch as MuiSwitch } from '@material-ui/core';
import Colors from '@common/constants/Colors';

export const Switch = withStyles((theme) => ({
  root: {
    width: 20,
    height: 12,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 0,
    height: '12px !important',
    color: theme.palette.grey[600],
    transform: 'translateX(-2px)',
    '&$checked': {
      transform: 'translateX(6px) !important',
      color: theme.palette.grey[600],
      '& + $track': {
        opacity: 1,
        backgroundColor: Colors.primary,
        borderColor: Colors.primary,
      },
    },
  },
  thumb: {
    width: '9px !important',
    height: '9px !important',
    boxShadow: 'none',
    backgroundColor: 'white'
  },
  track: {
    border: 'none',
    borderRadius: 12,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(MuiSwitch);