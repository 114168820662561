import { makeStyles } from "@material-ui/core";

const headerHeight = 80
const sideBarWidth = 425
const sideBarWidthSM = 292

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  gridContainer: {
    overflow: 'auto'
  },
  boxShadowInsetLeft: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 32,
    backgroundColor: 'white',
    zIndex: 10,
    // boxShadow: `inset 0px 0px 10px -10px rgba(0,0,0, 0), inset -10px 0px 10px -10px rgba(0,0,0, 0.08)`,
  },
  boxShadowInsetRight: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: 32,
    backgroundColor: 'white',
    zIndex: 10,
    // boxShadow: `inset 0px 0px 10px -10px rgba(0,0,0, 0), inset -10px 0px 10px -10px rgba(0,0,0, 0.08)`,
  },
  gridView: { padding: '12px 24px' },
  tableView: {
    height: 'calc(100% - 74px)',
    overflow: 'hidden',
    padding: '0px 32px',
    position: 'relative'
  },
  title: {
    fontSize: 40,
    margin: '24px 32px',
    marginBottom: 0,
    fontWeight: 600
  },
  loader: {
    width: '90%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.8)',
    display: 'flex',
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  rightDrawer: {
    marginTop: headerHeight,
    paddingBottom: headerHeight,
    width: sideBarWidth,
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: sideBarWidthSM,
    },
    boxShadow: '0px 12px 12px rgba(0,0,0,0.1)',
    '& .resource-content-form': {
      overflow: 'auto',
      height: 'calc(100vh - 152px)'
    }
  }
}))