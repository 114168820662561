import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

const headerHeight = 80
const sideBarWidth = 425
const sideBarWidthSM = 292

export default makeStyles((theme) => ({
  container: {
    padding: 16,
    height: '100%',
    position: 'relative'
  },
  rightContainer: {
    overflow: "auto",
    height: '100%'
  },
  rightContainerHidden: {
    width: '0px !important'
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column'
  },
  labels: {
    margin: '12px 0px'
  },
  nodeButton: {
    boxShadow: 'none !important',
    justifyContent: 'flex-start'
  },
  editorToolbar: {
    borderRadius: '4px !important',
    border: 'none !important'
  },
  editor: {
    border: 'none !important',
    padding: '12px 0px'
  },
  actionContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    right: 16,
    top: 16
  },
  deleteIcon: {
    width: 16,
    height: 16,
    marginRight: 8
  },
  emptyNodesContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  updatedAtText: {
    color: Colors.darkGrey
  },
  rightDrawer: {
    marginTop: headerHeight,
    paddingBottom: headerHeight,
    width: sideBarWidth,
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: sideBarWidthSM,
    },
    boxShadow: '0px 12px 12px rgba(0,0,0,0.1)',
    '& .resource-content-form': {
      overflow: 'auto',
      height: 'calc(100vh - 152px)'
    }
  },
  rightContentContainer: {
    flex: 1,
    padding: 16,
  },
  subtitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600
  }
}))