import React from 'react'
import { iOntology } from "@gloow/apiconsumer"
import useStyles from './OnboardingV2.styles'
import { NodePicker } from '@common/components'
function CreateNode({
  title = '',
  description = '',
  member,
  selectedNodes,
  onSelected = (node) => { },
  onDeleted = (node) => { }
}: {
  title: string,
  description: string,
  member: iOntology | null,
  selectedNodes
  onSelected: (node) => void
  onDeleted: (node) => void
}) {
  const classes = useStyles()
  return (
    <div>
      {member?.name}
      <div className={classes.questions}>{title}</div>
      <p className="mb-4">{description}</p>

      <NodePicker
        selectedNodes={selectedNodes}
        onDeleted={node => onDeleted(node)}
        onSelected={node => onSelected(node)}
      />
    </div>
  )
}

export default CreateNode
