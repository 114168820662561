import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { TextField, Button, IconButton, Typography } from '@material-ui/core'
import { ArrowForwardIos, ArrowBackIos, Add } from '@material-ui/icons'
import { AvatarField, NodeThumbnailList, ResourceThumbnailList, ResourceList, NodeList, ColorList, Labels, ConfirmDialog, Avatar, NodeContent } from '@common/components'
import Colors from '@common/constants/Colors'
import deleteIcon from '@assets/images/delete.svg'
import SwipeableViews from 'react-swipeable-views'
import useStyles from './NodeInfoForm.styles'
import { useAppDispatch } from '@store/hooks'
import * as Yup from 'yup'
import { updateNode, removeNode, addNode } from '@store/nodes/nodesSlice'
import { FractalVisualisation, VertexType } from '@gloow/navimator'
import { Node, iNode, iDomainNew, iResource, iLabel, Relation, iRelation, iLabelType } from "@gloow/apiconsumer"
import AnalyticsService from '@services/AnalyticsService'
import _ from 'lodash'
// import { iNodeSuggestion } from "@gloow/apiconsumer"
import { debounce } from 'lodash'
import { uploadFile } from '@helpers/media'
import {
  addRelationsLabel,
  // getNodeEnrichment,
  removeConnectedNode, removeConnectedResource
} from '@services/DomainDataService'
// import NodeEnrichment from './NodeEnrichment/NodeEnrichment'
// import NodeEnrichmentList from './NodeEnrichment/NodeEnrichmentList'
// import { addResource } from '@store/resources/resourcesSlice'
// import { addResourceMapping } from '@store/resourceMappings/resourceMappingsSlice'
// import { addNodeLabel } from '@store/labels/labelsSlice'
import { addExistingItemTypes, OnboardingEvents, contentType, iInteractionDialog, defaultEntityContents } from '@common/constants/Constants'
import { completeOnboardingEvent } from '@store/user/userSlice'
import CloseIcon from '@material-ui/icons/Close'
import { addRelation, removeAllRelationsConnectedToNode } from '@store/relations/relationsSlice'
import { nodeInfoTabTypes } from '@common/constants/Constants'
// import QuillEditor from '@common/components/QuillEditor/QuillEditor'
import { setLastUpdate } from '@store/domain/domainSlice'
import { useHistory } from 'react-router'
import { generateDetailURL } from '@helpers/utils'
import { iActiveVertex, iDomainPermissions } from '@store/domain/domain.interfaces'
import { useTranslation } from 'react-i18next'
import { isOntology } from '@helpers/labels'
import { applyOntologiesToNodes } from '@services/LabelService'
import { useParams } from 'react-router-dom'
import { RouteURI } from '@common/constants/Routes'

interface iNodeInfoForm {
  visualisation?: FractalVisualisation | null,
  creatingMode?: boolean,
  creatingFromDialog?: boolean,
  activeNode?: iNode,
  connectTo?: iActiveVertex,
  domainPermissions: iDomainPermissions | undefined,
  openDomain: iDomainNew | undefined,
  connectedNodes?: iNode[],
  connectedResources?: iResource[],
  nodeLabels?: iLabel[]
  handleClose?: (nodeId?: string | number, updateCentralNode?: boolean) => void,
  setNodeMenu?: (props: iInteractionDialog) => void,
  relations?: iRelation[],
  nodeDetailView?: boolean,
  autoSave?: boolean
}

const AutoSave = () => {
  // eslint-disable-next-line
  const { values, submitForm, dirty, isSubmitting, initialValues } = useFormikContext()
  // eslint-disable-next-line
  const autoSaveCb = useCallback(
    debounce(() => {
      console.log('autosave')
      submitForm()
    }, 2000),
    // eslint-disable-next-line
    // @ts-ignore
    [],
  )

  useEffect(() => {
    if (dirty) autoSaveCb()
    return () => { }
    // @ts-ignore
    // eslint-disable-next-line
  }, [values?.name, values?.thumbnail, values?.color])

  return null;
}

// @deprecated
const NodeInfoForm = ({
  visualisation = undefined,
  activeNode = undefined,
  connectTo = undefined,
  domainPermissions = undefined,
  creatingMode = false,
  creatingFromDialog = false,
  openDomain = undefined,
  connectedNodes = [],
  connectedResources = [],
  nodeLabels = [],
  handleClose = () => undefined,
  setNodeMenu = (props: iInteractionDialog) => { },
  relations = [],
  nodeDetailView = false,
  autoSave = false
}: iNodeInfoForm) => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const { slug } = useParams<{ slug?: string }>()
  const [deleteState, setDeleteState] = useState<{ deleteDialog: boolean, data: iNode | iResource | undefined }>({
    deleteDialog: false,
    data: undefined
  })

  const NodeFormSchema = Yup.object().shape({
    name: Yup.string().required(t('common.name_is_required')),
    labels: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string()
      })
    ),
    thumbnail: Yup.object()
      .shape({
        name: Yup.string(),
        type: Yup.string(),
        uri: Yup.string()
      })
      .nullable(),
    contents: Yup.array(),
    color: Yup.string()
  })

  const headerTitle = {
    [nodeInfoTabTypes.NODE_INFO]: t('common.node_info'),
    [nodeInfoTabTypes.COLOR_PICKER]: t('common.color'),
    [nodeInfoTabTypes.CONNECTED_NODES]: t('common.connected_nodes'),
    [nodeInfoTabTypes.CONNECTED_RESOURCES]: t('common.connected_resources'),
    [nodeInfoTabTypes.NODE_ENRICHMENT]: t('common.suggestions'),
  }

  // @ts-ignore
  const tab: nodeInfoTabTypes = new URLSearchParams(history.location.search).get('tab') ?? nodeInfoTabTypes.NODE_INFO
  const ref = new URLSearchParams(history.location.search).get('ref') ?? ''
  const [tabState, setTabState] = useState<nodeInfoTabTypes>(creatingMode ? nodeInfoTabTypes.NODE_INFO : tab)

  const [connectToNode, setConnectToNode] = useState<iNode | undefined>()
  // const [suggestions, setSuggestions] = useState<iNodeSuggestion[]>([])
  // const [selectedSuggestion, setSelectedSuggestion] = useState<iNodeSuggestion | undefined>(undefined)
  // const [suggestionsLoading, setSuggestionsLoading] = useState<boolean>(false)
  // eslint-disable-next-line
  // @todo change with new suggestion
  // const suggestionCallback = useCallback(
  //   debounce(text => {
  //     if (text.length >= 4) getSuggestions(text)
  //   }, 1000),
  //   []
  // )


  const initialValues = {
    name: activeNode?.name ?? '',
    labels: activeNode?.labels ?? [],
    contents: activeNode?.contents && Array.isArray(activeNode?.contents) ? activeNode?.contents : defaultEntityContents,
    thumbnail: activeNode?.thumbnailUrl ? { uri: activeNode?.thumbnailUrl } : null,
    color: activeNode?.color ?? Colors.default()
  }

  const form = useRef<any>(null)

  useEffect(() => {
    setConnectToNode(connectTo?.type === VertexType.Node ? connectTo?.data as iNode : undefined)
    if (connectTo?.type === VertexType.Label) {
      form.current.setFieldValue('labels', [...form.current.values.labels, connectTo?.data])
    }
    return () => { }
  }, [connectTo])

  useEffect(() => {
    // ignore query param if nodes opened from dialog
    if (tab && !creatingFromDialog) setTabState(tab)
    return () => { }
  }, [tab, creatingFromDialog])

  // const addNewLabel = (label: iLabel) => {
  //   dispatch(addNodeLabel(label))
  //   const event = label.isNodeLabel
  //     ? OnboardingEvents.CreateNodeLabel
  //     : OnboardingEvents.CreateDomainLabel
  //   dispatch(completeOnboardingEvent(event))
  // }

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files.length > 0) {
      form.current.setFieldValue('thumbnail', {
        uri: URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0]
      })
    }
  };

  const onRemovePicture = () => {
    form.current.setFieldValue('thumbnail', null)
  }

  const onSelectLabel = (label) => {
    const currentLabels = form.current.values.labels.slice()
    currentLabels.push(label)
    form.current.setFieldValue('labels', currentLabels)
    if (!creatingMode) return form.current.handleSubmit()
  }

  const onDeleteLabel = (label) => {
    const currentLabels = form.current.values.labels
    form.current.setFieldValue('labels', currentLabels.filter(d => d.id !== label.id))
    if (!creatingMode) return form.current.handleSubmit()
  }

  const onSubmit = async ({ name, contents, labels, thumbnail, color }) => {
    try {
      setLoading(true)
      const nodeService = new Node()
      let thumbnailUrl = thumbnail?.uri ?? null
      if (thumbnail && thumbnail?.file) {
        const uploadedFile = await uploadFile(thumbnail.file)
        if (uploadedFile) thumbnailUrl = uploadedFile
      }

      const newNodeObject = {
        ...activeNode,
        name,
        contents,
        thumbnailUrl,
        labels: labels.filter(d => !isOntology(d)).map(l => l.id),
        color: color
      }

      let newNode: iNode
      // @todo refactor suggestion
      // if (selectedSuggestion) {
      //   const newNodeWithSuggestion = await nodeService.createNodeWithSuggestion(
      //     newNodeObject
      //   )
      //   labels?.map(label => {
      //     const exist = nodeLabels.find(({ id }) => id === label.id)
      //     if (!exist) addNewLabel(label)
      //     return label
      //   })
      //   newNode = {
      //     ...newNodeObject,
      //     ...newNodeWithSuggestion.createdNode,
      //     labels: labels.filter(d => !isOntology(d))
      //   }
      //   if (newNodeWithSuggestion.createdResource)
      //     dispatch(addResource(newNodeWithSuggestion.createdResource))
      //   if (newNodeWithSuggestion.createdResourceMapping)
      //     dispatch(addResourceMapping(newNodeWithSuggestion.createdResourceMapping))
      // } else {
      newNode = await nodeService.create(newNodeObject)
      // }
      // setSelectedSuggestion(undefined)
      dispatch(addNode(newNode))

      // check ontology
      const ontologies = labels.filter(d => isOntology(d))
      if (ontologies?.length > 0) {
        const ontologyLabels = await applyOntologiesToNodes([newNode.id], ontologies)
        if (ontologyLabels?.length > 0) {
          newNode = {
            ...newNode,
            labels: [...newNode.labels as iLabel[], ...ontologyLabels as iLabel[]]
          }
          updateNode(newNode)
        }
      }

      dispatch(completeOnboardingEvent(OnboardingEvents.CreateNode))
      if (connectToNode?.id) {
        const relationService = new Relation()
        const newRelation = await relationService.create({
          sourceNode: connectToNode?.id,
          targetNode: newNode?.id
        })
        dispatch(addRelation(newRelation))
        dispatch(completeOnboardingEvent(OnboardingEvents.ConnectNode))
      }
      // setSuggestions([])
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
      let ignoreRecenter = false
      if (
        (connectTo?.type === VertexType.Node && connectToNode?.id === connectTo?.data?.id) ||
        (connectTo?.type === VertexType.Label && labels.find(d => d.id === connectTo?.data?.id))
      ) {
        ignoreRecenter = true
      }
      handleClose(newNode?.id, ignoreRecenter)
    } catch (e) {
      AnalyticsService.logError('connect-to-node', { e })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const onUpdate = async ({ name, contents, labels, thumbnail, color }) => {
    try {
      setLoading(true)
      const NS = new Node()
      let data = {
        id: activeNode?.id,
        name: name,
        contents,
        color: color,
        labels: labels.filter(d => !isOntology(d)).map(l => l.id),
        thumbnailUrl: thumbnail?.uri ?? null
      }

      if (thumbnail && thumbnail?.file) {
        const uploadedFile = await uploadFile(thumbnail.file)
        // @ts-ignore
        if (uploadedFile) data.thumbnailUrl = uploadedFile
      }

      let updatedNode = await NS.update(data)

      // check ontologies
      const ontologies = labels.filter(d => isOntology(d))
      if (ontologies.length > 0) {
        const ontologyLabels = await applyOntologiesToNodes([updatedNode.id], ontologies)
        if (ontologyLabels?.length)
          updatedNode = {
            ...updatedNode,
            labels: [
              ...updatedNode.labels as iLabel[],
              ...ontologyLabels as iLabel[]
            ]
          }
      }

      dispatch(updateNode(updatedNode))
      // setSuggestions([])
      // setSelectedSuggestion(undefined)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      AnalyticsService.logError('update-node', { e })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const onDelete = async () => {
    try {
      setLoading(true)
      const NS: Node = new Node()
      await NS.delete(activeNode?.id!)
      dispatch(removeNode(activeNode?.id))
      dispatch(removeAllRelationsConnectedToNode(activeNode?.id))
      // setSelectedSuggestion(undefined)
      // setSuggestions([])
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('delete-node', { e })
    }
  }

  // const getSuggestions = async (string) => {
  //   try {
  //     if (openDomain?.enableSuggestions && openDomain?.language) {
  //       setSuggestionsLoading(true)
  //       // const suggestions = await getNodeEnrichment(openDomain, string)
  //       // @todo change to new suggestion
  //       // setSuggestions(suggestions)
  //       setSuggestionsLoading(false)
  //     } else {
  //       if (suggestions.length > 0) setSuggestions([])
  //     }
  //   } catch (error) {
  //     if (suggestions.length > 0) setSuggestions([])
  //     if (suggestionsLoading) setSuggestionsLoading(false)
  //   }
  // }

  const onBack = () => {
    if ([nodeInfoTabTypes.NODE_ENRICHMENT, nodeInfoTabTypes.COLOR_PICKER].includes(tabState)) {
      // don't use query param when navigating node enrichment and color picker back and forth 
      return setTabState(nodeInfoTabTypes.NODE_INFO)
    }
    // if user is not on the node info tab, we replace the route
    // because when user start from blank browser then directly visit connected nodes e.g http://localhost:3000/22e07581-d0ce-4e00-a22d-ab23b68ddafc/mind-maps?id=33760&type=NODE&tab=CONNECTED_NODES we can't use history.goBack() since it will go to blank instead going back to node info
    if (tabState !== nodeInfoTabTypes.NODE_INFO) {
      return history.replace(generateDetailURL(history.location.pathname, {
        id: activeNode?.id!,
        type: contentType.NODE,
        tab: nodeInfoTabTypes.NODE_INFO,
        ref
      }))
    }
    return history.goBack()
  }

  const renderHeader = (hasChanges: boolean) => {
    return <div className={classes.dialogTitleWrapper}>
      {/* @ts-ignore */}
      {(tabState !== nodeInfoTabTypes.NODE_INFO || (ref.length > 0 && !creatingFromDialog)) &&
        <IconButton id="back" onClick={() => onBack()} className={classes.dialogBackArrow}>
          <ArrowBackIos className={classes.arrowBackIcon} />
        </IconButton>
      }
      <Typography className={classes.dialogTitle} variant="h3">{headerTitle[tabState]}</Typography>
      {creatingFromDialog && <CloseIcon id="close" className={classes.dialogBackIcon} onClick={() => handleClose()} />}
      {!creatingFromDialog && tabState === nodeInfoTabTypes.NODE_INFO && domainPermissions?.modify &&
        <Button id="submit" disabled={loading} className={!creatingMode && !hasChanges ? 'invisible' : classes.submit} variant={'contained'} color="primary" type="submit">
          {creatingMode ? t('common.add') : t('common.save')}
        </Button>
      }
      {!creatingFromDialog &&
        !hasChanges &&
        tabState === nodeInfoTabTypes.NODE_INFO &&
        activeNode?.id &&
        !history.location.pathname.includes(RouteURI.FOCUS_MODE) &&
        (
          <Button onClick={() => history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${activeNode?.id}`)} className={'link-button'}>
            <Typography variant='body2'>{t('common.full_screen_view', 'Full screen view')}</Typography>
          </Button>
        )}
    </div>
  }
  // @todo change to new suggestion
  // const onEnrichmentSelected = async (selected: iNodeSuggestion) => {
  //   try {
  //     let newNode: iNode
  //     setLoading(true)
  //     if (creatingMode) {
  //       setSelectedSuggestion(selected)
  //       //create node flow: update the form then user press add button manually
  //       const nodeService = new Node()
  //       const seedResult = await nodeService.createSeed(selected)
  //       newNode = {
  //         ...form?.current.values,
  //         ...seedResult.nodeSeed,
  //         labels: seedResult.labels
  //       }
  //     } else {
  //       //update node flow: update node directly
  //       const nodeService = new Node()
  //       const appliedSuggestions = await nodeService.applySuggestion(activeNode?.id!, selected)
  //       appliedSuggestions.newLabels?.map(label => {
  //         const exist = nodeLabels.find(({ id }) => id === label.id)
  //         if (!exist) addNewLabel(label)
  //         return label
  //       })
  //       newNode = {
  //         ...node,
  //         ...appliedSuggestions.updatedNode,
  //         labels: appliedSuggestions.newLabels,
  //         color: form?.current?.values?.color
  //       }
  //       dispatch(updateNode(newNode))
  //       if (appliedSuggestions.createdResource) {
  //         dispatch(addResource(appliedSuggestions.createdResource))
  //         dispatch(completeOnboardingEvent(OnboardingEvents.CreateResource))
  //       }
  //       if (appliedSuggestions.createdResourceMapping) {
  //         dispatch(addResourceMapping(appliedSuggestions.createdResourceMapping))
  //         dispatch(completeOnboardingEvent(OnboardingEvents.ConnectResource))
  //       }
  //       setSelectedSuggestion(undefined)
  //       dispatch(setLastUpdate(Date.now()))
  //     }
  //     setNode(newNode)
  //     setSuggestions([])
  //     setLoading(false)
  //   } catch (error) {
  //     setSuggestions([])
  //     setSelectedSuggestion(undefined)
  //     setNode({
  //       ...node!,
  //       // @todo change to
  //       // name: selected.result?.name,
  //       // info: selected.result?.description,
  //       // color: form?.current.values.color
  //     })
  //     setLoading(false)
  //   }
  // }

  /**
   * Update relation label
   * 
   * @param nodes <iNode[]>
   * @param label <iLabel | string>
   * @returns <void>
   */
  const _onRelationLabelAdded = async (nodes: iNode[], label: iLabel) => {
    if (!openDomain) return
    await addRelationsLabel(nodes, label, openDomain.uuid)
  }

  const renderSecondaryTab = () => {
    return <div className={`${classes.tabContentContainer}`} >
      {tabState === nodeInfoTabTypes.COLOR_PICKER && (
        <ColorList selectedValue={form?.current.values.color} onSelected={(value: string) => {
          form?.current.setFieldValue('color', value)
          setTabState(nodeInfoTabTypes.NODE_INFO)
        }} />
      )}
      {tabState === nodeInfoTabTypes.CONNECTED_NODES && (
        <NodeList
          activeNode={activeNode}
          nodes={connectedNodes}
          relations={relations}
          hideStat={true}
          showRelations={true}
          relationReadOnly={!domainPermissions?.modify}
          onItemClicked={(item) =>
            history.push(generateDetailURL(history.location.pathname, {
              id: item.id.toString(),
              type: contentType.NODE,
              tab: nodeInfoTabTypes.NODE_INFO,
              ref: activeNode?.id.toString()
            }))
          }
          onItemRemove={domainPermissions?.modify
            ? (item) => setDeleteState({ deleteDialog: true, data: item })
            : undefined
          }
          onRelationLabelAdded={_onRelationLabelAdded}
        />
      )}
      {tabState === nodeInfoTabTypes.CONNECTED_RESOURCES && (
        <ResourceList
          resources={connectedResources}
          onItemClicked={(item) =>
            history.push(generateDetailURL(history.location.pathname, {
              id: item.id.toString(),
              type: contentType.RESOURCE,
              ref: activeNode?.id.toString()
            }))
          }
          onItemRemove={domainPermissions?.modify ? (item) => setDeleteState({ deleteDialog: true, data: item }) : undefined}
        />
      )}
      {/* @todo change to new suggestion */}
      {/* {tabState === nodeInfoTabTypes.NODE_ENRICHMENT && (
        <NodeEnrichmentList suggestions={suggestions} onSelected={(selected) => {
          onEnrichmentSelected(selected)
          setTabState(nodeInfoTabTypes.NODE_INFO)
        }} />
      )} */}
      {domainPermissions?.modify && [nodeInfoTabTypes.CONNECTED_NODES, nodeInfoTabTypes.CONNECTED_RESOURCES].includes(tabState) &&
        <div className={classes.addRelationButton}>
          <Button
            id={`add-${tabState}`}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setNodeMenu({
              open: true,
              tab: tabState === nodeInfoTabTypes.CONNECTED_NODES ? addExistingItemTypes.NODES : addExistingItemTypes.RESOURCES,
              connectTo: {
                type: VertexType.Node,
                data: activeNode,
              },
              specificMenu: true
            })}
          >
            {tabState === nodeInfoTabTypes.CONNECTED_NODES ? t('common.connect_nodes') : t('common.add_resources')}
          </Button>
        </div>
      }
    </div >
  }

  const onRemoveItem = async () => {
    setDeleteState({ ...deleteState, deleteDialog: false })
    if (tabState === nodeInfoTabTypes.CONNECTED_NODES) {
      if (deleteState.data) await removeConnectedNode(deleteState.data as iNode)
    }
    else if (tabState === nodeInfoTabTypes.CONNECTED_RESOURCES) {
      if (deleteState.data) await removeConnectedResource(deleteState.data as iResource)
    }
    else if (tabState === nodeInfoTabTypes.NODE_INFO) {
      await onDelete()
    }
    dispatch(setLastUpdate(Date.now()))
    setDeleteState({ deleteDialog: false, data: undefined })
  }

  const getDeleteDesc = () => {
    switch (tabState) {
      case nodeInfoTabTypes.CONNECTED_RESOURCES:
      case nodeInfoTabTypes.CONNECTED_NODES:
        return t('common.are_you_sure_want_to_delete_this_connection', 'Are you sure want to delete this connection?')
      default:
        return t('common.are_you_sure_want_to_delete_this_node')
    }
  }

  return (
    <Formik
      innerRef={form}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={creatingMode ? onSubmit : onUpdate}
      enableReinitialize
      validationSchema={NodeFormSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        const hasChanges = !_.isEqual(values, initialValues)
        return <Form onSubmit={handleSubmit} className='node-info-form'>
          {autoSave && <AutoSave />}
          {!nodeDetailView && renderHeader(hasChanges)}
          <SwipeableViews index={tabState === nodeInfoTabTypes.NODE_INFO ? 0 : 1} disabled={true} enableMouseEvents >
            <div className={`${!creatingFromDialog ? classes.tab : ""} ${nodeDetailView ? classes.nodeDetailContainer : ''} node-info-tab`}>
              <div className={classes.form}>
                {domainPermissions?.modify ?
                  <>
                    {connectToNode &&
                      <div className={classes.connectingContainer}>
                        <div className={classes.connectingTitle}>{t('common.connecting_to')}</div>
                        <div className={classes.connectingNode}>
                          <Avatar name={connectToNode.name} src={connectToNode.thumbnailUrl} size={"24"} textSize={'sm'} color={connectToNode.color} />
                          <span className={classes.connectingNodeName}>{connectToNode.name}</span>
                        </div>
                      </div>
                    }
                    <div className={`${classes.nameWrapper} mb-6 mx-6`}>
                      <AvatarField
                        onChangeColor={() => setTabState(nodeInfoTabTypes.COLOR_PICKER)}
                        onChangeFiles={onChangeFiles}
                        editable={Boolean(domainPermissions?.modify) && !loading}
                        url={values.thumbnail?.uri}
                        onRemove={onRemovePicture}
                        color={values.color}
                      />
                      <TextField
                        id="node-name"
                        disabled={loading}
                        label={t('common.name')}
                        multiline
                        fullWidth value={values.name}
                        className={classes.name}
                        name='name'
                        onChange={(e) => {
                          setFieldValue('name', e.target.value)
                          // if (e.target.value.length >= 4) suggestionCallback(e.target.value)
                        }}
                        error={Boolean(errors?.name)}
                        helperText={errors?.name?.toString()} />
                    </div>
                    {/* @todo change to new suggestion */}
                    {/* {openDomain?.enableSuggestions && openDomain?.language &&
                      <NodeEnrichment
                        loading={suggestionsLoading || (loading && suggestions?.length > 0)}
                        bestSuggestion={suggestions?.length ? suggestions[0] : undefined}
                        onSelected={onEnrichmentSelected}
                        onSeeMore={suggestions.length > 0 ? () => setTabState(nodeInfoTabTypes.NODE_ENRICHMENT) : undefined}
                      />
                    } */}
                    <div className='mx-6'>
                    </div>
                  </>
                  :
                  <div className='mx-6'>
                    <div className={`${classes.nameWrapper} mb-6`}>
                      <div>
                        <div
                          className={classes.nodeColorWrap}
                          style={{
                            backgroundColor:
                              Colors.newColors[values.color]
                                ? Colors.newColors[values.color]
                                : values.color
                          }}
                        >
                        </div>
                      </div>
                      <div>
                        <div className="mb-1 mt-1">
                          <label>{t('common.name')}</label>
                        </div>
                        <Typography variant={'h4'} className={classes.name}>{values.name}</Typography>
                      </div>
                    </div>
                  </div>
                }

                <div className="mx-6">
                  <div className="mt-6 mb-2">
                    <label>{t('common.labels')}</label>
                  </div>
                  <Labels
                    labelType={iLabelType.NODE}
                    disabled={loading}
                    editable={domainPermissions?.modify!}
                    labels={values.labels as iLabel[]}
                    domainUuid={openDomain?.uuid!}
                    nodeId={activeNode?.id!}
                    onSelected={onSelectLabel}
                    onDeleted={onDeleteLabel}
                  />
                  <div className='mt-6 mb-2'>
                    <label>{t('common.description')}</label>
                  </div>

                  <NodeContent
                    id={`contents-${activeNode?.id}`}
                    initialValue={values.contents}
                    autoSaveDebounceMs={3000}
                    nodeId={activeNode?.id}
                    onChange={(value) => setFieldValue('contents', value)}
                    readOnly={!domainPermissions?.modify}
                  />

                  {creatingFromDialog && domainPermissions?.modify && (
                    <div className="flex justify-between mt-8">
                      <Button id="node-cancel" disabled={loading} className={classes.cancelButton} variant="contained" color="secondary" fullWidth onClick={() => handleClose()}>{t('common.cancel')}</Button>
                      <Button id="node-submit" disabled={loading} fullWidth variant="contained" color="primary" type="submit">{t('common.save')}</Button>
                    </div>
                  )}

                  {!creatingMode && !nodeDetailView &&
                    <>
                      <div className='connected-nodes'>
                        <div className="mt-6 mb-2">
                          <label>{t('common.connected_nodes')}</label>
                        </div>
                        <Button disabled={loading} id="node-connected-nodes" className={classes.relationButton} onClick={() => {
                          history.replace(generateDetailURL(history.location.pathname, {
                            id: activeNode?.id.toString()!,
                            type: contentType.NODE,
                            tab: nodeInfoTabTypes.CONNECTED_NODES,
                            ref
                          }))
                        }}>
                          <NodeThumbnailList nodes={connectedNodes} />
                          <ArrowForwardIos className={classes.arrowIcon} />
                        </Button>
                      </div>

                      <div className='connected-resources'>
                        <div className="mt-6 mb-2">
                          <label>{t('common.connected_resources')}</label>
                        </div>

                        <Button disabled={loading} id="node-resources" className={classes.relationButton} onClick={() =>
                          history.replace(generateDetailURL(history.location.pathname, {
                            id: activeNode?.id.toString()!,
                            type: contentType.NODE,
                            tab: nodeInfoTabTypes.CONNECTED_RESOURCES,
                            ref
                          }))
                        }>
                          <ResourceThumbnailList resources={connectedResources} />
                          <ArrowForwardIos className={classes.arrowIcon} />
                        </Button>
                      </div>

                      {domainPermissions?.remove && !nodeDetailView &&
                        <div className="mt-6 mb-2 delete-node">
                          <Button id="node-delete" disabled={loading} classes={{ label: classes.deleteNode, root: classes.deleteButton }} onClick={() => setDeleteState({ ...deleteState, deleteDialog: true })}>
                            <img src={deleteIcon} className={classes.deleteIcon} alt='delete' />
                            <Typography variant='body2'>{t('common.delete_node')}</Typography>
                          </Button>
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
            <div className={`${!creatingFromDialog ? `${classes.tab} ${[nodeInfoTabTypes.CONNECTED_NODES, nodeInfoTabTypes.CONNECTED_RESOURCES].includes(tabState) && domainPermissions?.modify ? classes.bottomSpacer : ''}` : ''} node-info-tab`}>
              {renderSecondaryTab()}
            </div>
          </SwipeableViews>

          <ConfirmDialog
            open={deleteState.deleteDialog}
            onClose={() => setDeleteState({ deleteDialog: false, data: undefined })}
            onConfirm={async () => {
              await onRemoveItem()
            }}
            text={getDeleteDesc()}
            confirmText={t('common.delete')}
            cancelText={t('common.cancel')}
          />
        </Form>
      }}
    </Formik >
  );
}

export default NodeInfoForm

