import React, { ReactElement } from 'react';
import moment from 'moment';
import { ButtonBase, IconButton, Typography } from '@material-ui/core';
import { iResource, eResourceType } from "@gloow/apiconsumer";
import useStyles from './ResourceButton.styles'
import deleteIcon from '@assets/images/icons-unlink.svg'
import { getResourceInfo, getResourceThumbnail, getResourceIcon } from '@helpers/resource'
import getVideoId from 'get-video-id'
import { useTranslation } from 'react-i18next'
import EmptyResourceIcon from '@assets/images/empty-resource.png'
interface IResourceButton {
  resource: iResource,
  size?: 'small' | 'medium'
  onClick?: (resource: iResource) => void,
  onRemove?: (resource: iResource) => void,
  selected?: boolean
  disabled?: boolean
}

export const ResourceButton = ({
  resource,
  size = 'medium',
  onClick = (resource) => { },
  onRemove = undefined,
  disabled = false
}: IResourceButton) => {
  const classes = useStyles()
  const info = getResourceInfo(resource)
  const { t, i18n } = useTranslation()
  const isHasThumbnail = getResourceThumbnail(resource, false)


  const renderContent = () => {
    let item: ReactElement | JSX.Element | undefined = undefined
    if ([eResourceType.YOUTUBE, eResourceType.VIMEO].indexOf(resource?.type!) > -1) {
      const video = getVideoId(resource?.url!)
      const iframeURL = video.service === 'youtube' ? 'https://youtube.com/embed' : 'https://player.vimeo.com/video'
      item = <iframe
        className={classes.iframe}
        src={`${iframeURL}/${video?.id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={resource?.title}
        width="200"
        height="200"
      />
    }
    else if (resource?.type === eResourceType.VIDEO_FILE) {
      item = <video className={classes.iframe} controls>
        <source src={resource?.url} type={resource?.mimeType} />
      </video>
    }
    else if (isHasThumbnail) item = <img src={getResourceThumbnail(resource)} alt={resource.title} />
    if (item) return <div className={`${classes.imageContainer} ${classes.imageContainerFull}`}>{item}</div>
    return <></>
  }

  const renderRemove = () => {
    if (!onRemove) return <></>
    return <IconButton size='small' id="remove-resource" className={classes.removeButton} onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      onRemove(resource)
    }}>
      <img className={classes.removeIcon} src={deleteIcon} alt={t('common.delete')} />
    </IconButton>
  }

  const renderMediumContent = () => {
    return <>
      <div className={classes.contentHeader}>
        <div className={classes.imageContainer}>
          <img
            className={classes.imageItem}
            src={isHasThumbnail ? getResourceIcon(resource?.type) : getResourceThumbnail(resource)}
            alt={resource.title}
          />
        </div>
        <div className={`${classes.textWrapper} textWrapper`} style={{ width: onRemove ? '70%' : 'auto' }}>
          <Typography className={classes.resourceName} variant='body1'>
            {resource.metaData?.title?.length > 0
              ? resource.metaData?.title
              : resource.title ?? t('common.picture_without_description')}
          </Typography>
          <Typography className={`${classes.resourceDesc} description`} variant='body2'>
            {info.length > 60 ? info.slice(0, 60) + '...' : info}
          </Typography>
          <div className={classes.resourceDate}>{moment(resource.updatedAt).locale(i18n.language).fromNow()}</div>
        </div>
        {renderRemove()}
      </div>
      {renderContent()}
    </>
  }

  const renderSmallContent = () => {
    return <>
      <div className={classes.contentHeaderSmall}>
        <div className={classes.textWrapperSmall}>
          <Typography className={classes.titleSmall} noWrap variant='body2'>
            {resource.metaData?.title?.length > 0
              ? resource.metaData?.title
              : resource.title ?? t('common.picture_without_description')}
          </Typography>
          <img className={classes.iconSmall} src={getResourceIcon(resource?.type)} alt={resource.title} />
        </div>
        <Typography className={classes.resourceDate} variant='caption'>{moment(resource.updatedAt).locale(i18n.language).fromNow()}</Typography>
      </div>
      {isHasThumbnail ?
        <img className={classes.imageFull} src={getResourceThumbnail(resource)} alt={resource.title} />
        :
        <img className={`${classes.imageFull} ${classes.imgPlaceholder}`} src={EmptyResourceIcon} alt='no-thumbnail' />
      }
    </>
  }

  return <ButtonBase disabled={disabled} onClick={() => onClick(resource)} className={`${classes.resourceButtonBase} ${size === 'small' ? classes.resourceButtonSmall : classes.resourceButton}`}>
    {size === 'small' && renderSmallContent()}
    {size === 'medium' && renderMediumContent()}
  </ButtonBase>
}
