import React, { useEffect } from 'react'
import useStyles from './Entity.styles'
import EntityTable from '../EntityTable/EntityTable'
import { iLabel, iNode, iRelation, iResource, PropertyValueTypes, SearchResultType } from '@gloow/apiconsumer'
import { useAppSelector } from '@store/hooks'
import EntityCSVImport from '../EntityCSVImport/EntityCSVImport'
import useEntity from '@hooks/useEntity'
import { useHistory, useParams } from 'react-router-dom'
import EntityHeader from '../EntityHeader/EntityHeader'
import { GridView, NodeSidePanel, Spinner } from '@common/components'
import { RouteURI } from '@common/constants/Routes'
import { EntityDisplayMode, EntityRouteURI } from '@store/entities/entities.interfaces'
import { contentType, iInteractionDialog } from '@common/constants/Constants'
import { Drawer } from '@material-ui/core'
import useDomain from '@hooks/useDomain'
import { generateDetailURL } from '@helpers/utils'

const Entity = ({
  labels = [],
  nodes = [],
  relations = [],
  displayMode = EntityDisplayMode.table,
  setNodeMenu = (props) => { }
}: {
  labels?: iLabel[],
  nodes?: iNode[],
  relations?: iRelation[],
  resources?: iResource[],
  displayMode?: EntityDisplayMode,
  setNodeMenu?: (props: iInteractionDialog) => void,
}) => {
  const classes = useStyles()
  const [lastUpdate, resourceMappings] = useAppSelector(state => [state.domain.lastUpdate, state.resourceMappings.resourceMappings])
  const history = useHistory()
  const { slug, route, labelId } = useParams<{ slug?: string, route?: string, labelId?: string }>()
  const { connectedNodes, connectedResources, domainPermissions, activeVertex } = useDomain()

  const id = new URLSearchParams(history.location.search).get('id') ?? ''

  // @TODO move it to EntityTable and make it as a standalone component
  const {
    loading,
    initiating,
    rows,
    columns,
    activeLabel,
    onCellChange,
    onColumnAdd,
    onColumnChange,
    onColumnDelete,
    onNodeChange,
    onOrderChange,
    onRowAdd,
    onRowsDelete,
    remapRows,
    changeActiveLabel,
    loadLabel,
    csvImportDone,
  } = useEntity()

  useEffect(() => {
    remapRows(labelId === 'all' ? true : false)
    return () => { }
    // eslint-disable-next-line
  }, [lastUpdate, labelId])

  useEffect(() => {
    if (!labelId) return changeActiveLabel(undefined)
    if (labelId) {
      let active = labels.find(d => d.id.toString() === labelId)
      if (active) {
        active = {
          ...active,
          properties: active?.properties.filter(d => d.valueType === PropertyValueTypes.RELATION)
            .map(d => {
              const nodeRelations = relations.filter(rel => {
                // @ts-ignore
                return rel.outgoingLabel?.id === d.metaData.relationLabel
              })
              return {
                ...d,
                relatedNodes: nodeRelations.map(d => d.targetNode)
              }
            })
        }
      }
      changeActiveLabel(active)
    }
    // eslint-disable-next-line
  }, [labelId, labels])

  useEffect(() => {
    if (activeLabel) loadLabel()
    return () => { }
    // eslint-disable-next-line
  }, [activeLabel?.id])

  const renderContent = () => {
    if (labelId !== 'all' && !activeLabel) return <></>
    switch (route) {
      case EntityRouteURI.import:
        return <EntityCSVImport
          initialColumns={columns.filter(d => d.editable === true)}
          labels={labels}
          label={activeLabel}
          onCancel={() => history.replace(`/${slug}/${RouteURI.ENTITIES}/${activeLabel?.id}`)}
          onDone={(nodes) => {
            history.replace(`/${slug}/${RouteURI.ENTITIES}/${activeLabel?.id}`)
            csvImportDone(nodes)
          }}
        />
      default:
        if (displayMode === EntityDisplayMode.grid) {
          const nodeIds = rows.map(d => d.id)
          return (
            <div className={classes.gridView}>
              <GridView
                key={nodeIds.join(',')}
                sort={{ by: (el) => el.getAttribute('data-title') }}
                data={labelId === 'all' ? nodes : nodes.filter(d => nodeIds.includes(d.id))}
                nodes={nodes}
                relations={relations}
                resourceMappings={resourceMappings}
                showConnections={true}
                onSelectedItem={(item) => history.push(generateDetailURL(history.location.pathname, { id: item.id, type: contentType.NODE }))}
              />
              {initiating && <div className={classes.loader}><Spinner size='md' /></div>}
            </div>
          )
        }

        return (
          <div className={classes.tableView}>
            <EntityTable
              label={activeLabel}
              labels={labels.filter(d => d.domainUuid)}
              nodes={nodes}
              relations={relations}
              loading={loading}
              columns={columns}
              rows={rows}
              onColumnAdd={labelId === 'all' ? undefined : onColumnAdd}
              onColumnChange={onColumnChange}
              onColumnDelete={onColumnDelete}
              onCellChange={onCellChange}
              onRowAdd={onRowAdd}
              onRowsDelete={onRowsDelete}
              onNodeChange={onNodeChange}
              onOrderChange={onOrderChange}
              onNodeClick={(row) => history.push(generateDetailURL(history.location.pathname, { id: row.id, type: contentType.NODE }))}
            />
            {initiating && <div className={classes.loader}><Spinner size='md' /></div>}
          </div>
        )
    }
  }

  return (
    <div className={route === EntityRouteURI.grid || displayMode === EntityDisplayMode.grid ? classes.gridContainer : classes.container}>
      <EntityHeader disabled={loading} data={activeLabel} displayMode={displayMode} />
      {renderContent()}

      <Drawer
        classes={{ paper: classes.rightDrawer }}
        BackdropProps={{ invisible: true }}
        open={Boolean(activeVertex.data) && Boolean(id)}
        onClose={() => history.push(history.location.pathname)}
        anchor={'right'}
      >
        <NodeSidePanel
          editable={domainPermissions?.modify}
          removeable={domainPermissions?.remove}
          onConnectedNodeClick={(nodeId) => history.push(generateDetailURL(history.location.pathname, {
            id: nodeId, type: SearchResultType.Node, ref: id
          }))}
          onConnectedResourceClick={(resourceId) => history.push(generateDetailURL(history.location.pathname, {
            id: resourceId, type: SearchResultType.Resource, ref: id
          }))}
          onLabelClick={(labelId) => history.push(generateDetailURL(history.location.pathname, {
            id: labelId, type: SearchResultType.Label, ref: id
          }))}
          setNodeMenu={setNodeMenu}
          node={activeVertex.data! as iNode}
          domainUuid={slug!}
          connectedNodes={connectedNodes}
          connectedResources={connectedResources}
        />
      </Drawer>
    </div>
  )
}

export default Entity
