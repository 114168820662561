import React from 'react'
import Colors from '@common/constants/Colors';
import { Card, CardActionArea } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        containerCard: {
            padding: 0,
            borderRadius: 12,
            background: theme.palette.background.paper,
            boxShadow: 'none !important',
            border: '1px solid',
            borderColor: theme.palette.primary.main
        },
        containerCardActive: {
            background: theme.palette.primary.main,

            '& div': {
                color: 'white !important'
            },
            '& .MuiButtonBase-root': {
                minHeight: 135
            }
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            padding: "15px",
            borderRadius: 12,
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight: 120,
            position: 'relative',
            transition: 'all 0.3s'
        },
        textWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        productName: {
            fontSize: 16,
            marginBottom: 4,
            fontWeight: 600,
        },
        productPrice: {
            color: Colors.primary,
            fontWeight: 600,
            fontSize: 18,
            marginBottom: 5
        },
        productDesc: {
            color: theme.palette.text.secondary
        },
    }),
);

interface IStripe {
    price?: any,
    active?: boolean,
    handeClickPrice?: () => void
}

const PayWallPrices = ({
    price,
    active = false,
    handeClickPrice = () => undefined
}: IStripe) => {
    const classes = useStyles();
    const { t } = useTranslation()

    return (
        <Card className={`${classes.containerCard} ${active ? classes.containerCardActive : ''}`} onClick={handeClickPrice}>
            <CardActionArea className={classes.container}>
                <div className={classes.textWrapper}>
                    <div className={classes.productName}>{price.metadata?.name}</div>
                    <div className={classes.productPrice}>{price.currency} {(price.amount / 100)}</div>
                    <div className={classes.productDesc}>{t('subscription.every_interval', { count: price.interval_count > 1 ? price.interval_count : undefined, interval: t(`common.${price.interval.toLowerCase()}`, price.interval.toLowerCase()).toLowerCase() })}</div>
                </div>
            </CardActionArea>
        </Card>
    )
}

export default PayWallPrices;