import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: '6px 12px',
    borderRadius: 10,
    '& .MuiButton-label': {
      display: "flex",
      gap: 4,
    }
  },
  label: {
    color: Colors.white
  },
  close: {
    color: Colors.white,
    fontSize: 16,
  },
  input: {
    width: 'auto',
    minWidth: 70,
    maxWidth: 150,
    backgroundColor: "transparent",
    outline: "none",
    color: Colors.white,
    '&::placeholder': {
      color: Colors.white,
      opacity: 0.5
    }
  },
  removeButton: {
    backgroundColor: 'transparent !important'
  }
}))