import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  header: {
    padding: '24px 32px 0px 32px',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  moreButton: {
    width: 36,
    height: 36
  },
  moreMenu: {

  },
  moreMenuImg: {
    height: 16,
    width: 16
  },
  moreMenuText: {
    marginLeft: 8
  },
  visualSwitch: {
    height: 22,
    width: 22
  },
  actions: {
    display: 'flex',
    gap: 8,
    alignItems: 'center'
  },

  importButton: {
    height: 35,
    borderRadius: 12,
    padding: '8px 20px',
    marginRight: 8,
  },
  submit: {
    marginRight: 10,
    height: 35,
    borderRadius: 12,
    padding: '5px 18px'
  },

  entityIcon: {
    height: 48,
    width: 48,
    marginRight: 16,
    marginTop: 6
  },
  entityTitle: {
    '& .MuiInputBase-root': {
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    },
    '& .MuiFormLabel-root': {
      fontSize: 30,
      fontWeight: 600,
      '&.Mui-focused, &.MuiInputLabel-shrink': {
        fontSize: 20
      }
    },
    '& ,.MuiInputBase-input': {
      fontSize: 34,
      fontWeight: 600,
      color: Colors.textPrimary,
      width: '90%',
      padding: 0,
      marginTop: 3
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  cancelButton: {
    marginRight: 8,
    height: 35
  }
}))