import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography } from '@material-ui/core'
import { DropZone, TheLoader } from '@common/components'
import { useAppDispatch } from '@store/hooks'
import { saveResource, uploadFile } from '@helpers/media'
import { getResourceType } from '@helpers/resource'
import AnalyticsService from "@services/AnalyticsService"
import { setLastUpdate } from '@store/domain/domainSlice'
import { iInteractionDialog } from '@common/constants/Constants'
import { iNode } from "@gloow/apiconsumer"
import { VertexType } from '@gloow/navimator'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    dialogTitle: {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 24,
      width: '80%'
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      minWidth: 500
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    },
    webLinkInput: {
      marginRight: 15,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderRadius: 12
      }
    },
    addButton: {
      height: 40
    },
    cancelButton: {
      marginRight: 30,
      boxShadow: "0 6px 12px 0 rgba(67, 84, 221, 0.08)",
      color: theme.palette.primary.main
    }
  }),
);

export interface IUploadFile {
  dialogProps: iInteractionDialog,
  handleClose: (resourceId?: string | number, redirectWhenDone?: boolean) => void
}

function UploadFileDialog({ dialogProps, handleClose = () => { } }: IUploadFile) {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [connectToNode, setConnectToNode] = useState<iNode>()

  useEffect(() => {
    setConnectToNode(dialogProps.connectTo?.type === VertexType.Node ? dialogProps.connectTo?.data as iNode : undefined)
    return () => { }
  }, [dialogProps.connectTo])

  const onDropFiles = async (files) => {
    setLoading(true)
    try {
      const promises: any = []
      let filesArr: any = []
      files.forEach(item => {
        promises.push(uploadFile(item))
      });

      await Promise.all(promises).then(function (values) {
        if (values.length) filesArr = values
      });

      if (filesArr.length) {
        const promises: any = []
        filesArr.forEach((item, index) => {
          const file = {
            originalFileName: files[index].name,
            location: item
          }
          const fileType = getResourceType(files[index].type)
          // @ts-ignore
          promises.push(saveResource(file, fileType, files[index].type, connectToNode ?? undefined))
        });
        await Promise.all(promises).then((values) => {
          dispatch(setLastUpdate(Date.now()))
          setLoading(false)
          // @ts-ignore
          if (values.length) handleClose(values[values.length - 1].id, dialogProps.redirectWhenDone)
        })
      }
    } catch (e) {
      console.error(e)
      AnalyticsService.logError('upload-error', { e });
      setLoading(false)
      handleClose()
    }
  }
  if (loading) return <TheLoader />
  return (
    <Dialog onClose={() => handleClose()} open={dialogProps.open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3">
          {t('common.upload_a_file')}
        </Typography>
        <CloseIcon id="close" className={classes.dialogBackIcon} onClick={() => handleClose()} />
      </div>
      <DropZone onDropFiles={onDropFiles} />
    </Dialog>
  );
}

export default UploadFileDialog;
