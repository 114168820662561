import { contentType, nodeInfoTabTypes } from '@common/constants/Constants';
import { iNode, iResource } from "@gloow/apiconsumer";

export const decamelCase = (string: String) =>
  string.replace(/([a-z](?=[A-Z]))/g, '$1 ')

export function filteredResource(resources, search) {
  const initResources = (data) => {
    return data.map((item) => ({
      ...item,
      title: item.metaData?.title ? item.metaData?.title : item.title
    }));
  }
  return initResources(resources).filter(n => n.title?.toLowerCase().includes(search?.toLowerCase()))
}

export function filteredNodes(nodes, search) {
  const loweredCaseSearch = search?.toLowerCase()

  return nodes.filter(d => {
    return d.name?.toLowerCase().includes(loweredCaseSearch)
      || d.info?.toLowerCase().includes(loweredCaseSearch)
      || d.labels.map((label) => label.name?.toLowerCase()).join(' ').includes(loweredCaseSearch)
  })
}

export function filteredLabels(nodes, search) {
  const loweredCaseSearch = search?.toLowerCase()

  return nodes.filter(d => {
    return d.name?.toLowerCase().includes(loweredCaseSearch)
  })
}

export const validURL = str => {
  // eslint-disable-next-line
  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  var pattern = new RegExp(expression)
  return !!pattern.test(str)
}


export const instanceOfNode = (object: any): object is iNode => {
  if (!object) return false
  return 'name' in object
}

export const instanceOfResource = (object: any): object is iResource => {
  if (!object) return false
  return 'title' in object
}

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

export const generateDetailURL = (pathname: string, params: {
  id: string | number, // node or resource id
  type: contentType | string, // contentType NODE/RESOURCE
  tab?: nodeInfoTabTypes | string, // nodeInfoTabType
  ref?: string, // any string to indicate referer
}) => {
  params.id = params.id.toString()
  if (!params.ref?.length) delete params.ref
  // @ts-ignore
  const query = new URLSearchParams(params).toString()
  return `${pathname}?${query}`
}

export const getAvatarURL = ({
  name,
  format = 'svg',
  background,
  color = 'ffffff',
  size
}: {
  format?: string,
  name: string,
  background: string
  color?: string,
  size: number
}) => {
  return `https://eu.ui-avatars.com/api/?name=${encodeURI(name)}&background=${background}&color=${color}&size=${size.toString()}&format=${format}`
}

/**
 * 
 * @param name 
 * @returns 
 */
export const getInitials = (name: string) => {
  // what to do when got falsy?
  if (!name) return ''

  const words = name.trim().split(' ')

  // normal condition, name contains more than 1 words
  if (words.length > 1) {
    return words.map(d => d.charAt(0).toUpperCase()).slice(0, 2).join('')
  }

  // if name only contains one char
  if (name.length === 1) {
    return name.toUpperCase() + name.toUpperCase();
  }

  // if name only contains one word
  return name.substring(0, 2).toUpperCase()
}

export const isValidUUID = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}

export const stripEmojis = str =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    .replace(/\s+/g, ' ')
    .trim()

export const isDev = process.env.NODE_ENV === 'development'