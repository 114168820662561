import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: 12,
    width: 765,
    maxWidth: 765,
    margin: 0,
  },
  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 32,
    marginBottom: 24
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 24,
    width: '80%'
  },
  dialogBackIcon: {
    cursor: 'pointer',
    position: 'relative',
    marginTop: 5
  },
  search: {
    marginRight: 32,
    marginLeft: 32,
  },
  tabButtonContainer: {
    marginTop: 12,
    marginRight: 32,
    marginLeft: 32,
  },
  tabIndicator: {
    display: 'none'
  },
  tabActive: {
    fontWeight: 600,
    opacity: 1
  },
  tab: {
    minWidth: 'auto',
    textTransform: 'capitalize',
    letterSpacing: 0,
    fontSize: 14,
    fontWeight: 600,
    '&.Mui-selected': {
      color: theme.palette.text.primary
    },
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'auto',
    paddingBottom: 4,
    '& .MuiButtonBase-root': {
      width: 220,
      minHeight: 85,
      borderRadius: '12px !important',
      boxShadow: '0px 6px 12px 0 rgba(67, 84, 221, 0.08)',
      margin: 8,
      transition: '0.1s',
      padding: '0 20px',

      '& .textWrapper': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 32,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    maxWidth: 370,
    '& .MuiButtonBase-root': {
      marginLeft: 12,
      marginRight: 12,
      '&.MuiButton-containedPrimary': {
        color: 'white'
      }
    }
  },
  dialogContent: {
    overflowY: 'auto',
    height: 200,
  },
  emptyIcon: {
    maxHeight: 150
  },
  tabEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))