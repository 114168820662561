import Colors from "@common/constants/Colors"
import { makeStyles } from "@material-ui/core"

export default makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  editor: {
    padding: 12,
    overflowX: 'hidden',
    border: `1px solid ${Colors.secondaryStroke}`,
    borderTop: 0,
    borderEndStartRadius: 10,
    borderEndEndRadius: 10,
    flex: 1,
    maxHeight: '100%'
  },
  readonly: { border: 0, padding: 1 },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#EDEDFE',
    padding: 8,
    borderStartStartRadius: 10,
    borderStartEndRadius: 10,
    border: `1px solid ${Colors.lightBlue2}`,
    borderBottom: 0,
    position: 'sticky',
    top: 0,
    zIndex: 100
  },
  toolbarButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
  },
  toolbarGroup: {
  },
  hoveringToolbarContainer: {
    position: 'absolute',
    zIndex: 1,
    top: -10000,
    left: -10000,
    marginTop: -6,
    opacity: 0,
    backgroundColor: Colors.lightBlue2,
    borderRadius: 4,
    transition: 'opacity 0.5s',
    padding: 8,
    '& button': {
      margin: '0px 4px'
    },
    boxShadow: `0 6px 12px 0 ${Colors.shadowColor} !important`,
  },
  mentionBox: {
    top: '-9999px',
    left: '-9999px',
    position: 'absolute',
    zIndex: 10000,
    background: 'transparent',
    borderRadius: '4px',
  },
  hidden: {
    display: 'none'
  },
  labelColor: {
    width: 16,
    height: 16
  },
  toolbarIcon: {
    fontSize: 18,
  },
  toolbarButton: {
    color: Colors.primary,
    borderRadius: 6
  },
  spacer: {
    margin: '0px 4px'
  },
  saved: {
    padding: '0px 4px'
  },
  savingText: {
    fontWeight: 500,
  },
  nodeContainer: {
    display: 'flex',
    gap: 12
  },
  button: {
    textAlign: 'left',
    boxShadow: 'none !important',
    padding: '8px 16px',
    borderRadius: '0px !important',
    '& .MuiButton-label': {
      justifyContent: 'flex-start'
    }
  },
}))
