import React, { useState } from 'react'
import useStyles from './index.styles'
import { Typography, ButtonBase, Button } from '@material-ui/core'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog, DomainForm } from '@common/components'
import { useAppSelector } from '@store/hooks'
import useDomain from '@hooks/useDomain'
import { deleteDomain } from '@services/DomainDataService'
import { UserPermissionsTable } from '@common/components/UserPermissionTable/UserPermissionTable'
// import { BaseLayout } from '@common/components'

const DomainSetting = () => {
  const history = useHistory()
  const openDomain = useAppSelector((state) => state.domain.openDomain)
  const classes = useStyles()
  const domainData = useDomain()
  const { t } = useTranslation()
  // @ts-ignore
  const { slug } = useParams()
  const domainSlug = slug ? slug + '/' : ''

  const [deleteDomainDialog, setDeleteDomainDialog] = useState(false)

  const routes = [
    {
      name: t('common.domain-setting-info', 'Basic Info & Settings'),
      exact: true,
      path: `/${domainSlug}domain-setting/basic`,
      component: () => (
        <DomainForm domainData={openDomain} horizontal autoSave />
      ),
    },
    {
      name: t('common.permissions', 'Users & Permissions'),
      exact: true,
      path: `/${domainSlug}domain-setting/permissions`,
      component: () => <UserPermissionsTable domain={openDomain} />,
    },
  ]

  return (
    <div className={classes.container}>
      <div className={classes.settingHeader}>
        <Typography variant={'h4'} className={classes.accountTitle}>
          {t('common.domain_settings', 'Domain Settings')}
        </Typography>
        <Button
          className='link-button'
          onClick={() => setDeleteDomainDialog(true)}
        >
          <Typography variant='body2' className='underline' color='error'>
            {t('common.delete_domain', 'Delete domain')}
          </Typography>
        </Button>
      </div>
      <div className={classes.tabs}>
        {routes.map((d) => (
          <ButtonBase
            key={d.path}
            className={
              classes.tab +
              ' ' +
              (history.location.pathname === d.path ? classes.tabActive : '')
            }
            onClick={() => history.push(d.path)}
          >
            {d.name}
          </ButtonBase>
        ))}
      </div>
      <div className={classes.containerCard}>
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Redirect
            exact
            from={`/${domainSlug}domain-setting`}
            to={`/${domainSlug}domain-setting/basic`}
          />
        </Switch>
      </div>

      {domainData?.domainPermissions?.remove && (
        <ConfirmDialog
          open={deleteDomainDialog}
          onClose={() => setDeleteDomainDialog(false)}
          onConfirm={() =>
            deleteDomain(domainData.openDomain!, () =>
              history.replace(`/domains`)
            )
          }
          text={t('common.are_you_sure_want_to_delete_this_domain')}
          confirmText={t('common.delete')}
          cancelText={t('common.cancel')}
        />
      )}
    </div>
  )
}

export default DomainSetting
