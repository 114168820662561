import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom'
import { DomainDialog, ConfirmDialog, UserPermissionsDialog, OnboardingV2, BusinessOnboarding, BaseLayout } from '@common/components'
import { ButtonBase, Container, Snackbar, Grow, Button } from '@material-ui/core'
import emptyDomain from '@assets/images/icons-empty-domain.png'
import { iDomainNew } from "@gloow/apiconsumer"
import { useAppDispatch, useAppSelector } from '@store/hooks'
import useStyles from './index.styles'
import { Alert, Color } from '@material-ui/lab'

import MyDomains from './MyDomains'
import Collaboration from './Collaboration'
import Public from './Public'
import { useTranslation } from 'react-i18next'
import { deleteDomain } from '@services/DomainDataService'
import { OnboardingEvents, useCase } from '@common/constants/Constants'
import { Add } from '@material-ui/icons'
import { updateSearchText } from '@store/filters/filtersSlice'
// import { updateSearchText } from '@store/filters/filtersSlice'

interface IDomainList {
  title?: string
  domains?: iDomainNew[]
  publicDomains?: iDomainNew[]
  searchText?: string
}

function domainsFilter(array, searchKey) {
  const initDomain = (data) => {
    return data.map((item) => ({
      ...item,
      nameLowercase: item.name.toLowerCase(),
      descLowercase: item.description?.toLowerCase(),
      labelsArray: item.labels.map((label) => label.name.toLowerCase())
    }));
  }
  const domains = initDomain(array);

  return domains.filter(object => Object.values(object).some((i: any) => {
    if (typeof i === "string" || Array.isArray(i)) return i.includes(searchKey)
    return false
  }));
}

const DomainList = ({ title }: IDomainList) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showDeleteDomain, setShowDeleteDomain] = useState(false)
  const [editDomain, setEditDomain] = useState(false)
  const [collaborationsDomain, setCollaborationsDomain] = useState(false)
  const [showUserPermissions, setShowUserPermissions] = useState(false)
  const [onboardingV2, setOnboardingV2] = useState(false)
  const [businessOnboarding, setBusinessOnboarding] = useState(false)
  const [domain, setDomain] = useState<iDomainNew>({} as iDomainNew)
  const searchText = useAppSelector(state => state.filters.searchText)
  const session = useAppSelector(state => state.session.user)
  const onboardingStatus = useAppSelector(state => state.user.onboardingStatus)
  const [snackBar, setSnackBar] = useState<{ open: boolean, message: string, severity: Color | undefined }>({
    // @ts-ignore
    open: location?.state && typeof location?.state?.error !== 'undefined' ? true : false,
    // @ts-ignore
    message: location?.state && typeof location?.state?.error !== 'undefined' ? location?.state?.error : '',
    severity: 'error'
  })

  const onDomainClick = (domain: iDomainNew) => {
    // @ts-ignore
    if (domain.slug) return history.push({ pathname: `/${domain.slug}/mind-maps` })
    history.push({ pathname: `/${domain.uuid}/mind-maps` })
  };

  const handleEditDomain = (domain) => {
    setDomain(domain)
    setEditDomain(true)
  }

  const handlePermissionsDomain = (domain) => {
    setDomain(domain)
    setShowUserPermissions(true)
  }

  const getOnboardingStatus = async () => {
    if (session.guest) return
    if (!onboardingStatus[OnboardingEvents.CreateDomain]) {
      const search = location.search
      const params = new URLSearchParams(search)
      const value = params.get('use-case')
      if (value === useCase.BUSINESS) return setBusinessOnboarding(true)
      return setOnboardingV2(true)
    }
  }

  useEffect(() => {
    getOnboardingStatus()
    return () => { }
    // eslint-disable-next-line
  }, [onboardingStatus])


  function renderEmptyDomain() {
    return (
      <div className={classes.emptyDomain}>
        <img className={classes.emptyDomainIcon} src={emptyDomain} alt="edit-icon" />
        <div>
          {!searchText ? (
            <>
              {t('common.no_domains_yet')}
              <span className={classes.newDomain} onClick={() => setOnboardingV2(true)}>
                {t('common.create_one')}?
              </span>
            </>
          ) : (
            <>{t('common.no_domains_found')}</>
          )}
        </div>
      </div>
    )
  }

  const closeSnackBar = () => {
    setSnackBar({ ...snackBar, open: false })
    history.replace('/domains', {})
  }

  const childRoutes = [
    {
      name: t('common.my_domains'),
      children: <MyDomains
        domainsFilter={domainsFilter}
        handleEditDomain={(domain: iDomainNew) => {
          setCollaborationsDomain(false)
          handleEditDomain(domain)
        }}
        handleDeleteDomain={(domain: iDomainNew) => {
          setDomain(domain)
          setShowDeleteDomain(true)
        }}
        handlePermissionsDomain={(domain: iDomainNew) => handlePermissionsDomain(domain)}
        onDomainClick={onDomainClick}
        renderEmptyDomain={renderEmptyDomain}
      />,
      exact: true,
      path: `/domains`,
    },
    {
      name: t('common.collaboration_domains'),
      children: <Collaboration
        domainsFilter={domainsFilter}
        handleEditDomain={(domain: iDomainNew) => {
          setCollaborationsDomain(true)
          handleEditDomain(domain)
        }}
        handleDeleteDomain={(domain: iDomainNew) => {
          setDomain(domain)
          setShowDeleteDomain(true)
        }}
        handlePermissionsDomain={(domain: iDomainNew) => handlePermissionsDomain(domain)}
        onDomainClick={onDomainClick}
        renderEmptyDomain={renderEmptyDomain}
      />,
      exact: true,
      path: `/domains/collaboration`,
    },
    {
      name: t('common.public_domains'),
      children: <Public
        domainsFilter={domainsFilter}
        onDomainClick={onDomainClick}
        renderEmptyDomain={renderEmptyDomain}
      />,
      exact: true,
      path: `/domains/public`,
      allowGuest: true
    },
  ]

  const routes = session.guest ? childRoutes.filter(route => route.allowGuest) : childRoutes

  return (
    <BaseLayout
      title={t('common.domains')}
      onSearch={(text) => dispatch(updateSearchText(text))}
      headerRightComponent={
        !session.guest ?
          <Button
            variant="contained"
            color="primary"
            className={'add-button-header'}
            startIcon={<Add />}
            onClick={(e) => setOnboardingV2(true)}
          >
            {t('common.add')}
          </Button> : undefined
      }>
      <Container className={classes.container}>
        <div className={classes.tabs}>
          {routes.map((d) =>
            <ButtonBase key={d.path} className={classes.tab + ' ' + (location.pathname === d.path ? classes.tabActive : '')} onClick={() => history.push(d.path)}>{d.name}</ButtonBase>
          )}
        </div>
        <Switch>
          {routes.map(route => <Route key={route.path} {...route} />)}
          {<Redirect from={`/domains`} to={session.guest ? `/domains/public` : `/domains`} />}
        </Switch>
      </Container>

      <BusinessOnboarding open={businessOnboarding && !onboardingV2} onClose={() => setBusinessOnboarding(false)} />
      <OnboardingV2 open={!businessOnboarding && onboardingV2} onClose={() => setOnboardingV2(false)} />

      <DomainDialog
        editing={editDomain}
        collaborationsDomain={collaborationsDomain}
        domain={domain}
        open={editDomain}
        onClose={() => setEditDomain(false)}
      />
      <UserPermissionsDialog
        open={showUserPermissions}
        domain={domain}
        onClose={() => setShowUserPermissions(false)}
      />
      <ConfirmDialog
        open={showDeleteDomain}
        onClose={() => setShowDeleteDomain(false)}
        onConfirm={() => {
          setShowDeleteDomain(false)
          deleteDomain(domain)
        }}
        text={t('common.are_you_sure_want_to_delete_this_domain')}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
      />
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackBar.open} TransitionComponent={Grow} onClose={() => closeSnackBar()} autoHideDuration={10000} >
        <Alert variant={'filled'} onClose={() => closeSnackBar()} severity={snackBar.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </BaseLayout>
  )
}

export default DomainList
