import React from 'react'
import { makeStyles, Card, Typography } from '@material-ui/core'
import Colors from '@common/constants/Colors'
import { iLabel } from "@gloow/apiconsumer"
import { contentType } from '@common/constants/Constants'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    width: '100%',
    wordBreak: 'break-word',
    borderRadius: 12,
  },
  card: {
    width: '100%',
    borderRadius: 12,
    padding: 24,
    boxShadow: 'none !important'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 'normal'
  },
  subtitle: {
    color: Colors.darkGrey,
    lineHeight: 1,
    marginTop: 4,
    fontWeight: 400
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  info: {
    lineHeight: 'normal',
    marginTop: 12,
    fontSize: '1rem',
    maxHeight: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  extraInfo: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 12,
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stats: {
    color: Colors.darkGrey,
    lineHeight: 'normal',
    marginRight: 8,
    fontWeight: 400
  },
  value: {
    color: Colors.darkGrey,
    marginRight: 4,
    fontWeight: 400
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageContainer: {
    marginRight: 10,
  },
  imageItem: {
    width: 48,
    height: 48,
    objectFit: 'cover'
  },
  connections: {
    marginTop: 14,
    paddingTop: 12,
    borderTop: '1px solid',
    borderColor: Colors.greyBorderBottom,
  },
  connectionImg: {
    width: 20,
    height: 20,
    padding: 2
  },
  connectedNodesResources: {
    fontSize: 14
  },
  labelColor: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    marginRight: 8,
  },
}));

interface iGridLabelCard {
  className?: string,
  data: iLabel,
  onClick: (data: iLabel) => void,
}

const GridLabelCard = ({ className, data, onClick = (label) => { } }: iGridLabelCard) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // prevent cross site scripting(xss)

  if(!data) return <></>
  
  return (
    <div
      className={`grid-card ${classes.container} ${className ?? ''}`}
      data-id={data.id}
      data-title={data.name?.toLowerCase() ?? ''}
      data-type={contentType.NODE}
      onClick={() => onClick(data)}
    >
      <Card className={`${classes.card}`}>
        <div className={classes.header}>
          <div className={`${classes.titleContainer}`}>
            <div className={classes.labelColor} style={{ backgroundColor: data.color ?? Colors.primary }}></div>
            <Typography className={classes.title} variant={'h6'}>{data.name}</Typography>
          </div>
        </div>
        <div className={classes.extraInfo}>
          <div className={classes.row}>
            {/* @ts-ignore */}
            <Typography variant={'subtitle2'} className={classes.value}>{data.nodeCount ?? 0}</Typography>
            <Typography variant={'subtitle2'} className={classes.stats}> {t('common.nodes').toLowerCase()}</Typography>
            {/* @ts-ignore */}
            <Typography variant={'subtitle2'} className={classes.value}>{data.resourceCount ?? 0}</Typography>
            <Typography variant={'subtitle2'} className={classes.stats}> {t('common.resources').toLowerCase()}</Typography>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default GridLabelCard
