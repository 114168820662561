import React from 'react'
import { Button, Typography } from '@material-ui/core'
import useStyles from './GuestModeDialog.styles'
import { useTranslation } from 'react-i18next';

function GuestModeDialog({ className, onConfirm }: { className?: string, onConfirm: () => void }) {
  const classes = useStyles();
  const { t } = useTranslation()
  return (
    <>
      <div className={`${classes.container} ${className}`}>
        <Typography variant="body1" align={'center'}>{t('auth.sign_up_to_edit_or_create')}</Typography>
        <Button variant="contained" color="primary" className={classes.confirm} onClick={onConfirm}>{t('auth.sign_up_for_free')}</Button>
      </div>
    </>
  );
}

export default GuestModeDialog;
