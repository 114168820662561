import React, { useCallback, useEffect, useRef, useState } from 'react'
import Colors from '@common/constants/Colors'
import { TextField, Typography } from '@material-ui/core'
import { Formik, FormikProps, useFormikContext } from 'formik'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import AvatarField from '../Form/AvatarField/AvatarField'
import useStyles from './NodeNameAvatar.styles'
import { useAppDispatch } from '@store/hooks'
import { setLastUpdate } from '@store/domain/domainSlice'
import { Node } from "@gloow/apiconsumer"
import { updateNodeField } from '@store/nodes/nodesSlice'
import { uploadFile } from '@helpers/media'
import ColorDialog from '../Dialog/ColorDialog/ColorDialog'
import * as yup from 'yup'

const AutoSave = ({ delay }: { delay: number }) => {
  const { values, submitForm, dirty, isSubmitting } = useFormikContext()
  // eslint-disable-next-line
  const autoSaveCb = useCallback(
    debounce(() => {
      console.log('autosave node name avatar')
      submitForm()
    }, delay),
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    if (dirty && !isSubmitting) autoSaveCb()
    return () => { }
    // @ts-ignore
    // eslint-disable-next-line
  }, [values?.name])

  return null;
}

export const NodeNameAvatar = ({
  className = '',
  data = { name: '', id: 0, thumbnailUrl: '', color: Colors.default() },
  editable = false,
  disabled = false,
  autoSaveDebounceMs = 0,
  onChange,
  onChangeColor
}: {
  className?: string,
  data: { name: string, id: number, thumbnailUrl: string, color: string },
  editable?: boolean,
  disabled?: boolean,
  autoSaveDebounceMs?: number,
  onChange?: ({ name, color, thumbnailUrl }) => void
  onChangeColor?: () => void
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [colorDialog, setColorDialog] = useState<boolean>(false)
  const form = useRef<FormikProps<any>>(null)
  const initialValues = data

  const schema = yup.object().shape({
    name: yup.string().required(t('common.name_is_required')),
  })

  const onSubmit = async (values) => {
    if (!autoSaveDebounceMs || !data.id) return onChange && onChange(values)
    setLoading(true)
    const NS = new Node()
    const updatedNode = await NS.update(values)
    setLoading(false)
    if (!updatedNode) return console.log('failed to update node')
    form.current?.resetForm({ values })
    dispatch(updateNodeField({ ...values, updatedAt: updatedNode.updatedAt }))
    dispatch(setLastUpdate(Date.now()))
  }

  const onChangeFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length) return
    setLoading(true)
    const uploadedFile = await uploadFile(event.target.files[0])
    setLoading(false)
    if (!uploadedFile) return
    form.current?.setFieldValue('thumbnailUrl', uploadedFile)
    form.current?.submitForm()
  };

  const onRemoveAvatar = () => {
    form.current?.setFieldValue('thumbnailUrl', '')
    form.current?.submitForm()
  }

  useEffect(() => {
    // reset form to prevent auto save without remount formik
    form.current?.resetForm({ values: data })
    return () => { }
    // eslint-disable-next-line
  }, [data.id, data.color])

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} innerRef={form} validationSchema={schema}>
        {({ values, handleSubmit, setFieldValue, errors, submitForm }) => {
          return <form onSubmit={handleSubmit} className={`${classes.container} ${className}`}>
            {!!autoSaveDebounceMs && <AutoSave delay={autoSaveDebounceMs} />}
            <AvatarField
              onChangeColor={() => onChangeColor ? onChangeColor() : setColorDialog(true)}
              onChangeFiles={onChangeFiles}
              editable={editable && !loading}
              url={values.thumbnailUrl}
              onRemove={onRemoveAvatar}
              color={values.color}
            />
            {editable ?
              <TextField
                id="node-name"
                disabled={loading || disabled || !editable}
                label={t('common.name')}
                multiline
                fullWidth value={values.name}
                className={classes.name}
                name='name'
                onChange={(e) => setFieldValue('name', e.target.value)}
                error={Boolean(errors?.name)}
                helperText={errors?.name?.toString()} />
              :
              <div>
                <Typography className={classes.label} variant='body2'>{t('common.name')}</Typography>
                <Typography className={classes.name} variant='h4'>{values.name}</Typography>
              </div>
            }
          </form>
        }}
      </Formik>
      <ColorDialog
        backButton={false}
        open={colorDialog}
        selectedValue={data.color}
        onClose={(color) => {
          setColorDialog(false)
          if (color === data.color) return
          form.current?.setFieldValue('color', Colors.newColors[color])
          form.current?.submitForm()
        }}
      />
    </>
  )
}
