import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { getResourceThumbnail, getResourceIcon } from '@helpers/resource'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailWrapper: {
      width: 30,
      height: 30,
      borderRadius: 10,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      color: 'white',
      marginRight: 5
    },
    thumbnail: {
      minHeight: 30,
      minWidth: 30,
      maxWidth: 'initial',
      objectFit: 'cover'
    }
  }),
);

interface IResourceThumbnail {
  resource?: any
}

const ResourceThumbnail = ({
  resource
}: IResourceThumbnail) => {
  const classes = useStyles()
  return (
    <div className={classes.thumbnailWrapper}>
      <img className={classes.thumbnail} src={ getResourceThumbnail(resource, getResourceIcon(resource.type), false) } alt="resource" />
    </div>
  )
}

export default ResourceThumbnail;
