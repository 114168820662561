import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
export default makeStyles((theme: Theme) =>
  createStyles({
    nameWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    nodeColorWrap: {
      width: 48,
      height: 48,
      borderRadius: 48,
      marginRight: 24,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      cursor: 'pointer'
    },
    node: {
      width: 24,
      height: 24,
      backgroundColor: 'white',
      borderRadius: 24,
      boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
      marginRight: -12,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex'
    },
    nodeDim: {
      width: 14,
      height: 14
    },
    deleteButton: {
      boxShadow: 'none !important',
      transform: 'translate(-6px, 0px)'
    },
    deleteNode: {
      color: '#d50001'
    },
    deleteIcon: {
      marginRight: 8
    },
    relationButton: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      boxShadow: 'none !important',
      transform: 'translateX(-6px)'
    },
    addRelationButton: {
      position: 'absolute',
      bottom: 0,
      paddingBottom: 24,
      margin: 'auto',
      width: '100%',
      '& .MuiButton-root': {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        '& .MuiButton-startIcon': {
          position: 'absolute',
          left: 24
        }
      },
      zIndex: 20,
      background: 'white'
    },
    arrowIcon: {
      opacity: 0.2,
      fontSize: 20
    },
    arrowBackIcon: {
      fontSize: 20,
      color: 'black',
      marginLeft: 10
    },
    closeIcon: {
      fontSize: 20
    },
    form: {
      paddingBottom: 16
    },
    tab: {
      overflow: 'auto',
      height: 'calc(100vh - 154px)'
    },
    tabContentContainer: {
      '& .MuiCardActionArea-root': {
        padding: '15px 24px'
      },
      '& .MuiList-root': {
        '& .MuiButtonBase-root': {
          padding: '8px 24px'
        }
      },
      paddingBottom: 24,
      '& .relation-picker': {
        marginRight: 20
      }
    },
    dialogBackArrow: {
      width: 40,
      height: 40,
      marginRight: 10
    },
    name: {
      fontWeight: 'bold',
      wordBreak: 'break-word',
      '& .MuiInputBase-input, & .MuiFormLabel-root': {
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: 1,
        paddingTop: 4
      },
      '& .MuiInputLabel-shrink': {
        fontSize: 16
      }
    },
    info: {
      lineHeight: 1.3,
      fontWeight: 'normal',
      wordBreak: 'break-word'
    },
    cancelButton: {
      marginRight: 30,
      boxShadow: "0 6px 12px 0 rgba(67, 84, 221, 0.08)",
      color: theme.palette.primary.main
    },
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 24,
      paddingTop: 12,
      paddingBottom: 12,
      '& .MuiButton-root': {
        padding: '8px 8px !important',
      }
    },
    dialogTitle: {
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10,
      fontWeight: 600,
      fontSize: 24,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'start'

    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    },
    submit: {
      '& .MuiButton-label': {
        color: 'white'
      }
    },
    connectingContainer: {
      padding: '12px 24px',
      paddingTop: 0
    },
    connectingTitle: {
      fontSize: 14,
      marginBottom: 6
    },
    connectingNode: {
      display: 'flex',
      alignItems: 'center'
    },
    connectingNodeName: {
      fontSize: 16,
      marginLeft: 8
    },
    nodeDetailContainer: {
      paddingTop: 24,
    },
    bottomSpacer: {
      height: 'calc(100vh - 240px)'
    }
  }),
)
