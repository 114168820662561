import React, { ReactElement, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { iNode, iRelation, iResource, iResourceMapping } from '@gloow/apiconsumer'

import GridNodeCard from './GridNodeCard'
import GridResourceCard from './GridResourceCard'

import emptyNodeIcon from '@assets/images/empty-node.png'
import emptyResourceIcon from '@assets/images/empty-resource.png'

import { contentType } from '@common/constants/Constants'
import { instanceOfResource, instanceOfNode } from '@helpers/utils'
import Shuffle, { SortOptions } from 'shufflejs'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getResourcesForActiveNode, getConnectedNodesForActiveNode, getConnectedNodesForActiveResource, getFilteredResourcesIds } from '@selectors/index'
import { setConnectionView } from '@store/filters/filtersSlice'
import { useTranslation } from 'react-i18next'
import { RouteURI } from '@common/constants/Routes'
import useStyles from './GridView.styles'

interface iGridView {
  data: Array<iNode | iResource | Partial<iNode>>,
  nodes?: iNode[],
  relations?: iRelation[],
  resourceMappings?: iResourceMapping[],
  sort?: SortOptions,
  highlight?: string,
  showConnections?: boolean,
  onSelectedItem: (item) => void
}

const GridView = ({
  data,
  nodes,
  relations,
  resourceMappings,
  showConnections = false,
  sort,
  highlight = "",
  onSelectedItem = (item) => { },
}: iGridView): ReactElement => {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const loading = useAppSelector(state => state.helpers.loading)
  const lastUpdate = useAppSelector(state => state.domain.lastUpdate)
  const labelFilter = useAppSelector(state => state.filters.labels)
  const connectionView = useAppSelector(state => state.filters.connectionView)

  const isResourcePage = location.pathname.includes(RouteURI.DOCUMENTS) ?? false
  const isNodesPage = location.pathname.includes(RouteURI.FOCUS_MODE) || location.pathname.includes(RouteURI.ENTITIES) ? true : false
  const shuffle = useRef<Shuffle>()
  const grid = useRef<HTMLDivElement>(null)
  const emptyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (data.length && !loading && !shuffle.current && grid.current) {
      shuffle.current = new Shuffle(grid.current, {
        itemSelector: '.grid-card',
        buffer: 150,
        speed: 500,
        staggerAmount: 1000,
        initialSort: sort
      });
    }
    return () => { }
    // eslint-disable-next-line
  }, [data.length, loading])

  useEffect(() => {
    if (shuffle.current) {
      // window.scrollTo(0, 0)
      filter(connectionView)
    }
    return () => { }
    // eslint-disable-next-line
  }, [highlight, labelFilter, connectionView])

  useEffect(() => {
    if (shuffle.current) shuffle.current.resetItems()
    return () => { }
  }, [lastUpdate])

  const getConnections = (selected: iNode | iResource) => {
    const type = instanceOfNode(selected) ? contentType.NODE : contentType.RESOURCE
    let connections: number[] = []
    if (type === contentType.NODE) {
      const connectedNodes = getConnectedNodesForActiveNode({ relations: { relations }, nodes: { nodes, activeNode: selected } }).map(d => d.id)
      const connectedResources = getResourcesForActiveNode({ resourceMappings: { resourceMappings }, nodes: { nodes, activeNode: selected } }).map(d => d.id)
      connections = [...connectedNodes, ...connectedResources]
    }
    else if (type === contentType.RESOURCE) {
      const connectedNodes = getConnectedNodesForActiveResource({ resourceMappings: { resourceMappings }, resources: { activeResource: selected }, nodes: { nodes } }).map(d => d.id)
      connections = connectedNodes
    }
    return connections //array of connected content id
  }

  const filter = (d: iNode | iResource | undefined = undefined) => {
    const filterConnections: number[] | undefined = d && showConnections ? getConnections(d) : undefined
    // to filter resource by labels, we need to get nodes by label then get connected resource ids
    const filterResourceByLabels = getFilteredResourcesIds({ resourceMappings: { resourceMappings }, nodes: { nodes }, filters: { labels: labelFilter, connectionView } })
    const filterLabelsNames: string[] = labelFilter.map(d => d.name?.toLowerCase())
    shuffle.current?.filter((el: any) => {
      const id = parseInt(el.getAttribute('data-id') as string)
      const title = el.getAttribute('data-title')
      const labels = el.getAttribute('data-labels')
      const desc = el.getAttribute('data-description')
      const type = el.getAttribute('data-type')
      // filter search
      let matched = title?.includes(highlight) || desc?.includes(highlight) || labels?.includes(highlight)
      // filter labels
      if (filterLabelsNames.length > 0) {
        // node can be filtered by labels directly
        if (type === contentType.NODE) matched = matched && filterLabelsNames.some(r => labels?.split('|').includes(r))
        else if (type === contentType.RESOURCE) matched = matched && filterResourceByLabels.includes(id)
      }
      // filter connections
      if (filterConnections) matched = matched && (filterConnections?.includes(id) || d?.id === id)
      return matched
    });
    if (!emptyRef.current) return
    if (!shuffle.current?.visibleItems) emptyRef.current.style.display = 'flex'
    else emptyRef.current.style.display = 'none'
  }

  const renderEmpty = () => {
    return <div className={classes.emptyGrid} ref={emptyRef}>
      <img className="mb-4" src={!isResourcePage ? emptyNodeIcon : emptyResourceIcon} alt="empty" />
      <p>
        {t(`common.no_${isNodesPage ? 'nodes' : (isResourcePage ? 'resources' : 'connections')}_found`)}
      </p>
    </div>
  }

  const viewConnection = (d: iNode | iResource) => {
    if (connectionView && connectionView?.id === d.id) {
      dispatch(setConnectionView(undefined))
      filter()
    }
    else {
      window.scrollTo(0, 0)
      dispatch(setConnectionView(d))
      filter(d)
    }
  }

  const renderItems = (d, index) => {
    if (instanceOfNode(d)) {
      return <GridNodeCard
        onConnectionFilter={showConnections ? () => viewConnection(d) : undefined}
        highlight={highlight}
        data={d}
        key={index.toString()}
        onClick={(item) => onSelectedItem(item)}
        selected={d.id === connectionView?.id}
      />
    }
    else if (instanceOfResource(d)) {
      return <GridResourceCard
        highlight={highlight}
        data={d}
        key={index.toString()}
        onClick={(item) => { onSelectedItem(item) }}
        onConnectionFilter={showConnections ? () => viewConnection(d) : undefined}
        selected={d.id === connectionView?.id}
      />
    }
    return <div></div>
  }

  return (
    <>
      <div className={classes.container} ref={grid}>
        {data.map(renderItems)}
      </div>
      {renderEmpty()}
    </>
  )
}

export default GridView
