import Auth from '@pages/Auth';
import { Redirect } from 'react-router';
import AuthCallback from "@pages/Auth/callback";
import Mobile from '@pages/Mobile';

const PublicRoutes = [
  {
    name: '',
    exact: true,
    path: '/',
    render: () => <Redirect to="/auth" />
  },
  {
    name: 'Auth',
    component: Auth,
    exact: true,
    path: '/auth',
  },
  {
    name: 'Domain Invitation',
    component: Auth,
    exact: true,
    path: '/domain-invitation/:invitationCode',
  },
  {
    name: 'Auth Callback',
    component: AuthCallback,
    path: '/auth/callback'
  },
  {
    name: 'Mobile',
    component: Mobile,
    path: '/mobile',
  }
];

export default PublicRoutes;
