import React, { useState, useEffect } from 'react'
import moment from 'moment'
import useStyles from './index.styles'
import { Typography, Grid, Button, Tabs, Tab, Snackbar, Grow } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { PayWallPrices, TabPanel, ConfirmDialog, PaywallPricesStatusDialog, Spinner } from '@common/components'
import { useAppSelector } from '@store/hooks'
import { loadStripe } from '@stripe/stripe-js'
import { fetchPrices, fetchSession, cancelSubscription } from '@services/PaywallServices'
import { Alert, Color } from '@material-ui/lab'
import checkIcon from '@assets/images/icons-check.svg'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { syncUserSubscribe } from '@services/AuthService'

const stripeKey = process.env.REACT_APP_AUTH_STRIPE_KEY || 'pk_test_7wrU1PEabIN9lgf40UjyDPnc'
const stripePromise = loadStripe(stripeKey)

const Subscription = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const user = useAppSelector(state => state.session.user)
  const email = useAppSelector(state => state.session.email)
  const prices = useAppSelector(state => state.paywall.prices.slice().sort((a, b) => a.amount - b.amount))
  const subscriptionStatus = useAppSelector(state => state.user.subscriptionStatus)
  const [loading, setLoading] = useState<boolean>(false)

  const payWallSession = new URLSearchParams(history.location.search).get('session_id') ?? ''
  const [payWallStatus, setPayWallStatus] = useState<boolean>(false)
  const [payWallStatusModal, setPayWallStatusModal] = useState<boolean>(false)
  const [subscribed, setSubscribed] = useState(false)
  const [subscriptionHistory, setSubscriptionHistory] = useState([])
  const [subscriptionData, setSubscriptionData] = useState<any>({})
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [activePrice, setActivePrice] = useState(0)
  const [tabValue, setTabValue] = useState(0)
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const [snackBar, setSnackBar] = useState<{ open: boolean, message: string, severity: Color | undefined }>({
    open: false,
    message: '',
    severity: 'success'
  })

  const openPaywall = async (price) => {
    setLoading(true)
    const param = {
      user: user.sub,
      userUuid: user['https://gloow.io/users/uuid'],
      email: email,
      priceId: price.id,
      success_url: `${window.location.origin}/account/subscription`,
      cancel_url: `${window.location.origin}/account/subscription`
    }

    const session: any = await fetchSession(param)
    const checkoutParam = {
      sessionId: session.sessionId
    }
    setLoading(false)
    const stripe = await stripePromise
    await stripe?.redirectToCheckout(checkoutParam)
  }

  const renderFeatures = () => {
    return (
      <ul className={classes.list}>
        <li><img src={checkIcon} alt="check" className='mr-2' /> {t('subscription.unlimited_nodes')}</li>
        <li><img src={checkIcon} alt="check" className='mr-2' /> {t('subscription.unlimited_resources')}</li>
        <li><img src={checkIcon} alt="check" className='mr-2' /> {t('subscription.access_to_all_feature')}</li>
      </ul>
    )
  }

  const onCancelSubscription = async () => {
    setConfirmDialog(false)
    await cancelSubscription()
    setSnackBar({ open: true, message: t('subscription.subscription_canceled', { date: monthFormat(subscriptionData?.endsAt) }), severity: 'success' })
    setSubscriptionData({ ...subscriptionData, willRenew: false })
  }

  const handleCancelSubscription = () => {
    if (subscriptionData?.source !== "Stripe") setSnackBar({ open: true, message: t('subscription.cancel_subscription_on_mobile'), severity: 'warning' })
    else setConfirmDialog(true)
  }

  const monthFormat = (date) => {
    return moment(date).locale(i18n.language).format('MMMM, YY')
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('subscription.plan_name'), flex: 200 },
    { field: 'subPrice', headerName: t('subscription.price'), flex: 200 },
    { field: 'date', headerName: t('subscription.billing_date'), minWidth: 150 },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true)
      await fetchPrices()
      setLoading(false)
    })()
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (payWallSession !== null && payWallSession !== "") {
      if (payWallSession !== 'false') {
        // TODO renew token from Auth0
        setPayWallStatus(true)
      }
      setPayWallStatusModal(true)
    }
    // eslint-disable-next-line
  }, [payWallSession])

  useEffect(() => {
    const subscribed = subscriptionStatus.subscribed
    const history: any = subscriptionStatus.history
    const expriresDate = subscriptionStatus?.subscribedUntil
    const expired = expriresDate ? new Date(expriresDate).getTime() < new Date().getTime() : true

    if (subscribed && !expired) {
      const initHistory = (data) => {
        return data.map((item) => ({
          ...item,
          subPrice: item.price + '€',
          date: moment(item.startsAt).format('DD MMM YYYY')
        }));
      }

      setSubscribed(subscribed)
      setSubscriptionData(history[history.length - 1])
      setSubscriptionHistory(initHistory(history))
    }
  }, [subscriptionStatus])

  useEffect(() => {
    syncUserSubscribe()
    return () => { }
  }, [])


  return (
    <>
      <Typography variant={'h5'} className={classes.tabTitle}>{t('common.subscription')}</Typography>
      {!subscribed ? (
        <>
          <Typography variant='body2' className={classes.desc}>{t('subscription.subscribe_to_get_useful_features')}</Typography>
          <Typography variant='body2' className={classes.desc}>{t('subscription.this_will_give_you')}:</Typography>

          {renderFeatures()}

          <div className={(prices?.length > 0 ? classes.priceContainer : '')}>
            <Grid container spacing={2} alignItems="center">
              {prices?.map((price, index) => (
                <Grid item xs={12} sm={12} md={4} key={price.id}>
                  <PayWallPrices active={index === activePrice} price={price} handeClickPrice={() => setActivePrice(index)} />
                </Grid>
              ))}
            </Grid>
            {loading && <Spinner size='md' />}
          </div>

          <Button disabled={loading} className={classes.action} color="primary" variant="contained" onClick={() => openPaywall(prices[activePrice])}>{t('subscription.subscribe')}</Button>
        </>
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            textColor="primary"
            classes={{
              indicator: classes.tabIndicator
            }}
          >
            <Tab className={classes.tab} label={t('subscription.plan')} />
            <Tab className={classes.tab} label={t('subscription.billing_history')} />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            {loading ?
              <Spinner size='md' />
              :
              <div className={classes.planWrapper}>
                <div>
                  <Typography variant={'h4'} className={classes.planTitle}>{subscriptionData?.name}</Typography>

                  <div className={classes.status}>
                    <div>
                      <Typography variant={'h4'} className={classes.planActive}>{t('common.active')}</Typography>
                      <span>{t('common.since_date', { date: monthFormat(subscriptionData?.startsAt) })}</span>
                    </div>

                    <div>
                      {/* <Button color="primary" className={classes.planButton}>{t('subscription.change_plan')}</Button> */}
                      {subscriptionData?.willRenew && <Button disabled={loading} color="primary" className={classes.planButton} onClick={() => handleCancelSubscription()}>{t('common.cancel')}</Button>}
                    </div>
                  </div>


                  <div className={classes.planContainer}>
                    <div>
                      <Typography variant={'h4'} className={classes.planPrice}>{subscriptionData?.price}€</Typography>
                      <span>{t(subscriptionData?.willRenew ? 'subscription.renews_at_date' : 'subscription.until_date', { date: monthFormat(subscriptionData?.endsAt) })}</span>
                    </div>

                    <span>{subscriptionData?.source}</span>
                  </div>
                </div>

                <div className={classes.planFeatures}>
                  {renderFeatures()}
                </div>
              </div>
            }
            <ConfirmDialog
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              onConfirm={async () => {
                await onCancelSubscription()
              }}
              text={t('subscription.are_you_sure_want_to_cancel_subscription')}
              confirmText={t('common.proceed')}
              cancelText={t('common.cancel')}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackBar.open} TransitionComponent={Grow} onClose={() => setSnackBar({ ...snackBar, open: false })} autoHideDuration={3000} >
              <Alert variant={'filled'} onClose={() => setSnackBar({ ...snackBar, open: false })} severity={snackBar.severity}>
                {snackBar.message}
              </Alert>
            </Snackbar>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <div style={{ height: 300, width: '100%' }}>
              <DataGrid className={classes.billingTable} rows={subscriptionHistory} columns={columns} rowHeight={70} disableColumnMenu disableColumnFilter hideFooter />
            </div>
          </TabPanel>

          <PaywallPricesStatusDialog
            succces={payWallStatus}
            open={payWallStatusModal}
            onClose={() => setPayWallStatusModal(false)}
            onConfirm={() => setPayWallStatusModal(false)}
          />
        </>
      )}
    </>
  )
}

export default Subscription
