import { makeStyles } from '@material-ui/core';
import Colors from '@common/constants/Colors';

export default makeStyles((theme) => ({
  relative: {
    position: 'relative',
  },
  resourceButtonBase: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: 'left',
    borderRadius: 10,
    transition: 'all ease 0.4s',
    '&:hover': {
      backgroundColor: Colors.lightBlue2
    },
    backgroundColor: Colors.white,
    boxShadow: `0 0px 12px 0 ${Colors.shadowColor} !important`,
  },
  resourceButton: {
    padding: 16,
    boxShadow: 'none !important',
  },

  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 10
  },
  hideContainer: {
    height: 60
  },
  imageContainer: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 12,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: 'white',
    boxShadow: '0 6px 12px 0 rgba(67, 84, 221, 0.15)',
  },
  imageItem: {
    width: 40,
    height: 40,
    objectFit: 'cover',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '90%',
    transition: 'all ease 0.3s',
    flex: 1
  },
  hideContent: {
    opacity: 0,
    '& .description': {
      whiteSpace: 'nowrap'
    }
  },
  resourceName: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  resourceDesc: {
    color: theme.palette.text.primary,
    fontSize: 12
  },
  resourceDate: {
    color: theme.palette.text.secondary,
    fontSize: 12
  },
  stat: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  statItem: {
    marginRight: 10
  },
  statCount: {
    color: Colors.primary,
    marginRight: 3
  },
  selected: {
    boxShadow: `0 0 0 2px ${Colors.primary} !important`,
  },
  removeButton: {
    width: 24,
    height: 24,
    backgroundColor: Colors.white
  },
  removeIcon: {
    width: 14,
    height: 14
  },
  imageContainerFull: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    marginTop: 15,
    marginLeft: 50,

    '& img': {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
    }
  },
  video: {
    width: '100%',
    height: 200
  },
  iframe: {
    width: '100%',
    height: 150,
    borderRadius: 12
  },
  imageFull: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center'
  },
  imgPlaceholder: {
    height: 100,
    objectFit: 'contain'
  },
  resourceButtonSmall: {
    padding: 0, 
    height: 180,
    flex: '1 1 45%',
    overflow: 'hidden',
  },
  contentHeaderSmall: { padding: 12, width: '100%', textAlign: 'left' },
  textWrapperSmall: { display: 'flex', justifyContent: 'space-between', width: '100%' },
  titleSmall: { width: '80%', fontWeight: 500, textAlign: 'left' },
  iconSmall: { width: 22, height: 22 }
}))