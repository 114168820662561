import Colors from '@common/constants/Colors';
import { Chip } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import React from 'react'
import useStyles from './ItemSearchButton.styles'

function ItemSearchButton({
  disabled = false,
  active,
  className = '',
  name = Colors.primary,
  onDelete,
  onClick
}: {
  disabled?: boolean
  active: boolean
  name: string
  className?: string
  onDelete?: () => void
  onClick?: () => void
}) {
  const classes = useStyles()
  return (
    <Chip
      disabled={disabled}
      label={name}
      deleteIcon={onDelete !== undefined ? <Close className={`${active ? classes.active : ''} `} fontSize="small" /> : <></>}
      onDelete={() => {
        if (onDelete !== undefined) onDelete()
      }}
      clickable={onClick !== undefined ? true : false}
      onClick={() => {
        if (onClick !== undefined) onClick()
      }}
      className={`${classes.label} ${active ? classes.active : ''} ${className}`}
    />
  )
}

export default ItemSearchButton