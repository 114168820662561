import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  tableContainer: {
  },
  nodeCell: {
    minWidth: '230px !important'
  },
  row: {
    padding: 32,
  },
  cell: {
    border: 'none'
  },
  column: {},
  avatar: {
    cursor: 'pointer'
  },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  nameValue: {
    cursor: 'pointer'
  },
  nodeEditable: {
    borderRadius: 8,
    cursor: 'text',
    '&:hover': {
      backgroundColor: Colors.newBgLightBlue
    }
  },
  nameContainer: {
    marginLeft: 8,
  },
  nameDesc: {
    opacity: 0.6,
    fontSize: 12,
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 160
  },
  addButton: {
    color: Colors.primary,
    boxShadow: 'none !important',
    marginTop: 12,
    marginBottom: 12
  },
  addButtonIcon: {
    fontSize: 20,
  },
  cellTextField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.primary
        }
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: 12,
      fontSize: 14
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  docs: {
    borderRadius: 12,
    padding: 2,
    color: Colors.primary,
    textDecoration: 'underline'
  },
  links: {
    // display: 'block',
    padding: '2px 4px',
    transform: 'translateX(-6px)',
    borderRadius: 12,
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    minWidth: 'unset',
    '& img': {
      display: 'inline-block'
    },
    '& p': {
      display: 'inline-block',
      textDecoration: 'underline',
      color: Colors.primary,
      fontSize: 12
    },
  },
  linksIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
    color: Colors.primary,
  },
  stickyLeft: { position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 },
  linkContainer: {
    // marginTop: 6,
  },
  bulkSelection: {
    backgroundColor: 'white',
    '& .bulk-inner-container': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  selection: {

  },
  addColumnIcon: {
    color: Colors.primary
  },
  addColumn: {
    backgroundColor: 'transparent'
  },
  selectionMenuButton: {
    minHeight: 24,
    transition: 'all ease 0.4s',
    color: Colors.primary
  },
  selectionMenuIcon: {
    color: Colors.primary,
    transition: 'all ease 0.4s',
    width: 24,
    height: 24
  },
  addRowCell: {
    padding: '0px 11px !important',
  },
  selectionMenuContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))