import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: { height: '100%' },
  swiper: { height: 'calc(100% - 64px)' },
  swiperSlide: { height: '100%', overflow: 'auto' },
  nodePanelContainer: {
    minHeight: 'calc(100% - 16px)',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    paddingTop: 0,
    flex: 1,
    gap: 16
  },
  p16: { padding: 16 },
  py16: { padding: '16px 0px' },
  px16: { padding: '16px 0px' },
  label: { fontWeight: 600 },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16
  },
  headerTitle: {
    display: 'flex',
    maxWidth: '65%'
  },
  backIcon: {
    color: Colors.black,
    fontSize: 24
  },
  connectedItemButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    boxShadow: 'none !important',
  },
  connectedItemArrow: {
    fontSize: 18,
    color: Colors.greyLabel
  }
}))