/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

// todo: find unused colors

const Colors = {
  transparent: 'rgba(0,0,0,0)',
  text: '#212529',
  textPrimary: '#060A22',
  textLight: 'rgba(6, 10, 34, 0.5)',
  textMediumLight: 'rgba(6, 10, 34, 0.85)',
  primary: '#4354dd',
  primaryFaded: '#8692e9',
  success: '#28a745',
  error: '#dc3545',
  main: '#090c9b',
  secondary: '#301aa7',
  whiteMin30: '#484EAD',
  whiteMin60: '#AFB1DC',
  yellow: '#ffbd00',
  red: '#fe391f',
  orange: '#fb8b42',
  darkRed: '#d72638',
  redPurple: '#C91141',
  purple: '#9e0059',
  green: '#51cb20',
  darkPurple: '#462255',
  aqua: '#abd2fa',
  lightBlue: '#47a8bd',
  lightBlue2: '#EDEDFF',
  brown: '#4c1c00',
  white: '#ffffff',
  blueTag: '#506CBB',
  whiteGrey: '#F0F1F9',
  black: '#000000',
  blackFilter: 'rgba(0,0,0,0.9)',
  grey: '#757575',
  grey4: '#444444',
  grey40: '#404040',
  greyLabel: 'rgba(0,0,0,0.4)',
  greyBorderBottom: '#D0D0D1',
  bgLightBlue: 'rgba(67, 84, 221, 0.02)',
  bgBlueEnrichment: 'rgba(67, 84, 221, 0.05)',
  bgDark: '#002884',
  shadowColor: 'rgba(6, 10, 34, 0.1)',
  scrollBarColor: '#d0d0da',
  darkGrey: '#93959F',
  newBgLightBlue: '#F6F6FD',
  redError: '#f44336',
  greyBackground: '#FAFAFB',
  secondaryStroke: "#E2E2E7",
  secondaryToast: "#414245",
  mediumBlue: '#C7CCF5',
  newColors: {
    melon: '#fdbcb4',
    burntSienna: '#e76f51',
    rawSienna: '#ce8d66',
    sandyBrown: '#f4a261',
    deepChampagne: '#f2d0a4',
    teaGreen: '#c8d6af',
    persianGreen: '#2a9d8f',
    tealBlue: '#077187',
    gloucous: '#8692e9',
    lavenderBlue: '#cfbff7',
    magentaHaze: '#9e4770',
    vividBurgundy: '#a4303f',
    darkIndigo: '#060a22',
    paleLilac: '#e7eaff',
    paleGrey: '#e1e3ed',
    warmBlue: '#4354dd',
    scarlet: '#d50001',
    pageGrey: '#e7e9f0',
  },
  random: assignRandomColor,
  default: defaultColor
}

export default Colors

export const newColors = [
  '#fdbcb4',
  '#e76f51',
  '#ce8d66',
  '#f4a261',
  '#f2d0a4',
  '#c8d6af',
  '#2a9d8f',
  '#077187',
  '#8692e9',
  '#cfbff7',
  '#9e4770',
  '#a4303f'
]

export function assignRandomColor() {
  return newColors[Math.floor(Math.random() * newColors.length)]
}

export function defaultColor() {
  return '#4354dd'
}
