import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  explorerSvg: {
    overflow: 'hidden',
    fontSize: '35px',
    fontFamily: 'monospace',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    // border: 'solid 2px blue'
  }
}));