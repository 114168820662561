
import { makeStyles } from '@material-ui/core/styles'
export default makeStyles((theme) => ({
  container: {
    paddingTop: 32,
    paddingBottom: 40,
    height: '100%'
  },
  containerGuest: {
    paddingTop: 140
  },
  tabs: {
    display: 'flex',
    margin: theme.spacing(4, 0),
  },
  tab: {
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    fontSize: 18,
    opacity: 0.4
  },
  tabActive: {
    fontWeight: 600,
    opacity: 1
  },
  emptyDomain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 300px)',
    flexDirection: 'column',
    fontSize: 16
  },
  emptyDomainIcon: {
    width: 320,
    marginBottom: 40
  },
  newDomain: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 5
  },
  addButton: {
    marginRight: 20,
    height: 35,
    borderRadius: 12,
    textTransform: 'capitalize',
    padding: '5px 18px'
  },
}))
