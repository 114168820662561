import { defaultEntityContents } from "@common/constants/Constants"
import { isDev, validURL } from "@helpers/index"
import { BaseEditor, Editor, Element, Node, Path, Range, Transforms } from "slate"
import { ReactEditor } from "slate-react"

export const selectionString = (editor) => `[${editor.selection?.anchor.path.join(',')}] ${editor.selection?.anchor.offset} [${editor.selection?.focus.path.join(',')}] ${editor.selection?.focus.offset}`

export const withOverrides = (editor: BaseEditor) => {
  const { insertBreak, normalizeNode, deleteBackward, deleteFragment, deleteForward, insertSoftBreak, onChange } = editor

  editor.onChange = () => {
    if (isDev) {
      console.log('[DEV] onchange children', editor.children)
      console.log('[DEV] onchange selection', selectionString(editor))
    }
    onChange()
  }

  editor.insertBreak = () => {
    if (isDev) console.log('[DEV] insert break', selectionString(editor))
    if (!editor.selection || !Range.isCollapsed(editor.selection)) return insertBreak()
    const selectedNodePath = Path.parent(editor.selection.anchor.path)
    const selectedNode = Node.get(editor, selectedNodePath)
    // if insert break is in void (like image) 
    if (Editor.isVoid(editor, selectedNode)) return Transforms.insertNodes(
      editor,
      { ...defaultEntityContents[0] },
      {
        at: !Path.hasPrevious(selectedNodePath) ? [0] : Path.next(selectedNodePath),
        select: true,
      });
    return insertBreak()
  }

  editor.insertSoftBreak = () => {
    if (!editor.selection) return
    if (isDev) console.log('[DEV] insert soft break', selectionString(editor))
    const selectedNodePath = Path.parent(editor.selection?.anchor.path)
    const selectedNode = Node.get(editor, selectedNodePath)
    // @ts-ignore
    if (['code', 'paragraph', 'quote', 'block-quote'].includes(selectedNode?.type ?? '') && Range.isCollapsed(editor.selection)) {
      return Transforms.insertText(editor, '\n')
    }
    insertSoftBreak()
  }

  editor.deleteForward = (unit) => {
    if (isDev) console.log('[DEV] delete forward', unit, selectionString(editor))
    deleteForward(unit)
  }

  editor.deleteFragment = (direction) => {
    if (isDev) console.log('[DEV]', 'delete fragment', direction, selectionString(editor))
    if (!editor.selection) return
    deleteFragment(direction)
    if (editor.children.length === 0) Transforms.insertNodes(editor, { ...defaultEntityContents[0] });
  }

  editor.deleteBackward = unit => {
    if (!editor.selection || !Range.isCollapsed(editor.selection) || editor.selection.anchor.offset !== 0
    ) return deleteBackward(unit)
    const parentPath = Path.parent(editor.selection.anchor.path)
    const parentNode = Node.get(editor, parentPath)
    const parentIsEmpty = Node.string(parentNode)?.length === 0
    if (isDev) console.log('[DEV] delete backward', selectionString(editor), { unit, parentPath, parentNode, parentIsEmpty, hasPrev: Path.hasPrevious(parentPath) })

    if (parentIsEmpty) {
      // if inside root node
      if (!Path.hasPrevious(parentPath)) {
        if (Editor.hasPath(editor, Path.next(parentPath)) && parentPath.length > 1) {
          Transforms.insertNodes(editor, { ...defaultEntityContents[0] }, { at: Editor.start(editor, [0]) });
        }
        if (parentPath.length > 1) return Transforms.removeNodes(editor, { voids: true })
      }
      if (Path.hasPrevious(parentPath)) {
        const prevNodePath = Path.previous(parentPath)
        const prevNode = Node.get(editor, prevNodePath)
        if (Editor.isVoid(editor, prevNode)) return Transforms.removeNodes(editor, { hanging: true })
      }
    }
    deleteBackward(unit)
    if (editor.children.length === 0) Transforms.insertNodes(editor, { ...defaultEntityContents[0] });
  }

  editor.normalizeNode = (entry) => {
    const [node, path] = entry
    // @ts-ignore
    const { url, type, caption, children } = node || {}
    if (path?.length === 0) return
    if (!type && !children && Path.parent(path).length === 0) {
      // @ts-ignore
      return Transforms.wrapNodes(editor, { type: 'paragraph', children: [] }, { at: path })
    }
    // if node has type but has no children delete immediatly because its not valid
    if (type && !children?.length) return Transforms.delete(editor, { at: path })

    if (Element.isElement(node)) {
      if (validURL(url) && typeof caption !== 'undefined' && type !== 'image') {
        return Transforms.setNodes(editor, { ...node, type: 'image' }, { at: path })
      }
      if (validURL(url) && typeof caption === 'undefined' && type !== 'link') {
        return Transforms.setNodes(editor, { ...node, type: 'link' }, { at: path })
      }

      if (['bulleted-list', 'numbered-list'].includes(type)) {
        for (const [child, childPath] of Node.children(node, [])) {
          // @ts-ignore
          if (Element.isElement(child) && !editor.isInline(child)) {
            // @ts-ignore
            if (child?.type !== 'list-item') {
              // in case void item is exist inside list, wrap it with list item 
              if (Editor.isVoid(editor, child)) Transforms.unwrapNodes(editor, { at: path })
              // @ts-ignore
              else Transforms.setNodes(editor, { ...child, type: 'list-item' }, { at: [...path, ...childPath] })
            }
          }
        }
        return
      }

      // list item should be wrapped using bulleted/numbered list
      if (['list-item'].includes(type) && Path.parent(path).length === 0) Transforms.unwrapNodes(editor, { at: path })
    }

    normalizeNode(entry)
  }

  return editor as ReactEditor
}