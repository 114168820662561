import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  label: {
    backgroundColor: Colors.newBgLightBlue,
    borderRadius: 12,
  },
  active: {
    backgroundColor: `${Colors.primary} !important`,
    color: 'white'
  }
}))