import { makeStyles } from '@material-ui/core';
import Colors from '@common/constants/Colors';

export default makeStyles((theme) => ({
  outerContainer: {
    position: 'relative'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 15,
    borderRadius: '0px !important',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 80,
    '&:hover': {
      backgroundColor: Colors.lightBlue2
    }
  },
  imageContainer: {
    minWidth: 40,
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: 'white',
    marginRight: 10,
    boxShadow: '0 6px 12px 0 rgba(67, 84, 221, 0.15)'
  },
  imageItem: {
    minHeight: 40,
    minWidth: 40,
    maxWidth: 'initial',
    objectFit: 'cover'
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'all ease 0.3s',
    overflow: 'hidden',
  },
  hideContent: {
    opacity: 0,
    '& .labels': {
      whiteSpace: 'nowrap'
    }
  },
  nodeName: {
    color: Colors.primary,
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  labels: {
    padding: '2px 0px',
    color: theme.palette.text.secondary,
    fontSize: 12
  },
  stat: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  statItem: {
    marginRight: 10
  },
  statCount: {
    color: Colors.primary,
    marginRight: 3
  },
  selected: {
    boxShadow: `0 0 0 2px ${Colors.primary} !important`,
  },
  removeButton: {
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 0,
    width: 24,
    height: 24,
    backgroundColor: Colors.white,
    '& img': {
      width: 14,
      height: 14
    }
  }
}))