import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    '& .grid-card': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1);',
      width: '31.6%',
      [theme.breakpoints.down('md')]: {
        width: '45%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '98%',
      },
      margin: 10,
      position: 'relative',
      cursor: 'pointer',
      borderRadius: 12,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0,
        transition: 'opacity ease 0.5s',
        boxShadow: '0 4px 12px 0 rgba(67, 84, 221, 0.3)',
        borderRadius: 12
      },
      '& .MuiCard-root': {
        transition: 'all ease 0.5s',
      },
      '&:hover': {
        '&::before': {
          opacity: 1
        },
        '& .MuiCard-root': {
          transform: 'scale(1.02)'
        }
      },
      '&:active': {
        '&::before': {
          transform: 'scale(0.98) !important'
        },
        '& .MuiCard-root': {
          transform: 'scale(0.98) !important'
        }
      }
    }
  },
  emptyGrid: {
    minHeight: '65vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 0',
    flexDirection: 'column'
  }
}));