import { useCallback, useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { SearchResultType } from "@gloow/apiconsumer"
import { useHistory, useParams } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import Colors from "@common/constants/Colors";
import { RouteURI } from "@common/constants/Routes";
import { getResourceThumbnail } from "@helpers/resource";
import emptyNodeIcon from "@assets/images/empty-node.png";

import { useAutocomplete, iSearchResultExtended, iGroupedSearchResult } from "./useAutocomplete";
import useStyles from "./SearchAutoComplete.styles";
import Avatar from "../Avatar/Avatar";
import Spinner from "../Spinner/Spinner";

// Allow this function to create another type of element (Label, Resource, etc)
export interface iSearchAutocomplete {
  term: string;
  disableRedirection?: boolean;
  onGroupClick?: () => void;
  onItemClick?: (item: iSearchResultExtended) => void;
  onResultFound?: (results: iGroupedSearchResult[]) => void;
  onLoading?: (loading: boolean) => void;
  onCreateNode?: (term: string, doneCb: () => void) => Promise<void>;
}

export const SearchAutocomplete = ({
  term,
  disableRedirection = false,
  onItemClick,
  onGroupClick,
  onResultFound,
  onLoading,
  onCreateNode,
}: iSearchAutocomplete) => {
  const { results, resultFlat, onDone, loading, busy, hidden } = useAutocomplete(
    term,
    onResultFound
  );
  const classes = useStyles();
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const { t } = useTranslation();
  const [index, setIndex] = useState(-1);

  const onKeyDown = useCallback((e) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        return setIndex((index) => {
          return Math.min(index + 1, resultFlat.length - 1);
        });
      case "ArrowUp":
        e.preventDefault();
        return setIndex((index) => {
          if (index - 1 >= -1) return index - 1;
          return index;
        });
      case "Tab":
        e.preventDefault();
        if (resultFlat && resultFlat[index]) {
          return _onClick(resultFlat[index]);
        }
        return false;
      case "Enter":
        if (resultFlat && resultFlat[index]) {
          e.preventDefault();
          return _onClick(resultFlat[index]);
        } else if (onCreateNode && index === -1) {
          e.preventDefault();
          return onCreateNode(term, onDone);
        }

        return false;
      case "Escape":
        e.preventDefault();
        return onDone();
    }
    // eslint-disable-next-line
  }, [term, index, resultFlat, onDone, onCreateNode]);

  useEffect(() => {
    if ((results.length > 0 || onCreateNode) && !hidden) {
      window.addEventListener("keydown", onKeyDown)
    };
    return () => {
      window.removeEventListener("keydown", onKeyDown)
    };
    // eslint-disable-next-line
  }, [results.length, index, term]);

  if (!term) {
    return null;
  }

  const _onClick = (item) => {
    onDone();
    if (onItemClick) onItemClick(item);
    if (disableRedirection) return
    if (item.type === SearchResultType.Node) return history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${item.node?.id}`)
    if (item.type === SearchResultType.Label) return history.push(`/${slug}/${RouteURI.ENTITIES}/${item.label?.id}`)
    if (item.type === SearchResultType.Resource) return history.push(`/${slug}/${RouteURI.DOCUMENTS}/${item.resource?.id}`)
    return
  };

  const onGroupClicked = (type) => {
    if (onGroupClick) return onGroupClick()
    if (disableRedirection) return
    if (type === SearchResultType.Node) return history.push(`/${slug}/${RouteURI.SEARCH}/${RouteURI.FOCUS_MODE}`)
    if (type === SearchResultType.Label) return history.push(`/${slug}/${RouteURI.SEARCH}/${RouteURI.ENTITIES}`)
    if (type === SearchResultType.Resource) return history.push(`/${slug}/${RouteURI.SEARCH}/${RouteURI.DOCUMENTS}`)
    return
  }


  const renderItem = (item: iSearchResultExtended, idx: number) => {
    switch (item.type) {
      case SearchResultType.Node:
        return (
          <Button
            key={idx.toString()}
            className={`${classes.nodeContainer} ${classes.button} ${item.index === index ? classes.active : ""
              }`}
            onClick={() => _onClick(item)}
          >
            {/* @ts-ignore */}
            <Avatar name={item.node?.name ?? ""} src={item.node?.thumbnail_url ?? ""} />
            <div className={classes.nodeTextContainer}>
              <Typography variant="body2" className={classes.shortText}>
                {item.shortText}
              </Typography>
              <Typography variant="body2" className={classes.longText}>
                {item.shortText}
              </Typography>
            </div>
          </Button>
        );
      case SearchResultType.Label:
        return (
          <Button
            key={idx.toString()}
            className={`${classes.nodeContainer} ${classes.button} ${item.index === index ? classes.active : ""
              }`}
            onClick={() => _onClick(item)}
          >
            <div
              className={classes.labelColor}
              style={{ backgroundColor: item.label?.color ?? Colors.primary }}
            ></div>
            <Typography
              variant="body2"
              className={`${classes.shortText} ${classes.labelName}`}
            >
              {item.shortText}
            </Typography>
          </Button>
        );
      case SearchResultType.Resource:
        if (!item.resource) return;
        const src = getResourceThumbnail(item.resource);
        return (
          <Button
            key={idx.toString()}
            className={`${classes.nodeContainer} ${classes.button} ${item.index === index ? classes.active : ""
              }`}
            onClick={() => _onClick(item)}
          >
            <img src={src} className={classes.resourceImg} alt="resource" />
            <Typography
              variant="body2"
              className={`${classes.shortText} ${classes.labelName}`}
            >
              {item.shortText}
            </Typography>
          </Button>
        );
      default:
        return (
          <Button
            key={idx.toString()}
            className={`${classes.button} ${item.index === index ? classes.active : ""
              }`}
            onClick={() => _onClick(item)}
          >
            <Typography variant="body1" className={classes.shortText}>
              {item.shortText}
            </Typography>
            <Typography variant="body2" className={classes.longText}>
              {item.longText.slice(0, 48)}
            </Typography>
          </Button>
        );
    }
  };

  const getGroupType = (type: SearchResultType) => {
    var name = type as string;
    if (name === SearchResultType.Node) name = t("common.entities", "Entities");
    else if (name === SearchResultType.Resource)
      name = t("common.doc_and_media", "Doc & Media");
    else if (name === SearchResultType.Label)
      name = t("common.labels", "Labels");
    return (
      <Button
        onClick={() => onGroupClicked(type)}
        className={`${classes.button} ${classes.groupButton}`}
      >
        <Typography variant="body2" className={classes.groupName}>
          {name}
        </Typography>
        <ChevronRight className={classes.chevron} />
      </Button>
    );
  };

  const renderResult = () => {
    const elements = (results as any[]).map((res, i) => (
      <div className={classes.group} key={i.toString()}>
        {getGroupType(res.type)}
        {res.data.map((d: iSearchResultExtended, idx) => renderItem(d, idx))}
        {i + 1 < results.length && <div className={classes.separator}></div>}
      </div>
    ));

    if (onCreateNode) {
      elements.unshift(
        <div className={classes.group} key={`new-node`}>
           <Button className={`${classes.nodeContainer} ${classes.button} ${
             index === -1 ? classes.active : ""
           }`} onClick={() => {
            console.log('onCreateNode', term)
            onCreateNode!(term, onDone)
           }}>
             <Typography variant='body2'>{t('common.create_new_node', `Create new node '${term}'`, { name: term })}</Typography>
           </Button>
        </div>
      )
    }
    else if (!results.length && !busy) {
      elements.push(<div className={classes.notFound}>
        <img src={emptyNodeIcon} alt="No result found img" />
        <Typography variant="body2">
          {t("common.no_result_found", "No result found")}
        </Typography>
      </div>)
    }
    return elements
  };


  const renderLoader = () => (
    <div className={classes.loadingContainer}>
      <Spinner size={"sm"} />
    </div>
  );

  if (hidden) return null;

  return (
    <div className={classes.container}>
      {loading ? renderLoader() : renderResult()}
    </div>
  );
};
