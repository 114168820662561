
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import useStyles from './ResourceLoader.styles'

const ResourceLoader = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={classes.color}><Skeleton variant="circle" width={48} height={48} /></div>
        <div className={classes.name}><Skeleton height={40} /></div>
      </div>
      <div className={classes.info}>
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
      </div>
      <div className={classes.avatar}>
        <Skeleton variant="rect" width={'100%'} height={200} />
      </div>
      <div className={classes.info}>
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
      </div>
    </div>
  )
}

export default ResourceLoader