import React, { useState } from 'react';
import { iLabel, iOntology } from "@gloow/apiconsumer";
import useStyles from './Labels.styles'
import Label from '@common/components/Label/Label';
import LabelPicker from './LabelPicker';
import { iLabelType } from "@gloow/apiconsumer";
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { applyLabelToNode, applyOntologiesToNodes, removeLabelFromNode } from '@services/LabelService';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { setLastUpdate } from '@store/domain/domainSlice';
import { isOntology } from '@helpers/labels';
import { updateNodeField } from '@store/nodes/nodesSlice';
interface ILabels {
  labels: iLabel[],
  disabled?: boolean,
  editable?: boolean,
  domainUuid: string,
  nodeId?: number,
  labelType: iLabelType,
  onDeleted?: (label: iLabel) => void
  onSelected?: (label: iLabel | iOntology) => void,
  onClick?: (label: iLabel | Partial<iLabel>) => void
}

const Labels = ({
  labelType,
  labels,
  disabled = false,
  editable = false,
  domainUuid,
  nodeId,
  onDeleted,
  onSelected,
  onClick = (label) => { }
}: ILabels) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const nodes = useAppSelector(state => state.nodes.nodes)
  const dispatch = useAppDispatch()

  const _onSelected = async (data: iLabel | iOntology) => {
    if (onSelected) return onSelected(data)
    if (labelType === iLabelType.NODE && nodeId) {
      setLoading(true)
      if (isOntology(data)) {
        const labels = await applyOntologiesToNodes([nodeId], [data] as iOntology[])
        if (!labels.length) return setLoading(false)
        const updatedNode = nodes.find(d => d.id === nodeId)
        if (!updatedNode) return setLoading(false)
        dispatch(updateNodeField({ id: nodeId, color: updatedNode.color, labels: [...updatedNode.labels, ...labels], updatedAt: updatedNode.updatedAt }))
      }
      else await applyLabelToNode(data.id, nodeId)
      setLoading(false)
      dispatch(setLastUpdate(Date.now()))
    }
    else if (labelType === iLabelType.DOMAIN && domainUuid) {
      console.log('handle label select for domain')
    }
  }

  const _onDeleted = async (data: iLabel) => {
    if (onDeleted) return onDeleted(data)
    if (labelType === iLabelType.NODE && nodeId) {
      const res = await removeLabelFromNode(data.id, nodeId)
      if (res) dispatch(setLastUpdate(Date.now()))
    }
    else if (labelType === iLabelType.DOMAIN && domainUuid) {
      console.log('handle delete label for domain')
    }
  }

  return (
    <div className={classes.labelContainer}>
      {labels?.map((label, index) => <Label
        editable={editable}
        disabled={loading || disabled}
        key={index.toString()}
        label={label as iLabel}
        onClick={onClick}
        onRemove={editable ? (d) => _onDeleted(d as iLabel) : undefined}
      />)}
      {!editable && labels.length === 0 && <Typography variant='body2'>{t('common.no_labels')}</Typography>}
      {editable && (
        <LabelPicker
          labelType={labelType}
          disabled={disabled}
          selectedLabels={labels}
          domainUuid={domainUuid}
          nodeId={nodeId}
          onSelected={_onSelected}
        />
      )}
    </div>
  );
}

export default Labels;
