import React from 'react'
import useStyles from './Indicator.styles'

export interface iSteps {
  id: string
  name?: string
}

interface iIndicator {
  active: number
  steps: number
}

const Indicator = ({
  active,
  steps
}: iIndicator) => {
  const classes = useStyles()

  const renderSteps = () => {
    const elements: any[] = []
    for (let i = 1; i <= steps; i++) {
      elements.push(<div
        key={i.toString()}
        className={`${classes.indicatorItem}`}
      >
        <div
          className={`
            ${classes.indicatorNumber}
            ${active === i ? classes.indicatorActive : (i <= active ? classes.indicatorPassed : '')}`
          }
        >
          {i}
        </div>
      </div>)
    }
    return elements
  }

  return (
    <div className={classes.indicator}>
      {renderSteps()}
    </div>
  )
}

export default Indicator
