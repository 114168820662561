import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography, TextField, Button } from '@material-ui/core'
import { useAppDispatch } from '@store/hooks';
import { iNode } from '@gloow/apiconsumer';
import { instanceOfNode } from '@helpers/utils';
import AnalyticsService from "@services/AnalyticsService";
import { setLastUpdate } from '@store/domain/domainSlice';
import { iInteractionDialog } from '@common/constants/Constants';
import { VertexType } from '@gloow/navimator';

import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup'
import { addWebLink } from '@services/DomainDataService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    dialogTitle: {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 24,
      width: '80%'
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      minWidth: 600
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    },
    webLinkInput: {
      marginRight: 15,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderRadius: 12
      }
    },
    addButton: {
      height: 40
    },
    form: {
      display: 'flex',
    }
  }),
);

export interface IAddWebLink {
  dialogProps: iInteractionDialog,
  handleClose: (resourceId?: string | number) => void
}

function AddWebLinkDialog({ dialogProps, handleClose = () => { } }: IAddWebLink) {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [connectToNode, setConnectToNode] = useState<iNode>()
  const [loading, setLoading] = useState<boolean>(false)
  const schema = yup.object({
    link: yup.string().url(t('common.link_is_not_valid', 'This does not look like a valid web link. Please don’t forget to also add the protocol. (example: https://en.wikipedia.org/wiki/Web_page)'))
  })

  const initialValues = {
    link: null
  }

  useEffect(() => {
    setConnectToNode(dialogProps.connectTo?.type === VertexType.Node ? dialogProps.connectTo?.data as iNode : undefined)
    return () => { }
  }, [dialogProps.connectTo]);


  const _addWebLink = async (values) => {
    setLoading(true)
    try {
      const response = await addWebLink(values.link, connectToNode && instanceOfNode(connectToNode) ? connectToNode.id : undefined)
      setLoading(false)
      if (!response) return
      dispatch(setLastUpdate(Date.now()))
      handleClose(response.resource.id)
    } catch (e) {
      AnalyticsService.logError('add-web-link-error', { e });
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog onClose={() => handleClose()} open={dialogProps.open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3">
          {t('common.add_web_link')}
        </Typography>
        <CloseIcon id="close" className={classes.dialogBackIcon} onClick={() => handleClose()} />
      </div>
      <Formik onSubmit={_addWebLink} validationSchema={schema} initialValues={initialValues}>
        {({ handleSubmit, handleChange, errors, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              id="link"
              className={classes.webLinkInput}
              placeholder={t('common.enter_the_link')}
              variant="outlined"
              value={values.link}
              color="primary"
              size="small"
              name='link'
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              error={Boolean(errors.link)}
              helperText={errors.link}
            />
            <Button
              id="submit"
              type="submit"
              disabled={loading}
              className={classes.addButton}
              variant="contained"
              color="primary"
              size="medium"
            >
              {t('common.add')}
            </Button>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddWebLinkDialog;
