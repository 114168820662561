import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
  },
  title: { padding: '24px 32px', fontSize: 40, lineHeight: 1, fontWeight: 600 },
  innerContainer: {
    margin: '0px 32px'
  },
  textField: {
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1,
    },
    '& .MuiInputLabel-shrink': {
      fontSize: 16,
      fontWeight: 'normal'
    },
    '& .Mui-disabled': {
      color: `${Colors.text} !important`
    }
  },
  answerContainer: {
    backgroundColor: Colors.bgBlueEnrichment,
    borderRadius: 12,
    padding: 32,
    marginTop: 32
  },
  contentContainer: {
  },
  answerTitle: {
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: 24
  },
  answerContent: {
  },
  suggestionTitle: {
    fontWeight: 600,
    marginTop: 32
  },
  horizontalSlider: {
    padding: '24px 28px',
    '& .react-multi-carousel-track': {
      width: '100%',
    },
    '& .grid-card': {
      height: '100%',
      boxShadow: `0 4px 12px 0 ${Colors.shadowColor} !important`,
      cursor: 'pointer',
      borderRadius: 12,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0,
        transition: 'opacity ease 0.5s',
        boxShadow: '0 4px 12px 0 rgba(67, 84, 221, 0.3)',
        borderRadius: 12
      },
      '& .MuiCard-root': {
        transition: 'all ease 0.5s',
      },
      '&:hover': {
        '&::before': {
          opacity: 1
        },
        '& .MuiCard-root': {
          transform: 'scale(1.02)'
        }
      },
      '&:active': {
        '&::before': {
          transform: 'scale(0.98) !important'
        },
        '& .MuiCard-root': {
          transform: 'scale(0.98) !important'
        }
      }
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .react-multiple-carousel__arrow': {
      backgroundColor: `${Colors.primary} !important`,
      height: 32,
      width: 32,
      minHeight: 'unset',
      minWidth: 'unset',
      '&:before': {
        fontSize: 16
      }
    }
  },
  gridContainer: {
    padding: 32,
    gap: 24,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  gridCardLoading: {
    borderRadius: 12,
    padding: 32
  },
  info: {
    display: 'flex',
    backgroundColor: Colors.greyBackground,
    margin: '12px 32px',
    padding: 17,
    borderRadius: 12,
    alignItems: 'center'
  },
  infoText: {
    paddingLeft: 12,
  },
  infoIcon: {
    height: 17,
    width: 17
  },
  questionLabel: {
    fontSize: 12,
    fontWeight: 'normal',
  },
  question: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 8
  },
  previousButton: {
    position: 'absolute',
    left: 0,
    top: '50%'
  },
  nextButton: {
    position: 'absolute',
    right: 0,
    top: '50%'
  },
  sliderItem: {
    margin: 12,
    minWidth: 300
  },
  sliderDot: {
    height: 4,
    width: 16,
    backgroundColor: Colors.primary,
    margin: '0px 4px',
    borderRadius: 12,
    opacity: 0.5,
    transition: 'all ease 0.2s',
    '&.active': {
      opacity: 1,
      width: 40
    }
  },
  errorContainer: {
    margin: 32,
    padding: 32,
    borderRadius: 12,
    backgroundColor: Colors.bgBlueEnrichment
  },
  email: {
    color: Colors.primary
  }
}))