import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    boxShadow: 'none !important',
    fontSize: 16,
    fontWeight: 500,
    '& .MuiButton-endIcon': {
      marginLeft: '0px !important',
    }
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 500
  },
  menuItemActive: {
    background: `${Colors.lightBlue2} !important`,
  },
  expand: {
    fontSize: 16,
    color: 'black'
  }
}))