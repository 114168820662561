import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

const headerHeight = 80
const sideBarWidth = 392
const sideBarWidthSM = 292
export default makeStyles((theme) => ({
  container: {
    padding: '24px 32px',
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    flex: 1
  },
  row: {
    flex: 1,
    display: 'flex',
    gap: 24,
    flexWrap: 'wrap'
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  cardContainerSm: {
    flex: '1 0 auto !important',
    padding: 24,
    maxWidth: 450
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600
  },
  cardContainer: {
    flex: 1,
    minWidth: 450,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: 24,
    borderRadius: 12,
    minHeight: 450,
  },


  nodeCard: {
    padding: '0px !important',
    maxHeight: 540,
    '& .node-info-tab': {
      maxHeight: 540
    }
  },
  cardRow: {
    display: 'flex',
    gap: 32
  },
  subtitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600
  },
  viewAll: {
    color: Colors.primary,
    backgroundColor: 'transparent'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    flex: 1,
    padding: '24px 32px',
  },
  goals: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    },
    '& label:not(:first-child)': {
      borderTop: `1px solid ${Colors.greyBorderBottom}`,
    }
  },
  documents: {
    display: 'flex',
    gap: 24
  },

  sideBar: {
    marginTop: headerHeight,
    paddingBottom: headerHeight,
    width: sideBarWidth,
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: sideBarWidthSM,
    },
    boxShadow: '0px 12px 12px rgba(0,0,0,0.1)',
  },
  closeButton: {
    position: 'absolute',
    right: 24,
    top: 22
  },
  closeIcon: {
    fontSize: 24,
    color: Colors.primary
  },
  connectToNode: {
    padding: 24,
    paddingTop: 0,
  },
  emptyTab: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonContainer: {
    display: 'flex',
    gap: 12
  },
}))