import Colors from '@common/constants/Colors'
import { makeStyles, Theme } from '@material-ui/core/styles'
export default makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-center',
    padding: '8px 16px',
    paddingTop: 0
  },
  tab: {
    overflow: 'auto',
    height: 'calc(100vh - 148px)'
  },
  name: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: 1,
      paddingTop: 4
    },
    '& .MuiInputLabel-shrink': {
      fontSize: 16
    }
  },
  nodeColorWrap: {
    width: 48,
    height: 48,
    borderRadius: 48,
    marginRight: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    cursor: 'pointer'
  },
  node: {
    width: 24,
    height: 24,
    backgroundColor: 'white',
    borderRadius: 24,
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
    marginRight: -12,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  nodeDim: {
    width: 14,
    height: 14
  },
  info: {
    lineHeight: 1.3,
    fontWeight: 'normal',
    wordBreak: 'break-word'
  },
  submit: {
    padding: 8,
    '& .MuiButton-label': {
      color: 'white'
    }
  },
  imageButton: {
    marginTop: 33,
    borderRadius: 12,
    width: '100%',
    overflow: 'hidden'
  },
  imageItem: {
    borderRadius: 12,
    width: '100%',
    objectFit: 'cover'
  },
  thumbnailWrapper: {
    width: 72,
    height: 72,
    minWidth: 72,
    borderRadius: 12,
    marginRight: 16,
    overflow: 'hidden'
  },
  thumbnail: {
    width: 72,
    height: 72,
    objectFit: 'cover'
  },
  createdAt: {
    marginTop: 8,
    fontWeight: 400,
    color: Colors.darkGrey
  },
  deleteButton: {
    boxShadow: 'none !important',
    transform: 'translate(-6px, 0px)'
  },
  deleteNode: {
    color: '#d50001'
  },
  deleteIcon: {
    marginRight: 8,
  },
  connectedNodesTitle: {
    fontWeight: 600,
    margin: 16
  },
  connectedNodes: {
    '& .MuiButtonBase-root.node-card': {
      marginTop: 16,
      borderRadius: '12px !important',
      boxShadow: '0 6px 12px 0 rgba(67, 84, 221, 0.15)',
      minHeight: 'unset',
      width: '100%'
    },
    padding: '8px 16px'
  },
  connectToNode: {
    marginTop: 16
  },
  video: {
    width: '100%',
    height: 200
  },
  iframe: { width: '100%', height: 190, borderRadius: 12 },
  dialogBackArrow: {
    width: 27,
    height: 27,
  },
  arrowBackIcon: {
    color: 'black',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    paddingBottom: 64,
    gap: 12,
    alignItems: 'flex-start',
    '& button': {
      padding: '6px 8px',
      borderRadius: 12,
      boxShadow: 'none !important',
      textAlign: 'left',
      color: Colors.newColors.scarlet
    },
  },
  connectNodeWrapper: {
    position: 'absolute',
    bottom: 0,
    padding: 16,
    width: '100%',
    '& .MuiButton-root': {
      '& .MuiButton-startIcon': {
      }
    },
    zIndex: 20,
    background: 'white'
  },
}))
