import React, { useEffect, useRef, useState } from 'react'
import Colors from '@common/constants/Colors'
import { RouteURI } from '@common/constants/Routes'
import useDomain from '@hooks/useDomain'
import { Button, IconButton, TextField, Menu, MenuItem, Typography } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AnalyticsService from '@services/AnalyticsService'
import { iLabel } from '@gloow/apiconsumer'
import { deleteLabel, deleteLabelWithNodes } from '@services/DomainDataService'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import ColorButton from '../../ColorButton/ColorButton'
import ConfirmDialog from '../../Dialog/ConfirmDialog/ConfirmDialog'
import useStyles from './EntityHeader.styles'

import IconList from '@assets/images/icons-list.svg'
import IconWindow from '@assets/images/icons-window.svg'
import IconImport from '@assets/images/icons-import.svg'
import IconDelete from '@assets/images/delete.svg'

import ColorDialog from '../../Dialog/ColorDialog/ColorDialog'
import { createLabel, updateLabel } from '@services/EntityService'
import { EntityDisplayMode, EntityRouteURI } from '@store/entities/entities.interfaces'
import { setEntityDisplayMode } from '@store/filters/filtersSlice'


const EntityHeader = ({ data, disabled = false, displayMode }: { data?: iLabel, disabled?: boolean, displayMode?: EntityDisplayMode }) => {
  const form = useRef<any>()
  const { t } = useTranslation()
  const { domainPermissions } = useDomain()
  const [colorDialog, setColorDialog] = useState(false)
  const [deleteState, setDeleteState] = useState<'label_only' | 'label_with_nodes' | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<any>(null)
  const [rows] = useAppSelector(state => [state.entities.rows])
  const dispatch = useAppDispatch()

  const { slug, route, labelId } = useParams<{ slug?: string, route?: string, labelId?: string }>()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const schema = yup.object({
    name: yup.string().required(t('common.name_is_required')),
    color: yup.string()
  })

  const initialValues = {
    name: data?.name ?? '',
    color: data?.icon ?? Colors.default()
  }

  useEffect(() => {
    form.current?.resetForm({ errors: {}, touched: {} });
    form.current?.setFieldValue('name', data?.name ?? '', false)
    form.current?.setFieldValue('color', data?.color ?? Colors.default(), false)
    return () => { }
    // eslint-disable-next-line
  }, [data?.id, labelId])

  const onLabelDelete = async () => {
    try {
      if (!data?.id) return
      setLoading(true)
      const nodeIds = rows?.filter(d => d.id).map(d => d.id) as number[]
      if (deleteState === 'label_only') {
        await deleteLabel(data?.id!, nodeIds)
      }
      else if (deleteState === 'label_with_nodes') {
        await deleteLabelWithNodes(data?.id!, nodeIds)
      }
      setDeleteState(null)
      setLoading(false)
      // if (labels.length > 0) return history.replace(`${history.location.pathname}?id=${labels[0].id}`)
      return history.replace(history.location.pathname)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-delete-label', { e })
    }
  }

  const renderDeleteDialog = () => {
    let textComponent = <></>
    if (deleteState === 'label_with_nodes') {
      textComponent = <Trans
        i18nKey="common.are_you_sure_want_to_delete_the_label_with_nodes"
        defaults="Are you sure you want to delete the label <bold>{{ labelName }}</bold> and <bold>all nodes with this label</bold>?"
        values={{ labelName: data?.name }}
        components={{ bold: <strong /> }}
      />
    }
    else if (deleteState === 'label_only') {
      textComponent = <Trans
        i18nKey="common.are_you_sure_want_to_delete_the_label"
        defaults="Are you sure you want to delete the label <bold>{{ labelName }}</bold>? this will not remove all nodes, but simply the label itself"
        values={{ labelName: data?.name }}
        components={{ bold: <strong /> }}
      />
    }
    return <ConfirmDialog
      loading={loading}
      open={Boolean(deleteState)}
      textComponent={<Typography variant='body1' align='center'>{textComponent}</Typography>}
      confirmText={`${t('common.delete')}`}
      cancelText={`${t('common.cancel')}`}
      onClose={() => setDeleteState(null)}
      onConfirm={() => onLabelDelete()}
    />
  }

  const renderDeleteMenu = () => {
    return <Menu
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      classes={{ paper: classes.moreMenu }}
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={() => setMenuAnchorEl(null)}
    >
      <MenuItem onClick={() => {
        setMenuAnchorEl(null)
        setDeleteState('label_only')
      }}>
        <img alt='delete' src={IconDelete} className={classes.moreMenuImg} />
        <Typography variant={'body2'} className={classes.moreMenuText}>
          {t('common.delete_label', 'Delete label')}
        </Typography>
      </MenuItem>
      {rows.length > 0 && (
        <MenuItem onClick={() => {
          setMenuAnchorEl(null)
          setDeleteState('label_with_nodes')
        }}>
          <img alt='delete' src={IconDelete} className={classes.moreMenuImg} />
          <Typography variant={'body2'} className={classes.moreMenuText}>
            {t('common.delete_label_and_nodes', 'Delete label + all nodes with this label')}
          </Typography>
        </MenuItem>
      )}
    </Menu>
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      if (data) {
        await updateLabel(data.id, values)
        setLoading(false)
      }
      else {
        const label = await createLabel(values)
        setLoading(false)
        if (label) return history.push(`/${slug}/${RouteURI.ENTITIES}/${label.id}`)
      }
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-submit', { e })
    }
  }

  const __onChangeDisplayMode = () => {
    const param = !displayMode ? EntityDisplayMode.grid : displayMode === EntityDisplayMode.grid ? EntityDisplayMode.table : EntityDisplayMode.grid
    return dispatch(setEntityDisplayMode(param))
  }

  return (
    <Formik validationSchema={schema} initialValues={initialValues} innerRef={form} onSubmit={onSubmit} >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.header}>
              <div className={classes.row}>
                {labelId !== 'all' && <ColorButton onClick={domainPermissions?.modify ? () => setColorDialog(true) : undefined} value={values.color} />}
                <TextField
                  disabled={loading || !domainPermissions?.modify || labelId === 'all'}
                  className={classes.entityTitle}
                  placeholder={labelId === 'all' ? `${t('common.all_entities', 'All entities')}` : `${t('common.name')}`}
                  name='name'
                  fullWidth
                  value={labelId === 'all' ? t('common.all_entities', 'All entities') : values.name}
                  onChange={handleChange}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.toString()}
                />
              </div>
              <div className={classes.actions}>
                {(data?.name !== values.name || (data?.color ?? Colors.default()) !== values.color || !data?.id) && labelId !== 'all' && domainPermissions?.modify &&
                  <Button type='submit' className={classes.submit} disabled={loading || disabled} variant={'contained'} color="primary">
                    {t('common.save')}
                  </Button>
                }

                {data && domainPermissions?.modify && route !== EntityRouteURI.import && (
                  <Button
                    disabled={loading || disabled}
                    variant="contained"
                    color="primary"
                    className={classes.importButton}
                    startIcon={<img alt='import' src={IconImport} />}
                    onClick={(e) => history.replace(`/${slug}/${RouteURI.ENTITIES}/${data.id}/${EntityRouteURI.import}`)}
                  >
                    {t('entities.import_csv', 'Import CSV')}
                  </Button>
                )}

                {data?.id && route === EntityRouteURI.import &&
                  <Button
                    disabled={loading}
                    className={classes.cancelButton}
                    onClick={() => history.replace(`/${slug}/${RouteURI.ENTITIES}/${data.id}`)}
                    variant='contained'
                    color='secondary'
                  >{t('common.cancel')}</Button>
                }

                {(data?.id || labelId === 'all') && (
                  <IconButton
                    size='small'
                    disabled={loading || disabled}
                    className={classes.moreButton}
                    onClick={__onChangeDisplayMode}>
                    {displayMode !== EntityDisplayMode.table ?
                      <img src={IconList} className={classes.visualSwitch} alt='img' /> :
                      <img src={IconWindow} className={classes.visualSwitch} alt='img' />
                    }
                  </IconButton>
                )}

                {data?.id && domainPermissions?.remove && (
                  <>
                    <IconButton size='small' className={classes.moreButton} color="primary" onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                      <MoreVert fontSize='medium' />
                    </IconButton>
                    {renderDeleteMenu()}
                    {renderDeleteDialog()}
                  </>
                )}
              </div>
            </div>
            <ColorDialog
              backButton={false}
              open={colorDialog}
              selectedValue={values.color}
              onClose={(value) => {
                if (value && Colors.newColors[value]) setFieldValue('color', Colors.newColors[value])
                setColorDialog(false)
              }} />
          </form>
        )
      }}
    </Formik>
  )
}

export default EntityHeader
