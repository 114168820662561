import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "@store/hooks";
import { TheLoader } from "@common/components";
import { Ator, syncUserOnboarding, syncUserSubscribe, logout, guestLogin } from '@services/AuthService';
import { APPLICATION_NAME } from "@env/constants";
import jwtDecode from 'jwt-decode'
import { iRoute } from "src/routes/index.interfaces";

const ProtectedRoute = ({ component: Component, name, allowGuest = false, ...restOfProps }: iRoute) => {
  const history = useHistory()
  const session = useAppSelector(state => state.session)
  const [loading, setLoading] = useState<boolean>(true)
  const location = useLocation()
  const mounted = useRef<any>(null)

  const userDataSync = async () => {
    await syncUserOnboarding()
    await syncUserSubscribe()
  }

  const redirectToLogin = (message = '') => {
    logout()
    return history.push('/auth', { error: message, referer: location.pathname })
  }

  const authenticateGuest = async () => {
    const guestTokenUsed = localStorage.getItem('__gtused')
    //if guest token ever used, logout immediately
    if (guestTokenUsed === 'true') return redirectToLogin('Please signup/login first.')
    await guestLogin()
  }

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    // self-executing anonymous function
    ;(async () => {
      if (session.loading === false) {
        // case #1: no id token
        // case #1.1: route is allowed for guest, try login as guest
        // case #1.2: route is not allowed for guest, no idtoken, or refresh token -> logout & go to auth
        // case #2: valid idtoken & access token -> set tokens & continue
        // case #3: idtoken or access token expired/null -> try refresh or go to auth

        // case #1
        if (!session.idToken) {
          // case #1.1
          if (allowGuest) await authenticateGuest()
          // case #1.2
          else {
            console.log("no id token found, logging out")
            return redirectToLogin()
          }
        } else if (session.accessToken) {
          const decodedIdToken = jwtDecode(session.idToken);
          const decodedAccessToken = jwtDecode(session.accessToken);
          // case #2
          // @ts-ignore
          if (decodedIdToken.exp > Date.now() / 1000 && decodedAccessToken.exp > Date.now() / 1000) {
            Ator.authenticateUsingToken(session.idToken)
            Ator.setUserAccessToken(session.accessToken)
            Ator.setUserRefreshToken(session.refreshToken)
            if (!session.user.guest) await userDataSync()
          }
          // case #3
          else if (session.refreshToken !== null) {
            try {
              // @josh decodedAccessToken.exp not updated when refreshing so it will always go to this case since we also check decodedAccessToken.exp 
              console.log("seems to be refreshing token every time! @remind me to fix it")
              console.log("refreshing token", session.refreshToken)
              await Ator.refreshToken(session.refreshToken, APPLICATION_NAME)
              await userDataSync()
            } catch (e) {
              return redirectToLogin()
            }
          }
          else { return redirectToLogin() }
        }
        // case #3
        else if (session.refreshToken !== null) {
          try {
            console.log("no access token, but refresh token found");
            await Ator.refreshToken(session.refreshToken, APPLICATION_NAME)
            await userDataSync()
          } catch (e) {
            return redirectToLogin()
          }
        }
        if (mounted.current) setLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [session, history])

  return (
    <Route
      {...restOfProps}
      render={(props: any) => {
        if (loading) return <TheLoader />
        // @ts-ignore
        return session.idToken ? <Component {...restOfProps} {...props} name={name} /> : <Redirect to={`/auth`} />;
      }}
    />
  );
}

export default ProtectedRoute;
