import { allowedLanguages } from '@common/constants/Languages'
import { Button, Checkbox, FormControlLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { iDomainNew, iLabel, iLabelType } from '@gloow/apiconsumer'
import { createDomain, updateDomain } from '@services/DomainDataService'
import { applyOntologiesToDomain } from '@services/LabelService'
import { Formik, useFormikContext } from 'formik'
import { iso6392 } from 'iso-639-2'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Labels from '../Labels/Labels'
import useStyles from "./DomainForm.styles";
import { updateDomain as storeUpdateDomain } from '@store/domain/domainSlice'
import { isOntology } from "@helpers/labels";

const AutoSave = ({ delay }: { delay: number }) => {
  const { values, submitForm, dirty, isSubmitting } = useFormikContext()
  // eslint-disable-next-line
  const autoSaveCb = useCallback(
    debounce(() => {
      console.log('autosave domain')
      submitForm()
    }, delay),
    // eslint-disable-next-line
    [],
  )
  useEffect(() => {
    if (dirty && !isSubmitting) autoSaveCb()
    return () => { }
    // @ts-ignore
    // eslint-disable-next-line
  }, [values?.name, values?.description, values?.userWantsPublic, values?.enableSuggestions, values?.language])

  return null;
}


const DomainForm = ({
  onDone = (domain) => { },
  domainData,
  collaborationsDomain = false,
  horizontal = false,
  autoSave = false,
}: {
  onDone?: (domain?) => void;
  domainData?: iDomainNew;
  collaborationsDomain?: boolean;
  horizontal?: boolean;
  autoSave?: boolean;
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const form = useRef<any>(null)
  const schema = yup.object().shape({
    name: yup.string().required(t('common.name_is_required'))
  })
  const languages = iso6392.filter((d) => {
    if (allowedLanguages.length) return d.iso6391 && allowedLanguages.indexOf(d.iso6391.toLowerCase()) !== -1
    return d.iso6391;
  })

  const initialValues = {
    name: domainData?.name ?? '',
    description: domainData?.description ?? '',
    labels: domainData?.labels ?? [],
    language: domainData?.language ?? 'en',
    userWantsPublic: domainData?.userWantsPublic ?? false,
    enableSuggestions: domainData?.enableSuggestions ?? false,
  }

  const onSubmit = async (values) => {
    setLoading(true)
    if (domainData?.uuid) {
      // update
      let updatedDomain = { ...values, id: domainData.id, uuid: domainData.uuid };
      // // @ts-ignore
      const labels = values.labels?.filter((d) => !isOntology(d));
      await updateDomain({
        ...updatedDomain,
        labels: labels?.map((l) => l.id)
      }, collaborationsDomain);
      // @ts-ignore
      const ontologies = values.labels?.filter((d) => isOntology(d));
      if (ontologies.length > 0) {
        const ontologyLabels = await applyOntologiesToDomain(domainData.uuid, ontologies);
        if (ontologyLabels.length > 0) {
          updatedDomain = {
            ...updatedDomain,
            // @ts-ignore
            labels: [...updatedDomain.labels, ...ontologyLabels],
          };
        }
      }
      storeUpdateDomain({ ...updatedDomain, properties: domainData.properties });
      onDone(updatedDomain);
    }
    else {
      // create
      const newDomain = await createDomain({ ...values, labels: values.labels?.map((l) => l.id) });
      onDone(newDomain);
    }
    setLoading(false)
    form.current?.resetForm({ values })
  }

  return (
    <Formik innerRef={form} initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, errors, handleSubmit, setFieldValue, submitForm }) => {
        return <form onSubmit={handleSubmit}>
          {autoSave && <AutoSave delay={3000} />}
          <div style={{
            display: horizontal ? 'flex' : 'block',
            gap: horizontal ? 32 : 0
          }}>
            <div style={{ flex: 1 }}>
              {horizontal && <Typography variant="h6" className={classes.mb16}>{t('common.basic_information', 'Basic Information')}</Typography>}
              <TextField
                disabled={loading}
                id="domain-name"
                name='name'
                className={classes.name}
                label={t("common.name")}
                fullWidth
                defaultValue={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />

              <TextField
                disabled={loading}
                id="domain-description"
                name='description'
                fullWidth
                label={t("common.description")}
                multiline
                minRows={4}
                maxRows={6}
                defaultValue={values.description}
                onChange={(e) => setFieldValue('description', e.target.value)}
              />

              {domainData?.uuid && (
                <>
                  <div className={classes.label}>
                    <Typography variant='body2'>{t("common.labels")}</Typography>
                  </div>
                  <Labels
                    editable={true}
                    domainUuid={domainData.uuid}
                    disabled={loading}
                    labels={values.labels as iLabel[]}
                    labelType={iLabelType.DOMAIN}
                    onDeleted={(label) => {
                      // @ts-ignore
                      setFieldValue('labels', values.labels?.filter(d => d.id !== label.id))
                      if (autoSave) submitForm()
                    }}
                    onSelected={(label) => {
                      setFieldValue('labels', [...values.labels, label])
                      if (autoSave) submitForm()
                    }}
                  />
                </>
              )}
            </div>
            <div style={{ flex: 1 }}>
              {horizontal && <Typography variant="h6" className={classes.mb16}>{t('common.settings', 'Settings')}</Typography>}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      id="domain-public"
                      checked={values.userWantsPublic}
                      color="primary"
                      name="userWantsPublic"
                      onChange={(e) => setFieldValue('userWantsPublic', e.target.checked)}
                    />
                  }
                  label={t("common.allow_public_to_view_domain")}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      id="domain-enrichment"
                      checked={values.enableSuggestions}
                      color="primary"
                      name="enableSuggestions"
                      onChange={(e) => {
                        setFieldValue('enableSuggestions', e.target.checked)
                        if (e.target.checked) setFieldValue('language', values.language ?? 'en')
                      }}
                    />
                  }
                  label={t("common.use_node_enrichments")}
                />
              </div>
              {values.enableSuggestions && (
                <Select
                  disabled={loading}
                  labelId="domain-language"
                  id="domain-language"
                  value={values.language.toLowerCase()}
                  name='language'
                  onChange={(e) => setFieldValue('language', e.target.value)}
                >
                  {languages.map((item, index) => (
                    <MenuItem value={item.iso6391?.toLowerCase()} key={index.toString()}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          </div>
          {!autoSave && (
            <div className={classes.actionContainer}>
              <Button
                disabled={loading}
                type="button"
                id="cancel"
                className={classes.cancelButton}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => onDone()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                disabled={loading}
                type="submit"
                id="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {t("common.save")}
              </Button>
            </div>
          )}
        </form>
      }}
    </Formik>
  )
}

export default DomainForm
