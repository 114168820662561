import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import {iUserIdJwtDecoded} from "@gloow/apiconsumer";

export interface iSessionState {
  loading: boolean | null,
  user: any,
  idToken: string | null,
  accessToken: string | null,
  refreshToken: string | null,
  refreshTokenObtained: number | null,
  purchases: [],
  email: string | null
}

const initialState: iSessionState = {
  loading: null,
  user: null,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  refreshTokenObtained: null,
  purchases: [],
  email: null
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    fetchUserByToken: (state, action) => {
      state.loading = true
      if (action.payload) {
        const tokens = JSON.parse(action.payload) ?? null
        if (tokens) {
          const decodedToken: iUserIdJwtDecoded = jwtDecode(tokens.id_token)
          if (decodedToken) {
            state.idToken = tokens.id_token
            state.accessToken = tokens.access_token
            state.refreshToken = tokens.refresh_token
            state.refreshTokenObtained = tokens.refresh_token_obtained
            // @ts-ignore
            decodedToken.updated_at = decodedToken.updated_at.toString()
            state.user = {
              // @ts-ignore
              ...decodedToken,
              // @ts-ignore
              guest: decodedToken['https://gloow.io/users/isGuestUser']
            }
            // @ts-ignore
            state.email = decodedToken.email
            state.loading = false
            return
          }
        }
      }
      logout()
      state.loading = false
      localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY!)
    },
    logout: (state) => {
      state.idToken = null
      state.accessToken = null
      state.refreshToken = null
      state.refreshTokenObtained = null
      state.user = null
      state.purchases = []
      state.email = null
      state.loading = false
      localStorage.setItem(process.env.REACT_APP_AUTH_STORAGE_KEY!, '')
    },
    authenticate: (state, action) => {
      state.loading = true
      const decodedToken: iUserIdJwtDecoded = jwtDecode(action.payload.id_token)
      // @ts-ignore
      decodedToken.updated_at = decodedToken.updated_at.toString()
      // @ts-ignore
      state.user = {
        // @ts-ignore
        ...decodedToken,
        // @ts-ignore
        guest: decodedToken['https://gloow.io/users/isGuestUser']
      }
      state.idToken = action.payload.id_token
      state.accessToken = action.payload.access_token
      state.refreshToken = action.payload.refresh_token ?? null
      state.refreshTokenObtained = Date.now()
      // @ts-ignore
      state.email = decodedToken.email
      state.loading = false
      //@josh should we encrypt this?
      //@ega not a bad idea
      // usually also there shouldn't be any side effects on the reducers
      // I think it's best to move it to middleware
      localStorage.setItem(process.env.REACT_APP_AUTH_STORAGE_KEY!, JSON.stringify({
        id_token: action.payload.id_token,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        refresh_token_obtained: Date.now()
      }))
    },
    removeSession: (state, action) => {
      state.idToken = null
      state.accessToken = null
      state.refreshToken = null
      state.refreshTokenObtained = null
      state.user = null
      state.purchases = []
      localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY!)
    },
    setPurchases: (state, action) => {
      state.purchases = action.payload
    },
    addPurchases: (state, action) => {
      // Avoid adding same purchase to store
      const uniqPurchases = (state.purchases ?? []).filter(
        // @ts-ignore
        d => d.transactionId !== action.payload.transactionId
      )
      // @ts-ignore
      state.purchases = [...uniqPurchases, action.payload]
    }
  }
})

export const { addPurchases, authenticate, removeSession, setPurchases, fetchUserByToken, logout } = sessionSlice.actions

export default sessionSlice.reducer
