import { createSlice } from '@reduxjs/toolkit'
import { iNode } from "@gloow/apiconsumer"

interface iNodesState {
  nodes: iNode[],
}

const initialState: iNodesState = {
  nodes: [],
}

const fixThumbnailProp = nodes => {
  return nodes.map(node => ({
    ...node,
    thumbnailUrl: node.nodeData.thumbnail
      ? node.nodeData.thumbnail
      : node.thumbnailUrl
  }))
}

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState: initialState,
  reducers: {
    setNodes: (state, action) => {
      state.nodes = fixThumbnailProp(action.payload)
    },
    addNode: (state, action) => {
      state.nodes = [...fixThumbnailProp([action.payload]), ...state.nodes]
    },
    addNodes: (state, action) => {
      state.nodes = [...fixThumbnailProp(action.payload), ...state.nodes]
    },
    removeNode: (state, action) => {
      const i = state.nodes.findIndex(n => n.id === action.payload)
      state.nodes = i === -1 ? [...state.nodes] : [...state.nodes.slice(0, i), ...state.nodes.slice(i + 1)]
    },
    updateNode: (state, action) => {
      state.nodes = [...state.nodes
        .map(n => {
          return n.id !== action.payload.id
            ? n
            : {
              ...action.payload
            }
        })
        .sort((a, b) => {
          return b.updatedAt - a.updatedAt
        })]
    },
    updateNodeField: (state, action) => {
      state.nodes = [...state.nodes.map(d => {
        return d.id !== action.payload.id ? d : {
          ...d,
          ...action.payload
        }
      })
        .sort((a, b) => {
          return b.updatedAt - a.updatedAt
        })]
    },
    removeNodes: (state, action) => {
      const nodes = state.nodes
      state.nodes = nodes.filter(n => !action.payload.includes(n.id))
    },
    removeLabelFromNodes: (state, action) => {
      const { labelId, nodeIds } = action.payload
      let nodes = state.nodes
      if (nodeIds?.length > 0) {
        nodes = nodes.map(node => {
          const tempNode = node
          if (nodeIds.includes(node.id)) {
            // @ts-ignore
            tempNode.labels = tempNode.labels.filter(label => {
              if (label.id) return label.id !== labelId
              else return label !== labelId
            })
          }
          return tempNode
        })
      }
      else {
        nodes = nodes.map(node => {
          const tempNode = node
          // @ts-ignore
          tempNode.labels = tempNode.labels.filter(label => {
            if (label.id) return label.id !== labelId
            else return label !== labelId
          })
          return tempNode
        })
      }
      state.nodes = nodes
    }
  }
})

export const { addNode, addNodes, removeNode, removeNodes, setNodes, updateNode, updateNodeField, removeLabelFromNodes } = nodesSlice.actions

export default nodesSlice.reducer
