import { Stripe } from '@gloow/apiconsumer';
import { store } from '@store/index'
import { setPrices, openPrice } from '@store/paywall/stripe'
import { syncUserSubscribe } from '@services/AuthService';
import AnalyticsService from "@services/AnalyticsService";

export async function fetchPrices() {
    store.dispatch(setPrices([]))
    try {
        const stripeService = new Stripe()
        const prices = await stripeService.getPrices()
        store.dispatch(setPrices(prices))
    } catch (error) {
        AnalyticsService.logError('paywall-error', { error });
        store.dispatch(setPrices([]))
    }
}

export async function fetchSession(param) {
    store.dispatch(openPrice(''))
    try {
        const stripeService = new Stripe()
        const session = await stripeService.createSession(param)
        store.dispatch(openPrice(session.sessionId))
        return session
    } catch (error) {
        AnalyticsService.logError('paywall-error', { error });
        store.dispatch(openPrice(''))
    }
}

export async function cancelSubscription() {
    try {
        const stripeService = new Stripe()
        const cancelResponse = await stripeService.cancelSubscription()
        syncUserSubscribe()
        return cancelResponse
    } catch (error) {
        AnalyticsService.logError('cancel-sub-error', { error });
        console.log(error)
    }
}
