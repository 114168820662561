import React from 'react'
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core'
import { addExistingItemTypes, contentType, iInteractionDialog } from '@common/constants/Constants'
import uploadIcon from '@assets/images/icons-upload.png'
import addIcon from '@assets/images/icons-add-blue.png'
import webIcon from '@assets/images/icons-web.png'
import linkIcon from '@assets/images/icons-link.png'
import addBulkIcon from '@assets/images/icons-add-bulk.svg'
import { Close } from '@material-ui/icons'
import useStyles from './NodeMenuDialog.styles'
import { useTranslation } from 'react-i18next'
import { addNewNode } from '@services/DomainDataService'
import { useAppDispatch } from '@store/hooks'
import { setLastUpdate } from '@store/domain/domainSlice'
import { VertexType } from '@gloow/navimator'
import { useHistory } from 'react-router-dom'
import { RouteURI } from '@common/constants/Routes'
import { generateDetailURL } from '@helpers/utils'

interface iNodeMenuDialog {
  dialogProps: iInteractionDialog,
  setAddNodeBulkDialog: (props: iInteractionDialog) => void,
  setAddWebLinkDialog: (props: iInteractionDialog) => void,
  setAddFileDialog: (props: iInteractionDialog) => void,
  setAddExistingItemDialog: (props: iInteractionDialog) => void,
  handleClose: () => void,
  onDone?: () => void
}

const NodeMenuDialog = ({
  dialogProps,
  setAddNodeBulkDialog,
  setAddWebLinkDialog,
  setAddFileDialog,
  setAddExistingItemDialog,
  handleClose = () => { },
  onDone = () => { }
}: iNodeMenuDialog) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Dialog open={dialogProps.open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3">
          {dialogProps.specificMenu ? (dialogProps.tab === addExistingItemTypes.NODES ? t('common.connect_nodes') : t('common.connect_resources')) : t('common.node_menu')}
        </Typography>
        <Close className={classes.dialogBackIcon} onClick={() => handleClose()} />
      </div>
      <DialogContent className={classes.dialogContent}>
        {(dialogProps.specificMenu ? dialogProps.tab === addExistingItemTypes.NODES : true) &&
          <>
            <Button className={classes.action} onClick={async () => {
              handleClose()
              if (!dialogProps.connectTo?.data) return
              const { type, data } = dialogProps.connectTo || {}
              let params: any = undefined
              if (type === VertexType.Node) {
                params = { connectTo: dialogProps.connectTo?.data?.id }
              }
              else if (type === VertexType.Label) {
                params = { labelIds: [dialogProps.connectTo?.data?.id] as number[] }
              }
              const newNode = await addNewNode(params)
              if (!newNode) return
              dispatch(setLastUpdate(Date.now()))
              if (history.location.pathname.includes(RouteURI.MIND_MAPS) && data) {
                return history.push(generateDetailURL(history.location.pathname, { id: newNode.id, type: contentType.NODE }), { ignoreRecenter: true })
              }
            }}><img src={addIcon} alt='Gloow add new node' /> {t('common.add_new_node')}</Button>
            <Button className={classes.action} onClick={() => {
              setAddNodeBulkDialog(dialogProps)
              handleClose()
            }}><img src={addBulkIcon} alt='Gloow add multiple nodes' /> {t('common.add_multiple_nodes')}</Button>
          </>
        }
        {(dialogProps.specificMenu ? dialogProps.tab === addExistingItemTypes.RESOURCES : true) &&
          <>
            <Button className={classes.action} onClick={() => {
              setAddFileDialog(dialogProps)
              handleClose()
            }}><img src={uploadIcon} alt='Gloow upload a file' /> {t('common.upload_a_file')}</Button>
            <Button className={classes.action} onClick={() => {
              setAddWebLinkDialog(dialogProps)
              handleClose()
            }}><img src={webIcon} alt='Gloow add a web link' /> {t('common.add_web_link')}</Button>
          </>
        }
        <Button className={classes.action} onClick={() => {
          setAddExistingItemDialog(dialogProps)
          handleClose()
        }}><img src={linkIcon} alt='Gloow link an existing item' /> {t('common.link_an_existing_item')}</Button>
      </DialogContent>
    </Dialog>
  )
}

export default NodeMenuDialog
