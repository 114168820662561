import React from 'react'
import { BaseEditor } from 'slate'
import { TEXT_ALIGN_TYPES } from '../common/config'
import { isBlockActive, toggleBlock } from '../common/utils'
import Colors from '@common/constants/Colors'
import useStyles from '../NodeContent.styles'
import { IconButton } from '@material-ui/core'
const ButtonFormat = ({ editor, format, icon }: { editor: BaseEditor, format: any, icon: JSX.Element }) => {
  const active = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
  const classes = useStyles()
  return (
    <IconButton
      size='small'
      className={classes.toolbarButton}
      style={{ backgroundColor: active ? `${Colors.primary}66` : 'transparent', }}
      type='button'
      onClick={() => toggleBlock(editor, format)}
    >
      {icon}
    </IconButton>
  )
}

export default ButtonFormat
