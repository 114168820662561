import {
  Button,
  Collapse,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import _ from "lodash";
import useStyles from "./NodeList.styles";
import { iNode } from "@gloow/apiconsumer";
import SearchField from "@common/components/Form/SearchField/SearchField";
import { Add, ChevronRight } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";
import { setLastUpdate } from "@store/domain/domainSlice";
import Colors from "@common/constants/Colors";
import { Avatar, Switch } from "@common/components";
import { addNewNode } from "@services/DomainDataService";
import { useHistory } from "react-router-dom";

export interface iNodeListProps {
  className?: string,
  minimized?: boolean,
  nodes: iNode[];
  disableSearch?: boolean;
  onClick?: (node: iNode) => void;
  onLabelClick?: (labelId?: number) => void;
  allowMultipleCollapse?: boolean
  filter?: string
  hideTitle?: boolean
  activeNodeId?: number
  allowAdd?: boolean,
  onAdded?: (node: iNode) => void,
  groupByLabel?: boolean,
  onGroupByLabelChange?: (checked: boolean) => void
}

export const NodeList = ({ className = '', minimized = false, nodes, onClick, disableSearch, filter, allowAdd, activeNodeId, onAdded, allowMultipleCollapse = true, onLabelClick, groupByLabel = false, onGroupByLabelChange }: iNodeListProps) => {
  const classes = useStyles();
  const [active, setActive] = useState<number>();
  const [searchText, setSearchText] = useState<string>("");
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const containerRef = useRef<any>(null)

  useEffect(() => {
    setActive(activeNodeId)
    return () => { }
  }, [activeNodeId])

  useEffect(() => {
    setSearchText(filter ?? '')
  }, [filter])

  const __onClick = (node: iNode) => {
    if (onClick) {
      return onClick(node);
    }
  };

  const __toggleCollapse = (groupId: string) => {
    return () => {
      const exist = collapsed.includes(groupId);
      onLabelClick && onLabelClick(!exist ? parseInt(groupId) : undefined)
      if (!allowMultipleCollapse) return setCollapsed(exist ? [] : [groupId])
      return setCollapsed(exist ? collapsed.filter((c) => c !== groupId) : [...collapsed, groupId]);
    };
  };

  const shouldCollapse = useMemo(() => {
    return (groupName: string) => {
      return collapsed.includes(groupName);
    };
  }, [collapsed]);

  const withFilter = (nodes: iNode[]) => {
    return nodes.filter((node) => {
      return node.name.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  const __renderNodes = (nodes: iNode[], asChild = false) => {
    return nodes.map((node) => (
      <Button
        key={node.id}
        className={cx(classes.nodeItem, {
          active: active === node.id,
          children: asChild,
        })}
        onClick={() => __onClick(node)}
      >
        <Avatar name={node.name} size={'24'} textSize='xs' src={node.thumbnailUrl} color={node.color ?? Colors.default()} />
        <Typography variant="body2" className={`${classes.nodeName} ${minimized ? classes.hidden : ''}`}>
          {/* <HighLightedText text={node.name} highlight={searchText} /> */}
          {node.name}
        </Typography>
      </Button>
    ));
  };

  const toggleGroupByLabel = (checked) => {
    history.replace(history.location.pathname + history.location.search)
    onGroupByLabelChange && onGroupByLabelChange(checked)
  }

  const __onAddedNewNode = async () => {
    try {
      const newNode = await addNewNode()
      if (!newNode) return
      dispatch(setLastUpdate(Date.now()))
      onAdded && onAdded(newNode)
    } catch (e) {
      console.error(e)
      // @TODO: show error message as toast?
    }
  }

  const __renderGroupedNodes = (nodes: iNode[]) => {
    let groups: any[] = _(nodes)
      .map((d) => d.labels)
      .flatten()
      .uniqBy((label) => label.id)
      .map((d) => {
        return {
          groupId: d.id,
          groupName: d.name,
          label: d,
          nodes: nodes.filter((node) =>
            node.labels.some((label) => label.id === d.id)
          ),
        };
      })
      .sortBy((d: any) => d.groupName)
      .value();

    // Filter by no labels attached nodes
    const noLabelAttached = _(nodes)
      .filter((node) => !node.labels || node.labels.length === 0)
      .value();


    if (noLabelAttached.length !== 0) {
      groups.unshift({ groupId: "unattached", groupName: t('common.without_label', 'Without label'), nodes: noLabelAttached })
    }

    // https://nebulae.atlassian.net/browse/GLW-816
    // Filter also applied for the group
    groups = _(groups).map((group) => {
      if (group.groupName.toLowerCase().includes(searchText.toLowerCase())) {
        return group;
      }

      // check if group member filter result > 0
      const filteredNodes = group.nodes.filter(node => node.name.toLowerCase().includes(searchText.toLowerCase()))

      if (filteredNodes.length === 0) {
        return undefined
      }

      return {
        ...group,
        nodes: filteredNodes
      }
    }).compact().value()

    return groups.map((group) => {
      return (
        <div key={group.groupId}>
          <Button
            className={classes.labelWrapper}
            onClick={__toggleCollapse(group.groupId)}
          >
            <ChevronRight
              className={cx(classes.collapseIcon, {
                active: shouldCollapse(group.groupId),
              })}
            />
            {group.label && (
              <div
                className={classes.labelIcon}
                style={{ backgroundColor: group.label.color ?? Colors.default() }}
              ></div>
            )}
            <Typography
              variant="body2"
              className={minimized ? classes.hidden : ''}
              style={{ fontWeight: group.label ? "inherit" : "500" }}
            >
              {/* <HighLightedText text={group.groupName} highlight={searchText} /> */}
              {group.groupName}
            </Typography>
          </Button>
          <Collapse
            in={shouldCollapse(group.groupId)}
            timeout="auto"
            unmountOnExit
            classes={{ wrapperInner: minimized ? classes.wrapperInnerMinimized : classes.wrapperInner }}
          >
            {__renderNodes(group.nodes, true)}
          </Collapse>
        </div>
      );
    });
  };

  return (
    <div className={`${classes.container} ${className}`} ref={containerRef}>
      {!minimized && (
        <div className={classes.headerWrapper}>
          <Typography variant="body2" style={{ fontWeight: "600" }}>
            {t('common.nodes', 'Nodes')}
          </Typography>
          <FormControlLabel
            value="start"
            control={
              <Switch size="small"
                checked={groupByLabel}
                color="primary"
                onChange={(value, checked) => toggleGroupByLabel(checked)}
                inputProps={{ "aria-label": "controller" }} />
            }
            labelPlacement="start"
            label={minimized ? undefined :
              <Typography className={classes.groupLabel}>
                {t('common.group_by_label', 'Group by label')}
              </Typography>
            }
          />
        </div>
      )}

      {!disableSearch && !minimized && (
        <SearchField
          className={classes.searchBar}
          text={searchText}
          onChange={setSearchText}
        />
      )}

      <div className={classes.listWrapper} style={{ height: minimized ? 'calc(100%)' : 'calc(100% - 92px)' }}>
        {allowAdd && (
          <Button onClick={__onAddedNewNode} className={classes.addButton}>
            <Add className={classes.addIcon} />
            <Typography variant='body2' className={`${classes.addText} ${minimized ? classes.hidden : ''}`}> {t('common.new_node', 'New node')}</Typography>
          </Button>
        )}
        {groupByLabel
          ? __renderGroupedNodes(nodes)
          : __renderNodes(withFilter(nodes))}
      </div>
    </div >
  );
};
