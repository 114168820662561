import React, { useEffect, useRef, useState } from 'react'
import { Button, ButtonBase, IconButton, TextField, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import useStyles from './ResourceInfoForm.styles'
import { iNode, iResource, Resource, eResourceType, webTypesList } from "@gloow/apiconsumer"
import ResourceLoader from './ResourceLoader/ResourceLoader'
import { setLastUpdate } from '@store/domain/domainSlice'
import _ from 'lodash'

import deleteIcon from '@assets/images/delete.svg'
import IconLink from '@assets/images/icons-link.png'

import { addExistingItemTypes, iInteractionDialog } from '@common/constants/Constants'
import { ConfirmDialog, NodeCard } from '@common/components'
import moment from 'moment'
import { useAppDispatch } from '@store/hooks'
import { removeResource, updateResource } from '@store/resources/resourcesSlice'
import { removeResourceMappings, removeSingleMapping } from '@store/resourceMappings/resourceMappingsSlice'
import AnalyticsService from '@services/AnalyticsService'
import { getResourceThumbnail, getResourceInfo, getResourceIcon } from '@helpers/resource'
import getVideoId from 'get-video-id'
import { instanceOfNode, validURL } from '@helpers/utils'
import { useHistory } from 'react-router'
import { Add, ChevronLeft } from '@material-ui/icons'
import { VertexType } from '@gloow/navimator'

import { useTranslation } from 'react-i18next'
import useDomain from '@hooks/useDomain'

interface iResourceInfoForm {
  activeResource?: iResource,
  onConnectedNodeClicked?: (item: iNode) => void
  handleClose?: () => void,
  setAddExistingItemDialog: (props: iInteractionDialog) => void
}

const ResourceInfoForm = ({
  activeResource = undefined,
  onConnectedNodeClicked = (item: iNode) => { },
  handleClose = () => { },
  setAddExistingItemDialog = () => { }
}: iResourceInfoForm) => {
  const form = useRef<any>(null)
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const history = useHistory()
  const { nodes, resourceMappings, domainPermissions } = useDomain()
  const { t } = useTranslation()
  const ref = new URLSearchParams(history.location.search).get('ref') ?? ''
  const [connectedNodes, setConnectedNodes] = useState<iNode[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [removeState, setRemoveState] = useState<{
    data: iNode | undefined,
    removeDialog: boolean
  }>({
    data: undefined,
    removeDialog: false
  })

  const FormSchema = Yup.object().shape({
    title: Yup.string().required(t('common.name_is_required')),
    info: Yup.string(),
  })

  useEffect(() => {
    const cn = resourceMappings
      .filter(rm => rm.resource === activeResource?.id)
      .map(rm => {
        const r = { ...nodes.find(r => r.id === rm.node) }
        // @ts-ignore
        if (r) r.resourceMappingId = rm.id
        return r
      })
      .filter(r => typeof r !== 'undefined')
    setConnectedNodes(cn as iNode[])
    return () => { }
    // eslint-disable-next-line
  }, [activeResource?.id, resourceMappings])


  const initialValues = {
    title: activeResource?.title ?? '',
    info: getResourceInfo(activeResource!) ?? '',
  }

  const onUpdate = async ({ title, info }) => {
    try {
      setLoading(true)
      const updatedResource = {
        ...activeResource,
        title,
        info
      }
      if (activeResource?.metaData) {
        updatedResource.metaData = {
          ...activeResource?.metaData,
          title: title,
          description: info,
          jsonld: {
            ...activeResource?.metaData?.jsonld,
            headline: info
          }
        }
      }
      // @ts-ignore
      if (updatedResource?.nodes) delete updatedResource.nodes
      const RS = new Resource()
      const res = await RS.update(updatedResource)
      dispatch(updateResource(res))
      setLoading(false)
      dispatch(setLastUpdate(Date.now()))
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('update-resource', { e })
    }
  }

  const renderHeader = (hasChanges: boolean) => {
    return <div className={classes.titleWrapper}>
      {/* @ts-ignore */}
      {ref.length > 0 &&
        <IconButton size='small' id="resource-back" onClick={() => history.goBack()} ><ChevronLeft className={classes.backIcon} /></IconButton>
      }
      <Typography noWrap className={classes.title} variant="h3">{t('common.resource_info')}</Typography>
      {domainPermissions?.modify && !loading ?
        <Button
          id="resource-save"
          disabled={loading}
          className={!hasChanges ? 'invisible' : classes.submit}
          variant={'contained'}
          color="primary"
          type="submit"
        >{t('common.save')}</Button>
        :
        <></>
      }
    </div>
  }

  const onRemoveItem = async () => {
    if (removeState.data) {
      try {
        // unlink node
        const rm = resourceMappings.find(d => d.resource === activeResource?.id && d.node === removeState.data?.id)
        if (rm) {
          setRemoveState({ data: undefined, removeDialog: false })
          const RS = new Resource()
          await RS.removeResourceMapping(rm.id)
          dispatch(removeSingleMapping(rm.id))
          dispatch(setLastUpdate(Date.now()))
        }
      } catch (e) {
        AnalyticsService.logError('delete-resource-mapping', { e })
      }
    }
    else {
      try {
        // remove resource
        setRemoveState({ data: undefined, removeDialog: false })
        const RS = new Resource()
        await RS.delete(activeResource?.id!)
        dispatch(removeResourceMappings(activeResource?.id))
        dispatch(removeResource(activeResource?.id))
        dispatch(setLastUpdate(Date.now()))
        handleClose()
      } catch (e) {
        AnalyticsService.logError('delete-resource', { e })
      }
    }
  }

  const renderContent = () => {
    if ([eResourceType.YOUTUBE, eResourceType.VIMEO].indexOf(activeResource?.type!) > -1) {
      const video = getVideoId(activeResource?.url!)
      const iframeURL = video.service === 'youtube' ? 'https://youtube.com/embed' : 'https://player.vimeo.com/video'
      return <div className={classes.imageButton}>
        <iframe
          className={classes.iframe}
          src={`${iframeURL}/${video?.id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={activeResource?.title}
        />
      </div>
    }
    else if (activeResource?.type === eResourceType.VIDEO_FILE) {
      return <div className={classes.imageButton}>
        <video className={classes.iframe} controls>
          <source src={activeResource?.url} type={activeResource?.mimeType} />
        </video>
      </div>
    }
    const thumbnail = activeResource?.previewUrl ?? getResourceThumbnail(activeResource!, false)
    if (validURL(thumbnail)) {
      return <ButtonBase key={activeResource?.id.toString()!} className={classes.imageButton} onClick={() => window.open(activeResource?.url, '_blank')}>
        {<img className={classes.imageItem} src={thumbnail} alt="resource" />}
      </ButtonBase>
    }
  }

  const renderLink = () => {
    // @ts-ignore
    if (!webTypesList.includes(activeResource?.type)) return <></>
    return <div className={classes.linkContainer}>
      <img alt='link-icon' src={IconLink} className={classes.linkIcon} />
      <a href={activeResource?.url} rel='noreferrer' target='_blank' className={classes.link}>{activeResource?.url}</a>
    </div>
  }

  return (
    <Formik
      innerRef={form}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={onUpdate}
      enableReinitialize
      validationSchema={FormSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm
      }) => {
        const hasChanges = !_.isEqual(values, initialValues)
        return <Form onSubmit={handleSubmit} className={classes.container}>
          {renderHeader(hasChanges)}
          {loading ?
            <ResourceLoader />
            :
            <div className={`${classes.contentForm} resource-content-form`}>
              {domainPermissions?.modify ?
                <>
                  <div className={`${classes.nameWrapper}`}>
                    <ButtonBase className={classes.thumbnailWrapper} onClick={() => window.open(activeResource?.url, '_blank')}>
                      {/* @ts-ignore */}
                      <img className={classes.thumbnail} src={getResourceIcon(activeResource?.type)} alt="resource" />
                    </ButtonBase>
                    <TextField
                      id="resource-name"
                      label={t('common.name')}
                      fullWidth
                      multiline
                      value={values.title}
                      className={classes.name}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                      error={Boolean(errors?.title)}
                      helperText={errors?.title?.toString()}
                    />
                  </div>
                  <div className={classes.label}>
                    {renderLink()}
                  </div>
                  <TextField
                    id="resource-description"
                    fullWidth
                    className={classes.info}
                    label={t('common.description')}
                    multiline
                    minRows={4}
                    value={values.info}
                    onChange={handleChange('info')}
                    error={Boolean(errors?.info)}
                    helperText={errors?.info?.toString()}
                  />
                </>
                :
                <>
                  <div className={`${classes.nameWrapper}`}>
                    <ButtonBase id="resource-url" className={classes.thumbnailWrapper} onClick={() => window.open(activeResource?.url, '_blank')}>
                      {/* @ts-ignore */}
                      <img className={classes.thumbnail} src={getResourceIcon(activeResource?.type)} alt="resource" />
                    </ButtonBase>
                    <div>
                      <div className="mb-1 mt-1">
                        <label>{t('common.name')}</label>
                      </div>
                      <Typography variant={'h4'} className={classes.name}>{values.title}</Typography>
                    </div>
                  </div>
                  <div className={classes.label}>
                    {renderLink()}
                  </div>
                  <div className={classes.label}>
                    <label>{t('common.description')}</label>
                  </div>
                  <Typography variant={'subtitle2'} className={classes.info}>{values.info}</Typography>
                </>
              }

              {renderContent()}

              <Typography variant={'body2'} className={classes.createdAt}>{t('common.added')} {moment(activeResource?.createdAt).format('DD.MM.YYYY')}</Typography>

              <div className={classes.connectedNodes}>
                {connectedNodes.length > 0 && <Typography variant={'subtitle2'} className={classes.connectedNodesTitle}>{t('common.connected_nodes')}</Typography>}
                {connectedNodes.map(d => <NodeCard
                  key={d.id}
                  onClick={onConnectedNodeClicked} node={d} hideStat={true} onRemove={domainPermissions?.modify ? (item) => setRemoveState({
                    data: item,
                    removeDialog: true
                  }) : undefined} />)}
                {domainPermissions?.modify && <Button id="resource-connect-to-node"
                  type="button" color='primary' onClick={() => setAddExistingItemDialog({
                    open: true,
                    tab: addExistingItemTypes.NODES,
                    connectTo: {
                      type: VertexType.Resource,
                      data: activeResource
                    },
                    connectedNodes,
                    specificMenu: true
                  })} className={`${classes.connectToNode} link-button`} startIcon={<Add />}>
                  <Typography variant='body2'>{t('common.connect_nodes')}</Typography>
                </Button>}
              </div>
              {domainPermissions?.remove &&
                <Button id="resource-delete" disabled={loading} classes={{ label: classes.deleteNode, root: classes.deleteButton }} onClick={() => setRemoveState({ data: undefined, removeDialog: true })}>
                  <img src={deleteIcon} className={classes.deleteIcon} alt='delete' />
                  <Typography variant='body2'>{t('common.delete_resource')}</Typography>
                </Button>
              }
            </div>
          }

          <ConfirmDialog
            open={removeState.removeDialog}
            onClose={() => setRemoveState({ removeDialog: false, data: undefined })}
            onConfirm={async () => {
              await onRemoveItem()
            }}
            text={removeState.data && instanceOfNode(removeState.data) ? t('common.are_you_sure_want_to_delete_this_connection', 'Are you sure want to delete this connection?') : t('common.are_you_sure_want_to_delete_this_resource')}
            confirmText={t('common.delete')}
            cancelText={t('common.cancel')}
          />
        </Form>
      }}
    </Formik>

  )
}

export default ResourceInfoForm
