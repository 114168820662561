import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppStackRoute from './routes';
import {
  ThemeProvider,
  CssBaseline
} from '@material-ui/core';
import { customerly } from 'react-customerly'
import TagManager from 'react-gtm-module'
import { useAppSelector } from './store/hooks'
import { TheLoader } from './common/components'
import { hotjar } from 'react-hotjar'
import { theme } from './App.styles'

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development') {
  console.log("dev env - skipping analytics & customerly initialisation")
} else {

  // init customerly
  customerly.initialize("c784f65f", { widget_position: "right" });

  // init gtm
  const tagManagerArgs = {
    gtmId: 'GTM-WVKGV6C'
  }
  TagManager.initialize(tagManagerArgs);

  // init hotjar
  hotjar.initialize(2730700, 6);
}

function App() {
  const loading = useAppSelector(state => state.helpers.loading)
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {loading && <TheLoader />}
        <AppStackRoute />
      </ThemeProvider>
    </Router>
  );
}

export default App;
