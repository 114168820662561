import React from 'react'
import { iResource, eResourceType } from "@gloow/apiconsumer"
import {
  Card,
  // IconButton,
  makeStyles, Typography
} from '@material-ui/core'
import moment from 'moment'
import { getResourceThumbnail, getResourceIcon, getTruncatedResourceInfo } from '@helpers/resource'
import getVideoId from 'get-video-id'
import HighLightedText from '../HighlightedText/HighlightedText'
import { contentType } from '@common/constants/Constants'
import Colors from '@common/constants/Colors'
// import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'
// import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    width: '100%',
    wordBreak: 'break-word',
    borderRadius: 12,
  },
  card: {
    width: '100%',
    borderRadius: 12,
    padding: 24,
    boxShadow: 'none !important'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 'normal'
  },
  subtitle: {
    color: Colors.darkGrey,
    lineHeight: 1,
    marginTop: 4
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  headerImage: {
    display: 'block',
  },
  info: {
    lineHeight: 'normal',
    marginTop: 12,
  },
  stats: {
    opacity: 0.4,
    lineHeight: 'normal',
    marginRight: 8
  },
  value: {
    color: theme.palette.primary.main,
    marginRight: 4
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  imageContainer: {
    width: 48,
    height: 48,
    borderRadius: 12,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: 'white',
    marginRight: 10,
    background: theme.palette.background.default
  },
  imageItem: {
    height: 48,
    width: 48,
    objectFit: 'cover'
  },
  imageContainerFull: {
    width: '100%',
    height: 200,
    marginTop: 15,
    objectFit: 'cover',
  },
  attachmentIcon: {
    height: 24,
    width: 24,
    margin: 12,
    color: theme.palette.primary.main,
    transform: 'rotate(-45deg)'
  },
  imageButton: {
    marginTop: 12,
    borderRadius: 12,
    width: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  video: {
    width: '100%',
    height: 200
  },
  iframe: {
    width: '100%',
    height: 190,
    borderRadius: 12
  },
  connections: {
    marginTop: 18,
    paddingTop: 12,
    borderTop: '1px solid',
    borderColor: Colors.greyBorderBottom,
  },
  connectionsToggle: {
    width: 30,
    height: 30,
    position: 'absolute',
    color: Colors.main,
    bottom: 24,
    right: 32
  },
  connectionIconHidden: {
    color: Colors.grey,
    opacity: 0.4
  },
  connectedNodesResources: {
    fontSize: 14
  }
}));

interface iGridResourceCard {
  data: iResource,
  highlight?: string,
  onClick: (data: iResource) => void,
  onConnectionFilter?: (data: iResource) => void,
  selected?: boolean
  hidePreview?: boolean
}

const GridResourceCard = ({ data, highlight = '', onClick = (data) => { }, onConnectionFilter, selected = false, hidePreview = false}: iGridResourceCard) => {
  const classes = useStyles()
  const isHasThumbnail = getResourceThumbnail(data, false)
  const description = getTruncatedResourceInfo(data, highlight)
  const { i18n } = useTranslation()

  const renderContent = () => {
    if ([eResourceType.YOUTUBE, eResourceType.VIMEO].indexOf(data?.type!) > -1) {
      const video = getVideoId(data?.url!)
      const iframeURL = video.service === 'youtube' ? 'https://youtube.com/embed' : 'https://player.vimeo.com/video'
      return <div className={classes.imageButton}>
        <iframe
          className={classes.iframe}
          src={`${iframeURL}/${video?.id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={data?.title}
        />
      </div>
    } else if (data?.type === eResourceType.VIDEO_FILE) {
      return <div className={classes.imageButton}>
        <video className={classes.iframe} controls>
          <source src={data?.url} type={data?.mimeType} />
        </video>
      </div>
    }

    if (hidePreview) {
      return null
    }

    return (
      <>
        {isHasThumbnail &&
          <img className={`${classes.imageContainer} ${classes.imageContainerFull}`} src={isHasThumbnail} alt={data.title} loading={'lazy'} />
        }
      </>
    )
  }

  return (
    <div className={classes.container + " grid-card"}
      data-id={data.id}
      data-title={data.title?.toLowerCase() ?? ''}
      data-description={description?.toLowerCase() ?? ''}
      data-type={contentType.RESOURCE}
      onClick={() => onClick(data)}
    >
      <Card className={classes.card}>
        <div className={`${classes.header}`}>
          <div className={`${classes.imageContainer}`}>
            <img
              loading={'lazy'}
              className={classes.imageItem}
              src={!isHasThumbnail ? getResourceThumbnail(data) : getResourceIcon(data?.type)}
              alt={data.title} />
          </div>
          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant={'h6'}>
              <HighLightedText text={data?.metaData?.title ?? data.title} highlight={highlight}></HighLightedText>
            </Typography>
            <Typography className={classes.subtitle} variant={'subtitle2'}>{moment(data.updatedAt).locale(i18n.language).fromNow()}</Typography>
          </div>
        </div>
        {/* @ts-ignore */}
        <Typography
          style={{ display: description.length === 0 ? 'none' : 'block' }}
          variant={'body1'}
          className={classes.info}>
          <HighLightedText text={description} highlight={highlight}></HighLightedText>
        </Typography>

        {renderContent()}

        {/* {onConnectionFilter && (
          <div className={classes.connections}>
            <Typography variant={'body2'} className={classes.connectedNodesResources}>Connections</Typography>
            <IconButton onClick={(e) => {
              e.stopPropagation()
              onConnectionFilter(data)
            }} className={classes.connectionsToggle}>{selected ? <VisibilityOutlined /> : <VisibilityOffOutlined className={classes.connectionIconHidden} />}
            </IconButton>
          </div>
        )} */}
      </Card>
    </div>
  )
}

export default GridResourceCard
