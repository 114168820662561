import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: 1,
      paddingTop: 4
    },
    '& .MuiInputLabel-shrink': {
      fontSize: 16
    }
  },
  label: {
    fontWeight: 600
  }
}))