import React, { useEffect, useRef } from 'react'
import useStyles from './Explorer.styles'
import { iDomainNew, iLabel, iNode, iRelation, iResourceMapping, analyticsEventCategory } from '@gloow/apiconsumer'
import { FractalVisualisation, FractalConfig, FractalVisNode, VertexType, VisualisationMode } from '@gloow/navimator'
import { addExistingItemTypes, contentType, iInteractionDialog, nodeInfoTabTypes } from '@common/constants/Constants'
import { useHistory } from 'react-router'
import { generateDetailURL } from '@helpers/utils'
import AnalyticsService from "@services/AnalyticsService";
import { iActiveVertex, iDomainPermissions } from '@store/domain/domain.interfaces'
import { useAppDispatch } from '@store/hooks'
import { setVisualisation } from '@store/helpers/helpersSlice'

interface IExplorerProps {
    labels: iLabel[],
    nodes: iNode[],
    relations: iRelation[],
    resourceMappings: iResourceMapping[],
    activeVertex: iActiveVertex,
    domainPermissions: iDomainPermissions | undefined,
    setNodeMenu: (props: iInteractionDialog) => void,
    lastUpdate: number
    domain: iDomainNew,
}

const fractalConfig: Partial<FractalConfig> = {
    hasAddBtn: true,
    RNode0: 80,
    ROrbit0: 260,
    zoomPadding: 0.8,
    orbitStrokeWidth: 8,
    baseFontSize: 70,
    addButtonNodeSizeFactor: 0.6,
    // enterAnimationDelay: 500
}

const Explorer = ({
    labels,
    nodes,
    relations,
    resourceMappings,
    activeVertex,
    domainPermissions,
    setNodeMenu,
    lastUpdate = Date.now(),
    domain
}: IExplorerProps) => {
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const Visualisation = useRef<FractalVisualisation | null>(null)
    const history = useHistory()

    const onAddNewNode = async (connectToNode: iNode | undefined, connectToLabel: iLabel | undefined) => {
        try {
            setNodeMenu({
                open: true,
                connectTo: connectToNode ? { data: connectToNode, type: VertexType.Node } : (connectToLabel ? { data: connectToLabel, type: VertexType.Label } : undefined),
                specificMenu: connectToLabel ? true : false,
                tab: connectToLabel ? addExistingItemTypes.NODES : undefined,
                redirectWhenDone: false
            })
        } catch (e) {
            console.error(e)
            // @TODO: show error message as toast?
        }
    }

    /**
     * @Ega Kamalludin
     *
     * I'm going to need some help here.
     * Until now we only had one visualisation type (the default type).
     * With the label tree and label grouping we will have different types.
     *
     * The general flow of the visualisation will stay the same, but with a couple of changes
     * One thing that is important to note is that there will be a distinction between a 'node' (which is falcon one entity called node)
     * and a 'Vertex' - which is something that is rendered as a circle on the visualisation.
     * A vertex can also be a label or even a domain (it will be clear when you see label tree)
     *
     * What I want to achieve is that, when the user clicks labels on the left sidebar, the visualisation
     * switches to the label tree. After that the update flow will be similar with nodes, when you click label on the left menu,
     * you should call update central 'Node' from outside, with the label uuid
     * (this is also an important change, instead of using ids we are switching to uuids)
     *
     *
     */

    const checkActiveVertex = (activeVertex: iActiveVertex) => {
        const { ignoreRecenter = undefined }: any = history.location?.state || {}
        // when user clicking node from navimator I added ignoreRecenter:true state to the history so the navimator won't be jumpy
        if (!ignoreRecenter && activeVertex.data) {
            let centralUuid = activeVertex.data.uuid
            if (activeVertex.type === VertexType.Resource) return
            if (activeVertex.type === VertexType.Domain && activeVertex.mode === VisualisationMode.LABEL_TREE) {
                centralUuid = domain.uuid
            }
            const mode = Visualisation.current?.config.mode
            if (mode !== activeVertex.mode) return Visualisation.current?.switchVisualisationType(activeVertex.mode!, centralUuid)
            const current = Visualisation.current?.getCurrentActiveVertex()
            if (current !== centralUuid) return Visualisation.current?.updateCentralVertexOutside({ uuid: centralUuid })
        }
    }

    const onVertexClick = (vertex: FractalVisNode) => {
        AnalyticsService.createUserEvent(analyticsEventCategory.USER_EVENT, 'NavimatorNodeClick');
        const { pathname } = history.location
        let params: { id: string, type: string, tab?: string } = { id: '', type: '', tab: '' }
        if (vertex.type === VertexType.Domain) return history.push(pathname)
        if (vertex.type === VertexType.Label) {
            const label = labels.find(l => l.uuid === vertex.id)
            if (!label) return
            params = { id: label.id.toString(), type: contentType.LABEL }
        }
        else {
            const node = nodes.find(n => n.uuid === vertex.id)
            if (!node) return
            params = { id: node.id.toString(), type: contentType.NODE, tab: nodeInfoTabTypes.NODE_INFO }
        }
        if (params.id && params.type) return history.push(generateDetailURL(pathname, params), { ignoreRecenter: true })
    }

    const onAddBtnClick = (uuid, connectToVertex: FractalVisNode) => {
        AnalyticsService.createUserEvent(analyticsEventCategory.USER_EVENT, 'NavimatorAddNode');
        if (!domainPermissions?.modify) return

        // GLW-810
        const selectedNode = connectToVertex?.type === VertexType.Node ? nodes?.find(d => d.uuid === connectToVertex.id) : undefined
        const selectedLabel = connectToVertex?.type === VertexType.Label ? labels?.find(d => d.uuid === connectToVertex.id) : undefined

        onAddNewNode(selectedNode, selectedLabel)
    }

    const onResourceLinkClick = (rm) => {
        AnalyticsService.createUserEvent(analyticsEventCategory.USER_EVENT, 'NavimatorResourceClick');
        return history.push(generateDetailURL(history.location.pathname, {
            id: rm.node.id,
            type: contentType.NODE,
            tab: nodeInfoTabTypes.CONNECTED_RESOURCES
        }))
    }

    const initAction = () => {
        if (Visualisation.current) {
            Visualisation.current.onVertexClick = onVertexClick
            Visualisation.current.onAddBtnClick = onAddBtnClick
            Visualisation.current.onResourceLinkClick = onResourceLinkClick
        }
    }

    const initVisualisation = () => {
        // console.log('expoler init', activeVertex.data?.uuid, activeVertex.mode, activeVertex.type, 'labels:' + labels.length, 'nodes:' + nodes.length)
        Visualisation.current = new FractalVisualisation(
            "#svgContainer",
            // @ts-ignore
            nodes,
            relations,
            resourceMappings,
            { ...fractalConfig, mode: activeVertex.mode },
            domain,
            [VertexType.Domain, VertexType.Resource].includes(activeVertex.type) ? domain.uuid : activeVertex.data?.uuid
        );
        initAction()
        dispatch(setVisualisation(Visualisation.current))
    }

    useEffect(() => {
        // unmount visualisation
        return () => {
            console.log('visualisation unmount called');
            Visualisation.current = null
        }
    }, [])

    //listening lastUpdate
    useEffect(() => {
        // console.log('last updated called')
        if (Visualisation.current) {
            console.log('explorer updating navimator..', new Date(lastUpdate).toString())
            // temp ts-ignore (should be removed after going live with dev22)
            // @ts-ignore
            Visualisation.current?.updateData(nodes, relations, resourceMappings)
            // re init action when data is updated
            initAction()
        }
        else if (!Visualisation.current) {
            console.log('no current visualisation, initialising new one');
            initVisualisation()
        }
        return () => { }
        // to update visualisation data we can trigger it by dispatching lastUpdate with Date.now()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUpdate])


    useEffect(() => {
        checkActiveVertex(activeVertex)
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeVertex])

    return (
        <svg id="svgContainer" className={classes.explorerSvg}></svg>
    );
}

export default Explorer;
