import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    overflow: 'hidden'
  },
  label: {
    textOverflow: 'ellipsis'
  },
  labelChecked: {
    textDecoration: 'line-through'
  }
}))