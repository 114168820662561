
import vimeo from '@assets/images/icons-vimeo.svg'
import youtube from '@assets/images/icons-youtube.svg'
import video from '@assets/images/icons-video.png'
import wiki from '@assets/images/icons-wiki.png'
import doc from '@assets/images/icons-doc.svg'
import pdf from '@assets/images/icons-pdf.svg'
import attachment from '@assets/images/icons-attachment.svg'
import imageicon from '@assets/images/icons-image.png'
import { iResource, eResourceType } from "@gloow/apiconsumer"
import { validURL } from './utils'
import getVideoId from 'get-video-id'

export const getWeblinkType = (url) => {
  let resourceType = eResourceType.WEB_LINK
  if (url.match(/wikipedia.org/)) {
    resourceType = eResourceType.WIKIPEDIA
  }
  else if (getVideoId(url)) {
    const video = getVideoId(url)
    if (video.service === 'youtube') {
      resourceType = eResourceType.YOUTUBE
    }
    else if (video.service === 'vimeo') {
      resourceType = eResourceType.VIMEO
    }
  }
  else if (url.match(/medium.com/) || url.match(/theverge.com/)) {
    resourceType = eResourceType.ARTICLE
  }
  return resourceType
}

export const getResourceType = (mimeType) => {
  let resourceType = eResourceType.FILE
  if (mimeType.match(/image/)) {
    resourceType = eResourceType.IMAGE_FILE
  }
  else if (mimeType.match(/video/)) {
    resourceType = eResourceType.VIDEO_FILE
  }
  else if (mimeType.match(/pdf/)) {
    resourceType = eResourceType.PDF_FILE
  }
  return resourceType
}

export const getResourceIcon = (resourceType: string) => {
  // workaround for existing item
  const type = resourceType?.match(/video/) ? eResourceType.VIDEO_FILE : resourceType
  if (resourceType?.match(/video/)) console.log(type)
  switch (type) {
    case eResourceType.IMAGE_FILE:
      return imageicon
    case eResourceType.WIKIPEDIA:
      return wiki
    case eResourceType.YOUTUBE:
      return youtube
    case eResourceType.VIDEO_FILE:
      return video
    case eResourceType.VIMEO:
      return vimeo
    case eResourceType.ARTICLE:
    case eResourceType.WEB_LINK:
      return attachment
    case eResourceType.FILE:
      return doc
    case eResourceType.PDF_FILE:
      return pdf
    default:
      return attachment
  }
}

export const getResourceThumbnail = (resource: iResource, fallback: any = getResourceIcon(resource?.type), showPreview = true): any => {
  if ( showPreview && validURL(resource?.previewUrl)) {
    return resource?.previewUrl.toString()
  }
  if (validURL(resource?.thumbnailUrl)) {
    return resource?.thumbnailUrl.toString()
  }
  else if (validURL(resource?.metaData?.image)) {
    return resource?.metaData?.image.toString()
  }
  else if (validURL(resource?.metaData?.image?.url)) {
    return resource?.metaData?.image?.url.toString()
  }
  else if (resource?.type?.match(/image/) || resource?.type === eResourceType.IMAGE_FILE) {
    return resource?.url.toString()
  }
  return fallback
}

export const getResourceInfo = (resource: iResource) => {
  if (resource?.metaData?.description) {
    return resource.metaData.description
  } else if (resource?.metaData?.jsonld?.headline) {
    return resource.metaData?.jsonld?.headline
  }
  return resource?.info || ''
}

const truncateLongText = (text: string, highlightText: string) => {
  const highlightIndex = text.toLowerCase().indexOf(highlightText.toLowerCase())

  const start = Math.max(highlightIndex - 150, 0)
  const end = Math.min(highlightIndex + 150, text.length)

  let truncatedText = ''

  if (start !== 0) {
    truncatedText += '...'
  }

  truncatedText += text.substring(start, end)

  if (end !== text.length) {
    truncatedText += '...'
  }

  return truncatedText
}

export const getTruncatedResourceInfo = (resource: iResource, highlightText: string) => {
  if (resource?.metaData?.description) {
    return truncateLongText(resource?.metaData?.description, highlightText)
  } else if (resource?.metaData?.jsonld?.headline) {
    return resource.metaData?.jsonld?.headline
  }
  return resource?.info || ''
}
