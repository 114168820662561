import { Transforms, Element as SlateElement, Editor, Range, BaseEditor } from "slate"
import { LIST_TYPES, TEXT_ALIGN_TYPES } from "./config"
import { SearchResultType } from "@gloow/apiconsumer";
import { ReactEditor } from "slate-react";

export const decorate = ([node, path], editor) => {
  if (editor.selection != null) {
    if (
      !Editor.isEditor(node) &&
      Editor.string(editor, [path[0]]) === "" &&
      Range.includes(editor.selection, path) &&
      Range.isCollapsed(editor.selection)
    ) {
      return [
        {
          ...editor.selection,
          placeholder: true,
        },
      ];
    }
  }
  return [];
}

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
  ReactEditor.focus(editor)
}


export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  )
  return !!match
}

export const toggleBlock = (editor: BaseEditor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n: any) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      // @ts-ignore
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: any
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = { align: isActive ? undefined : format, }
  } else {
    newProperties = { type: isActive ? 'paragraph' : isList ? 'list-item' : format }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }

  if (editor.children.length > 0) ReactEditor.focus(editor as ReactEditor)
}

export function findType(type, currentNode) {
  var result: any = []
  if (type === currentNode?.type) result.push(currentNode)
  else if (Array.isArray(currentNode)) {
    for (let i = 0; i < currentNode.length; i++) {
      const childRes = findType(type, currentNode[i])
      if (childRes.length) result = result.concat(childRes)
    }
  }
  else if (Array.isArray(currentNode?.children)) {
    for (let i = 0; i < currentNode.children.length; i++) {
      const childRes = findType(type, currentNode.children[i])
      if (childRes.length) result = result.concat(childRes)
    }
  }
  return result
}

export function getUniqueMention(slateJSON) {
  const mentions: { uuid: string, id: number, type: string }[] = findType('mention', slateJSON).map(d => {
    let value;
    if (d.value?.type === SearchResultType.Node) value = d.value?.node
    else if (d.value?.type === SearchResultType.Label) value = d.value?.label
    else if (d.value?.type === SearchResultType.Resource) value = d.value?.resource
    if (!value) return null
    return {
      uuid: value?.uuid,
      id: value?.id,
      type: d.value?.type
    }
  })
  const uniqueMentions: { uuid: string, id: number, type: string }[] = []
  const uniqueUuids = Array.from(new Set(mentions.map(d => d.uuid)))
  for (let i = 0; i < uniqueUuids.length; i++) {
    const mention = mentions.find(d => d.uuid === uniqueUuids[i])
    if (mention) uniqueMentions.push(mention)
  }
  return uniqueMentions
}

export function getUniqueResource(slateJSON) {
  const resources = findType('image', slateJSON).filter(d => d.value?.id).map(d => ({ id: d.value?.id, uuid: d.value?.uuid, type: SearchResultType.Resource }))
  const uniqueUuids = Array.from(new Set(resources.map(d => d.uuid)))
  const uniqueResources: { uuid: string, id: number, type: string }[] = []
  for (let i = 0; i < uniqueUuids.length; i++) {
    const data = resources.find(d => d.uuid === uniqueUuids[i])
    if (data) uniqueResources.push(data)
  }
  return uniqueResources
}

export const isAlphanumericKey = (key) => {
  if ((key >= 48 && key <= 57) || (key >= 65 && key <= 90)) return true
  return false
}