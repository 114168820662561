import React, { useEffect, useRef } from 'react'
import { contentType, defaultEntityContents, iInteractionDialog, nodeInfoTabTypes } from '@common/constants/Constants'
import { Button, IconButton, Typography } from '@material-ui/core'
import { iNode, iResource, Node, iLabel, iLabelType } from "@gloow/apiconsumer"
import { useTranslation } from 'react-i18next'
import Labels from '../Form/Labels/Labels'
import { NodeContent } from '../NodeContent/NodeContent'
import { NodeNameAvatar } from '../NodeNameAvatar/NodeNameAvatar'
import useStyles from './NodeSidePanel.styles'
import { useHistory, useParams } from 'react-router-dom'
import { RouteURI } from '@common/constants/Routes'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass, EffectCards } from 'swiper'
import 'swiper/swiper.scss';
import ColorList from '../ColorList/ColorList'
import Colors from '@common/constants/Colors'
import { useAppDispatch } from '@store/hooks'
import { updateNodeField } from '@store/nodes/nodesSlice'
import { setLastUpdate } from '@store/domain/domainSlice'
import { ArrowForwardIos, ChevronLeft } from '@material-ui/icons'
import NodeThumbnailList from '../NodeThumbnailList/NodeThumbnailList'
import ResourceThumbnailList from '../ResourceThumbnailList/ResourceThumbnailList'
import { iConnectedNode } from '@store/nodes/nodes.interfaces'
import { ConnectedNodes } from '../ConnectedNodes/ConnectedNodes'
import { ConnectedResources } from '../ConnectedResources/ConnectedResources'
import { generateDetailURL } from '@helpers/utils'
import { SearchResultType } from '@gloow/apiconsumer'

export const NodeSidePanel = ({
  node,
  domainUuid,
  editable = false,
  removeable = false,
  onBack,
  className = '',
  connectedNodes = [],
  connectedResources = [],
  onConnectedNodeClick = (nodeId) => { },
  onConnectedResourceClick = (resourceId) => { },
  onLabelClick = (labelId) => { },
  setNodeMenu = (props: iInteractionDialog) => { }

}: {
  node: iNode,
  onBack?: () => void,
  className?: string,
  editable?: boolean,
  removeable?: boolean,
  domainUuid: string,
  connectedNodes: iConnectedNode[],
  connectedResources: iResource[],
  onConnectedNodeClick?: (nodeId: number) => void,
  onConnectedResourceClick?: (resourceId: number) => void,
  onLabelClick?: (labelId: number) => void,
  setNodeMenu?: (props: iInteractionDialog) => void,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const tab = new URLSearchParams(history.location.search).get('tab') ?? nodeInfoTabTypes.NODE_INFO
  const ref = new URLSearchParams(history.location.search).get('ref') ?? undefined
  const dispatch = useAppDispatch()
  const swiper = useRef<SwiperClass>(null)
  const { slug } = useParams<{ slug: string }>()

  useEffect(() => {
    swiper.current?.slideTo(0)
    return () => { }
  }, [node.id])

  useEffect(() => {
    if (tab === nodeInfoTabTypes.NODE_INFO) swiper.current?.slideTo(0)
    else if (swiper.current?.realIndex !== 1) swiper.current?.slideTo(1)
    return () => { }
  }, [tab, swiper.current?.realIndex])

  const changeTab = (tab: nodeInfoTabTypes) => {
    return history.push(generateDetailURL(history.location.pathname, { id: node.id, type: contentType.NODE, tab }))
  }

  const headerTitle = {
    [nodeInfoTabTypes.NODE_INFO]: t('common.node_info'),
    [nodeInfoTabTypes.COLOR_PICKER]: t('common.color'),
    [nodeInfoTabTypes.CONNECTED_NODES]: t('common.connected_nodes'),
    [nodeInfoTabTypes.CONNECTED_RESOURCES]: t('common.connected_resources'),
  }

  const onColorChanged = async (value) => {
    _onBack()
    if (value === node.color) return
    const NS = new Node()
    const updatedNode = await NS.update({ id: node.id, color: value })
    if (!updatedNode) return
    dispatch(updateNodeField({ id: node.id, color: value, updatedAt: updatedNode.updatedAt }))
    dispatch(setLastUpdate(Date.now()))
  }

  const renderRightTab = () => {
    switch (tab) {
      case nodeInfoTabTypes.COLOR_PICKER:
        return <ColorList onSelected={onColorChanged} selectedValue={node?.color ?? Colors.default()} />
      case nodeInfoTabTypes.CONNECTED_NODES:
        return <ConnectedNodes
          activeNode={node}
          nodes={connectedNodes}
          editable={editable}
          removeable={removeable}
          domainUuid={domainUuid}
          onItemClicked={(item) => onConnectedNodeClick(item.id)}
          setNodeMenu={setNodeMenu}
        />
      case nodeInfoTabTypes.CONNECTED_RESOURCES:
        return <ConnectedResources
          activeNode={node}
          resources={connectedResources}
          onItemClicked={(item) => onConnectedResourceClick(item.id)}
          editable={editable}
          removeable={removeable}
          setNodeMenu={setNodeMenu}
        />
      default:
        return <div></div>
    }
  }

  const renderNodePanel = () => {
    return <div className={classes.nodePanelContainer}>
      <NodeNameAvatar
        data={{
          id: node?.id ?? 0,
          color: node?.color ?? '',
          name: node?.name ?? '',
          thumbnailUrl: node?.thumbnailUrl ?? ''
        }}
        editable={editable}
        autoSaveDebounceMs={node?.id ? 2000 : 0}
        onChangeColor={() => changeTab(nodeInfoTabTypes.COLOR_PICKER)}
      />
      <Labels
        nodeId={node?.id}
        labels={node?.labels ? node.labels as iLabel[] : []}
        editable={editable}
        domainUuid={domainUuid}
        labelType={iLabelType.NODE}
        onClick={(label) => onLabelClick(label.id as number)}
      />
      <Typography variant='body2' className={classes.label}>{t('common.description')}</Typography>
      <NodeContent
        id={`node-contents-${node.id}`}
        initialValue={node.contents && Array.isArray(node.contents) ? node.contents : defaultEntityContents}
        nodeId={node.id}
        autoSaveDebounceMs={node?.id ? 2000 : 0}
        readOnly={!editable}
        onMentionClick={(type, id) => {
          if (type === SearchResultType.Node) return onConnectedNodeClick(id)
          if (type === SearchResultType.Resource) return onConnectedResourceClick(id)
          if (type === SearchResultType.Label) return onLabelClick(id)
        }}
      />

      <Typography variant='body2' className={classes.label}>{t('common.connected_nodes')}</Typography>
      <Button id="node-resources" className={classes.connectedItemButton} onClick={() => changeTab(nodeInfoTabTypes.CONNECTED_NODES)}>
        <NodeThumbnailList nodes={connectedNodes} />
        <ArrowForwardIos className={classes.connectedItemArrow} />
      </Button>
      <Typography variant='body2' className={classes.label}>{t('common.connected_resources')}</Typography>
      <Button id="node-resources" className={classes.connectedItemButton} onClick={() => changeTab(nodeInfoTabTypes.CONNECTED_RESOURCES)}>
        <ResourceThumbnailList resources={connectedResources} />
        <ArrowForwardIos className={classes.connectedItemArrow} />
      </Button>
    </div>
  }

  const _onBack = () => {
    if (onBack) return onBack()
    return history.goBack()
  }

  const renderHeader = () => {
    return <div className={classes.header}>
      <div className={classes.headerTitle}>
        {(ref || tab !== nodeInfoTabTypes.NODE_INFO) && <IconButton size='small' onClick={() => _onBack()}><ChevronLeft className={classes.backIcon} /></IconButton>}
        <Typography variant='h5' noWrap style={{ fontWeight: 600 }}>{headerTitle[tab]}</Typography>
      </div>
      <Button onClick={() => history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${node?.id}`)} className={'link-button underline'}>
        <Typography variant='body2' noWrap>{t('common.full_screen_view', 'Full screen view')}</Typography>
      </Button>
    </div>
  }

  return (
    <div className={`${classes.container} ${className} node-side-panel`}>
      {renderHeader()}
      <Swiper
        // @ts-ignore
        onSwiper={(ref) => swiper.current = ref}
        modules={[EffectCards]}
        allowTouchMove={false}
        className={classes.swiper}
      >
        <SwiperSlide className={classes.swiperSlide} >
          {({ isActive }) => isActive && renderNodePanel()}
        </SwiperSlide>
        <SwiperSlide className={classes.swiperSlide}>
          {({ isActive }) => isActive && renderRightTab()}
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
