import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    darkMode: false
  },
  reducers: {
    toggleTheme: (state) => {
      state.darkMode = !state.darkMode
      // localStorage.setItem('darkMode', state.darkMode)
    }
  }
})

const { toggleTheme } = themeSlice.actions
export { toggleTheme } 

export default themeSlice.reducer