import React from 'react'
import Colors from '@common/constants/Colors'
import { makeStyles } from '@material-ui/core'
import { useSelected } from 'slate-react'
import Avatar from '@common/components/Avatar/Avatar'
import { SearchResultType } from "@gloow/apiconsumer"
import { useHistory, useParams } from 'react-router-dom'
import { RouteURI } from '@common/constants/Routes'
import { getResourceThumbnail } from '@helpers/resource'

const useStyles = makeStyles((theme) => ({
  container: {
    color: Colors.primary,
    fontSize: 14,
    backgroundColor: Colors.lightBlue2,
    padding: 2,
    paddingRight: 6,
    fontWeight: 500,
    borderRadius: 12,
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: `${Colors.primary}66`,
    },
    overflow: 'hidden'
  },
  focused: {
    backgroundColor: `${Colors.primary}66`,
  },
  avatar: {
    margin: '0px 4px 2px 1px',
    display: 'inline-block',
  },
  labelColor: {
    width: 14,
    height: 14,
    position: 'relative',
    display: 'inline-block',
    borderRadius: 8,
    top: 2,
    margin: '0px 4px 0px 2px',
  },
  imgResource: {
    display: 'inline-block',
    width: 16,
    height: 16,
    borderRadius: 10,
    margin: '0px 4px 2px 1px',
    objectFit: 'cover'
  },
  item: {
    display: 'flex',
    gap: 4
  }
}))

const Mention = ({ onMentionClick, ...rest }) => {
  const { style, attributes, children, element } = rest
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  // const focused = useFocused()
  const selected = useSelected()
  const classes = useStyles()
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!!onMentionClick) return onMentionClick(element.value?.type, element.value?.node?.id ?? element.value?.label?.id ?? element.value?.resource?.id)
    if (element.value?.type === SearchResultType.Node && element.value?.node?.id) return history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${element.value.node.id}`)
    if (element.value?.type === SearchResultType.Label && element.value?.label?.id) return history.push(`/${slug}/${RouteURI.ENTITIES}/${element.value.label.id}`)
    if (element.value?.type === SearchResultType.Resource && element.value?.resource?.id) return history.push(`/${slug}/${RouteURI.DOCUMENTS}/${element.value.resource.id}`)
  }

  const renderItem = () => {
    return <>
      {element.value.type === SearchResultType.Node && (
        <Avatar size='16' name={element?.value?.shortText} textSize={'xs'} className={classes.avatar} src={element?.value?.node?.thumbnail_url} />
      )}
      {element.value.type === SearchResultType.Label && (
        <span className={classes.labelColor} style={{ backgroundColor: element.value?.label?.color ?? Colors.primary }}></span>
      )}
      {element.value.type === SearchResultType.Resource && (
        <img alt='resource' src={getResourceThumbnail(element.value?.resource ?? '')} className={classes.imgResource} />
      )}
      {element?.value?.shortText}
    </>
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={style}
      className={`${classes.container} ${selected ? classes.focused : ''}`}
      onClick={onClick}
    >
      {children}{renderItem()}
    </span>
  )
}
export default Mention
