import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 12,
    boxShadow: `0 6px 12px 0 ${Colors.shadowColor} !important`,
    overflow: 'hidden',
    padding: '8px 0px'
  },
  shortText: {
    color: Colors.black,
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
  },
  nodeImageWrapper: {
    alignItems: "center",
    display: "flex",
  },
  longText: {
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    opacity: 0.4
  },
  groupName: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: Colors.secondaryToast,
    fontSize: 11
  },
  button: {
    textAlign: 'left',
    boxShadow: 'none !important',
    padding: '8px 16px',
    borderRadius: '0px !important',
    '& .MuiButton-label': {
      justifyContent: 'flex-start'
    }
  },
  group: {
    display: 'flex',
    flexDirection: 'column'
  },
  groupButton: {
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  chevron: {
    fontSize: 16
  },
  separator: {
    height: 1,
    borderBottom: `1px solid ${Colors.secondaryStroke}`,
    margin: '4px 16px'
  },
  nodeContainer: {
    display: 'flex',
    gap: 12
  },
  nodeTextContainer: {
    marginLeft: 12
  },
  labelColor: {
    width: 16,
    height: 16,
    borderRadius: '50%'
  },
  labelName: {
    marginLeft: 8,
    fontWeight: 400
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 150,
    minHeight: 120,
  },
  resourceImg: {
    width: 24,
    height: 24,
    borderRadius: 6
  },
  active: {
    backgroundColor: Colors.lightBlue2
  },
  notFound: {
    padding: '8px 16px',
    textAlign: 'center',
    '& img': {
      margin: '0 auto',
      width: 120,
      height: 120
    }
  }
}));
