import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import PublicRoutes from './PublicRoutes';
import { PaywallPricesDialog, ProtectedRoute } from '@common/components';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { fetchUserByToken } from '@store/session/sessionSlice';
import { isMobile } from 'react-device-detect';
import { IOService } from '@services/IOService';

import { ErrorHandler } from "@gloow/apiconsumer";
import { setPayWallModal } from "@store/paywall/stripe";

const AppStackRoute = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const payWallModal = useAppSelector(state => state.paywall.payWallModal)

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_STORAGE_KEY!)

    if (token) {
      IOService.instance.syncAuthenticationToken(token)
    }


    dispatch(fetchUserByToken(token))
    return () => { }
  }, [dispatch])

  useEffect(() => {
    if (isMobile) {
      // check if the route is not to accept invitation
      if (!history.location.pathname.includes('domain-invitation')) {
        history.push('/mobile')
      }
    } 


    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    ErrorHandler.registerHandler((e) => {
      const error = JSON.parse(JSON.stringify(e))
      if (error?.response?.status === 402) dispatch(setPayWallModal(true))
    })
    return () => {
      // @josh how to unregister handler?
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Switch>
        {PublicRoutes.map((route, index) => <Route key={index.toString()} {...route} />)}
        {/* auto redirect from old route structure to new one */}
        <Redirect from="/domain/:slug/*" to='/:slug/mind-maps' />
        {AppRoutes.map((route, index) => <ProtectedRoute key={index.toString()} {...route} />)}
        <Redirect from="/:slug*" to='/:slug/mind-maps' />
        <Redirect to='/auth' />
      </Switch>
      <PaywallPricesDialog
        open={payWallModal}
        onClose={() => dispatch(setPayWallModal(false))}
      />
    </>
  );
};

export default AppStackRoute;
