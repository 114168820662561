import { InputAdornment, Popover, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { iLabel, iNode, iProperty, iRelation, Label, Node, Relation, iLabelType } from "@gloow/apiconsumer"
import { useCallback, useEffect, useRef, useState } from 'react'
import SearchIcon from '@assets/images/icons-search.svg'
import useStyles from './CellRelations.styles'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { addRelation, removeRelation } from '@store/relations/relationsSlice'
import { debounce } from 'lodash'
import { addNode } from '@store/nodes/nodesSlice'
import Avatar from '@common/components/Avatar/Avatar'
import { useHistory, useParams } from 'react-router-dom'
import { RouteURI } from '@common/constants/Routes'
import { getConnectedNodesForActiveNode } from '@selectors/index'
import { setLastUpdate } from '@store/domain/domainSlice'
const CellRelation = ({
  node,
  nodes = [],
  relations = [],
  labels = [],
  edit = false,
  disabled = false,
  property,
  onClick,
  onSave = () => { }
}: {
  node: iNode,
  nodes: iNode[],
  relations: iRelation[],
  labels: iLabel[],
  edit?: boolean,
  disabled?: boolean,
  property: iProperty,
  onClick?: (node: iNode) => void,
  onSave?: () => void
}) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [options, setOptions] = useState<iNode[]>([])
  // eslint-disable-next-line
  const [label, setLabel] = useState(labels.find(d => d.id === parseInt(property.value)))
  const [values, setValues] = useState<iNode[]>([])
  const [loading, setLoading] = useState(false)
  const inputField = useRef<any>(null)
  const { slug } = useParams<{ slug?: string }>()
  const divRef = useRef(null)
  // eslint-disable-next-line
  const onInputChange = useCallback(debounce(text => { getOptions(text.length >= 2 ? text : '') }, 500), [nodes])
  const connectedNodes = useAppSelector(state => getConnectedNodesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: node } }))
  const [lastUpdate, openDomain] = useAppSelector(state => [state.domain.lastUpdate, state.domain.openDomain])

  const getOptions = (search = '') => {
    let result = nodes.filter(d => !values.find(val => val.id === d.id) && d.id !== node.id && !connectedNodes.find(cn => cn.id === d.id))
    // if label exist
    if (property.value) {
      result = result.filter(node => {
        // @ts-ignore
        const hasRelationLabel = node.labels.find(label => String(label.id) === property.value) // property.value is a label.id from relation column
        return hasRelationLabel
      })
    }
    if (search) {
      const findNode = result?.find(d => d.name.toLowerCase() === search.toLowerCase())
      // @ts-ignore
      if (!findNode) result = [{ name: search, labels: parseInt(property.value) ? [parseInt(property.value)] : [] }, ...result]
    }
    setOptions(result)
  }

  useEffect(() => {
    getOptions('')
    return () => { }
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    // to get connected node by relationLabel
    setValues(connectedNodes.filter(d => {
      if (property.metaData?.relationLabel && d.relationLabel?.id) return d.relationLabel?.id === property.metaData?.relationLabel
      if (property.metaData?.relatedNode) return [d.relation?.targetNode, d.relation?.sourceNode].includes(property.metaData?.relatedNode)
      return false
    }))
    return () => { }
    // eslint-disable-next-line
  }, [lastUpdate, node?.id, property.metaData])

  useEffect(() => {
    if (edit) getOptions()
    return () => { }
    // eslint-disable-next-line
  }, [edit])

  useEffect(() => {
    if (!disabled || edit) setTimeout(() => inputField.current?.focus(), 250)
    return () => { }
  }, [disabled, edit])


  const onChange = async (e, val) => {
    for (let i = 0; i < val.length; i++) {
      if (!val[i].uuid) {
        setLoading(true)
        const NS = new Node()
        const newNode = await NS.create(val[i])
        val[i] = newNode
        dispatch(addNode(newNode))
        setLoading(false)
      }
    }
    setValues(val)
    inputField.current?.focus()
  }

  const onBlur = async () => {
    if (loading || disabled) return
    const RS = new Relation()

    const existingRelationNodeIds = relations
      // @ts-ignore
      .filter(rel => rel.sourceNode === node.id && (rel.outgoingLabel?.id ?? rel.outgoingLabel) === property.metaData?.relationLabel)
      .map(d => d.targetNode)
    const newRelations = values.filter(val => !existingRelationNodeIds.includes(val.id))

    if (newRelations.length > 0) {
      // need to add label object into relation
      const LS = new Label()
      let outgoingLabel
      if (property.metaData!.relationLabel) outgoingLabel = await LS.get(property.metaData!.relationLabel)
      // this case happen when user add new connection to label inside entity detail page
      if (!outgoingLabel && property.metaData?.label?.id) {
        // search realtionLabel first
        // @ts-ignore
        const autocomplete = await LS.getLabelAutomplete({ q: property.metaData?.label?.name, domainUuid: openDomain?.uuid, labelType: iLabelType.RELATION })
        outgoingLabel = autocomplete.find(d => d.name.toLowerCase() === property.metaData?.label?.name?.toLowerCase())
        // if relationLabel not found then create a new one
        if (!outgoingLabel) outgoingLabel = await LS.create({ name: property.metaData?.label?.name, labelType: iLabelType.RELATION })
      }
      for (let i = 0; i < newRelations.length; i++) {
        const result = await RS.createOrUpdate({
          sourceNode: node.id,
          targetNode: newRelations[i].id,
          // @ts-ignore @josh @robby why this outgoingLabel is not exist on iRelation interface?
          outgoingLabel: outgoingLabel?.id ?? property.metaData!.relationLabel
        })
        dispatch(addRelation({ ...result, outgoingLabel }))
      }
    }

    const removedNodes = existingRelationNodeIds.filter(val => !values.find(d => d.id === val))
    const relationsRemoved = relations.filter(rel => removedNodes.includes(rel.targetNode))

    for (let i = 0; i < relationsRemoved.length; i++) {
      await RS.destroy(relationsRemoved[i].id)
      dispatch(removeRelation(relationsRemoved[i].id))
    }

    dispatch(setLastUpdate(Date.now()))
    onSave && onSave()
  }

  const renderValue = (node: iNode) => {
    return <div key={node.uuid.toString()} className={classes.value} onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      if (onClick) return onClick(node)
      return history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${node.id}`)
    }}>
      <Avatar name={node.name} src={node.thumbnailUrl ?? ''} textSize='xs' size="20" />
      <Typography variant='body2'>{node.name}</Typography>
    </div>
  }

  const renderMenu = () => {
    return <Popover
      open={Boolean(edit)}
      classes={{ paper: classes.menuPaper }}
      anchorEl={divRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Autocomplete
        disabled={disabled || loading}
        openOnFocus={true}
        onBlur={onBlur}
        onChange={onChange}
        value={values}
        multiple
        classes={{
          root: classes.autocomplete,
          input: classes.input,
          inputRoot: classes.inputRoot,
          endAdornment: classes.endAdornment,
          paper: classes.paper,
        }}
        options={options}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        onInputChange={(e, text) => onInputChange(text)}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputField}
            disabled={disabled || loading}
            variant="standard"
            placeholder={`Search ${label?.name ?? ''}`}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt='search' className={classes.iconSearch} />
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
      />
    </Popover>
  }
  return (
    <>
      <div className={classes.values} ref={divRef}>{values.map(d => renderValue(d))}</div>
      {renderMenu()}
    </>
  )
}

export default CellRelation
