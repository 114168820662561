import React from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { InputBase } from '@material-ui/core'
import { useAppDispatch } from "@store/hooks";
import searchIcon from '@assets/images/icons-search.svg'
import useStyles from './SearchField.styles'
import { updateSearchText } from '@store/filters/filtersSlice';
// import useDomain from '@hooks/useDomain'


interface iSearchField {
  className?: string,
  text: string,
  onChange: (text: string) => void
  onFocus?: (e: any) => void
  onKeyPress?: (e: any) => void
  redirectResultOnEnter?: boolean
}
const SearchField = ({ className = '', text, onChange = (text) => { }, onFocus = () => {}, onKeyPress = () => {}, redirectResultOnEnter = true }: iSearchField) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { slug } = useParams<{ slug: string }>();
  
  return (
    <div className={classes.searchContainer + ' ' + className}>
      <div className={classes.search + " searchContainer"}>
        <div className={classes.searchIcon}>
          <img src={searchIcon} alt="searchIcon" />
        </div>

        <InputBase
          placeholder={t('common.search')}
          classes={{
            root: classes.inputRoot + ' searchInputRoot',
            input: classes.inputInput + ' searchInput',
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={text}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={(e) => {
            onKeyPress(e)

            if (e.code === 'Enter') {
              dispatch(updateSearchText(text))
            
              if (redirectResultOnEnter) {
                // console.log('Redirect to search page')
                history.push(`/${slug}/search?q=${text}`)
              }
            }
          }}
          onFocus={onFocus}
        />
      </div>
    </div>
  )
}

export default SearchField
