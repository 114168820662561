import { createSelector } from 'reselect'
import { chain } from 'lodash'
import { store } from '@store/index'
import { instanceOfNode } from '@helpers/utils'
import { iConnectedNode } from '@store/nodes/nodes.interfaces'

const getActiveResource = state => state.resources.activeResource
const getActiveNode = state => state.nodes.activeNode
const getNodes = state => state.nodes.nodes
const getFilters = state => state.filters
const getNodeLabels = state => state.labels.NodeLabels
const getRelations = state => state.relations.relations
const getResourceMappings = state => state.resourceMappings.resourceMappings

export const createNodeList = createSelector(
  [getNodes, getFilters, getNodeLabels, getRelations, getResourceMappings],
  (nodes, filters, labels, relations, resourceMappings) => {
    const flattenNodes = nodes.map(n => ({
      ...n,
      // Mutate all labels into 1 text label's names, so search will be easier
      labelNames: n.labels?.map(({ name }) => name.toLowerCase()).join(' '),
    }))

    // deprecated since we have moved search into elastic search and we are using local state inside node list
    // start with filtering the nodes
    // let filteredNodes = flattenNodes
    //   .filter(n => {
    //     return n.name.toLowerCase().includes(filters.searchText.toLowerCase())
    //       || (!!n.labelNames && n.labelNames.includes(filters.searchText.toLowerCase()))
    //   })

    // // todo performance optimisation: create node enricher combiner
    // // call this before createNodeList (will be only called if nodes, resources or relations changes)

    // // add types, relation and resource count

    // // !!! this is mutating state !!!
    const initNodes = (data) => {
      return data.map((item) => {
        delete item.labelNames;

        return {
          ...item,
          relations: relations.filter(
            r => r.sourceNode === item.id || r.targetNode === item.id
          ).length,
          resources: resourceMappings.filter(rm => rm.node === item.id).length
        }
      });
    }

    return initNodes(flattenNodes)
  }
)

export const getUniqueRelationLabels = createSelector(
  [getRelations],
  relations => {
    return chain(relations)
      .map(d => [d.outgoingLabel, d.incomingLabel])
      .flatten()
      .compact()
      .uniqBy(d => d.id)
      .value()
  }
)

export const getConnectedNodesForActiveNode = createSelector(
  [getActiveNode, getRelations, getNodes],
  (activeNode, relations, nodes) => {
    if (!activeNode) return []

    const connectedNodes: iConnectedNode[] = []

    relations
      .filter(r => r.targetNode === activeNode?.id)
      .forEach(r => {
        const relnode = nodes.find(n => n.id === r.sourceNode)

        // if the data is consistent, this should not happen, but unfortunately, it does.
        if (!relnode) return

        // avoid nodes being added multiple times
        if (!connectedNodes.find(c => c.uuid === relnode.uuid)) {
          connectedNodes.push({
            ...relnode,
            relationLabel: r.incomingLabel,
            relation: r,
          })
        }
      })

    relations
      .filter(r => r.sourceNode === activeNode.id)
      .forEach(r => {
        const relnode = nodes.find(n => n.id === r.targetNode)

        // if the data is consistent, this should not happen, but unfortunately, it does.
        if (!relnode) return

        if (!connectedNodes.find(c => c.uuid === relnode.uuid)) {
          connectedNodes.push({
            ...relnode,
            relationLabel: r.outgoingLabel,
            relation: r,
          })
        }
      })

    // console.log('connectedNodes', connectedNodes)

    return connectedNodes
  }
)

export const getConnectedNodesForActiveResource = createSelector(
  [getActiveResource, getResourceMappings, getNodes],
  (activeResource, resourceMappings, nodes) => {

    const RM = resourceMappings
      .filter(rm => rm.resource === activeResource?.id)
      .map(rm => {
        const r = { ...nodes.find(r => r.id === rm.node) }
        if (r) r.resourceMappingId = rm.id
        return r
      })
      .filter(r => typeof r !== 'undefined') // check if resource actually exists

    return RM
  }
)

export const getResourcesForActiveNode = createSelector(
  [getActiveNode, getResourceMappings],
  (activeNode, resourceMappings) => {
    const resources = store.getState().resources.resources

    const RM = resourceMappings
      .filter(rm => rm.node === activeNode?.id)
      .map(rm => {
        const r = { ...resources.find(r => r.id === rm.resource) }
        if (r) r.resourceMappingId = rm.id
        return r
      })
      .filter(r => typeof r !== 'undefined' && r.id) // check if resource actually exists

    return RM
  }
)

// @deprecated
export const getLabels = createSelector(
  [getNodes, getNodeLabels, getResourceMappings], (nodes, labels, resourceMappings) => {
    const values = chain(nodes).reduce((tmp, node) => {
      const resourceCount = resourceMappings.filter(rm => rm.node === node.id).length

      node.labels.map((label) => {
        // console.log(tmp[label.id], label, node)
        if (tmp[label.id]) {
          tmp[label.id] = {
            ...tmp[label.id],
            nodeCount: tmp[label.id].nodeCount + 1,
            resourceCount: tmp[label.id].resourceCount + resourceCount,
          }
        } else {
          tmp[label.id] = {
            ...label,
            nodeCount: 1,
            resourceCount: 0
          }
        }
        return label
      })

      return tmp
    }, {}).map(d => d).value()

    // return merge(values,labels) // this makes label data inconsistent when all label is not connected to any node
    return values
  }
)

// used for grid view when filtering by labels
export const getFilteredResourcesIds = createSelector(
  [getNodes, getFilters, getResourceMappings], (nodes, filters, resourceMappings) => {
    const labels = filters.labels.map(d => d.id)
    const node = instanceOfNode(filters.connectionView ?? {}) ? filters.connectionView : false
    const n = nodes
      .filter(n => {
        if (node) return n.id === node.id
        return labels.some(l => n.labels.map(dl => dl.id).includes(l))
      })
      .map(d => d.id)
    const rIds = resourceMappings?.filter(rm => n?.includes(rm.node)).map(d => d.resource)
    return rIds
  }
)
