import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  Typography,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  FormControl,
  Select,
  Snackbar,
  Grow,
} from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import { DataGrid, GridColDef, GridCellParams } from '@material-ui/data-grid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import deleteIcon from '@assets/images/delete.svg'
import { Collaboration, iDomainNew, iCollaborationInvite } from "@gloow/apiconsumer"

import { ConfirmDialog } from '@common/components'
import moment from 'moment'
import AnalyticsService from '@services/AnalyticsService'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    dialogTitle: {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 24,
      width: '80%',
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      minWidth: 1000,
      maxWidth: 1000,
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5,
    },
    inviteText: {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 20,
    },
    inputSelectField: {
      marginBottom: 20,
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        background: theme.palette.background.default,
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 14px',
        paddingRight: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRight: 0,
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
        borderWidth: '1px !important',
      },
    },
    inputSelectDropdown: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 14px',
        paddingRight: 30,
      },
    },
    inputSelectButton: {
      marginLeft: 15,
      padding: '12px 15px',
    },
    userTable: {
      border: 'none !important',

      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
    },
    imageContainer: {
      width: 40,
      height: 40,
      borderRadius: 20,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 10,
    },
    userImage: {
      minHeight: 40,
      minWidth: 40,
      maxWidth: 'initial',
      objectFit: 'cover',
    },
    name: {
      fontSize: 14,
    },
    email: {
      fontSize: 13,
      opacity: 0.7,
    },
    copyLink: {
      fontSize: 13,
      opacity: 0.7,
      cursor: 'pointer',
      color: '#4354dd',
    },
    invitedText: {
      opacity: 0.7,
    },
    textCenter: {
      textAlign: 'center',
      flex: 1,
    },
    emptyRows: {
      top: 56,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      position: 'absolute',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    flex: {
      display: 'flex',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
  })
)

export interface iUserPermissionsTableProps {
  domain?: iDomainNew
}

export function UserPermissionsTable({ domain }: iUserPermissionsTableProps) {
  const classes = useStyles()
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [permission, setPermission] = useState<string[]>(['modify'])
  const [email, setEmail] = useState('')
  const [userList, setUserList] = useState([])
  const [deleteState, setDeleteState] = useState<{
    deleteDialog: boolean
    id: number | undefined
  }>({
    deleteDialog: false,
    id: undefined,
  })

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    if (permission.length > 0)
      setPermission(
        event.target.value[1] ? ([event.target.value[1]] as string[]) : []
      )
    else setPermission(event.target.value as string[])
  }

  const [snackBar, setSnackBar] = useState<{
    open: boolean
    message: string
    severity: Color | undefined
  }>({
    open: false,
    message: '',
    severity: 'success',
  })

  const permissions = [
    {
      value: 'read',
      label: t('collaboration.read_only'),
    },
    {
      value: 'modify',
      label: t('collaboration.can_edit'),
    },
  ]

  const columns: GridColDef[] = [
    {
      field: 'user',
      headerName: t('common.name'),
      flex: 220,
      renderCell: (params: any) => {
        const imageUrl =
          params.value?.user_metadata?.picture ?? params.value?.picture

        return (
          <div className={classes.flex}>
            <div className={classes.imageContainer}>
              {imageUrl && (
                <img className={classes.userImage} src={imageUrl} alt='node' />
              )}
            </div>
            <div>
              <Typography className={classes.name}>
                {params.value ? params.value.name : params.row.invitationEmail}
              </Typography>
              <Typography className={classes.email}>
                {params.value ? params.value.email : params.row.invitationEmail}
              </Typography>
            </div>
          </div>
        )
      },
    },
    {
      field: 'lastActive',
      headerName: t('common.last_active'),
      flex: 130,
      renderCell: (params: GridCellParams) => {
        if (!params.value) {
          return <div className={classes.textCenter}>-</div>
        }

        return <> {params.value} </>
      },
    },
    {
      field: 'view',
      headerName: t('collaboration.can_view'),
      minWidth: 110,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          disabled={loading}
          // @ts-ignore
          checked={params.value ?? false}
          color='primary'
          onChange={(e) =>
            handleChangePermissions(params.row, 'view', e.target.checked)
          }
        />
      ),
    },
    {
      field: 'expand',
      headerName: t('collaboration.can_add'),
      minWidth: 110,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          disabled={loading}
          // @ts-ignore
          checked={params.value ?? false}
          color='primary'
          onChange={(e) =>
            handleChangePermissions(params.row, 'expand', e.target.checked)
          }
        />
      ),
    },
    {
      field: 'modify',
      headerName: t('collaboration.can_edit'),
      minWidth: 110,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          disabled={loading}
          // @ts-ignore
          checked={params.value ?? false}
          color='primary'
          onChange={(e) =>
            handleChangePermissions(params.row, 'modify', e.target.checked)
          }
        />
      ),
    },
    {
      field: 'remove',
      headerName: t('collaboration.can_remove'),
      minWidth: 130,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          disabled={loading}
          // @ts-ignore
          checked={params.value ?? false}
          color='primary'
          onChange={(e) =>
            handleChangePermissions(params.row, 'remove', e.target.checked)
          }
        />
      ),
    },
    {
      field: 'id',
      headerName: ' ',
      minWidth: 80,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        if (!params.row.joined) {
          return (
            <div className={classes.flex}>
              <div>
                <Typography className={classes.name}>
                  {t('collaboration.invited')}
                </Typography>
                <CopyToClipboard
                  text={`${window.location.origin}/domain-invitation/${params.row.invitationCode}`}
                  onCopy={() => {
                    setSnackBar({
                      open: true,
                      message: 'Invitation code copied to clipboard.',
                      severity: 'success',
                    })
                  }}
                >
                  <Typography className={classes.copyLink}>
                    {t('common.copy_link')}
                  </Typography>
                </CopyToClipboard>
              </div>
            </div>
          )
        }

        return (
          <IconButton
            disabled={loading}
            onClick={() =>
              setDeleteState({ deleteDialog: true, id: params.value as number })
            }
          >
            <img src={deleteIcon} alt='delete' />
          </IconButton>
        )
      },
    },
  ]

  useEffect(() => {
    getDomainPermissions()
    // eslint-disable-next-line
  }, [])

  const getDomainPermissions = async () => {
    setLoading(true)
    const Collab = new Collaboration()
    const list = await Collab.list(domain!.uuid)

    const initUserList = (data) => {
      const userData = data.map((item) => ({
        ...item,
        lastActive: item.user
          ? moment(item.user.last_login).locale(i18n.language).fromNow()
          : '',
      }))
      return userData //.filter(item => item.joined)
    }

    setUserList(initUserList(list))
    setLoading(false)
  }

  const handleInvite = async () => {
    setLoading(true)
    try {
      const Collab = new Collaboration()
      const permissionsData = {
        modify: false,
        view: false,
        expand: false,
        remove: false,
      }
      if (permission.length) {
        if (permission[0] === 'modify')
          Object.keys(permissionsData).map(
            (key: any) => (permissionsData[key] = true)
          )
        else permissionsData.view = true
      }

      const inviteData: iCollaborationInvite = {
        domainUuid: domain!.uuid,
        email: email,
        permissions: { ...permissionsData },
      }
      const response = await Collab.invite(inviteData)
      if (response) {
        getDomainPermissions()
        setEmail('')
        setPermission(['modify'])
        setSnackBar({
          open: true,
          message: response.joined
            ? t('collaboration.successfully_added_user_to_domain')
            : t('collaboration.invitation_sent'),
          severity: 'success',
        })
        setLoading(false)
      }
    } catch (error) {
      AnalyticsService.logError('collaboration-invite-error', { error })

      setLoading(false)
      console.log(error)
    }
  }

  const handleLeave = async () => {
    setLoading(true)
    try {
      const Collab = new Collaboration()
      const response = await Collab.remove(deleteState.id as number)
      if (response) {
        getDomainPermissions()
        setSnackBar({
          open: true,
          message: t('collaboration.successfully_removed_user'),
          severity: 'success',
        })
        setLoading(false)
        setDeleteState({ deleteDialog: false, id: undefined })
      }
    } catch (error) {
      AnalyticsService.logError('collaboration-leave-error', { error })
      setLoading(false)
      console.log(error)
    }
  }

  const handleChangePermissions = async (rowsData, field, value) => {
    const user = { ...rowsData }
    user[field] = value

    let users: any = [...userList]
    const userIndex = users.findIndex((item: any) => item.id === user.id)
    users[userIndex] = user

    setUserList(users)

    const Collab = new Collaboration()
    const update = await Collab.update(user.id, {
      view: user.view,
      modify: user.modify,
      expand: user.expand,
      remove: user.remove,
    })
    if (update) {
      setSnackBar({
        open: true,
        message: t('collaboration.successfully_update_permission'),
        severity: 'success',
      })
    }
  }

  const noRows = () => {
    return (
      <div className={classes.emptyRows}>
        {t('collaboration.no_users_yet_on_this_domain')}
      </div>
    )
  }

  return (
    <>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant='h3'>
          {t('common.users_and_permissions')}
        </Typography>
      </div>

      <Typography className={classes.inviteText} variant='h4'>
        {t('collaboration.invite_users')}
      </Typography>

      <div className={`${classes.flex} ${classes.justifyBetween}`}>
        {/* <div>
          <div className='mb-2'>Invite link</div>
          <TextField
            className={classes.inputSelectField}
            variant='outlined'
            disabled
            value={'https://gloow.io/browse/GLW-355'}
          />
          <TextField
            className={classes.inputSelectDropdown}
            select
            value={permission}
            onChange={handleChange}
            variant='outlined'
          >
            {permissions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant='contained'
            color='primary'
            className={classes.inputSelectButton}
          >
            Copy Link
          </Button>
        </div> */}

        <div>
          <div className='mb-2'>{t('collaboration.invite_by_email')}</div>
          <TextField
            disabled={loading}
            className={classes.inputSelectField}
            variant='outlined'
            placeholder={t('auth.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl
            variant='outlined'
            className={classes.inputSelectDropdown}
          >
            <Select
              multiple
              displayEmpty
              value={permission}
              onChange={handleChange}
              renderValue={(selected: any) => {
                const selectedLabel = permissions.filter(
                  (item) => item.value === selected[0]
                )
                const label = selectedLabel.length
                  ? selectedLabel[0].label
                  : ''
                return selected.length
                  ? selected.length > 1
                    ? +selected.length +
                    ` ${t('common.selected').toLowerCase()}`
                    : label
                  : t('common.permissions')
              }}
            >
              {permissions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    color='primary'
                    checked={permission.indexOf(option.value) > -1}
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant='contained'
            color='primary'
            className={classes.inputSelectButton}
            onClick={() => handleInvite()}
            disabled={!email || loading}
          >
            {t('collaboration.invite')}
          </Button>
        </div>
      </div>

      <Typography className={classes.inviteText} variant='h4'>
        {t('collaboration.manage_permissions')}
      </Typography>

      <DataGrid
        className={classes.userTable}
        rows={userList}
        columns={columns}
        rowHeight={70}
        loading={loading}
        disableColumnMenu
        disableColumnFilter
        hideFooter
        components={{
          NoRowsOverlay: noRows,
        }}
        autoHeight
        autoPageSize
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBar.open}
        TransitionComponent={Grow}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
        autoHideDuration={3000}
      >
        <Alert
          variant={'filled'}
          onClose={() => setSnackBar({ ...snackBar, open: false })}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>

      <ConfirmDialog
        open={deleteState.deleteDialog}
        onClose={() => setDeleteState({ deleteDialog: false, id: undefined })}
        onConfirm={async () => {
          await handleLeave()
        }}
        text={t('collaboration.are_you_sure_want_to_remove_this_user')}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
      />
    </>
  )
}
