// eslint-disable-next-line
import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '@store/hooks'
import AnalyticsService from '@services/AnalyticsService'
import { setRows, setColumns } from '@store/entities/entitiesSlice'
import { changeColumnOrder, createOrUpdateCell, createOrUpdateColumn, createOrUpdateRow, deleteColumn, getColumns, importNodes } from '@services/EntityService'
import { setLastUpdate } from '@store/domain/domainSlice'
import { iEntityProperty } from '@store/entities/entities.interfaces'
import { iLabel, iNode, iProperty, PropertyValueTypes } from '@gloow/apiconsumer'
import { deleteNodes } from '@services/DomainDataService'
import useDomain from './useDomain'

const useEntity = () => {
  const dispatch = useAppDispatch()
  const [activeLabel, setActiveLabel] = useState<iLabel>()
  const [loading, setLoading] = useState(false)
  const [initiating, setInitiating] = useState(false)
  const { nodes } = useDomain()
  const [rows, columns] = useAppSelector(state => [state.entities.rows, state.entities.columns])

  const changeActiveLabel = (active?: iLabel) => setActiveLabel(active)

  const sortRow = (a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()

  const loadLabel = async () => {
    try {
      setLoading(true)
      setInitiating(true)
      await getColumns(activeLabel?.id!)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
      setInitiating(false)
    } catch (e) {
      setInitiating(false)
      setLoading(false)
      AnalyticsService.logError('entity-get-columns', { e })
    }
  }

  const remapRows = (allEntities = false) => {
    const rowsData = nodes.filter(d => {
      const labelIds = d.labels.map(d => d.id)
      if (allEntities) return true
      if (labelIds.includes(activeLabel?.id)) return true
      return false
    }).map(node => {
      const props = columns.map(column => {
        const exist = node.properties.find(d => d.key === column.key)
        if (exist) return { ...exist, order: column.order }
        // prop not exist in node, labelId, value should be null
        return { ...column, id: null, labelId: null, nodeId: node.id, value: PropertyValueTypes.RELATION === column.valueType ? column.value : '' }
      })
      return { ...node, properties: props }
    }).sort(sortRow)
    dispatch(setRows(rowsData ?? []))
  }

  const onColumnAdd = async (target: iEntityProperty) => {
    const newColumns = [...columns, target]
    dispatch(setColumns(newColumns))
  }

  const onColumnChange = async (current: iEntityProperty, target: Partial<iEntityProperty>) => {
    try {
      setLoading(true)
      await createOrUpdateColumn({ ...target, labelId: activeLabel?.id })
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-column-change', { e })
    }
  }

  const onColumnDelete = async (current: iProperty) => {
    try {
      setLoading(true)
      if (!current?.id) {
        const updatedColumns = [...columns]
        updatedColumns.splice(-1, 1)
        dispatch(setColumns(updatedColumns))
      }
      else await deleteColumn(current.id)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-delete-column', { e })
    }
  }

  const onCellChange = async (node: iNode, newProperty: iEntityProperty) => {
    try {
      setLoading(true)
      const res = await createOrUpdateCell(node.id, newProperty)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
      return res
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-cell-change', { e })
      return false
    }
  }

  const onRowAdd = () => {
    if (rows.length && !rows[rows.length - 1].id) return
    const newNode: Partial<iNode> = {
      name: '',
      labels: activeLabel?.id ? [activeLabel?.id!] : [],
      properties: []
    }
    dispatch(setRows([...rows, newNode]))
  }

  const onNodeChange = async (current: Partial<iNode>, target: Partial<iNode>) => {
    try {
      if (current.name === target.name) return
      setLoading(true)
      await createOrUpdateRow(target)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-node-change', { e })
    }
  }

  const onOrderChange = async (current: iEntityProperty, target: iEntityProperty) => {
    try {
      setLoading(true)
      await changeColumnOrder(current, target)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-column-order-change', { e })
    }
  }

  const onRowsDelete = async (selection) => {
    try {
      setLoading(true)
      const ids = selection.filter(d => d.id).map(d => d.id)
      if (ids.length > 0) await deleteNodes(ids)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-delete-nodes', { e })
    }
  }

  const csvImportDone = async (newNodes: iNode[]) => {
    try {
      setLoading(true)
      await getColumns(activeLabel?.id!)
      await importNodes(newNodes)
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('entity-import-nodes', { e })
    }
  }

  return {
    remapRows,
    onColumnAdd,
    onColumnChange,
    onColumnDelete,
    onNodeChange,
    onOrderChange,
    onRowsDelete,
    onRowAdd,
    onCellChange,
    loading,
    activeLabel,
    changeActiveLabel,
    rows,
    loadLabel,
    csvImportDone,
    initiating,
    columns
  }
}

export default useEntity
