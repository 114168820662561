import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: 32,
  },
  title: {
    fontWeight: 600
  },
  accountTitle: {
    fontSize: 36,
    fontWeight: 600,
    marginBottom: 40,
  },
  tabIndicator: {
    display: 'none'
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,

    '& .grid-card': {
      position: 'relative',
      cursor: 'pointer',
      borderRadius: 12,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0,
        transition: 'opacity ease 0.5s',
        boxShadow: '0 4px 12px 0 rgba(67, 84, 221, 0.3)',
        borderRadius: 12,
      },
      '& .MuiCard-root': {
        transition: 'all ease 0.5s',
      },
      '&:hover': {
        '&::before': {
          opacity: 1
        }
      },
      '&:active': {
        '&::before': {
          transform: 'scale(0.98) !important'
        },
        '& .MuiCard-root': {
          transform: 'scale(0.98) !important'
        }
      }
    }
  },
  containerCard: {
    borderRadius: 12
  },
  tabs: {
    display: 'flex',
    margin: theme.spacing(2, 0),
  },
  tab: {
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    fontSize: 16,
    opacity: 0.4
  },
  tabActive: {
    fontWeight: 600,
    opacity: 1
  },
  tabLabel: {
    // fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'capitalize'
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 150,
    minHeight: 120,
    margin: '24px 0'
  },
  noResultContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh'
  },
}))
