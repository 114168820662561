import React from 'react';
import useStyles from './AppSwitcher.styles'
import { useHistory, useParams } from 'react-router-dom'
// import IconGoals from '@assets/images/icons-goals.svg'
// import IconMarketplace from '@assets/images/icons-marketplace.svg'
import IconMindMaps from '@assets/images/icons-menu-map.svg'
import IconEntities from '@assets/images/icons-menu-entities.svg'
// import IconAssistant from '@assets/images/icons-menu-assistant.svg'
import IconNotes from '@assets/images/icons-menu-notes.svg'
import IconDocsMedia from '@assets/images/icons-menu-docs-media.svg'
import IconSetting from '@assets/images/icons-menu-setting.svg'

import IconMindMapsActive from '@assets/images/icons-menu-map-active.svg'
import IconEntitiesActive from '@assets/images/icons-menu-entities-active.svg'
// import IconAssistantActive from '@assets/images/icons-menu-assistant-active.svg'
import IconNotesActive from '@assets/images/icons-menu-notes-active.svg'
import IconDocsMediaActive from '@assets/images/icons-menu-docs-media-active.svg'
import IconSettingActive from '@assets/images/icons-menu-setting-active.svg'

import { IconButton, Tooltip } from '@material-ui/core';
import { RouteURI } from '@common/constants/Routes';
import Colors from '@common/constants/Colors';
import { useAppDispatch } from '@store/hooks';
import { setConnectionView, setLabelsFilter } from '@store/filters/filtersSlice';
import { iDomainPermissions } from '@store/domain/domain.interfaces';
interface iAppRoutes {
  icon: string,
  iconActive: string,
  name: string,
  onClick: () => void,
  uri: string,
  activeOn: string[],
  ownerOnly?: boolean
}

const AppSwitcher = ({ domainPermissions }: { domainPermissions: iDomainPermissions }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const pathname = history.location.pathname
  const { slug } = useParams<{ slug: string }>()
  const apps: iAppRoutes[] = [
    {
      icon: IconMindMaps,
      name: 'Mind Maps',
      iconActive: IconMindMapsActive,
      onClick: () => history.push(`/${slug}/${RouteURI.MIND_MAPS}`),
      uri: RouteURI.MIND_MAPS,
      activeOn: [RouteURI.MIND_MAPS]
    },
    {
      icon: IconNotes,
      name: 'Focus Mode',
      iconActive: IconNotesActive,
      onClick: () => history.push(`/${slug}/${RouteURI.FOCUS_MODE}`),
      uri: RouteURI.FOCUS_MODE,
      activeOn: [RouteURI.FOCUS_MODE]
    },
    {
      icon: IconEntities,
      name: 'Data Tables',
      iconActive: IconEntitiesActive,
      onClick: () => {
        dispatch(setConnectionView(undefined))
        dispatch(setLabelsFilter([]))
        history.push(`/${slug}/${RouteURI.ENTITIES}/all`)
      },
      uri: RouteURI.ENTITIES,
      activeOn: [RouteURI.ENTITIES]
    },
    {
      icon: IconDocsMedia,
      iconActive: IconDocsMediaActive,
      name: 'Docs & Media',
      onClick: () => {
        dispatch(setConnectionView(undefined))
        dispatch(setLabelsFilter([]))
        history.push(`/${slug}/${RouteURI.DOCUMENTS}/all`)
      },
      uri: RouteURI.DOCUMENTS,
      activeOn: [RouteURI.DOCUMENTS]
    },
    {
      icon: IconSetting,
      iconActive: IconSettingActive,
      name: 'Settings',
      onClick: () => history.push(`/${slug}/${RouteURI.DOMAIN_SETTING}`),
      uri: RouteURI.DOMAIN_SETTING,
      activeOn: [RouteURI.DOMAIN_SETTING],
      ownerOnly: true
    },
    // { icon: IconMarketplace, name: 'Marketplace', onClick: () => history.push(`/${slug}/marketplace`) },
  ]
  return <div className={classes.container}>
    {apps.filter(d => d.ownerOnly ? Boolean(domainPermissions?.owner) : true).map((d, idx) => {
      const active = d.activeOn.some(d => pathname.includes(d))
      return <Tooltip title={d.name} key={idx.toString()}>
        <IconButton key={idx.toString()} classes={{ root: classes.button }} style={active ? { backgroundColor: Colors.lightBlue2 } : {}} onClick={d.onClick}>
          <img src={active ? d.iconActive : d.icon} alt={`menu-${d.name}`} />
        </IconButton>
      </Tooltip>
    })}
  </div>
};

export default AppSwitcher;
