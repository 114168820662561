import React, { useCallback, useState } from 'react'
import { AppLayout, ConfirmDialog, GridView, ResourceInfoForm } from '@common/components'
import useDomain from '@hooks/useDomain'
import useStyles from './index.styles'
import { Button, Drawer, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Add, CloseOutlined, MoreVert } from '@material-ui/icons'

import DeleteIcon from '@assets/images/delete.svg'
import filterIcon from '@assets/images/icons-filter.svg'
import FilterForm from '@common/components/Form/FilterForm/FilterForm'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { iInteractionDialog } from '@common/constants/Constants'
import { useHistory, useParams } from 'react-router-dom'
import { RouteURI } from '@common/constants/Routes'
import webIcon from '@assets/images/icons-web.png'
import uploadIcon from '@assets/images/icons-upload.png'
import { ResourceRouteURI } from '@store/resources/resources.interfaces'
import { getFilteredResourcesIds } from '@selectors/index'

import IconList from '@assets/images/icons-list.svg'
import IconWindow from '@assets/images/icons-window.svg'
import { getResourceThumbnail } from '@helpers/resource'
import moment from 'moment'
import { iResource, Resource } from "@gloow/apiconsumer"
import AnalyticsService from '@services/AnalyticsService'
import { removeResourceMappings } from '@store/resourceMappings/resourceMappingsSlice'
import { removeResource } from '@store/resources/resourcesSlice'
import { setLastUpdate } from '@store/domain/domainSlice'
import emptyResourceIcon from '@assets/images/empty-resource.png'

const DocsMedia = ({
  setAddFileDialog,
  setAddExistingItemDialog,
  setAddWebLinkDialog
}: {
  setAddFileDialog: (dialog: iInteractionDialog) => void
  setAddWebLinkDialog: (dialog: iInteractionDialog) => void
  setAddExistingItemDialog: (dialog: iInteractionDialog) => void
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const { nodes, resources, relations, labels, resourceMappings, domainPermissions } = useDomain()
  const [filterDialog, setFilterDialog] = useState<boolean>(false)
  const [connectionView, labelFilter] = useAppSelector(state => [state.filters.connectionView, state.filters.labels])
  const filterCount = labelFilter.length + (connectionView ? 1 : 0)
  const { resourceId, slug, route } = useParams<{ resourceId?: string, slug?: string, route?: string }>()
  const [menuAnchor, setMenuAnchor] = useState<any>()
  const [rowMenu, setRowMenu] = useState<{ anchor?: HTMLButtonElement, data?: iResource }>({ anchor: undefined, data: undefined })
  const [dialog, setDialog] = useState<{ type?: 'delete', data?: iResource }>()
  const filteredResources = useAppSelector(state => {
    if (filterCount > 0 && route === ResourceRouteURI.table) {
      const ids = getFilteredResourcesIds(state)
      return state.resources.resources.filter(d => ids.includes(d.id))
    }
    return state.resources.resources
  })
  const onFormClosed = () => {
    if (route === ResourceRouteURI.table) return history.replace(`/${slug}/${RouteURI.DOCUMENTS}/all/${route}`)
    return history.replace(`/${slug}/${RouteURI.DOCUMENTS}/all`)
  }

  const onRemove = async () => {
    try {
      if (!dialog?.data) return
      setLoading(true)
      // remove resource
      const RS = new Resource()
      await RS.delete(dialog.data.id)
      dispatch(removeResourceMappings(dialog.data.id))
      dispatch(removeResource(dialog.data.id))
      dispatch(setLastUpdate(Date.now()))
      setDialog({ type: undefined, data: undefined })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('delete-resource', { e })
    }
  }

  const renderMenu = useCallback(
    () => {
      return <Menu
        className={classes.menu}
        anchorEl={menuAnchor}
        id={'1'}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(undefined)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={() => setMenuAnchor(undefined)}
      >
        <MenuItem onClick={() => setAddFileDialog({ open: true })}>
          <img src={uploadIcon} alt="upload a file" width="20" /> {t('common.upload_a_file', 'Upload a file')}
        </MenuItem>
        <MenuItem onClick={() => setAddWebLinkDialog({ open: true })}>
          <img src={webIcon} alt="add a web link" width="20" /> {t('common.add_a_web_link', 'Add a web link')}
        </MenuItem>
      </Menu >
      // eslint-disable-next-line
    }, [menuAnchor])

  const resource = useCallback(
    () => {
      const res = resources.find(d => d.id === parseInt(resourceId ?? ''))
      return res
    },
    // eslint-disable-next-line
    [resourceId, resources],
  )


  const renderHeader = () => {
    return <div className={classes.header}>
      <Typography variant='h4' className={classes.title}>{t('common.documents_and_media', 'Documents & Media')}</Typography>
      <div className={classes.actions}>
        {domainPermissions?.modify && (
          <Button
            variant="contained"
            color="primary"
            className={`add-button-header ${classes.addButton}`}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            startIcon={<Add />}
          >
            {t('common.add_resources')}
          </Button>
        )}
        <IconButton onClick={() => setFilterDialog(!filterDialog)} className={classes.actionButton} size={'small'}>
          <span className={`${classes.filterCount} ${filterCount > 0 ? classes.filterCountShow : classes.filterCountHide}`}>{filterCount}</span>
          <img alt='filter-icon' src={filterIcon} className={classes.actionIcon} />
        </IconButton>
        <IconButton
          size='small'
          className={classes.actionButton}
          onClick={() => history.replace(`/${slug}/${RouteURI.DOCUMENTS}/all${route === ResourceRouteURI.table ? '' : `/${ResourceRouteURI.table}`}`)}>
          {route === ResourceRouteURI.table ?
            <img src={IconList} alt='visual-switch' /> :
            <img src={IconWindow} alt='visual-switch' />
          }
        </IconButton>

      </div>
    </div>
  }

  const renderRowMenu = () => {
    return <Menu
      className={classes.menu}
      anchorEl={rowMenu.anchor}
      id={'1'}
      open={Boolean(rowMenu.anchor)}
      onClose={() => setRowMenu({ anchor: undefined, data: undefined })}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClick={() => setRowMenu({ anchor: undefined, data: undefined })}
    >
      <MenuItem onClick={() => {
        setDialog({ data: rowMenu.data, type: 'delete' })
        setRowMenu({ anchor: undefined, data: undefined })
      }}>
        <img src={DeleteIcon} alt="delete" width="20" />
        <Typography variant='body2'>{t('common.delete', 'Delete')}</Typography>
      </MenuItem>
    </Menu >
  }

  const renderTable = () => {
    return <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('common.name', 'Name')}</TableCell>
            <TableCell>{t('common.created_at', 'Created at')}</TableCell>
            <TableCell>{t('common.type', 'Type')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredResources.map(d => <TableRow
            className={classes.resourceRow}
            onClick={() => history.replace(`/${slug}/${RouteURI.DOCUMENTS}/${d.id}/${ResourceRouteURI.table}`)}
          >
            <TableCell>
              <div className={classes.resourceName}>
                <img alt='' src={getResourceThumbnail(d)} className={classes.resourceImg} />
                <Typography variant='body2'>{d.title}</Typography>
              </div>
            </TableCell>
            <TableCell>{moment(d.createdAt).locale(i18n.language).format('MMM DD, YYYY')}</TableCell>
            <TableCell>{d.type}</TableCell>
            <TableCell>
              <IconButton size='small' className={classes.more} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setRowMenu({ anchor: e.currentTarget, data: d })
              }}>
                <MoreVert color='primary' />
              </IconButton>
            </TableCell>
          </TableRow>
          )}
          {!filteredResources.length && <TableRow>
            <TableCell colSpan={4}>
              <div className={classes.emptyContainer}>
                <img src={emptyResourceIcon} alt="empty" />
                <p>
                  {t(`common.no_resources_found`)}
                </p>
              </div>
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  }

  return (
    <>
      <AppLayout
        renderContent={
          <div style={{ overflow: route === ResourceRouteURI.table ? 'hidden' : 'auto' }}>
            {renderHeader()}
            {route === ResourceRouteURI.table
              ?
              <div className={classes.contentContainerTable}>{renderTable()}</div>
              :
              <GridView
                data={resources}
                nodes={nodes}
                relations={relations}
                resourceMappings={resourceMappings}
                onSelectedItem={(item) => history.replace(`/${slug}/${RouteURI.DOCUMENTS}/${item.id}`)}
                showConnections={true}
              />
            }
          </div>
        }
      />
      {renderMenu()}
      {renderRowMenu()}
      <Drawer
        classes={{ paper: classes.filterSidebar }}
        BackdropProps={{ invisible: true }}
        open={filterDialog}
        onClose={() => setFilterDialog(false)}
        anchor={'right'}
      >
        <div className={classes.filterHeader}>
          <Typography className={classes.filterHeaderTitle} variant="h3">{t('common.filters', 'Filters')}</Typography>
          <IconButton className={classes.filterCloseButton} onClick={() => setFilterDialog(false)}>
            <CloseOutlined className={classes.filterCloseIcon} />
          </IconButton>
        </div>
        <div className={classes.filterContainer}>
          <FilterForm onClose={() => setFilterDialog(false)} labels={labels} />
        </div>
      </Drawer>

      <Drawer
        classes={{
          paper: classes.filterSidebar
        }}
        BackdropProps={{ invisible: true }}
        open={Boolean(resource())}
        onClose={() => onFormClosed()}
        anchor={'right'}
      >
        <div className={classes.filterContainer}>
          <IconButton className={classes.closeResource} size={'small'} onClick={() => history.replace(`/${slug}/${RouteURI.DOCUMENTS}`)}>
            <CloseOutlined className={classes.filterCloseIcon} />
          </IconButton>
          <ResourceInfoForm
            activeResource={resource()}
            handleClose={() => onFormClosed()}
            setAddExistingItemDialog={setAddExistingItemDialog}
            onConnectedNodeClicked={(item) => history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${item.id}`)}
          />
        </div>
      </Drawer>

      <ConfirmDialog
        loading={loading}
        open={Boolean(dialog?.type === 'delete')}
        onConfirm={() => onRemove()}
        onClose={() => setDialog({ type: undefined, data: undefined })}
        text={t('common.are_you_sure_want_to_delete_this_resource')}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
      />
    </>
  )
}

export default DocsMedia
