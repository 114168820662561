import Colors from "@common/constants/Colors";
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(
  createStyles({
    container: {},
    searchBar: {
      margin: "0px 16px",
      marginBottom: 16,
    },
    item: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexGrow: 1,
      boxShadow: "none !important",
      borderRadius: 12,
      padding: '8px 25px',
      "&.active": {
        backgroundColor: Colors.lightBlue2,
      },
      "&.active img": {
        opacity: 1,
      },
      width: "100%",
      justifyContent: "flex-start",
      textAlign: "left",
    },
    groupLabel: {
      fontSize: 11,
      color: Colors.darkGrey,
      marginRight: 8
    },
    emptyWrapper: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    headerWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: 16,
    },
    labelIcon: {
      width: 8,
      height: 8,
      backgroundColor: "red",
      borderRadius: "50%",
      marginRight: 11,
    },
    collapseIcon: {
      marginRight: 4,
      fontSize: 20,
      color: "#93959F",
      "&.active": {
        transform: "rotate(90deg)",
      },
    },
    listWrapper: {
    },
    addButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "8px 16px",
      flexGrow: 1,
      boxShadow: "none !important",
      borderRadius: 12,
      width: "100%",
      color: Colors.primary,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: Colors.lightBlue2,
      },
      "&.active": {
        backgroundColor: Colors.lightBlue2,
      },
      justifyContent: "flex-start",
      textAlign: "left",
    },
    addIcon: {
      fontSize: 18,
      margin: '0px 6px 0px 4px'
    },
    wrapperInner: {
      '& .children': {
        paddingLeft: 56
      }
    },
    menuIcon: {
      width: 20,
      height: 20,
      marginLeft: 3,
      marginRight: 5
    }
  })
);
