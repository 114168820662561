import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import useStyles from './NodePicker.styles'
import ItemSearchButton from '@common/components/ItemSearchButton/ItemSearchButton'

interface iNodePicker {
  selectedNodes: any
  onSelected: (node) => void
  onDeleted: (node) => void
  placeholder?: string
}

const NodePicker = ({
  selectedNodes = [],
  onSelected = (node) => { },
  onDeleted = (node) => { },
  placeholder,
}: iNodePicker) => {
  const [node, setNode] = useState("")
  const classes = useStyles()

  return (
    <div>
      <TextField
        id="node-picker"
        fullWidth
        value={node}
        variant="standard"
        placeholder={placeholder ?? "Start typing..."}
        className={classes.input}
        onChange={(e) => setNode(e.target.value)}
        onKeyPress={(e) => {
          if (e?.key === 'Enter') {
            e.preventDefault()
            onSelected({ id: node, name: node })
            setNode("")
          }
        }}
      />

      <div className={classes.nodeWrapper}>
        {selectedNodes.map(d => <ItemSearchButton active={true} name={d.name} onDelete={() => onDeleted(d)} />)}
      </div>
    </div >
  )
}

export default NodePicker
