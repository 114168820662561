import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  addColumnMenu: {
    minWidth: 177
  },
  addColumnTitle: {
    padding: '8px 16px',
    fontWeight: 600
  },
  addColumnImg: {
    height: 12,
    width: 12
  },
  addColumnText: {
    marginLeft: 12
  },
  color: {
    borderRadius: '50%',
    width: 8,
    height: 8
  },
  addIcon: {
    height: 14,
    width: 14,
    marginLeft: -3
  },
  menuItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    '& .MuiListItem-root': {
      borderRadius: 12
    }
  }
}))