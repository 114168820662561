import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  relationsPicker: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 20,
    minHeight: 33
  },
  imageContainer: {
    minWidth: 24,
    width: 24,
    height: 24,
    borderRadius: 20,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: 'white',
    marginRight: 10,
    boxShadow: '0 6px 12px 0 rgba(67, 84, 221, 0.15)'
  },
  imageItem: {
    minHeight: 24,
    minWidth: 24,
    maxWidth: 'initial',
    objectFit: 'cover'
  },
  relationLabel: {
    position: 'relative',
    cursor: 'pointer',
    width: '100%',
    '& span': {
      fontWeight: 600
    },
  },
  relationInput: {
    cursor: 'pointer',
    width: '100%'
  },
  labelsInput: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 6,
    paddingLeft: 10,
    paddingRight: 10,
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        height: 20,
        minWidth: 100,
        fontSize: 14
      },
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    }
  },
  label: {
    fontWeight: 600
  },
  menuPaper: {
    padding: 8,
    boxShadow: 'none !important'
  },
  listBox: {
    '& li': {
      fontSize: 14
    }
  }
}))
