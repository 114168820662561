import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  cancelButton: {
    boxShadow: "0 6px 12px 0 rgba(67, 84, 221, 0.08)",
    color: theme.palette.primary.main
  },
  connectingContainer: {
    padding: '12px 0px',
    paddingTop: 0
  },
  connectingTitle: {
    fontSize: 14,
    marginBottom: 6
  },
  connectingNode: {
    display: 'flex',
    alignItems: 'center'
  },
  connectingNodeName: {
    fontSize: 16,
    marginLeft: 8
  },
  my8: { margin: '8px 0px' },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    marginTop: 16
  }
}))