import { stripEmojis } from '@helpers/utils';
import { createAvatarComponent, SrcSource, ValueSource } from 'react-avatar';
import useStyles from './Avatar.styles'
const CustomAvatar = createAvatarComponent({
  sources: [ValueSource, ValueSource, SrcSource]
});
export interface iAvatar {
  className?: string,
  name: string
  color?: string
  src?: string
  size?: string
  alt?: string,
  textSize?: 'sm' | 'md' | 'xs'
}

// #4354dd
export const Avatar = ({ className = '', name, src, size = "40", textSize = 'md', color = "#4354dd", alt, ...props }: iAvatar) => {
  const classes = useStyles()
  const strippedName = stripEmojis(name)

  const textStyle = {
    'xs': classes.reactAvatarXS,
    'sm': classes.reactAvatarSM,
    'md': classes.reactAvatar
  }

  if (src) return <img className={`${classes.image} ${className}`} style={{ height: parseInt(size), width: parseInt(size) }} src={src} alt={alt ?? ''} />
  return (
    <CustomAvatar
      className={`${textStyle[textSize]} ${className}`}
      name={strippedName}
      color={color}
      size={size}
      round
      maxInitials={2}
      // initials={getInitials}
      {...props}
    />
  )
}

export default Avatar