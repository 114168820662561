import { jsx } from 'slate-hyperscript'
import { Transforms, Node, Descendant, Editor } from 'slate'
import { validURL } from '@helpers/utils'
import { addWebLink } from '@services/DomainDataService'
import { SearchResultType } from "@gloow/apiconsumer"
import { uploadImages } from './withImage'
import { ReactEditor } from 'slate-react'
// import escapeHtml from 'escape-html'

const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src'), caption: '' }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}


// @todo
export const serialize = node => {
  // if (Text.isText(node)) {
  //   let string = escapeHtml(node.text)
  //   // @ts-ignore
  //   if (node.bold) string = `<strong>${string}</strong>`
  //   return string
  // }

  // const children = node.children?.map(n => serialize(n)).join('')

  // switch (node.type) {
  //   case 'quote':
  //     return `<blockquote><p>${children}</p></blockquote>`
  //   case 'paragraph':
  //     return `<p>${children}</p>`
  //   case 'link':
  //     return `<a href="${escapeHtml(node.url)}">${children}</a>`
  //   default:
  //     return children
  // }
}

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes)
    .map(deserialize)
    .flat()

  if (children?.length === 0) {
    children = [{ text: '' }]
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    children = children.map(d => !d ? '' : d)
    const attrs = ELEMENT_TAGS[nodeName](el)
    // async resource add (might contains so much link here since user can copy a whole page html)
    // commenting this since it can break the app
    // if (nodeName === 'A' && attrs.url) addWebLink(attrs.url, nodeId)
    return jsx('element', attrs, children)
  }
  // https://github.com/ianstormtaylor/slate/issues/3350#issuecomment-615501513
  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children?.map((child: Node) => jsx("text", attrs, child ?? ''));
  }

  return children
}

export const insertResource = async (editor, text, nodeId) => {
  const link = jsx('element', { type: 'link', url: text }, [{ text: text, identifier: Date.now() }])
  Transforms.insertNodes(editor, link)
  const { resource } = await addWebLink(text, nodeId) || {}
  console.log(resource)
  if (resource) {
    const title = resource.metaData?.title?.length > 0
      ? resource.metaData?.title
      : resource.title ?? text
    const mention = {
      type: 'mention',
      value: { type: SearchResultType.Resource, resource, shortText: title },
      children: [{ text: title }]
    }
    try {
      ReactEditor.focus(editor)
      const linkPath = ReactEditor.findPath(editor, link)
      // type of children is changed we can't use setNodes so we need to remove link first and insert new node as mention
      if (Editor.hasPath(editor, linkPath)) Transforms.removeNodes(editor, { at: linkPath })
      Transforms.insertNodes(editor, mention, { at: linkPath })
      Transforms.move(editor)
    } catch (error) {
      console.log(error, 'failed to add resource')
    }
  }
  return
}


export const withHtml = (editor, nodeId?: number) => {
  const { isInline, isVoid, insertText } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  // https://github.com/ianstormtaylor/slate/issues/4857#issuecomment-1053893908
  editor.insertData = async (data: DataTransfer) => {
    if (!editor.insertFragmentData(data)) {
      if (!!data.files.length) return uploadImages(editor, data.files, nodeId)

      const html = data.getData('text/html')
      if (html) {
        const parsed = new DOMParser().parseFromString(html, 'text/html')
        const fragmentsRaw = deserialize(parsed.body) as Array<Descendant>;
        if (!Array.isArray(fragmentsRaw)) return console.log(`Unexpected fragmentsRaw is not an array`)
        const fragmentsCleaned = fragmentsRaw.filter((slateNode) => {
          if ('text' in slateNode && (slateNode.text === `` || slateNode.text === `\n` || slateNode.text === `\n\n`)) return false;
          return true;
        })
        try {
          Transforms.insertFragment(editor, fragmentsCleaned)
          Transforms.move(editor)
        } catch (e) {
          console.log(`Error inserting data`, e)
        }
        return
      }
      const text = data.getData('text/plain')
      // if copy pasted a single url text, create resource and mention
      if (validURL(text) && nodeId) return await insertResource(editor, text, nodeId)
      return insertText(text)
    }
  }

  return editor
}
