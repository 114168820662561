import Colors from "@common/constants/Colors"
import { makeStyles } from "@material-ui/core"
import banner from '@assets/images/bg-try-gloow.svg'

export default makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: 32,
    bottom: 32,
    boxShadow: `0 8px 24px 0 ${Colors.shadowColor} !important`,
    borderRadius: 12,
    overflow: 'hidden',
    width: 347,
    transition: 'transform ease 0.3s',
    zIndex: 1000
  },
  open: {
    transform: 'scale(1)'
  },
  closed: {
    transform: 'scale(0)'
  },
  header: {
    width: '100%',
    height: 197,
    backgroundImage: `url(${banner})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: 40,
    margin: '0px 50px',
    textAlign: 'center',
    fontWeight: 600,
    color: 'white'
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: 24,
    height: 24
  },
  closeIcon: {
    fontSize: 20,
    color: 'white'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  contentText: {
    padding: 24,
    textAlign: 'center'
  },
  confirm: {
    marginBottom: 24
  },
}))