import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  addColumnMenuItem: {
  },
  addColumnImg: {
    height: 18,
    width: 18
  },
  menuContainer: {
    display: 'flex',
    gap: 8
  }
}))