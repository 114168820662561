import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  tabEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  resourcesContainer: { overflow: 'auto', display: 'flex', flexDirection: 'column' },
  resourcesContainerSmall: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
  actionContainer: { margin: 16 },
  actionContainerSmall: { marginTop: 16 }
}))