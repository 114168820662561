import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: Colors.white,
    overflow: 'hidden',
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 80px)',
    overflow: 'hidden',
    position: 'relative',
  },
  leftContainer: {
    width: 223,
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRight: `1px solid ${Colors.secondaryStroke}`,
    transition: 'width 0.2s ease-in-out'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  rightContainer: {
    borderLeft: `1px solid ${Colors.secondaryStroke}`
  },
  addButton: {
    marginRight: 20,
    height: 35,
    borderRadius: 12,
    textTransform: 'capitalize',
    padding: '5px 18px'
  },
  leftSidebarButton: {
    position: "absolute",
    top: 55,
    left: 205,
    zIndex: 1000,
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(67, 84, 221, 0.1)",
    width: 32,
    height: 32,
    transition: 'all 0.1s ease-in-out',
    '&:hover': {
      backgroundColor: Colors.lightBlue2
    },
  },
  panelHidden: {
    width: '0px !important',
    minWidth: 'unset !important',
    border: 0
  },
  panelMinimized: {
    width: '80px !important',
    minWidth: 'unset !important',
    border: 0
  },
  resizeAnchor: {
    position: 'absolute',
    height: '100%',
    width: '8px !important',
    cursor: 'col-resize',
    zIndex: 100,
    '&:hover': {
      '&::before': {
        backgroundColor: `${Colors.primary} !important`
      },
    },
    '&::before': {
      width: 5,
      borderRadius: 12,
      height: 100,
      position: 'absolute',
      top: 0,
      bottom: 0,
      margin: 'auto 0',
      content: '""',
      backgroundColor: Colors.greyBorderBottom
    }
  }
}))
