import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography, Button } from '@material-ui/core'
import paywallIcon from '@assets/images/paywall-success.svg'
import logoIcon from '@assets/images/logo.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 15
    },
    dialogTitle: {
      marginTop: 20,
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 32
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      maxWidth: 620
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    },
    paywallImage: {
      display:'block',
      margin: '0 auto'
    },
    logoImage: {
      margin: '0 auto',
      width: 120,
    }
  }),
);


function PaywallPricesStatusDialog({
  onClose = () => { },
  open = false,
  succces = false,
  onConfirm = () => { }
}: {
  open: boolean
  succces?: boolean
  onClose: () => void,
  onConfirm?: () => void
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <CloseIcon className={classes.dialogBackIcon} onClick={onClose} />
      </div>

      <div>
        {succces ? <img className={classes.paywallImage} src={paywallIcon} alt="paywall" />
          :
          <img className={classes.logoImage} src={logoIcon} alt='gloow' />
        }

        <Typography className={classes.dialogTitle} variant="h3" color="primary">
          {succces ? t('subscription.you_are_all_set') : 'Oops'}
        </Typography>
        <p className="mt-4 my-6">
          {succces
            ? t('subscription.enjoy_all_features')
            : t('common.something_wrong_with_the_server')
          }

        </p>

        <Button fullWidth color="primary" variant="contained" onClick={onConfirm}>{t('common.got_it')}</Button>
      </div>
    </Dialog>
  );
}

export default PaywallPricesStatusDialog;
