import { makeStyles } from '@material-ui/core/styles'
export default makeStyles((theme) => ({
  container: {
    padding: '24px 32px'
  },
  accountTitle: {
    fontSize: 36,
    fontWeight: 600,
    marginBottom: 40,
  },
  tabIndicator: {
    display: 'none'
  },
  containerCard: {
    padding: 32,
    borderRadius: 12
  },
  tabs: {
    display: 'flex',
    margin: theme.spacing(4, 0),
  },
  tab: {
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    fontSize: 18,
    opacity: 0.4
  },
  tabActive: {
    fontWeight: 600,
    opacity: 1
  },
}));
