import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  // container: {
  //   overflow: 'hidden',
  //   padding: '0px 32px',
  //   minHeight: '100%',
  // },
  tableContainer: {
    height: '100%',
    overflowY: 'auto',
    '& .MuiTableCell-root': {
      padding: 8,
      minWidth: 200,
    },
    '& .MuiTableCell-body': {
      border: 'none'
    }
  },
  actions: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      height: 35,
      '&:last-child': {
        marginLeft: 16
      }
    }
  },
  dropZoneContainer: {
    width: '50%',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none !important'
    },
    margin: '0 auto'
  },
  addButton: {
    color: Colors.darkGrey,
    boxShadow: 'none !important'
  },
  addButtonIcon: {
    marginRight: 8,
    color: Colors.darkGrey
  },
  addCell: {
    padding: '0px !important',
    paddingTop: '8px !important',
  },
  entityIcon: {
    height: 48,
    width: 48,
    marginRight: 16,
    marginTop: 6
  },
  entityInfo: {
    display: 'flex',
    width: '70%'
  }
}))