import { iNode, iResource } from "@gloow/apiconsumer"
import { VertexType } from '@gloow/navimator'
import { iActiveVertex } from "@store/domain/domain.interfaces"

export enum OnboardingEvents {
  IntroducingDomain = 'INTRODUCING_DOMAIN',
  CreateDomain = 'CREATE_DOMAIN',
  CreateDomainLabel = 'CREATE_DOMAIN_LABEL',
  SelectLabelSuggestion = 'SELECT_LABEL_SUGGESTION',
  TapDomainOverlay = 'TAP_DOMAIN_OVERLAY',
  IntroducingNode = 'INTRODUCING_NODE',
  CreateNode = 'CREATE_NODE',
  CreateNodeLabel = 'CREATE_NODE_LABEL',
  CreateNodeOverlay = 'USED_PLUS_ON_EXPLORER',
  AddedNodeThumbnail = 'ADD_NODE_THUMBNAIL',
  SwitchedToMapView = 'SWITCHED_TO_MAP_VIEW',
  ViewedNodeDetail = 'VIEWED_NODE_DETAIL_PAGE',
  ConnectNode = 'CONNECT_NODE',
  NavimateToNode = 'NAVIMATE_TO_NODE',
  CreateResource = 'CREATE_RESOURCE',
  ConnectResource = 'CONNECT_RESOURCE',
  CreatedRelationName = 'CREATE_RELATION_NAME',
  IntroducedShareToGloowFeature = 'INTRODUCE_SHARE_TO_GLOOW_FEATURE'
}

let is = {}
let is_true = {}

for (const Event in OnboardingEvents) {
  is[OnboardingEvents[Event]] = false
  is_true[OnboardingEvents[Event]] = true
}

export const initialOnboardingStatus = { ...is }
export const initialOnboardingStatusGuest = { ...is_true }

export enum addExistingItemTypes {
  NODES = 0,
  RESOURCES = 1
}

export interface iSubscriptionStatus {
  subscribed: boolean,
  history: any,
  createdNodes?: number;
  createdResources?: number;
  totalUsage?: number;
  maxUsage?: number;
  usagePercentage?: number;
  subscribedUntil?: Date;
}

export const initialSubscriptionStatus: iSubscriptionStatus = {
  subscribed: false,
  history: []
}

// Leaving them here for now so we can merge PR and proceed with testing
// most likely deprecated
export const resourceTypes = {
  FILE: 'fil', // unknown file
  IMAGE_FILE: 'img', // ! don't allow SVG files
  PDF_FILE: 'pdf',
  VIDEO_FILE: 'vid',
  WEB_LINK: 'web', // unknown web link
  ARTICLE: 'article', // eg medium, the verge, MIT tech review, ..
  YOUTUBE: 'yt',
  VIMEO: 'vim',
  WIKIPEDIA: 'wiki'
}

export enum nodeInfoTabTypes {
  NODE_INFO = 'node_info',
  COLOR_PICKER = 'color_picker',
  CONNECTED_NODES = 'connected_nodes',
  CONNECTED_RESOURCES = 'connected_resources',
  NODE_ENRICHMENT = 'node_enrichment'
}

export enum labelId {
  BUSINESS = 566
}

export enum useCase {
  BUSINESS = 'business'
}

export enum labelInfoTabTypes {
  LABEL_INFO = 'label_info',
  COLOR_PICKER = 'color_picker',
}

export enum contentType {
  NODE = 'node',
  RESOURCE = 'resource',
  LABEL = 'label',
  DOMAIN = 'domain',
  GROUP = 'group',
  ADD_BUTTON = 'add_button',
  LABEL_GROUP = 'label_group',
}

export const vertexContentType = {
  [contentType.NODE]: VertexType.Node,
  [contentType.DOMAIN]: VertexType.Domain,
  [contentType.RESOURCE]: VertexType.Resource,
  [contentType.LABEL]: VertexType.Label,
  [contentType.GROUP]: VertexType.Group,
  [contentType.ADD_BUTTON]: VertexType.AddButton,
  [contentType.LABEL_GROUP]: VertexType.NodeCnxLabelGroup,
}

export interface iInteractionDialog {
  open: boolean,
  connectTo?: iActiveVertex,
  tab?: addExistingItemTypes,
  connectedNodes?: iNode[],
  connectedResources?: iResource[],
  specificMenu?: boolean,
  redirectWhenDone?: boolean
}

export const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'bullet' }, { list: 'ordered' }, { 'align': [] }],
    ['code-block', 'clean'],
  ]
}

export const quillFormats = ['bold', 'italic', 'underline', 'code-block', 'align', 'list']

export const imageExt = ['png', 'jpg', 'jpeg']

export const imageMimes = ['image/png', 'image/jpg', 'image/jpeg']

export const defaultEntityContents = [{ type: 'paragraph', children: [{ text: '' }] }]
