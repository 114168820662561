import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography } from '@material-ui/core'
import DomainCard from '@assets/images/domain-card.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    dialogTitle: {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 32,
      width: '80%',
      color: theme.palette.primary.main
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      minWidth: 420,
      maxWidth: 420,
      fontSize: 16
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    }
  }),
);

export interface SimpleDialogProps {
  open: boolean
  onClose: () => void
  onBtnClick?: () => void
}

function OnboardingDialog(props: SimpleDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation()
  const { onClose, open, onBtnClick } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3">
          {t('onboarding.lets_get_started')}
        </Typography>
        <CloseIcon className={classes.dialogBackIcon} onClick={() => handleClose()} />
      </div>

      <p className="mb-4">{t('onboarding.info')}</p>

      <img src={DomainCard} alt="domain" />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={onBtnClick}
      >
        {t('onboarding.create_domain')}
      </Button>
    </Dialog>
  );
}

export default OnboardingDialog;
