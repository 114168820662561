import React, { useEffect, useMemo, useState } from 'react'
import useStyles from './EntityTable.styles'
import { Button, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography } from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import Column from './Column/Column'
import ColumnMenu from './ColumnMenu/ColumnMenu'
import { iLabel, iNode, iRelation } from "@gloow/apiconsumer"
import Cell from './Cell/Cell'
import { ConfirmDialog, NodeAutocomplete } from '@common/components'
import { Avatar } from '@common/components/Avatar/Avatar'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setConnectionView } from '@store/filters/filtersSlice'
import { iEntityProperty, newProperty, RowActionTypes } from '@store/entities/entities.interfaces'
import { useTranslation } from 'react-i18next'
import RowMenu from './RowMenu/RowMenu'
import { RouteURI } from '@common/constants/Routes'
import moment from 'moment'
import { generateDetailURL } from '@helpers/utils'
import { contentType } from '@common/constants/Constants'
import AnalyticsService from '@services/AnalyticsService'

const EntityTable = ({
  loading = false,
  label,
  labels,
  columns,
  rows,
  nodes,
  relations,
  onColumnAdd,
  onColumnChange,
  onColumnDelete,
  onCellChange = (node, newProperty) => { },
  onRowAdd = (e) => { },
  onRowsDelete = (selection) => { },
  onNodeChange = (current, target) => { },
  onOrderChange,
  onNodeClick = (node) => { }
}: {
  rows: iNode[] | Partial<iNode>[],
  label?: iLabel,
  labels: iLabel[],
  nodes: iNode[],
  relations: iRelation[],
  loading: boolean,
  columns: iEntityProperty[],
  onColumnAdd?: (target: iEntityProperty) => void,
  onColumnChange?: (current: iEntityProperty, target: Partial<iEntityProperty>) => void,
  onColumnDelete?: (current: iEntityProperty) => void,
  onCellChange?: (node: iNode, newProperty: iEntityProperty) => void,
  onRowAdd?: (e) => void,
  onRowsDelete?: (selection: Partial<iNode>[]) => void,
  onNodeChange?: (current: Partial<iNode> | iNode, target: Partial<iNode> | iNode) => void
  onOrderChange?: (current: iEntityProperty, target: iEntityProperty) => void,
  onNodeClick?: (node: iNode) => void
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const domainPermissions = useAppSelector(state => state.domain.openDomainPermissions)
  const [rowMenu, setRowMenu] = useState<{
    anchor?: any,
    selected?: iNode | Partial<iNode>
  }>({
    anchor: undefined,
    selected: undefined
  })
  const [columnMenu, setColumnMenu] = useState<{ anchor: any, data: iEntityProperty | null }>({ anchor: null, data: null })
  const { slug } = useParams<{ slug?: string }>()
  const [deleteProp, setDeleteProp] = useState<iEntityProperty | null>(null)
  const [deleteRows, setDeleteRows] = useState<boolean>(false)
  const [editNode, setEditNode] = useState<iNode | Partial<iNode> | null>(null)
  const [selection, setSelection] = useState<Partial<iNode>[]>([])
  const [sort, setSort] = useState<{
    by: 'name' | 'createdAt' | 'updatedAt',
    direction: 'asc' | 'desc'
  }>({ by: 'updatedAt', direction: 'asc' })


  const mandatoryColumn = useMemo(() => ([
    { title: t('common.name'), key: 'name', show: true },
    { title: t('common.created_at', 'Created at'), key: 'createdAt', show: !label },
    { title: t('common.updated_at', 'Updated at'), key: 'updatedAt', show: !label },
    // eslint-disable-next-line
  ]), [label])

  const sortValues = (a, b) => {
    if (!a.hasOwnProperty(sort.by) || !b.hasOwnProperty(sort.by)) return 0
    const varA = (typeof a[sort.by] === 'string')
      ? a[sort.by].toUpperCase() : a[sort.by];
    const varB = (typeof b[sort.by] === 'string')
      ? b[sort.by].toUpperCase() : b[sort.by];
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return ((sort.direction === 'desc') ? (comparison * -1) : comparison)
  }

  // const getInfoTextContent = (node: iNode): string => {
  //   if (node.info === null) return '';
  //   const div = document.createElement('div');
  //   div.innerHTML = node.info.trim();
  //   return div.innerText;
  // }

  const renderBulkSelection = () => {
    if (!domainPermissions?.remove) return <></>
    return (
      <TableCell className={`${classes.bulkSelection} column-selection`}>
        <div className={'bulk-inner-container'}>
          <Checkbox
            color='primary'
            className={classes.bulkSelection}
            onChange={(e) => {
              if (e.target.checked) setSelection(rows)
              else setSelection([])
            }}
            checked={selection.length === rows.length && selection.length > 0 ? true : false}
            disabled={loading || Boolean(editNode) || rows.length === 0}
          />
          <IconButton
            disabled={!selection.length}
            className={classes.selectionMenuButton}
            onClick={(e) => setRowMenu({ anchor: e.currentTarget, selected: undefined })}
            size={'small'}
          >
            <MoreVert />
          </IconButton>
        </div>
      </TableCell>
    )
  }

  const renderCellSelection = (row: iNode | Partial<iNode>) => {
    if (!domainPermissions?.remove) return <></>
    const selected = selection.find(d => d.uuid === row.uuid)
    return (
      <TableCell className={`${classes.selection} column-selection`}>
        <Checkbox
          color='primary'
          className={classes.selection}
          checked={selected ? true : false}
          onChange={(e) => {
            if (selected) {
              const updatedSelection = selection.filter(d => d.uuid !== row.uuid)
              setSelection(updatedSelection)
            }
            else {
              const updatedSelection = [...selection]
              updatedSelection.push(row)
              setSelection(updatedSelection)
            }
          }}
          disabled={loading || Boolean(editNode)}
        />
      </TableCell>
    )
  }

  const renderNode = (row) => {
    return <TableCell
      className={`${!loading ? classes.nodeEditable : ''} ${classes.nodeCell} ${classes.stickyLeft}`}
      onClick={() => {
        if (!loading && !editNode && domainPermissions?.modify) setEditNode(row)
      }}
    >
      {!!row.id && editNode?.id !== row.id &&
        <div className={classes.name}>
          {(row.thumbnailUrl || row.name) && (
            <div onClick={(e) => onNodeClick(row)}>
              <Avatar
                src={row.thumbnailUrl}
                name={row.name}
                color={row.color}
                size="40"
                alt={row.name}
                className={classes.avatar}
              />
            </div>
          )}
          <div className={classes.nameContainer}>
            <Typography variant='body2' onClick={(e) => onNodeClick(row)} className={classes.nameValue}>{row.name}</Typography>
            {/* info is not being used */}
            {/* <Typography variant='body2' onClick={(e) => onNodeClick(row)} className={classes.nameDesc}>{getInfoTextContent(row)}</Typography> */}
          </div>
        </div>
      }
      {editNode && typeof editNode.id !== 'undefined' && row.id && editNode.id === row.id &&
        <TextField
          variant='outlined'
          color='primary'
          disabled={loading}
          value={editNode?.name ?? ''}
          onKeyPress={async (e) => {
            if (e.key === 'Enter') {
              await onNodeChange(row, { ...row, name: editNode?.name, labels: row.labels?.map(d => d.id) ?? [] })
              setEditNode(null)
            }
          }}
          onChange={(e) => setEditNode({ ...row, name: e.target.value })}
          onBlur={async (e) => {
            if (!e.target.value) return setEditNode(null)
            await onNodeChange(row, { ...row, name: e.target.value, labels: row.labels?.map(d => d.id) ?? [] })
            setEditNode(null)
          }}
          className={classes.cellTextField}
          autoFocus
        />
      }
      {!row.id &&
        <NodeAutocomplete
          autoHighlight
          disabled={loading}
          openOnFocus={true}
          allowAdd={true}
          placeholder={t('common.search_or_create', 'Search or create')}
          onChange={async (e, value) => {
            if (!value) return
            let params = {}
            // new node
            if (typeof value?.id === 'undefined') params = { name: value.name, labels: label?.id ? [label?.id] : [] }
            // update node
            else params = { id: value?.id, labels: label?.id ? [...value.labels?.map(d => d.id), label?.id] : value.labels.map(d => d.id) }
            await onNodeChange(row, params)
            setEditNode(null)
          }}
          onBlur={() => setEditNode(null)}
          value={editNode as iNode}
          options={nodes.filter(d => !rows.map(r => r.id).includes(d.id))}
        />
      }
    </TableCell>
  }

  const renderRow = (row: iNode | Partial<iNode>) => <TableRow key={row.uuid ?? ''}>
    {renderCellSelection(row)}
    {/* mandatory */}
    {renderNode(row)}
    {!label && <TableCell>{moment(row.createdAt).format('DD MMM YYYY')}</TableCell>}
    {!label && <TableCell>{moment(row.updatedAt).format('DD MMM YYYY')}</TableCell>}
    {/* mandatory */}
    {!!label && row?.properties && row?.properties
      .filter(d => d.order >= 0 && columns.find(c => d.key === c.key))
      .map((d, index) => <Cell
        node={row as iNode}
        nodes={nodes}
        relations={relations}
        disabled={loading || Boolean(editNode)}
        key={index.toString()}
        property={{ ...d, editMode: false, editable: true }}
        onSave={(property) => onCellChange(row as iNode, property)}
        labels={labels}
      />)}
    <TableCell>
      <IconButton size='small' onClick={(e) => {
        setRowMenu({ anchor: e.currentTarget, selected: row })
        setSelection([row])
      }}>
        <MoreVert color='primary' />
      </IconButton>
    </TableCell>
  </TableRow>

  const renderColumn = (d, index) => <Column
    key={index.toString()}
    disabled={loading}
    data={d}
    onClick={(e) => setColumnMenu({ anchor: e.target, data: d })}
    onDelete={(e) => setDeleteProp(d)}
    onChange={(prop) => domainPermissions?.modify ? onColumnChange && onColumnChange(d, prop) : undefined}
    onOrderChange={onOrderChange}
  />

  const renderOptions = () => {
    if (!onColumnAdd || !domainPermissions?.modify) return <TableCell>
      {`${t('entities.options', 'Options')}`}
    </TableCell>
    return <TableCell className={classes.addColumn}>
      <Tooltip title={`${t('entities.add_column', 'Add column')}`}>
        <IconButton
          size='small'
          onClick={(e) => setColumnMenu({ anchor: e.target, data: null })} >
          <Add className={classes.addColumnIcon} />
        </IconButton>
      </Tooltip>
    </TableCell>
  }

  const toggleSort = (key) => {
    setSort({
      by: key,
      direction: key !== sort.by ? sort.direction : (sort.direction === 'asc' ? 'desc' : 'asc')
    })
  }

  const onConfirmDelete = async () => {
    try {
      setDeleteRows(false)
      if (!!selection.length) await onRowsDelete(selection)
      setSelection([])
    } catch (e) {
      AnalyticsService.logError('entity-delete-node', { e })
    }
  }

  const onRowMenuClick = (action: RowActionTypes) => {
    if (action === RowActionTypes.DELETE) {
      setDeleteRows(true)
      if (rowMenu.selected) setSelection([rowMenu.selected])
    }
    else if (action === RowActionTypes.VIEW_ON_MINDMAP && rowMenu.selected?.id) return history.push(generateDetailURL(`/${slug}/${RouteURI.MIND_MAPS}`, {
      id: rowMenu.selected?.id, type: contentType.NODE
    }))
    else if (action === RowActionTypes.VIEW_DOCS_MEDIA) {
      dispatch(setConnectionView(rowMenu.selected))
      return history.push(`/${slug}/${RouteURI.DOCUMENTS}`)
    }
    setRowMenu({ anchor: undefined, selected: undefined })
  }

  useEffect(() => {
    setSelection([])
    return () => { }
  }, [label?.id])

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {renderBulkSelection()}
              {mandatoryColumn.filter(d => d.show).map((d, idx) => <TableCell key={idx.toString()}>
                <TableSortLabel active={sort.by === d.key} direction={sort.direction} onClick={() => toggleSort(d.key)}>
                  {d.title}
                </TableSortLabel>
              </TableCell>)}
              {!!label && columns.map(renderColumn)}
              {renderOptions()}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice().sort(sortValues).map((d) => renderRow(d))}
            <TableRow>
              <TableCell className={`${classes.stickyLeft} ${classes.addRowCell}`} colSpan={2}>
                {domainPermissions?.modify && (
                  <Button startIcon={<Add className={classes.addButtonIcon} />} disabled={loading} onClick={onRowAdd} className={classes.addButton}>
                    <Typography variant='body2'>{t('entities.new_row', 'New Row')}</Typography>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ColumnMenu
        labels={labels}
        anchorEl={columnMenu.anchor}
        onClose={() => setColumnMenu({ anchor: null, data: null })}
        onClick={(target) => {
          if (!onColumnAdd) return
          onColumnAdd({ ...newProperty, ...target, order: new Date().getTime() })
          setColumnMenu({ anchor: null, data: null })
        }}
      />
      <ConfirmDialog
        open={Boolean(deleteProp)}
        onConfirm={() => {
          onColumnDelete && onColumnDelete(deleteProp!)
          setDeleteProp(null)
        }}
        onClose={() => setDeleteProp(null)}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
        text={t('entities.are_you_sure_want_to_delete_this_column', 'Are you sure want to delete this column?')}
      />

      <ConfirmDialog
        open={deleteRows}
        onConfirm={onConfirmDelete}
        onClose={() => setDeleteRows(false)}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
        text={t('common.are_you_sure_want_to_delete_this_node')}
      />

      <RowMenu
        onClick={onRowMenuClick}
        bulk={selection.length > 1}
        domainPermissions={domainPermissions}
        onClose={() => setRowMenu({ anchor: undefined, selected: undefined })}
        anchorEl={rowMenu.anchor}
      />
    </>
  )
}

export default EntityTable
