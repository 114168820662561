import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  nodeColorWrap: {
    width: 48,
    height: 48,
    borderRadius: 48,
    marginRight: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    cursor: 'pointer'
  },
  node: {
    width: 24,
    height: 24,
    backgroundColor: 'white',
    borderRadius: 24,
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
    marginRight: -12,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  nodeDim: {
    width: 14,
    height: 14
  },
}))