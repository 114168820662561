import { makeStyles, Theme } from "@material-ui/core"
export default makeStyles((theme: Theme) => ({
  dialog: {
    display: 'flex',
  },
  dialogForm: {
    marginLeft: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1
  },
  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    marginBottom: 10,
    fontWeight: 600,
    fontSize: 24,
    color: theme.palette.primary.main,
    marginRight: 30
  },
  dialogPaper: {
    borderRadius: 12,
    padding: 40,
    minWidth: 720,
    fontSize: 16
  },
  dialogBackIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 4
  },
  dialogContent: {
    position: 'relative',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogActionsBack: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  dialogActionsButton: {
    width: '50%',
    alignSelf: 'flex-end'
  },
  name: {
    marginBottom: 20,

    '& .MuiFormLabel-root': {
      fontSize: 30,
      fontWeight: 500,

      '&.Mui-focused, &.MuiInputLabel-shrink': {
        fontSize: 20
      }
    },
    '& ,.MuiInputBase-input': {
      fontSize: 30
    }
  },
  questions: {
    opacity: 0.8,
    fontSize: 14,
    marginBottom: 10,
    marginTop: 4
  },
  labels: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    overflow: 'auto',
    maxHeight: 230
  }
}))