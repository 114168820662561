import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ResourceThumbnail } from '@common/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailList: {
      display: 'flex'
    },
    thumbnailItem: {
      minWidth: 32,
      height: 32,
      padding: 10,
      borderRadius: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.info.main,
      color: 'white',
      fontWeight: 600
    }
  }),
);

interface IResourceThumbnailList {
  resources?: any
}

const ResourceThumbnailList = ({
  resources = []
}: IResourceThumbnailList) => {
  const classes = useStyles();

  if (!resources.length) {
    return (
      <div className={classes.thumbnailList}>
        <div className={classes.thumbnailItem}>0</div>
      </div>
    )
  }

  return (
    <div className={classes.thumbnailList}>
      {resources.slice(0, 4).map((resource: any) => {
        return <ResourceThumbnail resource={resource} key={resource.id} />
      })}

      {resources.length > 4 && (
        <div className={classes.thumbnailList}>
          <div className={classes.thumbnailItem}>+{resources.length - 4}</div>
        </div>
      )}
    </div>
  );
}

export default ResourceThumbnailList;