import React from 'react'
import { escapeRegExp } from 'lodash'

interface iHighLightedText {
  text: string;
  highlight: string;
}

const HighLightedText = ({text = '', highlight = ''}: iHighLightedText) => {
  if (!highlight.trim()) {
    return <span>{text}</span>
  }
  
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi')
  const parts = text.split(regex)
  return (
    <span>
       {parts.filter(part => part).map((part, i) => (
           regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
       ))}
   </span>
  )
}

export default HighLightedText;