import Colors from '@common/constants/Colors';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: 12,
      width: 420,
      padding: 20
    },
    actions: {
      justifyContent: 'center',
      paddingTop: 10,
      paddingBottom: 10
    },
    cancelButton: {
      marginRight: 25,
      boxShadow: "0 6px 12px 0 rgba(67, 84, 221, 0.08)",
      color: theme.palette.primary.main
    },
    confirm: {
      color: Colors.white,
      backgroundColor: Colors.darkRed,
      '&:hover': {
        backgroundColor: `${Colors.darkRed} !important`,
      }
    },
  }))