import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    position: 'relative'
  },
  containerEditable: {
    borderRadius: 8,
    cursor: 'text',
    '&:hover': {
      backgroundColor: Colors.newBgLightBlue,
    },
  },
  cell: {
    // width: 250,
    // wordBreak: 'break-word'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  editButtonIcon: {
    height: 18,
    width: 18,
    color: Colors.darkGrey,
  },
  editButton: {
    margin: '0px 0px 3px 8px',
  },
  cellTextField: {
    margin: '0px 8px',
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.primary
        }
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: 12,
      fontSize: 14
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    },
    width: 'auto'
    // minWidth: 220,
  },
  menuPaper: {
    padding: 0,
    width: 180,
    marginLeft: -8,
    boxShadow: 'none !important'
  }
}))