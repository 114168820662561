import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography } from '@material-ui/core'
import { Indicator } from '@common/components'
import { createDomain, createManyNodes, saveOnboardingData, setDomainProperty, updateDomain as _updateDomain } from '@services/DomainDataService'
import { Domain, iNode, iOntology, iLabelType, PropertyValueTypes } from '@gloow/apiconsumer'
import { useAppDispatch } from '@store/hooks'
import { useHistory } from 'react-router-dom'
import { assignRandomColor } from '@common/constants/Colors'
import { useTranslation } from 'react-i18next'
import useStyles from './OnboardingV2.styles'
import { applyOntologiesToDomain, applyOntologiesToNodes, getDomainRoot, getOntologiesMembers, getOntologyChildren, getOntologyDefaultMembers } from '@services/LabelService'
import { updateDomain } from '@store/domain/domainSlice'
import CreateDomain from './CreateDomain'
import ChooseMember from './ChooseMember'
import CreateNode from './CreateNode'
import { completeOnboardingEvent } from '@store/user/userSlice'
import { OnboardingEvents } from '@common/constants/Constants'
import { domainProp } from '@store/domain/domain.interfaces'

export interface SimpleDialogProps {
  open: boolean
  onClose: () => void
}

function OnboardingV2(props: SimpleDialogProps) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { onClose, open } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedNodes, setSelectedNodes] = useState<Partial<iNode>[]>([])
  const initialValues = {
    name: "",
    description: "",
    id: undefined,
    uuid: "",
    labels: []
  }
  const [domain, setDomain] = useState<any>(initialValues)
  const [active, setActive] = useState(1)
  const [questionActive, setQuestionActive] = useState(0)
  const [error, setError] = useState('')

  const [searchResult, setSearchResult] = useState<iOntology[]>([])
  const [selected, setSelected] = useState<iOntology[]>([])
  const [currentMember, setCurrentMember] = useState<iOntology | null>(null)
  const selectedMembers = selected.filter(d => d.type === iLabelType.NODE)

  const loadData = async () => {
    try {
      if (!domain?.uuid) return
      setLoading(true)
      const ds = new Domain()
      await ds.openDomain(domain.uuid)
      const domainRoot = await getDomainRoot()
      setSearchResult(domainRoot)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const removeOntology = (ontology: iOntology) => {
    const getChildrenIds = _target => {
      let toDelete: number[] = []
      for (let i = 0; i < searchResult.length; i++) {
        if (
          searchResult[i].id === _target ||
          searchResult[i].parent === _target ||
          searchResult[i].memberOfDomain === _target
        ) {
          toDelete.push(searchResult[i].id)
          if (searchResult[i].id !== _target) {
            toDelete = toDelete.concat(
              getChildrenIds(searchResult[i].id).slice(1)
            )
          }
        }
      }
      return toDelete
    }
    const targets = getChildrenIds(ontology.id)
    setSearchResult(
      searchResult.filter(
        d => !targets.filter(l => l !== ontology.id).includes(d.id)
      )
    )
    setSelected(selected.filter(d => !targets.includes(d.id)))
  }

  const addOntology = async (ontology: iOntology) => {
    try {
      // console.log(ontology.id, ontology.parent, ontology.memberOfDomain, ontology.type)
      setLoading(true)
      let newResult = searchResult.map(d => {
        if (d.id === ontology.id) return ontology
        return d
      })
      // get children and member if label type is domain
      if (ontology.type === iLabelType.DOMAIN) {
        let childrenMembers: iOntology[] = []
        const children = await getOntologyChildren([ontology.id])
        const members = await getOntologiesMembers([ontology.id])
        if (children?.length)
          childrenMembers = [...childrenMembers, ...children]
        if (members?.length) childrenMembers = [...childrenMembers, ...members]
        const idx = searchResult.findIndex(d => d.id === ontology.id)
        if (idx >= 0 && childrenMembers.length) {
          const childrenMembersIds = childrenMembers.map(d => d.id)
          newResult = newResult.filter(d => !childrenMembersIds.includes(d.id))
          newResult.splice(idx + 1, 0, ...childrenMembers)
        }
        console.log(
          ontology.name,
          'children:' + children.length,
          'members:' + members.length
        )
      }
      setSearchResult(newResult)
      setSelected([...selected, ontology])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onOntologyPressed = async (ontology: iOntology) => {
    const exist = selected?.find(d => d.id === ontology.id)
    if (exist) return removeOntology(ontology)
    // @ts-ignore
    return addOntology({ ...ontology, selectedAt: new Date() })
  }


  useEffect(() => {
    if (active === 2) loadData()
    return () => { }
    // eslint-disable-next-line
  }, [active, domain?.uuid])

  useEffect(() => {
    if (open) {
      setActive(1)
      setDomain(initialValues)
      setSelected([])
      setSearchResult([])
    }
    return () => { }
    // eslint-disable-next-line
  }, [open])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (active === 1) {
      if (!domain.name) return setError(t('common.name_is_required'))
      if (!domain.id) await onCreateDomain()
      else {
        setLoading(true)
        const updatedDomain = await _updateDomain({
          ...domain,
          labels: domain?.labels?.map(d => d.id)
        })
        setDomain(updatedDomain)
      }
      setActive(active + 1)
      setLoading(false)
    } else if (active === 2) {
      if (!selected.length) return
      setLoading(true)
      const domainOntologies = selected.filter(d => d.type === iLabelType.DOMAIN)
      await applyOntologiesToDomain(domain.uuid, domainOntologies)
      // @ts-ignore
      await saveOnboardingData(domain, selected.map(d => ({ ontologyId: d.id, selectedAt: d.selectedAt ?? new Date() })))
      let selectedMembers = selected.filter(d => d.type === iLabelType.NODE)
      if (!selectedMembers?.length) {
        selectedMembers = await getOntologyDefaultMembers(selected[0].id)
        setSelected([...selected, ...selectedMembers])
      }
      setCurrentMember(selectedMembers[0])
      setLoading(false)
      return setActive(active + 1)
    } else {
      setLoading(true)
      if (selectedNodes.length > 0) {
        const result = await createManyNodes(domain, selectedNodes)
        if (result) {
          const ds = new Domain()
          await ds.openDomain(domain.uuid)
          await applyOntologiesToNodes(result.map(d => d.id), [currentMember!])
        }
        dispatch(completeOnboardingEvent(OnboardingEvents.CreateNode))
      }
      setLoading(false)
      onNext()
    }
  }

  const onCreateDomain = async () => {
    try {
      setLoading(true)
      let newDomain = await createDomain(domain)
      newDomain = await setDomainProperty(newDomain, [
        { key: domainProp.createdOnApp, value: 'gravity', type: PropertyValueTypes.STRING },
        { key: domainProp.createdUsingOnboardingVersion, value: '2', type: PropertyValueTypes.STRING },
      ])
      dispatch(updateDomain(newDomain))
      setDomain(newDomain)
      setActive(active + 1)
      setLoading(false)
      return newDomain
    } catch (error) {
      console.log(error)
      setLoading(false)
      return false
    }
  }

  const onSelected = (node) => {
    setSelectedNodes([{
      name: node.name,
      info: "",
      labels: [],
      color: assignRandomColor()
    }, ...selectedNodes])
  }

  const onDeleted = (node) => {
    const updatedSelected = selectedNodes.filter(d => d.name !== node.name)
    setSelectedNodes(updatedSelected)
  }

  const goBack = () => {
    if (questionActive <= selected.length - 1 && questionActive !== 0)
      setQuestionActive(questionActive - 1)
    else setActive(active - 1)
    if (active - 1 === 1) {
      setSelected([])
      setSearchResult([])
    }
  }

  const onNext = () => {
    if (loading) return
    const selectedMembers = selected.filter(d => d.type === iLabelType.NODE)
    const idx = selectedMembers.findIndex(d => d.id === currentMember?.id)
    setSelectedNodes([])
    if (selectedMembers[idx + 1]) return setCurrentMember(selectedMembers[idx + 1])
    return history.push(`/${domain.slug ?? domain.uuid}/mind-maps`)
  }

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialog}>
        <Indicator active={active} steps={3} />
        <form onSubmit={onSubmit} noValidate className={classes.dialogForm}>
          <div className={classes.dialogContent}>
            <CloseIcon className={classes.dialogBackIcon} onClick={() => onClose()} />
            {active === 1 && <CreateDomain
              title={t('onboarding.lets_get_started')}
              description={t('onboarding.info')}
              domain={domain}
              setDomain={setDomain}
              error={error}
            />}
            {active === 2 && <ChooseMember
              title={t('onboarding.what_kind_of_data')}
              description={t('onboarding.choose_labels')}
              loading={loading}
              ontologies={searchResult}
              selected={selected}
              onClick={onOntologyPressed}
            />}
            {active === 3 && <CreateNode
              title={t('onboarding.question_index', { index: selectedMembers.filter(d => d.type === iLabelType.NODE).findIndex(d => d.id === currentMember?.id) + 1, length: selectedMembers.length })}
              // @ts-ignore
              description={t('onboarding.type_in_names_of_plural_name', { name: currentMember?.pluralName ? currentMember?.pluralName : currentMember?.name })}
              selectedNodes={selectedNodes}
              onSelected={onSelected}
              onDeleted={onDeleted}
              member={currentMember}
            />}
          </div>
          <div className={classes.dialogActions}>
            <Typography
              className={classes.dialogActionsBack}
              onClick={() => {
                if (active === 2) return goBack()
                if (active === 3) return onNext()
              }}
            >
              {active === 2 && t('common.go_back')}
            </Typography>
            <Button
              type="submit"
              className={classes.dialogActionsButton}
              variant="contained"
              color="primary"
              disabled={(active > 1 && !selected?.length) || loading}
            >
              {t('common.next')}
            </Button>
          </div>
        </form>
      </div >
    </Dialog >
  );
}

export default OnboardingV2;
