import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import feature from '@assets/images/feature.png'
// import { ThemeToggle } from '@common/components'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { InputAdornment, IconButton, Link, Grow, Snackbar } from '@material-ui/core'
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons'
import googleIcon from '@assets/images/google.svg'
// import facebookIcon from '@assets/images/facebook.svg'
import appleIcon from '@assets/images/apple.svg'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import useStyles from './index.styles'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { login, socialLogin, signUp, Ator, resetPassword } from '@services/AuthService'
import { Alert, Color } from '@material-ui/lab'
import { Collaboration, analyticsEventCategory } from "@gloow/apiconsumer"
import { setLoading } from '@store/helpers/helpersSlice'
import AnalyticsService from "@services/AnalyticsService"
import { useCase } from '@common/constants/Constants'
import { IOService } from '@services/IOService'
import LanguageDropdown from '@common/components/LanguageDropdown/LanguageDropdown'
import { useTranslation } from 'react-i18next'

export interface iAuthLocationState {
  referer?: string
}

const Auth = () => {
  const { t } = useTranslation()
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { state } = useLocation<iAuthLocationState>();

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth.email_must_be_valid'))
      .required(t('auth.email_is_required')),
    password: Yup.string().required(t('auth.password_is_required'))
  })

  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth.email_must_be_valid'))
      .required(t('auth.email_is_required')),
    password: Yup.string().required(t('auth.password_is_required')).min(6)
  })

  const ForgotPassSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth.email_must_be_valid'))
      .required(t('auth.email_is_required'))
  })

  // @ts-ignore
  const { invitationCode } = useParams()

  const initialValues: {
    email: string,
    password: string
  } = { email: '', password: '' };

  const initialForgotPassValues: {
    email: string
  } = { email: '' };

  const session = useAppSelector(state => state.session)
  const [showPassword, setShowPassword] = useState(false)
  const [signIn, setSignIn] = useState<boolean>(false)
  const [forgotPassword, setForgotPassword] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [snackBar, setSnackBar] = useState<{ open: boolean, message: string, severity: Color | undefined }>({
    // @ts-ignore
    open: state && typeof state?.error !== 'undefined' ? true : false,
    // @ts-ignore
    message: state && typeof state?.error !== 'undefined' ? state.error : '',
    severity: 'error'
  })

  useEffect(() => {
    AnalyticsService.createUserEvent(analyticsEventCategory.USER_EVENT, 'view-auth-screen');
  }, [])

  useEffect(() => {
    (async () => {
      if (session.idToken) {
        // if user is logged in from invitation code
        if (invitationCode) {
          try {
            dispatch(setLoading(true))
            // re-set the ator token is needed when visiting domain-invitation/:code with logged user (this route is not using protected route)
            Ator.authenticateUsingToken(session.idToken)
            Ator.setUserAccessToken(session.accessToken)
            Ator.setUserRefreshToken(session.refreshToken)

            // collaboration flow
            const CS = new Collaboration()
            const result = await CS.join(invitationCode)
            if (result?.length > 0 && result[0].domainUuid) {
              // @TODO:change to slug
              return history.push(`/${result[0].domainUuid}`)
            }
            else if (result.err) {
              AnalyticsService.logError('collaboration-error', { result });

              return history.push('/domains', { error: result?.message ?? t('collaboration.collaboration_not_found_or_you_already_joined') })
            }
            dispatch(setLoading(false))
          } catch (e) {
            dispatch(setLoading(false))
            AnalyticsService.logError('collaboration-error', { e });

            return history.push('/domains', { error: t('collaboration.try_invitation_link_later') })

          }
        }
        // if user is logged in form business use case
        const search = location.search
        const params = new URLSearchParams(search)
        const value = params.get('use-case')
        if (value === useCase.BUSINESS) {
          return history.push({
            pathname: '/domains',
            search: `use-case=${useCase.BUSINESS}`
          })
        }

        if (params.get('callback') && params.get('callback') === 'extension-login') {
          if (params.get('pub')) {
            localStorage.setItem(process.env.REACT_APP_EXT_PUB_KEY ?? 'glwext', params.get('pub')!)

            /**
             * this method would produce unexpected result
             * find another approach,
             * idea:
             * - create a ping, with interval every 1 seconds to content script,
             * - if content script replied, then sync the auth token, clear the previous interval
             * - profit!
             */
            await new Promise<void>((resolve) => {
              setTimeout(() => {
                const { loading, email, purchases, user, ...rest } = session;
                console.log(rest)
                IOService.instance.syncAuthenticationToken(JSON.stringify({
                  id_token: session.idToken,
                  refresh_token: session.refreshToken,
                  access_token: session.accessToken
                }))
                resolve()
              }, 1000)
            })
          } else {
            console.warn('Extension didn\'t provide pub key!')
          }
        }

        if (state && typeof state?.referer !== 'undefined') {
          // prevent not authenticated user or guess user to redirect to /domains
          if (!session.user || (session.user && session.user.guest)) {
            return
          }

          return history.push(state.referer)
        }

        return history.push('/domains')
      }
    })()
    return () => { }
    // eslint-disable-next-line
  }, [session])

  const handleLogin = async (values) => {
    try {
      setDisabled(true)
      const response = await login(values.email, values.password)
      setDisabled(false)
      if (!response) setSnackBar({ open: true, message: t('auth.wrong_email_or_password'), severity: 'error' })
    } catch (e) {
      setDisabled(false)

      AnalyticsService.logError('authentication-error', { e });

      setSnackBar({ open: true, message: t('common.something_wrong_with_the_server'), severity: 'error' })
    }
  }

  const handleSocialLogin = async (connection) => {
    // this will redirect to /auth/callback
    try {
      setDisabled(true)
      await socialLogin(connection, invitationCode, state?.referer)
      setDisabled(false)
    } catch (e) {
      setDisabled(false)
      AnalyticsService.logError('authentication-error', { e });
      console.log('error calling social login')
    }
  }

  const handleSignUp = async (values) => {
    try {
      setDisabled(true)
      // assuming form validation is done on the form level
      // nothing happens? -> this is where you get the access token
      await signUp(values.email, values.password)

      await handleLogin(values)
      setDisabled(false)
    } catch (err) {
      // @ts-ignore
      if (err.code === 'user_exists') {
        // try logging in instead
        setSnackBar({ open: true, message: t('auth.user_already_exist_trying_to_login'), severity: 'warning' })
        setSignIn(true);
        return handleLogin(values);
      }
      AnalyticsService.logError('authentication-error', { err });

      setSnackBar({ open: true, message: t('common.something_wrong_with_the_server'), severity: 'error' })
      setDisabled(false)
    }
  }

  const onSubmit = (values) => {
    if (signIn) return handleLogin(values)
    else return handleSignUp(values)
  }

  const handleForgotPassSubmit = async (values) => {
    try {
      setDisabled(true)
      await resetPassword(values.email)
      setDisabled(false)
      setSnackBar({ open: true, message: t('auth.we_have_just_sent_you_an_email_to_reset_your_password'), severity: 'success' })
    } catch (err) {
      setSnackBar({ open: true, message: t('common.something_wrong_with_the_server'), severity: 'error' })
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={6} md={7} className={classes.image}>
        <Grow in={true} timeout={{ enter: 1500 }}><img className={classes.feature} src={feature} alt='Gloow Feature' /></Grow>
        <Grow in={true} timeout={{ enter: 1500 }}>
          <Typography variant={'h1'} className={classes.tagline}>{t('common.see_the_bigger_picture_with')} <span className={classes.highlight}>Gloow</span></Typography>
        </Grow>
      </Grid>
      <LanguageDropdown className={classes.languageDropdown} />
      <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} className={classes.paper} >
        {/* <div className={classes.themeToggle}><ThemeToggle /></div> */}
        {!forgotPassword ? (
          <>
            <Typography variant={'h2'} className={classes.welcome}>{t('common.welcome_to_gloow')}</Typography>
            <Typography variant={'body1'} className={classes.subtitle}>
              {!invitationCode ? t('auth.login_or_sign_up_with_social_account') : t('collaboration.need_to_create_account_first')}
            </Typography>
            <div className={classes.social}>
              <Button variant='contained' disabled={disabled} color='secondary' fullWidth onClick={() => handleSocialLogin('google-oauth2')} >
                <img alt={t('auth.sign_in_with_google')} src={googleIcon} />{t('auth.sign_in_with_google')}
              </Button>
              {/*<Button variant='contained' disabled={disabled} color='secondary' fullWidth onClick={() => handleSocialLogin('facebook')}>*/}
              {/*  <img alt={t('auth.sign_in_with_facebook')} src={facebookIcon} />{t('auth.sign_in_with_facebook')}*/}
              {/*</Button>*/}
              <Button variant='contained' disabled={disabled} color='secondary' fullWidth onClick={() => handleSocialLogin('apple')}>
                <img alt={t('auth.sign_in_with_apple')} src={appleIcon} />{t('auth.sign_in_with_apple')}
              </Button>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.or}>{t('common.or')}</Typography>
            </div>
            <Formik
              key={JSON.stringify(initialValues)}
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={signIn ? SignInSchema : SignUpSchema}>
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    // @ts-ignore
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    margin="normal"
                    fullWidth
                    id="email"
                    label={t('auth.email')}
                    name="email"
                    autoComplete="email"
                    onChange={(e) => handleChange(e)}
                    autoFocus
                    disabled={disabled}
                    value={values['email']}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label={t('auth.password')}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={values['password']}
                    // @ts-ignore
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    onChange={(e) => handleChange(e)}
                    disabled={disabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOutlined style={{ opacity: 0.4 }} /> : <VisibilityOffOutlined style={{ opacity: 0.4 }} />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Grid container className={signIn ? classes.visible : classes.notVisible} spacing={1}>
                    <Grid item xs={12}>
                      <Typography align={'right'} className="d-block">
                        <Link color='textPrimary' onClick={() => setForgotPassword(true)}>{t('auth.forgot_password')}</Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type={signIn ? "submit" : "button"}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleSubmit()}
                        className={classes.submit}
                        disabled={disabled}
                      >
                        {t('auth.sign_in')}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'body1'} align={'center'} className="mt-2">{t('auth.dont_have_an_account')} <Link onClick={() => setSignIn(false)}>{t('auth.sign_up')}</Link></Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className={classes.signup + ' ' + (signIn ? classes.notVisible : classes.visible)}>
                    {/* {!invitationCode && (
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => console.log('todo: guest users')}
                        disabled={disabled}
                      >
                        Enter as a guest
                      </Button>
                    </Grid>
                  )} */}
                    <Grid item xs={12}>
                      <Button
                        type={!signIn ? "submit" : "button"}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleSubmit()}
                        className={classes.submit}
                        disabled={disabled}
                      >
                        {t('auth.sign_up')}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'body1'} align={'center'}>{t('auth.already_have_an_account')} <Link onClick={() => setSignIn(true)}>{t('auth.sign_in')}</Link></Typography>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <Typography variant={'h2'} className={classes.welcome}>{t('auth.forgot_password')}</Typography>
            <Typography variant={'body1'} className={classes.subtitle}>
              {t('auth.enter_your_email_to_reset_password')}
            </Typography>
            <Formik
              key={JSON.stringify(initialForgotPassValues)}
              enableReinitialize={true}
              initialValues={initialForgotPassValues}
              onSubmit={handleForgotPassSubmit}
              validationSchema={ForgotPassSchema}>
              {({ values, handleChange, handleSubmit, errors }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    // @ts-ignore
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    margin="normal"
                    fullWidth
                    id="email"
                    label={t('auth.email')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    disabled={disabled}
                    className="mt-2"
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={disabled}
                  >
                    {t('common.send')}
                  </Button>

                  <div className="mt-2">
                    <Typography align={'center'} className="d-block">
                      <Link color='textPrimary' onClick={() => setForgotPassword(false)}>{t('auth.return_to_sign_in')}</Link>
                    </Typography>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}

        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackBar.open && snackBar.message.length > 0} TransitionComponent={Grow} onClose={() => setSnackBar({ ...snackBar, open: false })} autoHideDuration={5000} >
          <Alert variant={'filled'} onClose={() => setSnackBar({ ...snackBar, open: false })} severity={snackBar.severity}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default Auth
