import { AppLayout } from '@common/components'
import React, { useEffect, useState } from 'react'
import { Assistant, PreviousQuestions } from '@common/components'
// import { useAppSelector } from '@store/hooks'
import { Athena, AnswerPublic, Question } from "@gloow/apiconsumer"
const AssistantPage = ({
  setShowAddDomain = (props) => { },
  setDeleteDomainDialog = (props) => { },
  setUserPermissionsDialog = (props) => { }
}: {
  setShowAddDomain: (props) => void
  setDeleteDomainDialog: (props) => void
  setUserPermissionsDialog: (props) => void
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [answer, setAnswer] = useState<AnswerPublic | undefined>()
  const [question, setQuestion] = useState<Question | undefined>()
  const [prevQuestions, setPrevQuestions] = useState<Question[]>([])
  const [error, setError] = useState<{
    message: string,
    status: number | null
  } | undefined>(undefined)
  const [athena, setAthena] = useState<{ athena: Athena | null, session_id: number | null }>({
    athena: null,
    session_id: null
  })
  const init = async () => {
    try {
      setLoading(true)
      setError(undefined)
      const athena = new Athena()
      const { session_id } = await athena.startSession()
      setAthena({ athena, session_id })
      const prevQuestion = await athena.getPreviousQuestions()
      setPrevQuestions(prevQuestion ?? [])
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const { response } = error
      setError({ message: response?.data?.message ?? 'Domain not found', status: response?.status ?? 404 })
    }
  }

  const askQuestion = async (values) => {
    try {
      if (!athena?.session_id) return
      setLoading(true)
      // maybe we need to return the question object as well when asking a question
      const answer = await athena.athena?.askQuestion({
        session_id: athena.session_id,
        question: values.question
      })
      setAnswer(answer?.data)

      // reload prev question for now
      const prevQuestion = await athena.athena?.getPreviousQuestions()
      setPrevQuestions(prevQuestion ?? [])
      // set the current question object based on prev question for now
      const currentQuestion = prevQuestion?.find(d => d.question === values.question)
      setQuestion(currentQuestion)

      setLoading(false)
    } catch (error: any) {
      const { response } = error
      setError({ message: response?.data?.message, status: response?.status ?? 401 })
    }
  }

  const onPrevQuestionClick = async (question: Question) => {
    setLoading(true)
    setQuestion(question)
    setAnswer(question.answers)
    setLoading(false)
  }

  useEffect(() => {
    init()
    return () => { }
    // eslint-disable-next-line
  }, [])

  return (
    <AppLayout
      renderLeftContent={<PreviousQuestions currentQuestion={question} data={prevQuestions} onClick={onPrevQuestionClick} />}
      renderContent={<Assistant currentQuestion={question} loading={loading} data={answer} onSubmit={askQuestion} error={error} />}
    />
  )
}

export default AssistantPage
