import { BaseLayout } from "@common/components";
import Account from "@common/components/Account";
import { Container } from "@material-ui/core";

export const AccountSettings = () => {
  return (
    <BaseLayout>
      <Container>
        <Account />
      </Container>
    </BaseLayout>
  );
};
