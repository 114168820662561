import Colors from '@common/constants/Colors';
import { makeStyles } from '@material-ui/core/styles'
export default makeStyles((theme) => ({
  tabTitle: {
    fontWeight: 500,
    fontSize: 24,
    marginBottom: 15
  },
  priceContainer: {
    minHeight: 160,
    maxWidth: 640
  },
  tab: {
    minWidth: 'auto',
    textTransform: 'capitalize',
    letterSpacing: 0,
    fontSize: 18,
    fontWeight: 400,
    marginRight: 30,
    marginBottom: 10,
    borderRadius: 12,

    '&.Mui-selected': {
      color: theme.palette.text.primary,
      fontWeight: 600,
    }
  },
  tabIndicator: {
    display: 'none'
  },
  planTitle: {
    fontWeight: 500,
    fontSize: 28,
    marginBottom: 15,
    marginTop: 5,
  },
  planFeatures: {
    backgroundColor: Colors.lightBlue2,
    borderRadius: 12,
    padding: '25px 30px',
    marginLeft: 100,
    display: 'flex',
    alignItems: 'center',
    '& ul': {
      listStyleType: 'none'
    }
  },
  planActive: {
    color: '#2A9D8F',
    fontSize: 24,
    fontWeight: 600
  },
  planButton: {
    boxShadow: 'none !important',
    fontSize: 11,
    marginLeft: 5,
    marginTop: 16
  },
  planContainer: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 12,
    marginTop: 15,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: 20,
    minWidth: 280,

    '& span': {
      textTransform: 'capitalize'
    }
  },
  planWrapper: {
    display: 'flex',
  },
  planPrice: {
    fontSize: 24,
    fontWeight: 600
  },
  billingTable: {
    border: 'none !important',

    '& .MuiDataGrid-iconSeparator': {
      display: 'none'
    }
  },
  desc: {
    marginBottom: 16
  },
  action: {
    marginTop: 16
  },
  list: {
    padding: 0,
    listStyleType: 'none',
    '& li': {
      display: 'flex',
      gap: 8,
      marginBottom: 8,
      alignItems: 'center',
      fontWeight: '500'
    }
  },
  status: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }
}));
