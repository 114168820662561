import i18n from "i18next"
import { initReactI18next } from "react-i18next"
// import Backend from 'i18next-http-backend'
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"

const enabled = ['en', 'es', 'id', 'nl']

export const languages = {}

for (let i = 0; i < enabled.length; i++) {
  try {
    if (enabled[i] !== 'en') import(`moment/locale/${enabled[i]}`) //import moment locale
    languages[enabled[i]] = { translation: require(`../translations/${enabled[i]}`) }
  } catch (error) {
    console.log('error importing ' + enabled[i])
  }
}

i18n
  // .use(new Backend(null, { loadPath: '/translations/{{lng}}.json' }))
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources: languages,
    fallbackLng: 'en',
    // cleanCode: true,
    // load: 'languageOnly',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false,
    },
    returnEmptyString: false,
    returnNull: false
  });

// i18n.on('languageChanged', (lng) => { });

export default i18n