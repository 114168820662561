import React, { useState } from 'react'
import { Avatar, ConfirmDialog, RelationsPicker } from '@common/components'
import { iLabel, iNode } from "@gloow/apiconsumer"
import useStyles from './ConnectedNodes.styles'
import EmptyNodeIcon from '@assets/images/empty-node.png'
import { Button, ClickAwayListener, IconButton, Typography } from '@material-ui/core'
import { iConnectedNode } from '@store/nodes/nodes.interfaces'
import { useAppDispatch } from '@store/hooks'
import { addRelationsLabel, removeConnectedNode } from '@services/DomainDataService'
import { useTranslation } from 'react-i18next'
import { setLastUpdate } from '@store/domain/domainSlice'
import { addExistingItemTypes, iInteractionDialog, nodeInfoTabTypes } from '@common/constants/Constants'
import { Add } from '@material-ui/icons'
import { VertexType } from '@gloow/navimator'
import deleteIcon from '@assets/images/icons-unlink.svg'
interface iConnectedNodes {
  activeNode?: iNode,
  nodes: iConnectedNode[],
  editable?: boolean,
  removeable?: boolean,
  domainUuid: string,
  onItemClicked?: (node: iConnectedNode) => void,
  setNodeMenu?: (props: iInteractionDialog) => void,
}

export const ConnectedNodes = ({
  activeNode,
  nodes,
  editable = false,
  removeable = false,
  domainUuid,
  onItemClicked = (node: iConnectedNode) => { },
  setNodeMenu = (props) => { }

}: iConnectedNodes) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [groupRelation, setGroupRelation] = useState<boolean>(true)
  const [deleteState, setDeleteState] = useState<{ dialog: boolean, data?: iNode }>({
    dialog: false,
    data: undefined
  })
  const { t } = useTranslation()

  const removeConnection = async () => {
    setLoading(true)
    if (deleteState.data) await removeConnectedNode(deleteState.data)
    setLoading(false)
    dispatch(setLastUpdate(Date.now()))
    setDeleteState({ dialog: false, data: undefined })
  }

  const renderRelation = (node, index) => {
    if (nodes[index - 1] && nodes[index - 1].relationLabel?.name === node.relationLabel?.name && groupRelation) return <></>
    if (!node.relationLabel && !editable) return <></>
    return <RelationsPicker
      activeNode={activeNode!}
      targetNodes={[node]}
      relationLabel={node.relationLabel}
      onRelationLabelAdded={(nodes: iConnectedNode[], label: iLabel) => addRelationsLabel(nodes, label, domainUuid)}
      readOnly={!editable}
      onClick={() => setGroupRelation(false)}
    />
  }

  const renderItem = (node: iNode, index: number) => {
    return <div key={index.toString()}>
      {activeNode && renderRelation(node, index)}
      <div className={classes.nodeContainer}>
        <Button className={classes.node} onClick={() => onItemClicked && onItemClicked(node)}>
          <Avatar name={node.name} src={node.thumbnailUrl} color={node.color} />
          <div className={classes.nodeTitle}>
            <Typography variant="body2" className={classes.nodeName}>{node.name}</Typography>
            <Typography variant='body2' className={classes.labels}>{node.labels?.map(d => d.name).join(',')}</Typography>
          </div>
        </Button>
        {removeable &&
          <IconButton size='small' id="remove-node" className={classes.removeButton} onClick={() => setDeleteState({ dialog: true, data: node })}>
            <img className={classes.removeIcon} src={deleteIcon} alt='delete' />
          </IconButton>
        }
      </div>
    </div>
  }


  return (
    <div className={classes.container}>
      {nodes.length === 0 ?
        <div className={classes.tabEmpty}>
          <img src={EmptyNodeIcon} alt='Gloow Nodes' />
          <Typography variant='body2'>{t('common.no_connections_found')}</Typography>
        </div>
        :
        <ClickAwayListener onClickAway={() => {
          if (!editable) return
          return setGroupRelation(true)
        }}>
          <div className={classes.nodesContainer} style={{ height: editable ? 'calc(100% - 50px)' : '100%' }}>
            {nodes.sort((a, b) => {
              if (!a.relationLabel || !b.relationLabel) return 1
              if (a.relationLabel?.name < b.relationLabel?.name) { return -1; }
              if (a.relationLabel?.name > b.relationLabel?.name) { return 1; }
              return 0;
            })
              .map(renderItem)}
          </div>
        </ClickAwayListener>
      }

      <ConfirmDialog
        loading={loading}
        open={deleteState.dialog}
        onClose={() => setDeleteState({ dialog: false, data: undefined })}
        onConfirm={() => removeConnection()}
        text={t('common.are_you_sure_want_to_delete_this_connection', 'Are you sure want to delete this connection?')}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
      />
      {editable && (
        <div className={`${classes.actionContainer} connect-nodes-container`}>
          <Button
            id={`add-${nodeInfoTabTypes.CONNECTED_NODES}`}
            type="button"
            color="primary"
            className='link-button'
            startIcon={<Add />}
            onClick={() => setNodeMenu({
              open: true,
              tab: addExistingItemTypes.NODES,
              connectTo: { type: VertexType.Node, data: activeNode, },
              connectedNodes: nodes,
              specificMenu: true
            })}
          >
            <Typography variant='body2'>{t('common.connect_nodes')}</Typography>
          </Button>
        </div>
      )}
    </div>
  )
}
