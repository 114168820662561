import { createSlice } from '@reduxjs/toolkit'
import { iResourceMapping } from "@gloow/apiconsumer"

interface iResourceMappingsState {
  resourceMappings: iResourceMapping[]
}

const initialState: iResourceMappingsState = {
  resourceMappings: []
}

export const resourceMappingsSlice = createSlice({
  name: 'resourceMappings',
  initialState: initialState,
  reducers: {
    setResourceMappings: (state, action) => {
      state.resourceMappings = action.payload
    },
    addResourceMapping: (state, action) => {
      state.resourceMappings = [...state.resourceMappings, action.payload]
    },
    addResourceMappings: (state, action) => {
      state.resourceMappings = [...state.resourceMappings, ...action.payload]
    },
    removeResourceMappings: (state, action) => {
      const data = state.resourceMappings
      state.resourceMappings = data.filter(rm => rm.resource !== action.payload)
    },
    removeSingleMapping: (state, action) => {
      const data = state.resourceMappings
      state.resourceMappings = data.filter(rm => rm.id !== action.payload)
    },
    bulkRemoveResourceMappings: (state, action) => {
      const data = state.resourceMappings
      state.resourceMappings = data.filter(rm => !action.payload.includes(rm.id))
    },
  }
})

export const { addResourceMapping, addResourceMappings, removeResourceMappings, removeSingleMapping, setResourceMappings, bulkRemoveResourceMappings } = resourceMappingsSlice.actions

export default resourceMappingsSlice.reducer
