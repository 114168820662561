import { v4 as uuidv4 } from 'uuid'
import path from 'path'
import * as mime from 'mime-types'
import { Import, iNode, Resource, Upload } from '@gloow/apiconsumer'
import { store } from '@store/index'
import { setUser } from '@store/user/userSlice';
import { addResource } from '@store/resources/resourcesSlice'
import { addResourceMapping } from '@store/resourceMappings/resourceMappingsSlice'
import { updateUserMetaData } from '@services/AuthService'
import Compressor from 'compressorjs'
import { getResourceInfo } from './resource'
import AnalyticsService from "@services/AnalyticsService";

const compressImageType = ['image/jpg', 'image/jpeg', 'image/png']

export const fileToBlob = (file: File) => {
  return new Blob([file], { type: file.type })
}

// playground https://fengyuanchen.github.io/compressorjs/
export const compressImage = async (file: File) => {
  return new Promise((resolve, reject) => {
    return new Compressor(file, {
      maxWidth: 1024,
      quality: (file.size / 1000 / 1000) >= 0.6 ? 0.9 : 1, // if >= 600KB then set the quality to 0.9
      mimeType: 'jpg',
      success(result: Blob) {
        resolve(result)
      },
      error(error: Error) {
        // failed to compress image, continue upload without compressing image
        const blob = fileToBlob(file)
        resolve(blob)
      }
    })
  })
}

export const uploadFile = async (file: File) => {
  try {
    let ext = path.extname(file.name)
    if (!ext || ext === '') {
      ext = mime.extension(file.type)
      if (!!ext) { ext = '.jpeg' }
      else { ext = '.' + ext }
    }
    const fileName = `${uuidv4()}${ext}`
    let blob: Blob
    if (compressImageType.indexOf(file.type) > -1) {
      // @ts-ignore
      blob = await compressImage(file)
    }
    else {
      blob = fileToBlob(file)
    }
    const RS = new Resource()
    const { url } = await RS.getSignedUrl({ key: fileName })
    if (url) {
      const upload = await fetch(url, {
        method: 'PUT',
        body: blob
      })
      if (upload) return `${process.env.REACT_APP_S3_PUBLIC_URL}/${fileName}`
    }
    return false
  } catch (error) {
    AnalyticsService.logError('upload-file-error', { error });
    console.log(error)
    return false
  }
}

export const saveResource = async (file, type, mimeType, node?: iNode) => {
  try {
    const RS = new Resource()

    const newResource = {
      title: file.originalFileName,
      info: '',
      type,
      mimeType, // this working without adding new type on iResourceInput
      url: file.location
    }

    const createdRs = await RS.create(newResource)

    // @josh: this one doesn't work, always returns 404
    // const createdRs = await RS.createSimple(newResource)

    store.dispatch(addResource(createdRs))

    if (node) {
      // @ts-ignore
      const resourceMapping = await RS.addResourceToNode(node, createdRs)

      /**
       * @todo
       * @josh, resourceMapping returned object, while the reducer expecting number (id)
       * please correct me if I'm wrong, doing this temporary until discuss
       */
      store.dispatch(
        addResourceMapping({
          ...resourceMapping,
          // @ts-ignore
          node: resourceMapping.node.id,
          // @ts-ignore
          resource: resourceMapping.resource.id
        })
      )
    }
    return {
      ...createdRs,
      title: createdRs.metaData?.title?.length > 0 ? createdRs.metaData?.title : createdRs.title,
      info: getResourceInfo(createdRs)
    }
  } catch (e) {
    AnalyticsService.logError('save-resource-error', { e });
    console.log(e)
  }
}

export const uploadAvatar = async (file: File) => {
  try {
    const formData: any = new FormData();
    // @ts-ignore
    const blob = await compressImage(file)
    formData.append('file', blob);

    const RS = new Upload()
    const { url } = await RS.avatar(formData)
    if (url) {
      const metaData = { ...store.getState().user.user.user_metadata, picture: url }
      store.dispatch(setUser({ ...store.getState().user.user, user_metadata: metaData }))
      updateUserMetaData(metaData)
      return url
    }
    return false
  } catch (error) {
    console.log(error)
    AnalyticsService.logError('upload-avatar-error', { error });
    return false
  }
}

export const dataURItoBlob = (dataURI) => {
  var byteString = atob(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export const uploadCSV = async (file: File, labelId: number) => {
  try {
    const formData: any = new FormData();
    // @ts-ignore
    formData.append('file', file);
    formData.append('domainUuid', store.getState().domain.openDomain?.uuid)
    formData.append('labelId', labelId)

    const ImportSvc = new Import()
    const { label, nodes } = await ImportSvc.csv(formData)

    if (label) {
      // const metaData = { ...store.getState().user.user.user_metadata, picture: url }
      // store.dispatch(setUser({ ...store.getState().user.user, user_metadata: metaData }))
      // updateUserMetaData(metaData)
      // return url
      return { label, nodes }
    }
    return null
  } catch (error) {
    console.log(error)
    AnalyticsService.logError('import-csv-error', { error });
    return null
  }
}
