import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(createStyles({
  tabEmpty: {
    height: 'calc(100vh - 248px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spacer: {
    marginBottom: 16
  }
}))