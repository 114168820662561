import React, { useEffect, useState } from 'react'
import { AppLayout, ConfirmDialog, ConnectedResources, EntityPropertiesTable, LabelInfoForm, Labels, NodeContent, NodeListV2, NodeNameAvatar, NodeSidePanel, ResourceInfoForm } from '@common/components'
import { iInteractionDialog } from '@common/constants/Constants'
import useDomain from '@hooks/useDomain'
import { useHistory, useParams } from 'react-router-dom'
import useStyles from './index.styles'
import { RouteURI } from '@common/constants/Routes'
import { iLabel, iLabelType, iNode, iResource, SearchResultType } from "@gloow/apiconsumer"
import { Button, Drawer, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { deleteNode } from '@services/DomainDataService'
import IconDelete from '@assets/images/delete.svg'
import IconDocument from '@assets/images/icons-document.svg'
import IconMindMap from '@assets/images/icons-mind-maps.svg'
import IconResources from '@assets/images/icons-docs-media.svg'
import IconEmptyNode from '@assets/images/empty-node.png'
import moment from 'moment'
import { generateDetailURL } from '@helpers/utils'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setConnectionView, setGroupByLabelFocusMode, setShowProperties, setShowResources } from '@store/filters/filtersSlice'
import { getResourcesForActiveNode } from '@selectors/index'

const FocusMode = ({
  setNodeMenu,
  setAddExistingItemDialog
}: {
  setNodeMenu: (props: iInteractionDialog) => void,
  setAddExistingItemDialog: (props: iInteractionDialog) => void,
}) => {
  const classes = useStyles()
  const { nodes, labels, resources, openDomain, domainPermissions, connectedNodes, connectedResources } = useDomain()
  const [groupByLabel] = useAppSelector(state => [state.filters.groupByLabelFocusMode])
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [anchor, setAnchor] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [drawerData, setDrawerData] = useState<iNode | iLabel | iResource>()
  const { nodeId, slug } = useParams<{ slug: string, nodeId: string }>()

  const id = new URLSearchParams(history.location.search).get('id') ?? ''
  const type = new URLSearchParams(history.location.search).get('type') ?? ''
  const ref = new URLSearchParams(history.location.search).get('ref') ?? ''

  const [node, setNode] = useState<iNode | Partial<iNode> | undefined>(nodes.find(d => d.id === parseInt(nodeId)) ?? undefined)
  const [showProperties, showResources, currentResources] = useAppSelector(state => [
    state.filters.showProperties,
    state.filters.showResources,
    getResourcesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: { id: parseInt(nodeId) } } })
  ])

  useEffect(() => {
    if (parseInt(id) && type) {
      let data: any = undefined
      if (type === SearchResultType.Node) data = nodes.find(d => d.id === parseInt(id))
      else if (type === SearchResultType.Label) data = labels.find(d => d.id === parseInt(id))
      else if (type === SearchResultType.Resource) data = resources.find(d => d.id === parseInt(id))
      setDrawerData(data)
    }
    else setDrawerData(undefined)
    return () => { }
    // eslint-disable-next-line
  }, [id, type, nodes, resources, labels])

  useEffect(() => {
    if (!nodeId && nodes.length > 0) history.replace(`/${slug}/${RouteURI.FOCUS_MODE}/${nodes[0].id}`)
    // if (nodeId && node?.id !== parseInt(nodeId)) setNode(nodes.find(d => d.id === parseInt(nodeId)))
    if (nodeId) setNode(nodes.find(d => d.id === parseInt(nodeId)))
    return () => { }
    // eslint-disable-next-line
  }, [nodeId, nodes])

  const renderNodeMenu = () => {
    return <Menu
      open={Boolean(anchor)}
      anchorEl={anchor}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => setAnchor(undefined)}
    >
      <MenuItem onClick={() => {
        dispatch(setShowProperties(!showProperties))
        setAnchor(undefined)
      }}>
        <img alt='node-properties' src={IconDocument} className={classes.deleteIcon} />
        <Typography variant='body2'>{t(`common.${!showProperties ? 'show' : 'hide'}_properties`, `${!showProperties ? 'Show' : 'Hide'} properties`)}</Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        dispatch(setShowResources(!showResources))
        setAnchor(undefined)
      }}>
        <img alt='node-properties' src={IconResources} className={classes.deleteIcon} />
        <Typography variant='body2'>{t(`common.${!showResources ? 'show' : 'hide'}_resources`, `${!showResources ? 'Show' : 'Hide'} resources`)}</Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        if (!node?.id) return
        history.push(generateDetailURL(`/${slug}/${RouteURI.MIND_MAPS}`, { id: node?.id, type: SearchResultType.Node }))
      }}>
        <img alt='node-properties' src={IconMindMap} className={classes.deleteIcon} />
        <Typography variant='body2'>{t('common.view_on_mindmap', 'View on mindmap')}</Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        setDeleteDialog(true)
        setAnchor(undefined)
      }}>
        <img alt='delete-node' src={IconDelete} className={classes.deleteIcon} />
        <Typography variant='body2'>{t('common.delete_node')}</Typography>
      </MenuItem>
    </Menu>
  }

  const onDelete = async () => {
    setDeleteDialog(false)
    if (!node?.id) return
    setLoading(true)
    await deleteNode(node as iNode)
    setLoading(false)
    return history.replace(`/${slug}/${RouteURI.FOCUS_MODE}`)
  }

  return (
    <AppLayout
      contentClassName={classes.container}
      renderLeftContent={<NodeListV2
        activeNodeId={node?.id}
        nodes={nodes}
        allowAdd={domainPermissions?.modify}
        onAdded={(node) => history.replace(`/${slug}/${RouteURI.FOCUS_MODE}/${node.id}`)}
        onClick={(d) => history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${d.id}`)}
        groupByLabel={groupByLabel}
        onGroupByLabelChange={(checked) => dispatch(setGroupByLabelFocusMode(checked))}
      />}
      renderContent={<>
        {(!node?.id || nodes.length === 0) ? <div className={classes.emptyNodesContainer}>
          <img alt='empty-node' src={IconEmptyNode} />
          <Typography variant='body2'>{t('common.no_nodes_found')}</Typography>
        </div>
          :
          <>
            <NodeNameAvatar
              data={{
                id: node?.id ?? 0,
                color: node?.color ?? '',
                name: node?.name ?? '',
                thumbnailUrl: node?.thumbnailUrl ?? ''
              }}
              editable={domainPermissions?.modify}
              autoSaveDebounceMs={node?.id ? 2000 : 0}
            />
            {renderNodeMenu()}
            <div className={classes.actionContainer}>
              <Typography variant='body2' className={classes.updatedAtText}>{t('common.updated_at_date', `Updated at ${moment(node?.updatedAt).format('MMM DD YYYY HH:mm')}`, { date: moment(node?.updatedAt).format('MMM DD YYYY') })}</Typography>
              {domainPermissions?.remove && <IconButton size='small' onClick={(e) => setAnchor(e.currentTarget)}><MoreVert fontSize='small' color='primary' /></IconButton>}
            </div>
            <div className={classes.labels}>
              <Labels
                nodeId={node.id}
                labels={node.labels as iLabel[]}
                editable={domainPermissions?.modify!}
                domainUuid={openDomain?.uuid!}
                labelType={iLabelType.NODE}
                onClick={(l) => l?.id ? history.push(`/${slug}/${RouteURI.ENTITIES}/${l.id}`) : {}}
              />
            </div>
            <NodeContent
              nodeId={node.id}
              className={classes.editor}
              toolbarClassName={classes.editorToolbar}
              id={`contents-${node.id}`}
              initialValue={node.contents}
              autoSaveDebounceMs={node.id ? 2000 : 0}
              readOnly={!domainPermissions?.modify!}
              onMentionClick={(type, id) => {
                if (!id || !type) return
                return history.push(generateDetailURL(history.location.pathname, { id, type, ref }))
              }}
            />
            <ConfirmDialog
              loading={loading}
              open={deleteDialog}
              onConfirm={() => onDelete()}
              onClose={() => setDeleteDialog(false)}
              confirmText={t('common.delete')}
              cancelText={t('common.cancel')}
              text={t('common.are_you_sure_want_to_delete_this_node')}
            />
            <Drawer
              classes={{ paper: classes.rightDrawer }}
              BackdropProps={{ invisible: true }}
              open={Boolean(drawerData)}
              onClose={() => history.push(history.location.pathname)}
              anchor={'right'}
            >
              {type === SearchResultType.Node && (
                <NodeSidePanel
                  editable={domainPermissions?.modify}
                  removeable={domainPermissions?.remove}
                  onConnectedNodeClick={(nodeId) => history.push(generateDetailURL(history.location.pathname, {
                    id: nodeId, type: SearchResultType.Node, ref: id
                  }))}
                  onConnectedResourceClick={(resourceId) => history.push(generateDetailURL(history.location.pathname, {
                    id: resourceId, type: SearchResultType.Resource, ref: id
                  }))}
                  onLabelClick={(labelId) => history.push(generateDetailURL(history.location.pathname, {
                    id: labelId, type: SearchResultType.Label, ref: id
                  }))}
                  setNodeMenu={setNodeMenu}
                  node={drawerData! as iNode}
                  domainUuid={openDomain?.uuid!}
                  connectedNodes={connectedNodes}
                  connectedResources={connectedResources}
                />
              )}
              {type === SearchResultType.Resource && (
                <ResourceInfoForm
                  activeResource={drawerData as iResource}
                  onConnectedNodeClicked={(item) => history.push(generateDetailURL(history.location.pathname, {
                    id: item.id, type: SearchResultType.Node, ref: id
                  }))}
                  setAddExistingItemDialog={setAddExistingItemDialog}
                />
              )}
              {type === SearchResultType.Label && (
                <LabelInfoForm
                  activeLabel={drawerData as iLabel}
                  onConnectedNodeClicked={(item) => history.push(generateDetailURL(history.location.pathname, {
                    id: item.id, type: SearchResultType.Node, ref: id
                  }))}
                  connectedNodes={connectedNodes}
                  setNodeMenu={setNodeMenu}
                  domainPermissions={domainPermissions}
                />
              )}
            </Drawer>
          </>
        }
      </>
      }
      renderRightContent={<div className={classes.rightContainer}>
        {node?.id && showProperties && (
          <div className={`${classes.rightContentContainer} right-content`}>
            <div className={classes.subtitleContainer}>
              <Typography
                noWrap
                variant={'subtitle1'}
                className={classes.subtitle}
              >
                {t('properties.properties.property_or_connection', 'Properties or Connections')}
              </Typography>
            </div>
            <EntityPropertiesTable
              node={node as iNode}
              openConnectionMenu={setNodeMenu}
              onNodeClick={(node) => history.push(generateDetailURL(history.location.pathname, {
                id: node.id, type: SearchResultType.Node, ref: id
              }))}
            />
          </div>
        )}
        {node?.id && showResources && (
          <div className={`${classes.rightContentContainer} right-content`}>
            <div className={classes.subtitleContainer}>
              <Typography noWrap variant={'subtitle1'} className={classes.subtitle}>{t('common.connected_resources')}</Typography>
              <Button className={'link-button underline'} onClick={() => {
                dispatch(setConnectionView(node as iNode))
                // @ts-ignore
                history.push(`/${slug}/${RouteURI.DOCUMENTS}`)
              }}>
                <Typography variant='body2'>{t('common.view_all', 'View all')}</Typography>
              </Button>
            </div>
            <ConnectedResources
              size='small'
              activeNode={node as iNode}
              resources={currentResources}
              onItemClicked={(resource) => history.push(generateDetailURL(history.location.pathname, {
                id: resource.id, type: SearchResultType.Resource, ref: id
              }))}
              editable={domainPermissions?.modify}
              removeable={domainPermissions?.remove}
              setNodeMenu={setNodeMenu}
            />
          </div>
        )}
      </div>
      }
    />
  )
}

export default FocusMode
