import Colors from "@common/constants/Colors";
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(
  createStyles({
    container: { height: 'calc(100% - 16px)' },
    searchBar: {
      margin: "0px 16px",
      marginBottom: 16,
    },
    nodeItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexGrow: 1,
      boxShadow: "none !important",
      borderRadius: 12,
      padding: '8px 16px',
      "&.active": {
        backgroundColor: Colors.lightBlue2,
      },
      "&.active img": {
        opacity: 1,
      },
      width: "100%",
      justifyContent: "flex-start",
      textAlign: "left",
    },
    groupLabel: {
      fontSize: 11,
      color: Colors.darkGrey,
      marginRight: 8,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    groupLabelText: {
    },
    headerWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: 16,
    },
    labelWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "8px 19px",
      flexGrow: 1,
      boxShadow: "none !important",
      borderRadius: 12,
      width: "100%",
      justifyContent: "flex-start",
      textAlign: "left",
    },
    labelIcon: {
      width: 8,
      height: 8,
      backgroundColor: "red",
      borderRadius: "50%",
      marginRight: 8,
    },
    collapseIcon: {
      marginRight: 4,
      fontSize: 20,
      color: "#93959F",
      "&.active": {
        transform: "rotate(90deg)",
      },
    },
    listWrapper: {
      overflow: 'auto',
      overflowX: 'hidden'
    },
    addButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "8px 16px",
      flexGrow: 1,
      boxShadow: "none !important",
      borderRadius: 12,
      width: "100%",
      color: Colors.primary,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: Colors.lightBlue2,
      },
      justifyContent: "flex-start",
      textAlign: "left",
    },
    addIcon: {
      fontSize: 18,
      marginLeft: 4
    },
    addText: {
      marginLeft: 6
    },
    wrapperInner: {
      '& .children': {
        paddingLeft: 56
      }
    },
    hidden: {
      width: '0px !important',
      opacity: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    wrapperInnerMinimized: {
      padding: 0
    },
    nodeName: {
      marginLeft: 8
    }
  })
);
