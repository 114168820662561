import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { PayWallPrices } from '@common/components'
import { Dialog, Typography, Button, Grid } from '@material-ui/core'
import paywallIcon from '@assets/images/paywall-image.svg'
import { fetchPrices, fetchSession } from '@services/PaywallServices'
import { useAppSelector } from '@store/hooks'
import { loadStripe } from '@stripe/stripe-js'
import TheLoader from '@common/components/TheLoader/TheLoader'
import { useTranslation } from 'react-i18next'
import { syncUserSubscribe } from '@services/AuthService'

const stripeKey = process.env.REACT_APP_AUTH_STRIPE_KEY || 'pk_test_7wrU1PEabIN9lgf40UjyDPnc'
const stripePromise = loadStripe(stripeKey)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 15
    },
    dialogTitle: {
      marginTop: 20,
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 32
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      maxWidth: 620
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    },
    paywallImage: {
      display: 'block',
      margin: '0 auto'
    },
    priceContainer: {
      minHeight: 160,
      margin: '16px 0px'
    }
  }),
);

export interface SimpleDialogProps {
  open: boolean
  onClose: () => void
}

function PaywallPricesDialog(props: SimpleDialogProps) {
  const user = useAppSelector(state => state.session.user)
  const email = useAppSelector(state => state.session.email)
  // Sort by price because the data start from higher
  const prices = useAppSelector(state => state.paywall.prices.slice().sort((a, b) => a.amount - b.amount))
  const classes = useStyles()
  const { onClose, open } = props
  const [activePrice, setActivePrice] = useState(0)
  const handleClose = () => {
    onClose();
  };
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const openPaywall = async (price) => {
    setLoading(true)
    const param = {
      user: user.sub,
      userUuid: user['https://gloow.io/users/uuid'],
      email: email,
      priceId: price.id,
      success_url: `${window.location.origin}/account/subscription`,
      cancel_url: `${window.location.origin}/account/subscription`
    }

    const session: any = await fetchSession(param)
    const checkoutParam = {
      sessionId: session.sessionId
    }
    handleClose()
    setLoading(false)
    const stripe = await stripePromise
    await stripe?.redirectToCheckout(checkoutParam)
  }

  useEffect(() => {
    (async () => {
      if (open) {
        setLoading(true)
        await syncUserSubscribe()
        await fetchPrices()
        setLoading(false)
      }
    })()
  }, [open])

  if (loading) return <TheLoader />

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <CloseIcon className={classes.dialogBackIcon} onClick={() => handleClose()} />
      </div>
      <div>
        <img className={classes.paywallImage} src={paywallIcon} alt="paywall" />
        <Typography className={classes.dialogTitle} variant="h3" color="primary">
          {t('subscription.its_time_to_subscribe')}
        </Typography>
        <p>{t('subscription.reached_nodes_resources_limit')}</p>
        <div className={(prices?.length > 0 ? classes.priceContainer : '')}>
          <Grid container spacing={2} alignItems="center">
            {prices?.map((price, index) => (
              <Grid item xs={12} sm={12} md={4} key={price.id}>
                <PayWallPrices active={index === activePrice} price={price} handeClickPrice={() => setActivePrice(index)} />
              </Grid>
            ))}
          </Grid>
        </div>
        <Button fullWidth color="primary" variant="contained" onClick={() => openPaywall(prices[activePrice])}>{t('subscription.subscribe')}</Button>
      </div>
    </Dialog >
  );
}

export default PaywallPricesDialog;
