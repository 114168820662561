import Colors from '@common/constants/Colors';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  paper: {
    borderRadius: 12,
    justifyContent: 'center',
    position: 'relative',
    width: 420,
  },
  close: {
    position: 'absolute',
    right: 12,
    top: 12,
    width: 24,
    height: 24,
    color: Colors.black
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    '& .MuiButtonBase-root': {
      padding: '10px 20px',
      marginBottom: 12
    }
  },
  title: {
    color: Colors.primary,
    fontWeight: 'bold',
    padding: 24,
    paddingBottom: 12,
    fontSize: '22px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  description: {
    color: Colors.newColors.darkIndigo,
    fontSize: 16,
    textAlign: 'center'
  },
})