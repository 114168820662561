import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, Popover, TableCell, TextField, Typography } from '@material-ui/core'
import { iLabel, iNode, iRelation, PropertyValueTypes } from "@gloow/apiconsumer"
import useStyles from './Cell.styles'
import CellRelation from '../CellRelation/CellRelation'
import { useAppSelector } from '@store/hooks'
import { iEntityProperty } from '@store/entities/entities.interfaces'
const Cell = ({ node, nodes = [], relations = [], property, disabled = false, labels = [], onSave = (property) => { }, onNodeClick }:
  {
    node?: iNode,
    property: iEntityProperty,
    relations?: iRelation[],
    nodes?: iNode[],
    labels?: iLabel[],
    disabled?: boolean,
    onNodeClick?: (node: iNode) => void
    onSave: (property) => void
  }
) => {
  const classes = useStyles()
  const domainPermissions = useAppSelector(state => state.domain.openDomainPermissions)
  const [value, setValue] = useState<any>(property.value ?? '')
  const [edit, setEdit] = useState(false)
  const cellRef = useRef<any>(null)

  useEffect(() => {
    setValue(property.value)
    return () => { }
  }, [property.value])

  const onBlur = (val: any) => {
    setEdit(false)
    if (property.value !== val) onSave({ ...property, value: val })
  }

  const renderProp = () => {
    switch (property.valueType) {
      case PropertyValueTypes.JSON:
      case PropertyValueTypes.STRING:
        return <TextField type='text'
          autoFocus
          variant='outlined'
          className={classes.cellTextField}
          value={value}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onBlur(value)
            }
            if (e.key === 'Escape') {
              setEdit(false)
            }
          }}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => { onBlur(e.target.value) }}
          disabled={disabled}
        />
      case PropertyValueTypes.INT:
      case PropertyValueTypes.FLOAT:
        return <TextField
          autoFocus
          type='number'
          variant='outlined'
          className={classes.cellTextField}
          value={value}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onBlur(value)
            }
            if (e.key === 'Escape') {
              setEdit(false)
            }
          }}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => { onBlur(e.target.value) }}
          disabled={disabled}
        />
      case PropertyValueTypes.DATETIME:
        return <TextField
          autoFocus
          type="date"
          variant='outlined'
          defaultValue={value}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onBlur(value)
            }
            if (e.key === 'Escape') {
              setEdit(false)
            }
          }}
          onBlur={(e) => { onBlur(e.target.value) }}
          onChange={(e) => setValue(e.target.value)}
          className={classes.cellTextField}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        />
      case PropertyValueTypes.BOOL:
        return <Checkbox
          color='primary'
          checked={parseInt(value) > 0 ? true : false}
          onChange={(e) => {
            setValue(e.target.checked ? 1 : 0)
            onBlur(e.target.checked ? 1 : 0)
          }}
          disabled={disabled}
        />
      case PropertyValueTypes.RELATION:
        return <CellRelation
          node={node!}
          key={`${node!.id}_${property.id}`}
          nodes={nodes}
          relations={relations}
          edit={edit}
          disabled={disabled}
          property={property}
          labels={labels ?? []}
          onSave={() => setEdit(false)}
          onClick={onNodeClick}
        />
      default:
        return <></>
    }
    // eslint-disable-next-line
  }

  const renderValue = () => {
    return <Typography variant={'body2'} style={{ opacity: Boolean(edit) ? 0 : 1 }}>{property.value.toString()}</Typography>
  }

  const renderPopover = () => {
    return <Popover
      open={Boolean(edit)}
      classes={{ paper: classes.menuPaper }}
      anchorEl={cellRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      {renderProp()}
    </Popover>
  }

  return (
    <TableCell ref={cellRef} className={`${classes.container} ${!disabled ? classes.containerEditable : ''}`} onClick={() => { if (!edit && !disabled && domainPermissions?.modify) setEdit(true) }}>
      <div className={classes.cell}>
        {[PropertyValueTypes.BOOL, PropertyValueTypes.RELATION].includes(property.valueType) ?
          renderProp() : <>
            {renderValue()}
            {renderPopover()}
          </>
        }
      </div>
    </TableCell>
  )
}

export default Cell
