import { Typography } from '@material-ui/core'
import { Question } from "@gloow/apiconsumer"
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './PreviousQuestions.styles'
const PreviousQuestions = ({
  currentQuestion,
  data,
  onClick = (d) => { } }: {
    currentQuestion?: Question,
    data: Question[],
    onClick?: (d: Question) => void
  }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.container}>
      <Typography variant='subtitle1' className={classes.title}>{t('assistant.previous_questions', 'Previous Questions')}</Typography>
      {data.map((d, index) => <Typography
        key={index.toString()}
        variant='body2'
        className={`${classes.button} ${currentQuestion?.id === d?.id ? 'active' : ''}`}
        onClick={() => onClick(d)}>
        {d.question}
      </Typography>)}
    </div>
  )
}

export default PreviousQuestions
