import { createSlice } from '@reduxjs/toolkit'
import { iRelation } from "@gloow/apiconsumer"

interface iRelationsState {
  relations: iRelation[]
}

const initialState: iRelationsState = {
  relations: []
}

export const relationsSlice = createSlice({
  name: 'relations',
  initialState: initialState,
  reducers: {
    setRelations: (state, action) => {
      state.relations = action.payload
    },
    addRelation: (state, action) => {
      // sometimes API returns populated sourceNode / targetNode
      action.payload.sourceNode = action.payload.sourceNode.id || action.payload.sourceNode
      action.payload.targetNode = action.payload.targetNode.id || action.payload.targetNode
      state.relations = [...state.relations, action.payload]
    },
    addOrUpdateRelation: (state, { payload }) => {
      payload.sourceNode = payload.sourceNode.id || payload.sourceNode
      payload.targetNode = payload.targetNode.id || payload.targetNode

      const relation = state.relations.find(d => d.id === payload.id)
      if (relation) state.relations = state.relations.map(rel => {
        if (rel.id === payload.id) return { ...rel, payload };
        return rel;
      })
      else state.relations = [...state.relations, payload]
    },
    removeRelation: (state, { payload }) => {
      // const i = state.relations.findIndex(r => r.id === action.payload)
      // state.relations = i === -1 ? [...state.relations] : [...state.relations.slice(0, i), ...state.relations.slice(i + 1)]
      state.relations = state.relations.filter(r => r.id !== payload)
    },
    removeRelations: (state, { payload }) => {
      state.relations = state.relations.filter(r => !payload.includes(r.id))
    },
    addRelations: (state, action) => {
      state.relations = [...state.relations, ...action.payload]
    },
    removeAllRelationsConnectedToNode: (state, action) => {
      const data = state.relations
      state.relations = data.filter(r => {
        // @ts-ignore
        if (r.sourceNode.id) {
          // @ts-ignore
          return r.sourceNode.id !== action.payload && r.targetNode.id !== action.payload
        } else {
          return r.sourceNode !== action.payload && r.targetNode !== action.payload
        }
      })
    },
    updateRelation: (state, { payload }) => {
      state.relations = state.relations.map(rel => {
        if (rel.id === payload.id) {
          payload.sourceNode = payload.sourceNode.id || payload.sourceNode
          payload.targetNode = payload.targetNode.id || payload.targetNode

          return payload;
        }

        return rel;
      })
    },
    removeAllRelationsConnectedToNodes: (state, action) => {
      const data = state.relations
      state.relations = data.filter((r: any) => {
        if (r.sourceNode.id) {
          return !action.payload.includes(r.sourceNode.id) && !action.payload.includes(r.targetNode.id)
        } else {
          return !action.payload.includes(r.sourceNode) && !action.payload.includes(r.targetNode)
        }
      })
    },
  }
})

export const { addRelation, addRelations, removeAllRelationsConnectedToNode, removeRelation, setRelations, updateRelation, removeAllRelationsConnectedToNodes, addOrUpdateRelation, removeRelations } = relationsSlice.actions

export default relationsSlice.reducer
