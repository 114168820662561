import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import domainReducer from './domain/domainSlice'
import filtersReducer from './filters/filtersSlice'
import helpersReducer from './helpers/helpersSlice'
import labelsReducer from './labels/labelsSlice'
import nodesReducer from './nodes/nodesSlice'
import relationsReducer from './relations/relationsSlice'
import resourceMappingsReducer from './resourceMappings/resourceMappingsSlice'
import resourcesReducer from './resources/resourcesSlice'
import sessionReducer from './session/sessionSlice'
import themeReducer from './theme/themeSlice'
import userReducer from './user/userSlice'
import paywallReducer from './paywall/stripe'
import { analytics } from './middleware/analytics'
import { authentication } from "@store/middleware/authentication";
import explorerReducer from './explorer/explorer'
import entitiesReducer from './entities/entitiesSlice'

const middleware = getDefaultMiddleware({
  serializableCheck: false
})

middleware.push(analytics);
middleware.push(authentication);

export const store = configureStore({
  reducer: {
    domain: domainReducer,
    filters: filtersReducer,
    helpers: helpersReducer,
    labels: labelsReducer,
    nodes: nodesReducer,
    relations: relationsReducer,
    resourceMappings: resourceMappingsReducer,
    resources: resourcesReducer,
    session: sessionReducer,
    theme: themeReducer,
    user: userReducer,
    paywall: paywallReducer,
    explorer: explorerReducer,
    entities: entitiesReducer
  },
  middleware
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
