import { createSlice } from '@reduxjs/toolkit'
import { iLabel } from "@gloow/apiconsumer"

export interface iLabelsState {
  NodeLabels: iLabel[]
}

const initialState: iLabelsState = {
  NodeLabels: []
}

export const labelsSlice = createSlice({
  name: 'labels',
  initialState: initialState,
  reducers: {
    setNodeLabels: (state, action) => {
      state.NodeLabels = action.payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    },
    addNodeLabel: (state, action) => {
      state.NodeLabels = [action.payload, ...state.NodeLabels]
    },
    removeLabel: (state, action) => {
      const labels = state.NodeLabels
      state.NodeLabels = labels.filter(d => d.id !== action.payload)
    },
    upsertNodeLabels: (state, action) => {
      const newIds = action.payload.map(d => d.id)
      const oldLabels = state.NodeLabels.filter(d => !newIds.includes(d.id))
      return {
        ...state,
        NodeLabels: [...oldLabels, ...action.payload]
      }
    },
    updateNodeLabel: (state, action) => {
      state.NodeLabels = state.NodeLabels.map(d => {
        if (d.id === action.payload.id) return { ...d, ...action.payload }
        return d
      })
    }
  }
})

export const { addNodeLabel, setNodeLabels, removeLabel, upsertNodeLabels, updateNodeLabel } = labelsSlice.actions

export default labelsSlice.reducer
