import Domains from '@pages/Domains'
import Domain from '@pages/Domain'
import { AccountSettings } from '@pages/Account/Account'
import { iRoute } from './index.interfaces';

const AppRoutes: iRoute[] = [
  {
    name: 'Domains',
    component: Domains,
    path: '/domains',
    allowGuest: true
  },
  {
    name: 'Account',
    component: AccountSettings,
    path: '/account*',
  },
  {
    name: 'Domain',
    component: Domain,
    path: '/:slug/*',
    allowGuest: true
  }
];

export default AppRoutes
