import React, { useState } from 'react'
import { InputBase } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import useStyles from './NodeAutocomplete.styles'
import searchIcon from '@assets/images/icons-search.svg'
import { iNodeAutocomplete } from './NodeAutocomplete.interfaces'

export const NodeAutocomplete = ({
  clearOnBlur = true,
  clearOnEscape = true,
  blurOnSelect = true,
  allowAdd = false,
  ...props
}: iNodeAutocomplete) => {
  const [search, setSearch] = useState<string>('')
  const { t } = useTranslation()
  const classes = useStyles()
  const options = allowAdd && search.length > 0 ? [{ name: search }, ...props.options] : props.options
  return (
    <Autocomplete
      {...props}
      className={`${classes.container} node-autocomplete`}
      classes={{
        popper: classes.autoCompletePopper,
        noOptions: classes.autoCompleteNoOptions,
      }}
      clearOnBlur={clearOnBlur}
      clearOnEscape={clearOnEscape}
      blurOnSelect={blurOnSelect}
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => !value?.id ? false : option?.id === value?.id}
      disableCloseOnSelect={true}
      renderInput={(params) => (
        <div className={classes.inputContainer}>
          <img src={searchIcon} alt="searchIcon" className={classes.icon} />
          <InputBase
            autoFocus
            onChange={(e) => setSearch(e.target.value)}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            type='text'
            placeholder={props.placeholder ?? t('common.search')}
            className={classes.textField}
          />
        </div>
      )}
    />
  )
}
