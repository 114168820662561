import { createSlice } from '@reduxjs/toolkit'
import { iStripePrice } from "@gloow/apiconsumer"

export interface iStripeState {
    open: boolean,
    prices: iStripePrice[],
    sessionId: string,
    payWallModal: boolean
}

const initialState: iStripeState = {
    open: false,
    prices: [],
    sessionId: '',
    payWallModal: false
}

export const stripeSlice = createSlice({
    name: 'stripe',
    initialState: initialState,
    reducers: {
        setPrices: (state, action) => {
            state.prices = action.payload
        },
        openPrice: (state, action) => {
            state.open = true
            state.sessionId = action.payload
        },
        closePrice: (state) => {
            state.open = false
            state.sessionId = ''
        },
        setPayWallModal: (state, action) => {
            state.payWallModal = action.payload
        }
    }
})

export const { setPrices, openPrice, closePrice, setPayWallModal } = stripeSlice.actions

export default stripeSlice.reducer
