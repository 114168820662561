import Colors from '@common/constants/Colors'
import React from 'react'
import useStyles from './ColorButton.styles'
import iconEditBlue from '@assets/images/icons-edit-blue.png'

const ColorButton = ({ onClick, value }: { onClick?: () => void, value: string }) => {
  const classes = useStyles()
  return (
    <div onClick={onClick}>
      <div
        className={`${classes.nodeColorWrap} node-color-wrap`}
        style={{
          backgroundColor:
            Colors.newColors[value]
              ? Colors.newColors[value]
              : value
        }}
      >
        {onClick && (
          <div className={classes.node}>
            <img className={classes.nodeDim} src={iconEditBlue} alt="edit-icon" />
          </div>
        )}
      </div>
    </div >
  )
}

export default ColorButton