
import { encryptPublicLong } from '@lsqswl/rsaencrypt'
/**
 * @todo: Encrypt token payload or use proper browser com!
 */

enum IOServiceEventType {
  LOGIN = 'GLW_LOGIN',
  OPEN_DOMAIN = 'GLW_OPEN_DOMAIN'
}

export class IOService {
  static get instance(): IOService {
    if (!instance) {
      instance = new IOService()
    }

    return instance
  }

  encryptEvent(payload: any) {
    const pkey = localStorage.getItem(process.env.REACT_APP_EXT_PUB_KEY ?? 'glwext')

    if (!pkey) {
      throw new Error('Pubkey not exist!')
    }

    return encryptPublicLong(payload, atob(pkey!));
  }

  syncAuthenticationToken(token) {
    try {
      console.log("[GLW_EXT] sync authentication token")

      document.dispatchEvent(new CustomEvent<any>("GLW_EXT", {
        detail: {
          type: IOServiceEventType.LOGIN,
          payload: this.encryptEvent(token)
        }
      }));

    } catch (e) {
      console.error(e)
    }
  }

  syncActiveDomain(domain) {
    try {
      console.log("[GLW_EXT] sync active domain")

      document.dispatchEvent(new CustomEvent<any>("GLW_EXT", {
        detail: {
          type: IOServiceEventType.OPEN_DOMAIN,
          payload: domain
        }
      }));
    } catch (e) {
      console.error(e)
    }
  }

}

let instance: IOService;