import React, { useCallback } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import iconAddPictureBlue from '@assets/images/icons-add-picture-blue.png'

import { useDropzone } from 'react-dropzone'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadContainer: {
      width: 200,
      height: 200,
      borderRadius: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
      fontSize: 16,
      cursor: 'pointer',
      position: 'relative'
    },
    uploadDrop: {
      opacity: 0.7
    },
    uploadButton: {
      margin: 15,
      width: 180,
      height: 40
    },
    uploadNotes: {
      opacity: 0.6,
      fontSize: 12
    },
    cameraIcon: {
      width: 28
    },
    cameraButton: {
      background: theme.palette.background.paper,
      position: 'absolute',
      top: 10,
      right: 0,
      boxShadow: '0px 4px 8px rgba(67, 84, 221, 0.1)',

      '&:hover': {
        background: theme.palette.background.paper,
        opacity: 0.9
      }
    },
    avatarContainer: {
      width: 200,
      height: 200,
      borderRadius: 100,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    avatar: {
      minHeight: 200,
      minWidth: 200,
      maxWidth: 'initial',
      objectFit: 'cover'
    }
  }),
);

interface IDropAvatar {
  onDropFiles?: (files) => void
  url?: string
}

const DropAvatar = ({
  onDropFiles = (files) => { },
  url
}: IDropAvatar) => {
  const classes = useStyles();

  const onDrop = useCallback(acceptedFiles => {
    onDropFiles(acceptedFiles)
  }, [onDropFiles])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'image/jpeg, image/png' })

  return (
    <>
      <div className={`${classes.uploadContainer} ${isDragActive ? classes.uploadDrop : ''}`} {...getRootProps()}>
        <input {...getInputProps()} />

        {!url ? (
          <img src={iconAddPictureBlue} alt="cam-icon" />
        ) : (
          <>
            <div className={classes.avatarContainer}>
              <img className={classes.avatar} src={url} alt="avatar" />
            </div>

            <IconButton className={classes.cameraButton} size="medium">
              <img className={classes.cameraIcon} src={iconAddPictureBlue} alt="cam-icon" />
            </IconButton>
          </>
        )}
      </div>
    </>
  );
}

export default DropAvatar;