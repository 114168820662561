import * as auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'
import { Authenticator, User } from "@gloow/apiconsumer"
import { store } from '@store/index'
import { authenticate, logout as removeSession } from '@store/session/sessionSlice';
import { setUser, setCurrentUserOnboardingStatus, setCurrentUserSubscriptionStatus } from '@store/user/userSlice';
import { APPLICATION_NAME } from "@env/constants";
import { setCollaborationDomains, setDomains, setPublicDomains } from '@store/domain/domainSlice';
import { initialOnboardingStatusGuest } from '@common/constants/Constants';
import { IOService } from './IOService';
import { clearDomainData } from './DomainDataService';
import qs from 'querystring';

export const Ator = new Authenticator();

const Auth0 = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/auth/callback`,
  scope: 'openid profile email offline_access read:current_user update:current_user_metadata',
  responseType: 'code token id_token',
  audience: process.env.REACT_APP_AUTH_AUDIENCE
});

export const login = async (username, password) => {

  console.log("login called, will be called twice");

  try {
    const response = await Ator.authenticateUsingCredentials(username, password, APPLICATION_NAME)


    if (response) {
      IOService.instance.syncAuthenticationToken(response)
      store.dispatch(authenticate(response))
      await syncUserOnboarding()
      await syncUserSubscribe()
    }
    return response
  } catch (error) {
    return error
  }
}

export const socialLogin = async (connection, invitationCode = null, ref?: string) => {
  try {
    const options = { connection, responseType: 'code', redirectUri: `${window.location.origin}/auth/callback` }

    const params: any = {}

    if (invitationCode) {
      params.invitationCode = invitationCode
    }

    if (ref) {
      params.refererUrl = ref
    }

    options.redirectUri = `${options.redirectUri}?${qs.stringify(params)}`

    const response = await Auth0.authorize(options);

    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

export const signUp = async (username, password) => {
  try {
    return new Promise((resolve, reject) => {
      Auth0.signup({
        connection: 'Username-Password-Authentication',
        email: username,
        password: password,
        appMetadata: {
          signup_source: 'gravity'
        }
      }, (err, signup) => {
        if (err) {
          reject(err)
        }
        resolve(signup)
      })
    })
  } catch (e) {
    console.log(e)
    return false
  }
}

export const syncUserOnboarding = (guest = false) => {

  return new Promise((resolve, reject) => {
    const userToken = Ator.getUserToken()
    const accessToken = Ator.getUserAccessToken()

    try {
      // @ts-ignore
      const userTokenData: any = jwtDecode(userToken)

      if (!userToken || !accessToken) {
        reject('missing user token')
      }

      if (guest) {
        store.dispatch(setCurrentUserOnboardingStatus(initialOnboardingStatusGuest))
        resolve('sync guest onboarding completed')
      }

      const auth0Manage = new auth0.Management({
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        token: accessToken
      });

      auth0Manage.getUser(encodeURI(userTokenData.sub), (err, profile) => {
        if (profile) {

          if (profile.logins_count === 1) {
            //@ts-ignore
            fbq('track', 'CompleteRegistration')
          }

          store.dispatch(setUser(profile))

          const onboardingStatus =
            profile.user_metadata && profile.user_metadata.onboardingStatus
              ? profile.user_metadata.onboardingStatus
              : {}

          store.dispatch(setCurrentUserOnboardingStatus(onboardingStatus))

          setTimeout(() => {
            resolve('sync onboarding completed')
          }, 300)
        }
      })
    } catch (e) {
      console.log(e)
    }
  })
}

export const syncUserSubscribe = () => {
  return new Promise((resolve, reject) => {
    try {
      const UserAPI = new User();

      UserAPI.getSubscriptionStatus().then(status => {
        UserAPI.getSubscriptionHistory().then(history => {
          const subsStatus = {
            ...status,
            history: [...history]
          }
          store.dispatch(setCurrentUserSubscriptionStatus(subsStatus))

          setTimeout(() => {
            resolve('sync subscription completed')
          }, 300)
        })
      })
    } catch (e) {
      console.log(e)
    }
  })
}

export const updateUserMetaData = (userData: any) => {
  return new Promise((resolve, reject) => {
    const accessToken = Ator.getUserAccessToken()
    const userToken = Ator.getUserToken()

    try {
      // @ts-ignore
      const userTokenData: any = jwtDecode(userToken)

      if (!accessToken || !userToken) {
        reject('missing access or user token')
      }

      var auth0Manage = new auth0.Management({
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        token: accessToken
      });

      // example, how to update user metadata on Auth0
      // ----------------------------------------------
      // this is a patch function, so it will not overwrite other properties unless explicitly mentioned
      // so you can easily add new properties to the user metadata
      // only use this to update data that the user has read/write access to (e.g. color_preference, blog_url, etc.)
      auth0Manage.patchUserMetadata(encodeURI(userTokenData.sub), userData, (err, profile) => {
        resolve(profile);
      });

    } catch (e) {
      console.log("update user metadata error")
      reject(e)
      console.log(e)
    }
  })
}

export const changePassword = (newPassword: string) => {
  return new Promise((resolve, reject) => {
    try {
      const UserAPI = new User();

      UserAPI.changePassword(newPassword).then(res => {
        resolve(res)
      });
    } catch (e) {
      console.log("change password error")
      reject(e)
      console.log(e)
    }
  })
}

export const resetPassword = (email: string) => {
  return new Promise((resolve, reject) => {
    try {
      Auth0.changePassword({
        connection: 'Username-Password-Authentication',
        email: email
      }, function (err, resp) {
        if (err) {
          console.log(err.message);
        } else {
          resolve(resp);
        }
      });

    } catch (e) {
      console.log("forgot password error")
      reject(e)
      console.log(e)
    }
  })
}

export const logout = () => {
  try {
    store.dispatch(removeSession())
    store.dispatch(setDomains([]))
    store.dispatch(setPublicDomains([]))
    store.dispatch(setCollaborationDomains([]))
    clearDomainData()
  } catch (error) {
    console.log("logout error")
    console.log(error)
  }
}

export const guestLogin = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const authData = await Ator.authenticateGuest()
      store.dispatch(authenticate(authData))
      localStorage.setItem('__gtused', 'true')
      await syncUserOnboarding(true)
      resolve(authData)
    } catch (error) {
      reject(error)
    }
  })
}
