import { iSearchResultExtended } from '@common/components/SearchAutocomplete/useAutocomplete'
import { Transforms } from 'slate'
import { Node, Relation, SearchResultType } from "@gloow/apiconsumer"

import { applyResourceToNode } from '@services/DomainDataService'
import { applyLabelToNode } from '@services/LabelService'
import { setLastUpdate } from '@store/domain/domainSlice'
import { store } from '@store/index'
import { addNode } from '@store/nodes/nodesSlice'
import { addOrUpdateRelation } from '@store/relations/relationsSlice'
import { Node as SlateNode, Element } from 'slate'

export const withMentions = editor => {
  const { isInline, isVoid, normalizeNode } = editor
  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }
  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }
  editor.normalizeNode = entry => {
    const [node, path] = entry
    // @ts-ignore
    if (Element.isElement(node) && node.type === 'mention') {
      for (const [child, childPath] of SlateNode.children(editor, path)) {
        // @ts-ignore
        return Transforms.setNodes(editor, { text: child?.text ?? '' }, { at: childPath })
      }
    }
    normalizeNode(entry)
  }
  return editor
}

export const createNodeFromMention = async (editor, nodeId, name) => {
  try {
    const $node = new Node()
    const node = await $node.create({ name })

    // Store created node to store
    store.dispatch(addNode(node))

    if (node) {
      const element: Partial<iSearchResultExtended> = {
        type: SearchResultType.Node,
        node,
        shortText: node.name,
      }

      await insertMention(editor, element, nodeId)
    }
  } catch (e) {
    console.log(`Failure to create node from mention: ${e}`)
    console.error(e)
  }
}

// @todo: Propose to insert the Slate element then update node connection to reduce lag when typing
export const insertMention = async (editor, element, nodeId) => {
  const mention: any = {
    type: 'mention',
    value: element,
    children: [{ text: element.shortText }]
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
  if (nodeId) {
    if (element.type === SearchResultType.Node && element?.node?.id) {
      const RS = new Relation()
      const result = await RS.createOrUpdate({ sourceNode: nodeId, targetNode: element.node.id })
      if (!result) return
      store.dispatch(addOrUpdateRelation(result))
      store.dispatch(setLastUpdate(Date.now()))
    }
    else if (element.type === SearchResultType.Label && element?.label?.id) {
      const result = await applyLabelToNode(element?.label?.id, nodeId)
      if (!result) return
      store.dispatch(setLastUpdate(Date.now()))
    }
    else if (element.type === SearchResultType.Resource && element?.resource?.id) {
      const result = await applyResourceToNode(element?.resource?.id, nodeId)
      if (!result) return
    }
  }
}
