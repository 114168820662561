import React from 'react'
import Colors from '@common/constants/Colors'
import { IconButton, Typography, Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { iLabel } from "@gloow/apiconsumer"
import useStyles from './Label.styles'
import { useTranslation } from 'react-i18next'

const Label = ({
  label,
  editable = false,
  disabled = false,
  onRemove,
  onChange,
  onClick = (l) => { }
}: {
  label: iLabel | Partial<iLabel>,
  editable?: boolean,
  disabled?: boolean,
  onRemove?: (l: iLabel | Partial<iLabel>) => void,
  onChange?: (l: iLabel | Partial<iLabel>) => void,
  onClick?: (l: iLabel | Partial<iLabel>) => void,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Button onClick={() => onClick(label)} className={classes.container} style={{ backgroundColor: label?.color ?? Colors.default() }}>
      {editable && onChange ?
        <input value={label.name} className={classes.input} onChange={(e) => onChange({ ...label, name: e.target.value })} size={(label.name?.length ?? 0) + 1} autoFocus placeholder={t('common.type', 'Type...')} />
        :
        <Typography variant='body2' className={classes.label}>{label.name}</Typography>
      }
      {onRemove && (
        <IconButton size='small' className={classes.removeButton} disabled={disabled} type='button' onClick={(e) => {
          e.stopPropagation()
          onRemove(label)
        }}><Close className={classes.close} fontSize='small' /></IconButton>
      )}
    </Button>
  )
}

export default Label
