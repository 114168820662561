import { createSlice } from '@reduxjs/toolkit'
import {
  initialOnboardingStatus,
  initialSubscriptionStatus,
  iSubscriptionStatus
} from '@common/constants/Constants'

export interface iUserState {
  user: any,
  onboardingStatus: {},
  subscriptionStatus: iSubscriptionStatus,
}

const initialState: iUserState = {
  user: null,
  onboardingStatus: { ...initialOnboardingStatus },
  subscriptionStatus: { ...initialSubscriptionStatus }
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    completeOnboardingEvent: (state, action) => {
      state.onboardingStatus = {
        ...state.onboardingStatus,
        [action.payload]: true
      }
    },
    setCurrentUserOnboardingStatus: (state, action) => {
      return {
        ...state,
        onboardingStatus: {
          ...initialOnboardingStatus,
          ...action.payload
        }
      }
    },
    setCurrentUserSubscriptionStatus: (state, action) => {
      return {
        ...state,
        subscriptionStatus: {
          ...action.payload
        }
      }
    },
    resetOnboardingEvent: (state) => {
      state.onboardingStatus = initialState.onboardingStatus
    },
    reset: (state) => {
      state.user = null
      state.onboardingStatus = initialState.onboardingStatus
      state.subscriptionStatus = initialState.subscriptionStatus
    },
  }
})

export const { completeOnboardingEvent, reset, resetOnboardingEvent, setCurrentUserOnboardingStatus, setUser, setCurrentUserSubscriptionStatus } = userSlice.actions

export default userSlice.reducer
