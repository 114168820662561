import i18n, { languages } from '@helpers/i18n'
import { Button, Menu, MenuItem } from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import useStyles from './LanguageDropdown.styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const LanguageDropdown = ({ className }: { className?: string }) => {
  const changeLocale = (l) => {
    moment().locale(l)
    i18n.changeLanguage(l)
    setAnchorEl(null)
  }
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const classes = useStyles()
  return (
    <>
      <Button className={`${classes.container} ${className}`} onClick={(e) => setAnchorEl(e.currentTarget)} endIcon={<ExpandMoreIcon className={classes.expand} />}>
        {i18n.language.substring(0, 2).toUpperCase()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Object.keys(languages).map(d => <MenuItem key={d.toString()} className={`${classes.menuItem} ${d === i18n.language ? classes.menuItemActive : ''}`} onClick={() => changeLocale(d)}>{d.toUpperCase()}</MenuItem>)}
      </Menu>
    </>
  )
}

export default LanguageDropdown
