import { Ontology, iOntology, iLabel, Label, Node, iLabelType } from '@gloow/apiconsumer'
import AnalyticsService from './AnalyticsService'

import { store } from '@store/index'
import { openDomain, setDomains, setCollaborationDomains } from '@store/domain/domainSlice'
import { addNodeLabel, setNodeLabels, upsertNodeLabels } from '@store/labels/labelsSlice'
import { updateNode, updateNodeField } from '@store/nodes/nodesSlice'
import { addLabel } from '@store/entities/entitiesSlice'


export async function getDomainRoot() {
  try {
    const os = new Ontology()
    const domainRoot = await os.getDomainRoot()
    return domainRoot
  } catch (e) {
    AnalyticsService.logError('ontology-get-domain-root', { e })
    return []
  }
}

export async function getOntologyChildren(parentIds: number[]) {
  try {
    const os = new Ontology()
    let ontologies: iOntology[] = []
    for (let i = 0; i < parentIds.length; i++) {
      const children = await os.getOntologyChildren(parentIds[i])
      if (children?.length) ontologies = [...ontologies, ...children]
    }
    return ontologies
  } catch (e) {
    AnalyticsService.logError('ontology-get-children', { e })
    return []
  }
}

export async function getOntologyDefaultMembers(ontologyId: number) {
  try {
    const os = new Ontology()
    const defaultMembers = await os.getOntologyDefaultMembers(ontologyId)
    return defaultMembers
  } catch (e) {
    AnalyticsService.logError('ontology-get-default-members', { e })
    return []
  }
}


export async function getOntologiesMembers(parentIds?: number[]) {
  try {
    const os = new Ontology()
    let members: iOntology[] = []
    if (parentIds?.length) {
      for (let i = 0; i < parentIds.length; i++) {
        const children = await os.getOntologyMembers(parentIds[i])
        if (children?.length) members = [...members, ...children]
      }
    }
    return members
  } catch (e) {
    AnalyticsService.logError('ontology-get-member', { e })
    return []
  }
}


export async function getOntologies(parentIds?: number[]) {
  try {
    const os = new Ontology()
    let ontologies = await os.getDomainRoot()
    if (!parentIds?.length) return ontologies
    for (let i = 0; i < parentIds.length; i++) {
      const children = await os.getOntologyChildren(parentIds[i])
      if (children?.length) ontologies = [...ontologies, ...children]
    }
    return ontologies
  } catch (e) {
    AnalyticsService.logError('ontology-get-ontology', { e })
    return []
  }
}

export async function applyOntologiesToDomain(
  domainUuid: string,
  ontologies: iOntology[]
) {
  try {
    const labels: iLabel[] = []
    const os = new Ontology()
    for (let i = 0; i < ontologies.length; i++) {
      const label = await os.applyOntologyToDomain(ontologies[i].id)
      labels.push(label)
    }
    if (labels?.length === 0) return []

    console.log(
      `ontology: applied ${labels
        .map(d => d.name)
        .join(',')} to domain ${domainUuid}`
    )

    const currentDomain = store.getState().domain.openDomain

    //update open domain
    if (currentDomain?.uuid === domainUuid) {
      store.dispatch(openDomain({
        ...currentDomain,
        labels: [...currentDomain.labels, ...labels]
      }))
    }

    //update domain list
    const domains = store.getState().domain.domains?.map(d => {
      if (d.uuid === domainUuid)
        return { ...d, labels: [...d.labels, ...labels] }
      return d
    })
    store.dispatch(setDomains(domains))

    // update domain collaboration list
    const collabDomains = store
      .getState()
      .domain.collaborationDomains?.map(d => {
        if (d.uuid === domainUuid)
          return { ...d, labels: [...d.labels, ...labels] }
        return d
      })
    store.dispatch(setCollaborationDomains(collabDomains))

    return labels
  } catch (e) {
    AnalyticsService.logError('ontology-apply-to-domain', { e })
    return []
  }
}

export async function applyOntologiesToNodes(
  nodeIds: number[],
  ontologies: iOntology[]
) {
  try {
    const os = new Ontology()
    const labels = await os.applyOntologyToManyNodes({
      nodeIds,
      ontologyIds: ontologies.map(d => d.id)
    })
    if (labels?.length === 0) return []
    console.log(
      `ontology: applied ${labels.map(d => d.name).join(',')} to node ${nodeIds.join(',')}`
    )
    store.dispatch(upsertNodeLabels(labels))
    return labels
  } catch (e) {
    AnalyticsService.logError('ontology-apply-to-node', { e })
    return []
  }
}

export async function searchOntology(labelType: iLabelType, text: string) {
  try {
    const os = new Ontology()
    let result: iOntology[] = []
    if (labelType === iLabelType.DOMAIN)
      result = await os.searchDomainOntology(text)
    else if (labelType === iLabelType.NODE)
      result = await os.searchNodeOntology(text)
    return result
  } catch (e) {
    AnalyticsService.logError('ontology-search', { e })
    return []
  }
}

export async function createLabel(label: Partial<iLabel>) {
  try {
    const ls = new Label()
    const result = await ls.create(label)
    if (label.labelType === iLabelType.NODE) {
      store.dispatch(addLabel(result))
      store.dispatch(addNodeLabel(result))
    }
    return result
  } catch (e) {
    AnalyticsService.logError('label-create', { e })
    return undefined
  }
}

export async function applyLabelToNode(labelId: number, nodeId: number) {
  try {
    const node = store.getState().nodes.nodes.find(d => d.id === nodeId)
    if (!node) return false
    // @ts-ignore
    const labelExist = node.labels?.find((d: iLabel) => d.id === labelId)
    if (labelExist) return true
    const ns = new Node()
    const result = await ns.update({ id: nodeId, labels: [...node.labels.map(d => d.id), labelId] })
    if (result) store.dispatch(updateNodeField({ id: nodeId, color: result.color, labels: result.labels, updatedAt: result.updatedAt }))
    return result
  } catch (e) {
    AnalyticsService.logError('label-apply-to-node', { e })
    return undefined
  }
}

export async function applyLabelsToNode(labelIds: number[], nodeId: number) {
  try {
    const node = store.getState().nodes.nodes.find(d => d.id === nodeId)
    if (!node) return false
    // @ts-ignore
    const tobeAddedIds = labelIds.filter(d => !node.labels?.map(l => l.id).includes(d))
    if (!tobeAddedIds?.length) return true
    const ns = new Node()
    const result = await ns.update({ id: nodeId, labels: [...node.labels.map(d => d.id), ...tobeAddedIds] })
    if (result) store.dispatch(updateNodeField({ id: nodeId, color: result.color, labels: result.labels, updatedAt: result.updatedAt }))
    return result
  } catch (e) {
    AnalyticsService.logError('label-bulk-apply-to-node', { e })
    return undefined
  }
}

export async function removeLabelFromNode(labelId: number, nodeId: number) {
  try {
    const node = store.getState().nodes.nodes.find(d => d.id === nodeId)
    if (!node) return false
    // @ts-ignore
    const labelExist = node.labels?.find((d: iLabel) => d.id === labelId)
    if (!labelExist) return true
    const ns = new Node()
    // @ts-ignore
    const result = await ns.update({ id: nodeId, labels: node.labels.map(d => d.id).filter(d => d !== labelId) })
    if (result) store.dispatch(updateNode(result))
    return result
  } catch (e) {
    AnalyticsService.logError('label-remove-from-node', { e })
    return undefined
  }
}

export async function removeLabelsFromNode(labelIds: number[], nodeId: number) {
  try {
    const node = store.getState().nodes.nodes.find(d => d.id === nodeId)
    if (!node) return false
    const ns = new Node()
    const result = await ns.update({ id: nodeId, labels: node.labels.map(d => d.id).filter(d => !labelIds.includes(d)) })
    if (result) store.dispatch(updateNode(result))
    return result
  } catch (e) {
    AnalyticsService.logError('label-bulk-remove-from-node', { e })
  }
}

export async function getAllNodeLabels() {
  try {
    const ls = new Label()
    const nodeLabels = await ls.all({ labelType: iLabelType.NODE })
    store.dispatch(setNodeLabels(nodeLabels))
    return nodeLabels
  } catch (e) {
    AnalyticsService.logError('label-get-all-node-label', { e })
    return []
  }
}
