import React, { useEffect } from 'react'
import { DomainCard } from '@common/components'
import { Box, Grid } from '@material-ui/core'
import { FetchPublicDomains } from '@services/DomainDataService'
import { iDomainNew } from "@gloow/apiconsumer"
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setLoading } from '@store/helpers/helpersSlice'

interface IDomains {
  domainsFilter: any
  onDomainClick: (domain: iDomainNew) => void
  renderEmptyDomain: any
}

const Domains = ({
  domainsFilter,
  onDomainClick,
  renderEmptyDomain
}: IDomains) => {
  const dispatch = useAppDispatch()
  const domains: iDomainNew[] = useAppSelector(state => domainsFilter(state.domain.publicDomains, state.filters.searchText))

  useEffect(() => {
    (async () => {
      try {
        if (!domains.length) {
          dispatch(setLoading(true))
          await FetchPublicDomains()
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
      }
    })()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Box mt={3}>
        {domains?.length ? (
          <Grid container spacing={3}>
            {domains?.map(domain => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={domain.id}>
                <DomainCard
                  domain={domain}
                  publicDomain={true}
                  handeClickDomain={() => onDomainClick(domain)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          renderEmptyDomain()
        )}
      </Box>
    </>
  )
}

export default Domains
