import React, { useEffect, useRef, useState } from 'react'
import { Typography, IconButton, TextField, Button } from '@material-ui/core'
import { Add, ChevronLeft } from '@material-ui/icons'
import useStyles from './LabelInfoForm.styles'
import { iNode, iLabel, Node } from "@gloow/apiconsumer"
import SwipeableViews from 'react-swipeable-views'
import { addExistingItemTypes, contentType, iInteractionDialog, labelInfoTabTypes } from '@common/constants/Constants'
import { ColorList, NodeCard, ConfirmDialog, ColorButton } from '@common/components'
import Colors from '@common/constants/Colors'
import { useAppDispatch } from '@store/hooks'
import AnalyticsService from '@services/AnalyticsService'
import { updateNode } from '@store/nodes/nodesSlice'
import { setLastUpdate } from '@store/domain/domainSlice'
import { iDomainPermissions } from '@store/domain/domain.interfaces'
import { Trans, useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import _ from 'lodash'
import * as yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { generateDetailURL } from '@helpers/utils'
import IconDelete from '@assets/images/delete.svg'
import { deleteLabel, deleteLabelWithNodes } from '@services/DomainDataService'
import useDomain from '@hooks/useDomain'
import { RouteURI } from '@common/constants/Routes'
import { VertexType } from '@gloow/navimator'
import { updateLabel } from '@services/EntityService'
// import iconEditBlue from '@assets/images/icons-edit-blue.png'

interface iLabelInfoForm {
  activeLabel?: iLabel,
  domainPermissions: iDomainPermissions | undefined,
  connectedNodes?: iNode[],
  onConnectedNodeClicked?: (item: iNode) => void
  setNodeMenu?: (props: iInteractionDialog) => void
}

const LabelInfoForm = ({
  activeLabel = undefined,
  domainPermissions = undefined,
  onConnectedNodeClicked = (item: iNode) => { },
  setNodeMenu = (props: iInteractionDialog) => { },
}: iLabelInfoForm) => {
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const form = useRef<any>(null)
  const { nodes } = useDomain()
  const [loading, setLoading] = useState<boolean>(false)
  const [connectedNodes, setConnectedNodes] = useState<iNode[]>([])
  const [removeDialog, setRemoveDialog] = useState<{ data: iNode | undefined, show: boolean }>({ show: false, data: undefined })
  const [deleteState, setDeleteState] = useState<'label_only' | 'label_with_nodes' | null>(null)
  const [tabState, setTabState] = useState<labelInfoTabTypes>(labelInfoTabTypes.LABEL_INFO)
  const { slug } = useParams<{ slug?: string }>()
  const ref = new URLSearchParams(history.location.search).get('ref') ?? ''

  const schema = yup.object({
    name: yup.string().required(t('common.name_is_required')),
    color: yup.string()
  })

  const initialValues = {
    name: activeLabel?.name ?? '',
    color: activeLabel?.color ?? Colors.default()
  }
  useEffect(() => {
    if (activeLabel) {
      form?.current.setFieldValue('name', activeLabel.name)
      form?.current.setFieldValue('color', activeLabel.color ?? Colors.default())

      // check conencted nodes
      const connectedNodes = nodes
        .filter(node => {
          // @ts-ignore
          const labels = node.labels?.filter(label => label.id === activeLabel.id)
          if (labels.length) return node
          return false
        });
      setConnectedNodes(connectedNodes)
    }
    return () => { }
    // eslint-disable-next-line
  }, [activeLabel, nodes])

  const onRemoveItem = async () => {
    try {
      const node = removeDialog.data
      if (!node) return
      setLoading(true)
      setRemoveDialog({ show: false, data: undefined })
      const NS = new Node()
      let data = {
        id: node.id,
        // @ts-ignore
        labels: node?.labels?.filter(l => l.id !== activeLabel?.id).map(l => l.id)
      }
      const updatedNode = await NS.update(data)
      setConnectedNodes(connectedNodes.filter(d => d.id !== node.id))
      dispatch(updateNode(updatedNode))
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
    } catch (e) {
      AnalyticsService.logError('update-node', { e })
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await updateLabel(activeLabel?.id!, { color: values.color, name: values.name })
      history.push(generateDetailURL(history.location.pathname, { id: activeLabel?.id!, type: contentType.LABEL }))
      dispatch(setLastUpdate(Date.now()))
      // need to reroute so query params and activeVertex always matched
      setLoading(false)
    } catch (e) {
      AnalyticsService.logError('update-label', { e })
      setLoading(false)
    }
  }

  const renderHeader = (hasChanges) => {
    return <div className={classes.titleWrapper}>
      <div className={classes.headerLeft}>
        {(Boolean(ref) || tabState === labelInfoTabTypes.COLOR_PICKER) && (
          <IconButton size='small' onClick={() => {
            if (tabState === labelInfoTabTypes.COLOR_PICKER) return setTabState(labelInfoTabTypes.LABEL_INFO)
            return history.goBack()
          }} className={classes.dialogBackArrow}>
            <ChevronLeft className={classes.arrowBackIcon} />
          </IconButton>
        )}
        <Typography className={classes.title} variant="h3">
          {tabState === labelInfoTabTypes.LABEL_INFO ? t('common.label_info') : t('common.color_picker')}
        </Typography>
      </div>
      {hasChanges && (
        <Button
          disabled={loading}
          className={classes.submit}
          variant={'contained'}
          color="primary"
          type="submit"
        >
          {t('common.save')}
        </Button>
      )}
      {!hasChanges && tabState === labelInfoTabTypes.LABEL_INFO && (
        <Button onClick={() => history.push(`/${slug}/${RouteURI.ENTITIES}/${activeLabel?.id}`)} className={'link-button underline'}>
          <Typography variant='body2'>{t('common.full_screen_view', 'Full screen view')}</Typography>
        </Button>
      )}
    </div>
  }

  const onLabelDelete = async () => {
    try {
      if (!activeLabel?.id) return
      setLoading(true)
      const nodeIds = connectedNodes?.filter(d => d.id).map(d => d.id) as number[]
      if (deleteState === 'label_only') {
        await deleteLabel(activeLabel?.id!, nodeIds)
      }
      else if (deleteState === 'label_with_nodes') {
        await deleteLabelWithNodes(activeLabel?.id!, nodeIds)
      }
      dispatch(setLastUpdate(Date.now()))
      setLoading(false)
      setDeleteState(null)
      history.replace(history.location.pathname)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('delete-label', { e })
    }
  }



  const renderDeleteDialog = () => {
    let textComponent = <></>
    if (deleteState === 'label_with_nodes') {
      textComponent = <Trans
        i18nKey="common.are_you_sure_want_to_delete_the_label_with_nodes"
        defaults="Are you sure you want to delete the label <bold>{{ labelName }}</bold> and <bold>all nodes with this label</bold>?"
        values={{ labelName: activeLabel?.name }}
        components={{ bold: <strong /> }}
      />
    }
    else if (deleteState === 'label_only') {
      textComponent = <Trans
        i18nKey="common.are_you_sure_want_to_delete_the_label"
        defaults="Are you sure you want to delete the label <bold>{{ labelName }}</bold>? this will not remove all nodes, but simply the label itself"
        values={{ labelName: activeLabel?.name }}
        components={{ bold: <strong /> }}
      />
    }
    return <ConfirmDialog
      loading={loading}
      open={Boolean(deleteState)}
      textComponent={<Typography variant='body1' align='center'>{textComponent}</Typography>}
      confirmText={`${t('common.delete')}`}
      cancelText={`${t('common.cancel')}`}
      onClose={() => setDeleteState(null)}
      onConfirm={() => onLabelDelete()}
    />
  }

  return (
    <>
      <Formik validationSchema={schema} innerRef={form} initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, errors, values, handleChange, setFieldValue }) => {
          const hasChanges = !_.isEqual(values, initialValues)
          return <form onSubmit={handleSubmit}>
            {renderHeader(hasChanges)}
            <SwipeableViews index={tabState === labelInfoTabTypes.LABEL_INFO ? 0 : 1} disabled={true} enableMouseEvents >
              <div className={classes.tab}>
                <div className={classes.container}>
                  <div className={`${classes.nameWrapper}`}>
                    <ColorButton value={values.color} onClick={domainPermissions?.modify ? () => setTabState(labelInfoTabTypes.COLOR_PICKER) : undefined} />
                    {domainPermissions?.modify ?
                      <TextField
                        disabled={loading}
                        name='name'
                        label={t('common.name')}
                        multiline
                        fullWidth
                        value={values.name}
                        className={classes.name}
                        onChange={handleChange}
                        error={Boolean(errors?.name)}
                        helperText={errors?.name?.toString()}
                      />
                      :
                      <>
                        <div className="mb-1 mt-1">
                          <label>{t('common.name')}</label>
                        </div>
                        <Typography variant={'h4'} className={classes.name}>{activeLabel?.name}</Typography>
                      </>
                    }
                  </div>

                  <div className={classes.connectedNodes}>
                    {connectedNodes.length > 0 && <Typography variant={'subtitle2'} className={classes.connectedNodesTitle}>{t('common.connected_nodes')}</Typography>}
                    {connectedNodes.map(d => <NodeCard
                      key={d.id}
                      onClick={onConnectedNodeClicked}
                      node={d}
                      hideStat={true}
                      onRemove={domainPermissions?.modify || loading ? (item) => setRemoveDialog({ show: true, data: item }) : undefined}
                    />)}
                  </div>
                  {domainPermissions?.remove && (

                    <div className={classes.actions}>
                      <Button onClick={() => setDeleteState('label_only')}>
                        <img alt='delete' src={IconDelete} className={classes.deleteIcon} />
                        <Typography variant='body2' >{t('common.delete_label', 'Delete label')}</Typography>
                      </Button>
                      {connectedNodes.length > 0 && (
                        <Button onClick={() => setDeleteState('label_with_nodes')}>
                          <img alt='delete' src={IconDelete} className={classes.deleteIcon} />
                          <Typography variant='body2' >{t('common.delete_label_and_nodes', 'Delete label + all nodes with this label')}</Typography>
                        </Button>
                      )}
                    </div>
                  )}

                  {domainPermissions?.modify &&
                    <div className={classes.connectNodeWrapper}>
                      <Button
                        className={'link-button'}
                        type="button"
                        color="primary"
                        startIcon={<Add fontSize='small' />}
                        onClick={() => {
                          setNodeMenu({
                            open: true,
                            tab: addExistingItemTypes.NODES,
                            connectTo: {
                              type: VertexType.Label,
                              data: activeLabel,
                            },
                            specificMenu: true,
                            connectedNodes: connectedNodes,
                          })
                        }}
                      >
                        <Typography variant='body2'>{t('common.connect_nodes')}</Typography>
                      </Button>
                    </div>
                  }
                </div>
              </div>

              <div className={classes.tab}>
                <ColorList
                  selectedValue={values.color}
                  onSelected={(value: string) => {
                    if (value) setFieldValue('color', value)
                    setTabState(labelInfoTabTypes.LABEL_INFO)
                  }}
                />
              </div>
            </SwipeableViews>
          </form>
        }}
      </Formik>
      {domainPermissions?.remove && renderDeleteDialog()}
      {domainPermissions?.modify && (
        <ConfirmDialog
          open={removeDialog.show}
          onClose={() => setRemoveDialog({ show: false, data: undefined })}
          onConfirm={() => onRemoveItem()}
          textComponent={<Typography variant='body2'>
            <Trans
              i18nKey="common.are_you_sure_want_to_remove_label_from_this_node"
              defaults="Are you sure you want to remove the label <bold>{{labelName}}</bold> from this node?"
              values={{ labelName: activeLabel?.name }}
              components={{ bold: <strong /> }}
            /></Typography>}
          confirmText={t('common.delete')}
          cancelText={t('common.cancel')}
        />
      )}
    </>
  )
}

export default LabelInfoForm
