import React, { useState, useCallback, useEffect, useRef } from 'react'
import { iLabel, iOntology } from "@gloow/apiconsumer"
import { Alert, Autocomplete, Color } from '@material-ui/lab'
import {
  Button,
  Grow,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  // Typography
} from '@material-ui/core'
import useStyles from './Labels.styles'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@services/AnalyticsService'
import { searchOntology, createLabel } from '@services/LabelService'
import { useAppSelector } from '@store/hooks'
import { iLabelType } from '@gloow/apiconsumer'
import { isOntology } from '@helpers/labels'
import Colors, { assignRandomColor } from '@common/constants/Colors'
import { Add, Close } from '@material-ui/icons'

interface iLabelPicker {
  disabled?: boolean,
  labelType?: iLabelType,
  selectedLabels: (iOntology | iLabel)[],
  domainUuid: string,
  nodeId?: number,
  onSelected?: (data: iLabel | iOntology) => void
}

const LabelPicker = ({
  disabled = false,
  labelType = iLabelType.NODE,
  selectedLabels = [],
  domainUuid,
  nodeId,
  onSelected = (data) => { },
}: iLabelPicker) => {
  const nodeLabels = useAppSelector(state => state.labels.NodeLabels)
  const mounted = useRef<any>(null)
  const input = useRef<any>(null)
  const { t } = useTranslation()

  const [newLabel, setNewLabel] = useState<Partial<iLabel>>()
  const [suggestions, setSuggestions] = useState<(iOntology | iLabel)[]>([])
  const [searchResult, setSearchResult] = useState<(iOntology | iLabel | Partial<iLabel>)[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [snackBar, setSnackBar] = useState<{ open: boolean, message: string, severity: Color | undefined }>({
    open: false,
    message: '',
    severity: 'success'
  })

  const sortResult = (a, b) => a.name.localeCompare(b.name)

  const classes = useStyles()

  const searchLabel = async text => {
    try {
      setLoading(true)
      let result: (iOntology | iLabel | Partial<iLabel>)[] = []
      // @ts-ignore
      const ontologyIds = selectedLabels.map(d => isOntology(d) ? d.id : d.ontologyClass).filter(d => !!d)
      const labelIds = selectedLabels.map(d => d.id).filter(d => !!d)

      if (labelType === iLabelType.DOMAIN) {
        // for domain directly search using autocomplete - exclude selected labels and ontologies
        if (text.length >= 2) result = await searchOntology(labelType, text)
        result = result?.filter(d => !ontologyIds.includes(d.id))
      } else if (labelType === iLabelType.NODE) {
        // search all local labels first - exclude selected labels
        result = nodeLabels?.filter(d =>
          d.name.toLowerCase().includes(text.toLowerCase()) &&
          !labelIds.includes(d.id) &&
          !ontologyIds.includes(d.ontologyClass)
        )
        if (!result.length && text.length >= 2) {
          result = await searchOntology(labelType, text)
          result = result?.filter(d => !ontologyIds.includes(d.id))
        }
      }
      if (text.length) result = [{ name: text, color: newLabel?.color ?? assignRandomColor(), labelType: !Array.isArray(labelType) ? labelType : iLabelType.NODE }, ...result]
      setSearchResult(result?.sort(sortResult))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      AnalyticsService.logError('label-search', { e })
    }
  }

  // eslint-disable-next-line
  const searchCb = useCallback(debounce(searchLabel, 500), [selectedLabels, newLabel?.color])

  const selectLabel = async (item: iLabel | iOntology | Partial<iLabel>) => {
    setNewLabel(undefined)
    let data = item
    if (!data.id) {
      setLoading(true)
      const newLabel = await createLabel(data as Partial<iLabel>)
      setLoading(false)
      if (!newLabel) return setSnackBar({ open: true, message: t('common.failed_to_create_new_label', 'Failed to create new label'), severity: 'error' })
      data = newLabel
    }
    if (onSelected) onSelected(data as iLabel)
    setSearchResult(searchResult.filter(d => d.uuid !== data.uuid))
    setSuggestions(suggestions.filter(d => d.uuid !== data.uuid))
  }

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!!newLabel) searchLabel('')
    return () => { }
    // eslint-disable-next-line
  }, [!!newLabel])


  useEffect(() => {
    (async () => {
      if (newLabel) setNewLabel(undefined)
    })()
    return () => { }
    // eslint-disable-next-line
  }, [labelType, domainUuid, nodeId])

  return (
    <>
      <div ref={input} className='label-picker'>
        {!!newLabel ?
          <Autocomplete
            classes={{
              root: classes.autoComplete,
              input: classes.autoCompleteInput,
              inputRoot: classes.autoCompleteInputRoot,
              endAdornment: classes.autoCompleteEndAdornment,
              popper: classes.autoCompletePopper,
              noOptions: classes.autoCompleteNoOptions,
            }}
            autoHighlight
            noOptionsText={t('common.no_labels')}
            options={searchResult}
            open={true}
            getOptionLabel={(option) => option.name ?? ''}
            getOptionSelected={(option, value) => value.id === newLabel?.id}
            onInputChange={(e, value) => {
              setNewLabel({ ...newLabel, name: value })
              if (e.type !== 'click') searchCb(value)
            }}
            onChange={(e, value) => {
              if (!value) return
              selectLabel(value)
            }}
            renderInput={(params) => (
              <>
                <TextField autoFocus placeholder={t('common.type', 'Type...')} variant='outlined' {...params} style={{ backgroundColor: newLabel.color ?? Colors.default() }} />
                <IconButton disabled={loading} className={classes.autoCompleteClose} size='small' onClick={() => setNewLabel(undefined)}><Close className={classes.autoCompleteCloseIcon} fontSize='small' /></IconButton>
              </>
            )}
          />
          :
          <Button disabled={disabled} onClick={() => setNewLabel({ name: '', color: Colors.random() })} className={classes.addButton}>
            <Add className={classes.addIcon} />
            <Typography variant='body2' >{t('common.add_label', 'Add label')}</Typography>
          </Button>
        }
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBar.open}
        TransitionComponent={Grow}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
        autoHideDuration={3000}
      >
        <Alert variant={'filled'} onClose={() => setSnackBar({ ...snackBar, open: false })} severity={snackBar.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default LabelPicker
