import React, { useCallback } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadContainer: {
      width: '100%',
      height: 320,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: 12,
      border: '1px dashed',
      borderColor: theme.palette.primary.main,
      fontSize: 16,
      '&:focus': {
        outline: 'none !important'
      }
    },
    uploadDrop: {
      opacity: 0.7
    },
    uploadButton: {
      margin: 15,
      width: 180,
      height: 40
    },
    uploadNotes: {
      opacity: 0.6,
      fontSize: 12
    }
  }),
);

interface IDropzone {
  onDropFiles?: (files) => void,
  accept?: string
}

const DropZone = ({
  onDropFiles = (files) => { },
  accept = ''
}: IDropzone) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const onDrop = useCallback(acceptedFiles => {
    onDropFiles(acceptedFiles)
  }, [onDropFiles])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept })

  return (
    <>
      <div id="dropzone-container" className={`${classes.uploadContainer} ${isDragActive ? classes.uploadDrop : ''}`} {...getRootProps()}>
        <input id="dropzone-input" {...getInputProps()} />

        <p>{t('common.drag_your_file_here')}</p>
        <p>{t('common.or')}</p>
        <Button id="dropzone-browse" className={classes.uploadButton} color="primary" variant="outlined" size="large">{t('common.browse')}...</Button>
        <p className={classes.uploadNotes}>{t('common.upload_size_limit')}</p>
      </div>
    </>
  );
}

export default DropZone;