import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  name: {
    marginBottom: 16,
    '& .MuiFormLabel-root': {
      fontSize: 30,
      fontWeight: 500,

      '&.Mui-focused, &.MuiInputLabel-shrink': {
        fontSize: 20
      }
    },
    '& ,.MuiInputBase-input': {
      fontSize: 30
    }
  },
  cancelButton: {
    boxShadow: "0 6px 12px 0 rgba(67, 84, 221, 0.08)",
    color: theme.palette.primary.main
  },
  label: {
    margin: '8px 0px'
  },
  actionContainer: {
    display: 'flex',
    gap: 16,
    marginTop: 24
  },
  horizontalContainer: { display: 'flex', flexWrap: 'wrap', gap: 50 },
  horizontalRight: { padding: 0, flex: 1 },
  horizontalLeft: { padding: 0, flex: 1 },
  mb16: { marginBottom: 16 }
}));
