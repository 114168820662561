import React, { useEffect, useState } from 'react'
import { DomainCard } from '@common/components'
import { Box, Grid } from '@material-ui/core'
import { FetchCollaborationDomains } from '@services/DomainDataService'
import { Domain, iDomainNew } from "@gloow/apiconsumer"
import { useAppDispatch, useAppSelector } from '@store/hooks'
import jwtDecode from 'jwt-decode'
import { iDomainPermissions } from '@store/domain/domain.interfaces'
import { setLoading } from '@store/helpers/helpersSlice'

interface IDomains {
  domainsFilter: any
  handleEditDomain: (domain: iDomainNew) => void
  handleDeleteDomain: (domain: iDomainNew) => void
  handlePermissionsDomain: (domain: iDomainNew) => void
  onDomainClick: (domain: iDomainNew) => void
  renderEmptyDomain: any
}

const Collaboration = ({
  domainsFilter,
  handleEditDomain,
  handleDeleteDomain,
  handlePermissionsDomain,
  onDomainClick,
  renderEmptyDomain
}: IDomains) => {
  const dispatch = useAppDispatch()
  const domains: iDomainNew[] = useAppSelector(state => domainsFilter(state.domain.collaborationDomains, state.filters.searchText))
  const collaborations = useAppSelector(state => state.domain.collaborationDomains)
  const [domainPermissions, setDomainPermissions] = useState<iDomainPermissions[]>([])

  useEffect(() => {
    (async () => {
      try {
        if (!domains.length) {
          dispatch(setLoading(true))
          await FetchCollaborationDomains()
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
      }
    })()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    FetchCollaborationDomainsPermissions()
    // eslint-disable-next-line
  }, [collaborations])

  const FetchCollaborationDomainsPermissions = async () => {
    const domainPermissionsData: iDomainPermissions[] = []
    const DomainService = new Domain()

    if (!domainPermissions.length && collaborations.length) {
      collaborations.forEach(async (item: iDomainNew) => {
        const domainToken = await DomainService.openDomain(item.uuid)
        const decodedDomainToken: iDomainPermissions = jwtDecode(domainToken.domainToken)
        domainPermissionsData.push(decodedDomainToken)
      });

      setTimeout(() => {
        setDomainPermissions(domainPermissionsData)
      }, 1000)
    }
  }

  const getDomainPermission = (domainUuid: string) => {
    const permission = domainPermissions.filter((domain: iDomainPermissions) => domain.domainUuid === domainUuid)
    return permission[0]
  }

  return (
    <>
      <Box mt={3}>
        {domains?.length ? (
          <Grid container spacing={3}>
            {domains?.map(domain => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={domain.id}>
                <DomainCard
                  domain={domain}
                  domainPermissions={domainPermissions.length ? getDomainPermission(domain.uuid) : undefined}
                  handleEditDomain={() => handleEditDomain(domain)}
                  handleDeleteDomain={() => handleDeleteDomain(domain)}
                  handlePermissionsDomain={() => handlePermissionsDomain(domain)}
                  handeClickDomain={() => onDomainClick(domain)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          renderEmptyDomain()
        )}
      </Box>
    </>
  )
}

export default Collaboration
