import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import listIcon from '@assets/images/icons-list.png'
import mapIcon from '@assets/images/icons-map.png'
import AnalyticsService from "@services/AnalyticsService";
import { analyticsEventCategory } from "@gloow/apiconsumer";
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapIconButton: {
      background: theme.palette.background.paper,
      height: 35,
      padding: '5px 12px'
    },
    mapListIcon: {
      marginRight: 10,
      width: 20,
    }
  }),
);

const MapListSwitcher = ({ className = '' }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  // @ts-ignore
  const { slug } = useParams()
  const explorerView = location.pathname.includes('mind-maps') ?? false

  const clickSwitcher = () => {
    history.push(explorerView ? `/${slug}/grid/connections` : `/${slug}/mind-maps`);
    AnalyticsService.createUserEvent(analyticsEventCategory.USER_EVENT, 'switchedBetweenMapView', { switchedTo: explorerView ? 'connections' : 'explorer' });
  }

  return (
    <Button className={`${className} ${classes.mapIconButton}`} onClick={clickSwitcher}>
      <img className={classes.mapListIcon} src={explorerView ? listIcon : mapIcon} alt="maplist" />
      {explorerView ? t('common.list') : t('common.map')}
    </Button>
  );
}

export default MapListSwitcher
