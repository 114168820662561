import { iSearchResult, Search } from "@gloow/apiconsumer";
import { useCallback, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import * as _ from 'lodash'

import { updateSearchText } from "@store/filters/filtersSlice";

export interface iGroupedSearchResult {
  type: string;
  data: iSearchResult[];
}

// this make user enable to use arrow on keyboard to choose suggestion
export interface iSearchResultExtended extends iSearchResult {
  index: number;
}

export function useAutocomplete(search, onResultFound) {
  let _search: Search;

  const [loading, setLoading] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [results, setResults] = useState<iGroupedSearchResult[]>([]);
  const [resultFlat, setResultFlat] = useState<iSearchResultExtended[]>([]);
  const [hidden, setHidden] = useState<boolean>(false);
  // const dispatch = useDispatch();

  // eslint-disable-next-line
  const performSearch = useCallback(
    _.debounce(async (text) => {
      setHidden(false);

      // dispatch update search text
      updateSearchText(search)

      if (text.length < 2) {
        setLoading(false);
        setBusy(false);
        console.log("Not enough chars", text);
        return;
      }

      if (!_search) {
        _search = new Search();
      }

      setLoading(true);
      // Got from APIConsumer
      const data: any[] = await _search.autocomplete(text);

      if (onResultFound) onResultFound(data);

      const flat = data
        .sort((a, b) => a.type.localeCompare(b.type))
        .map((d, idx) => ({ ...d, index: idx }));

      setResultFlat(flat);

      const grouped = _.chain(flat)
        .groupBy("type")
        .map((value, key) => {
          return {
            type: key,
            data: value,
          };
        });

      const finalResults = Array.from(grouped.value());

      setBusy(false);
      setResults(finalResults);
      setLoading(false);
    }, 500),
    []
  );

  const onDone = () => {
    setResults([]);
    setLoading(false);
    setHidden(true);
  };

  useEffect(() => {
    setBusy(true)
    performSearch(search);
    return () => {};
    // eslint-disable-next-line
  }, [search]);

  return { results, resultFlat, onDone, loading, busy, hidden };
}
