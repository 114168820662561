import React from 'react'
import useStyles from './index.styles'
import { Typography, Card, ButtonBase } from '@material-ui/core'
import Settings from './Settings'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Subscription from './Subscription'

const Account = () => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  // @ts-ignore
  const { slug } = useParams()
  const domainSlug = slug ? slug + '/' : ''
  const routes = [
    { name: t('common.account'), exact: true, path: `/${domainSlug}account`, component: Settings },
    { name: t('common.subscription'), exact: true, path: `/${domainSlug}account/subscription`, component: Subscription }
  ]
  return (
    <div className={classes.container}>
        <Typography variant={'h2'} className={classes.accountTitle}>{t('common.account_settings')}</Typography>
        <div className={classes.tabs}>
          {routes.map((d) =>
            <ButtonBase
              key={d.path}
              className={classes.tab + ' ' + (history.location.pathname === d.path ? classes.tabActive : '')}
              onClick={() => history.push(d.path)}>{d.name}
            </ButtonBase>
          )}
        </div>
        <Card className={classes.containerCard}>
          <Switch>
            {routes.map(route => <Route key={route.path} {...route} />)}
          </Switch>
        </Card>
      </div>
  )
}

export default Account

