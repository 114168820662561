import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import grey from '@material-ui/core/colors/grey';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Colors from '@common/constants/Colors';
import { decamelCase } from '@helpers/utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  dialogTitle: {
    paddingTop: 20,
    paddingBottom: 5
  },
  dialogPaper: {
    borderRadius: 12
  },
  dialogBackIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: -2,
    marginRight: 3
  },
  color: {
    width: 24,
    height: 24,
    borderRadius: 8,
    marginRight: 10
  },
  colorName: {
    textTransform: 'capitalize',
    minWidth: 280
  },
  colorItem: {
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,

    '& + &': {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 20,
        right: 20,
        top: 0,
        borderTop: '1px solid',
        borderColor: grey[300]
      }
    }
  }
});

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  backButton?: boolean
}

function ColorDialog({ onClose, selectedValue, open, backButton = true }: SimpleDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation()

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle className={classes.dialogTitle}>
        {backButton && <ArrowBackIosIcon className={classes.dialogBackIcon} onClick={() => handleClose()} />}
        {t('common.color', 'Color')}
      </DialogTitle>
      <List>
        {Object.keys(Colors.newColors).map((color, i) => (
          <ListItem className={classes.colorItem} button onClick={() => handleListItemClick(color)} key={color}>
            <div
              className={classes.color}
              style={{
                backgroundColor: Colors.newColors[color]
              }}
            ></div>
            <ListItemText className={classes.colorName} primary={decamelCase(color)} />
            {(selectedValue === color || selectedValue === Colors.newColors[color]) && <CheckIcon color="primary" />}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default ColorDialog;
