// import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { iLabel, iNode, iProperty } from "@gloow/apiconsumer"
import React, { useState } from 'react'
// import { useTranslation } from 'react-i18next'
import useStyles from './EntityCSVImport.styles'
// import IconImport from '@assets/images/icons-import.svg'
import {
  // ColorButton, ConfirmDialog,
  DropZone
} from '@common/components'
// import { Add } from '@material-ui/icons'
// import Column from '../EntityTable/Column/Column'
// import Cell from '../EntityTable/Cell/Cell'
// import ColumnMenu from '../EntityTable/ColumnMenu/ColumnMenu'
import { uploadCSV } from '@helpers/media'
import AnalyticsService from '@services/AnalyticsService'
import TheLoader from '@common/components/TheLoader/TheLoader'
// import Colors from '@common/constants/Colors'

const EntityCSVImport = ({
  label,
  labels = [],
  initialColumns = [],
  onCancel = () => { },
  onDone = (nodes: iNode[]) => { }
}: {
  label?: iLabel,
  labels: iLabel[],
  initialColumns?: iProperty[],
  onCancel: () => void,
  onDone: (nodes: iNode[]) => void
}) => {
  // const { t } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  // const [columns, setColumns] = useState<iProperty[]>([])
  // const [rows, setRows] = useState<iProperty[][]>([])
  // const [columnMenu, setColumnMenu] = useState<any>({
  //   anchor: null,
  //   data: null
  // })
  // const [deleteProp, setDeleteProp] = useState<iProperty | null>(null)


  const onCSVReceived = async (files) => {
    if (!label) {
      window.alert('@todo: add error message, label needs to be available first')
      return
    }

    try {
      setLoading(true)

      if (files.length) {
        // let promises: any = []

        // files.forEach(item => {
        //   promises.push(uploadCSV(item, label?.id))
        // });

        // Import should only support 1 file no? @joshua
        const result = await uploadCSV(files[0], label?.id)

        setLoading(false)
        // setColumns(initialColumns)
        // @ts-ignore
        onDone(result.nodes)
      }


    } catch (error) {
      console.log(error)
      AnalyticsService.logError('import-csv-error', { error });
      setLoading(false)
    }
  }


  // const onCellChange = (prop, rowIndex, cellIndex) => {
  //   const updatedRows = [...rows]
  //   updatedRows[rowIndex][cellIndex] = prop
  //   setRows(updatedRows)
  // }

  // const onRowAdd = () => {
  //   const newRow = columns.map(d => d)
  //   setRows([...rows, newRow])
  // }

  // const renderRow = () => {
  //   return rows.map((row, rIndex) => <TableRow key={rIndex.toString()}>
  //     {columns.map((columnProp, cIndex) => {
  //       const prop = row.find(cell => columnProp.key === cell.key)
  //       return (
  //         <Cell
  //           key={`${rIndex.toString()}-${cIndex.toString()}`}
  //           labels={labels}
  //           // @ts-ignore
  //           property={prop ?? columnProp}
  //           onSave={(property) => onCellChange(property, rIndex, cIndex)}
  //         />
  //       )
  //     })}
  //   </TableRow>
  //   )
  // }

  // const onColumnChange = (prop, columnIndex) => {
  //   // const keyExist = columns.find(d => d.key === prop.key)
  //   // if (keyExist) return
  //   const updatedColumns = [...columns]
  //   updatedColumns[columnIndex] = { ...prop, editMode: false }
  //   setColumns(updatedColumns)
  // }

  // const onColumnAdd = (column) => {
  //   const updatedColumns = [...columns, column]
  //   setColumns(updatedColumns)
  // }

  // const onColumnDelete = (prop) => {
  //   const updatedColumns = columns.filter(d => d.key !== prop.key)
  //   console.log(updatedColumns)
  //   setColumns(updatedColumns)
  // }

  // const onSubmit = () => {
  //   setLoading(true)
  //   console.log(rows)
  //   setLoading(false)
  // }

  // const onEdit = (data, index) => {
  //   const cols = [...columns]
  //   // @ts-ignore
  //   cols[index] = { ...cols[index], editMode: true }
  //   setColumns(cols)
  // }

  if (loading) return <TheLoader />

  return (
    <>
      {/* {rows.length > 0 || columns.length > 0 ? (
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((d, cIndex) => <Column
                  error={Boolean(cIndex % 2)}
                  errorMessage={'Column mismatch'}
                  key={cIndex.toString()}
                  // @ts-ignore
                  data={d}
                  onEdit={(data) => onEdit(data, cIndex)}
                  onClick={(e) => setColumnMenu({ anchor: e.target, data: d })}
                  disabled={loading}
                  onChange={(prop) => onColumnChange(prop, cIndex)}
                  onDelete={(prop) => setDeleteProp(prop)}
                />)}
                <Column
                  disabled={loading}
                  // @ts-ignore
                  data={{ key: 'add', id: null, value: '', editable: true }}
                  onClick={(e) => setColumnMenu({ anchor: e.target, data: null })}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRow()}
              <TableRow>
                <TableCell className={classes.addCell}>
                  <Button disabled={loading} onClick={onRowAdd} className={classes.addButton}><Add className={classes.addButtonIcon} /> New Row</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : ( */}
      <div className={classes.dropZoneContainer}>
        <DropZone onDropFiles={onCSVReceived} accept='.csv' />
      </div>
      {/* )} */}
      {/* <ColumnMenu
        labels={[]}
        anchorEl={columnMenu.anchor}
        onClose={() => setColumnMenu({ anchor: null, data: null })}
        onClick={(target) => {
          onColumnAdd({ ...newColumn, ...target })
          setColumnMenu({ anchor: null, data: null })
        }}
      /> */}
      {/* <ConfirmDialog
        open={Boolean(deleteProp)}
        onConfirm={() => {
          onColumnDelete(deleteProp!)
          setDeleteProp(null)
        }}
        onClose={() => setDeleteProp(null)}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
        text={'Are you sure want to delete this column?'}
      /> */}
    </>
  )
}

export default EntityCSVImport
