import React from 'react'
import { TextField, Typography } from '@material-ui/core'
import useStyles from './OnboardingV2.styles'
import { useTranslation } from 'react-i18next'
const CreateDomain = ({
  title = '',
  description = '',
  domain,
  setDomain,
  error
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div>
      <Typography className={classes.dialogTitle} variant="h3">
        {title}
      </Typography>
      <TextField
        className={classes.name}
        label={t('common.name')}
        fullWidth
        value={domain.name}
        onChange={(e) => setDomain({ ...domain, name: e.target.value })}
        error={domain.name === '' && error !== ''}
        helperText={domain.name === '' && error !== '' ? error : ''}
      />
      <p className="mb-4">{description}</p>
    </div>
  )
}

export default CreateDomain
