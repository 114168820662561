import React, { useState, useEffect } from 'react'
import useStyles from './index.styles'
import { Typography, TextField, Button, Snackbar, Grow } from '@material-ui/core'
import { uploadAvatar } from '@helpers/media'
import { DropAvatar, ChangePasswordDialog } from '@common/components'
import { useAppSelector } from '@store/hooks'
import { updateUserMetaData } from '@services/AuthService'
import { Alert, Color } from '@material-ui/lab'
import AnalyticsService from "@services/AnalyticsService";
import { useTranslation } from 'react-i18next'

type AccountField = {
  name?: string
  email?: string
}

const Account = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useAppSelector(state => state.user.user)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [url, setUrl] = useState('')
  const account: AccountField = {
    name: !user?.user_metadata?.name ? user?.nickname ?? '' : user?.user_metadata?.name,
    email: user?.email ?? ''
  }

  useEffect(() => {
    setAccountData(account)
    if (user?.user_metadata?.picture) setUrl(user.user_metadata?.picture)
    else {
      if (user?.picture) setUrl(user?.picture)
    }
    return () => { }
    // eslint-disable-next-line
  }, [user])

  const [accountData, setAccountData] = useState<AccountField>(account)
  const [snackBar, setSnackBar] = useState<{ open: boolean, message: string, severity: Color | undefined }>({
    open: false,
    message: '',
    severity: 'success'
  })

  const onDropFiles = async (files) => {
    setLoading(true)

    try {
      setUrl(URL.createObjectURL(files[0]))
      const uploadStatus = await uploadAvatar(files[0])
      if (uploadStatus) {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      AnalyticsService.logError('change-avatar-error', { error });

      setLoading(false)
      setSnackBar({ open: true, message: t('common.something_wrong_with_the_server'), severity: 'error' })
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const res = await updateUserMetaData({ ...user?.user_metadata, name: accountData.name })
      if (res) {
        setLoading(false)
        setSnackBar({ open: true, message: t('common.account_succesfully_updated'), severity: 'success' })
      }
    } catch (error) {
      setSnackBar({ open: true, message: t('common.something_wrong_with_the_server'), severity: 'error' })
      AnalyticsService.logError('update-user-profile-error', { error });
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <>
      <Typography variant={'h5'} className={classes.tabTitle}>{t('common.account')}</Typography>
      <div className={classes.formContainer}>
        <DropAvatar onDropFiles={onDropFiles} url={url} />
        <div className={classes.inputWrapper}>
          <div className={classes.inputContainer}>
            <TextField
              label={t('common.name')}
              fullWidth
              value={accountData.name}
              onChange={(e) => setAccountData({ ...accountData, name: e.target.value })}
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              label={t('auth.email')}
              disabled
              fullWidth
              value={accountData.email}
              onChange={(e) => setAccountData({ ...accountData, email: e.target.value })}
            />
          </div>

          <div className={classes.actions}>
            <Button
              disabled={loading}
              className={classes.saveBtn}
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
            >
              {t('common.save')}
            </Button>
            <Button
              disabled={loading}
              className={classes.changePassBtn}
              variant="contained"
              color="primary"
              onClick={() => setShowChangePassword(true)}
            >
              {t('auth.change_password')}
            </Button>
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBar.open}
        TransitionComponent={Grow}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
        autoHideDuration={3000}
      >
        <Alert variant={'filled'} onClose={() => setSnackBar({ ...snackBar, open: false })} severity={snackBar.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>

      <ChangePasswordDialog
        open={showChangePassword}
        onClose={(isSuccessChangePassword) => {
          setShowChangePassword(false)
          if (isSuccessChangePassword) {
            setSnackBar({ open: true, message: 'Password succesfully updated.', severity: 'success' })
          }
        }}
      />
    </>
  )
}

export default Account
