import Colors from '@common/constants/Colors'
import { makeStyles, Theme } from '@material-ui/core/styles'
export default makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
  },
  contentForm: {
    padding: '8px 16px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    '& .MuiButton-root': {
      padding: '8px 8px !important',
    }
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-center',
    margin: '8px 0px',
  },
  name: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: 1,
      paddingTop: 4
    },
    '& .MuiInputLabel-shrink': {
      fontSize: 16
    }
  },
  info: {
    lineHeight: 1.3,
    fontWeight: 'normal',
    wordBreak: 'break-word'
  },
  submit: {
    '&:disabled': {
      zIndex: 1000
    },
    '& .MuiButton-label': {
      color: 'white'
    }
  },
  imageButton: {
    marginTop: 12,
    borderRadius: 12,
    width: '100%',
    overflow: 'hidden'
  },
  imageItem: {
    borderRadius: 12,
    width: '100%',
    objectFit: 'cover'
  },
  thumbnailWrapper: {
    width: 72,
    height: 72,
    minWidth: 72,
    borderRadius: 12,
    marginRight: 16,
    overflow: 'hidden'
  },
  thumbnail: {
    width: 72,
    height: 72,
    objectFit: 'cover'
  },
  createdAt: {
    marginTop: 16,
    fontWeight: 400,
    color: Colors.darkGrey
  },
  deleteButton: {
    boxShadow: 'none !important',
    margin: '16px 0px'
  },
  deleteNode: {
    color: '#d50001'
  },
  deleteIcon: {
    marginRight: 8,
    width: 18,
    height: 18,
    marginLeft: -3
  },
  connectedNodesTitle: {
    fontWeight: 600,
    marginTop: 24,
    marginBottom: 16
  },
  connectedNodes: {
    '& .MuiButtonBase-root.node-card': {
      marginTop: 16,
      borderRadius: '12px !important',
      boxShadow: '0 6px 12px 0 rgba(67, 84, 221, 0.15)',
      minHeight: 'unset',
      width: '100%'
    }
  },
  connectToNode: {
    marginTop: 16
  },
  video: {
    width: '100%',
    height: 200
  },
  iframe: { width: '100%', height: 190, borderRadius: 12 },
  backIcon: {
    color: Colors.black,
    fontSize: 24
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  linkIcon: {
    height: 16,
    marginRight: 6
  },
  link: {
    color: Colors.primary,
    width: '70%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal'
  },
  label: {
    margin: '16px 0px',
    marginBottom: 8,
    fontWeight: 600
  }
}))