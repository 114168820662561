import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Colors, { assignRandomColor } from '@common/constants/Colors'
import { NodeThumbnailList } from '@common/components'
import { Card, CardActionArea, IconButton, Menu, MenuItem } from '@material-ui/core'
import { iDomainNew } from "@gloow/apiconsumer"

import MoreVertIcon from '@material-ui/icons/MoreVert'
import editIcon from '@assets/images/icons-edit-blue.png'
import deleteIcon from '@assets/images/delete.svg'
import settingIcon from '@assets/images/icons-setting.svg'
import { iDomainPermissions } from '@store/domain/domain.interfaces'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCard: {
      padding: 0,
      borderRadius: 12,
      background: theme.palette.background.paper,
      boxShadow: '0 4px 12px 0 rgba(67, 84, 221, 0.05)',
      position: 'relative'
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      padding: '30px 55px',
      paddingRight: 36,
      borderRadius: 12,
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: 240,
      position: 'relative',
      cursor: 'pointer'
    },
    domainEditButton: {
      position: 'absolute',
      right: 20,
      top: 30,
      zIndex: 10
    },
    domainLine: {
      display: 'block',
      position: 'absolute',
      width: 8,
      height: 40,
      borderRadius: 6,
      backgroundColor: assignRandomColor(),
      top: 38,
      left: 25
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    nodeName: {
      color: Colors.primary,
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 5
    },
    labels: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      marginBottom: 5,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '20rem'
    },
    descriptions: {
      fontSize: 16,
      marginBottom: 10,
      minHeight: 45
    },
    stat: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      display: 'flex',
      marginBottom: 15
    },
    statItem: {
      marginRight: 10
    },
    statCount: {
      color: Colors.primary,
      marginRight: 3
    },
    domainMenu: {
      '& .MuiPaper-root': {
        minWidth: 150
      },
      '& li img': {
        marginRight: 8
      }
    }
  }),
);

interface IDomainCard {
  domain?: iDomainNew
  domainPermissions?: iDomainPermissions
  publicDomain?: boolean
  isOwner?: boolean
  handeClickDomain?: () => void
  handleEditDomain?: () => void
  handlePermissionsDomain?: () => void
  handleDeleteDomain?: () => void
}

const DomainCard = ({
  domain,
  domainPermissions,
  isOwner = false,
  handeClickDomain = () => undefined,
  handleEditDomain = () => undefined,
  handlePermissionsDomain = () => undefined,
  handleDeleteDomain = () => undefined,
  publicDomain = false
}: IDomainCard) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const [domaineAnchorEl, setDomainAnchorEl] = useState<null | HTMLElement>(null)
  const isDomainMenuOpen = Boolean(domaineAnchorEl)

  const getStatValue = key => {
    return domain ? domain[key === 'connections' ? 'relations' : key] ?? 0 : 0
  }

  const renderLabel = () => {
    const labelText = (domain?.labels ?? []).map(l => l.name).join(', ')

    return (
      <div className={classes.labels}>
        {domain?.labels?.length !== 0 ? labelText : 'No Labels'}
      </div>
    )
  }

  const renderDomainMenu = (
    <Menu
      className={classes.domainMenu}
      anchorEl={domaineAnchorEl}
      id={'domain-menu'}
      keepMounted
      open={isDomainMenuOpen}
      onClose={() => setDomainAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {(isOwner || domainPermissions?.modify) ? <MenuItem onClick={handleEditDomain}><img src={editIcon} alt="edit" width="20" /> {t('common.edit')}</MenuItem> : null}
      {isOwner && <MenuItem onClick={handlePermissionsDomain}><img src={settingIcon} alt="edit" width="20" /> {t('common.users_and_permissions')}</MenuItem>}
      {isOwner && <MenuItem onClick={handleDeleteDomain}><img src={deleteIcon} alt="delete" width="20" /> {t('common.delete')}</MenuItem>}
    </Menu >
  );

  return (
    <Card className={classes.containerCard}>
      {!publicDomain && isOwner &&
        <IconButton className={classes.domainEditButton} color="primary" onClick={(e) => setDomainAnchorEl(e.currentTarget)}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      }
      {renderDomainMenu}
      <CardActionArea className={classes.container} onClick={handeClickDomain}>
        <div className={classes.domainLine} style={{ backgroundColor: Colors.random() }}></div>
        <div className={classes.textWrapper}>
          {/* Domain Title */}
          <div className={classes.nodeName}>
            {domain?.name}
          </div>

          {/* Labels */}
          <div>{renderLabel()}</div>

          {/* Descriptions */}
          <div className={classes.descriptions}>
            {domain?.description && domain.description.length > 75 ? `${domain?.description.substring(0, 75)}...` : domain?.description}
          </div>

          {/* Stats */}
          <div className={classes.stat}>
            {['nodes', 'relations', 'resources'].map((stat, key) => (
              <div className={classes.statItem} key={key}>
                <span className={classes.statCount}>{getStatValue(stat)}</span>
                {t(`common.${stat}`).toLowerCase()}
              </div>
            ))}
          </div>

          {/* @ts-ignore */}
          <NodeThumbnailList nodes={domain?.lastOpenedNodes ?? []} noMargin={true} />
        </div>
      </CardActionArea>
    </Card>
  );
}

export default DomainCard;
