import React, { MouseEvent, RefObject } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react'
import { Editor, Path, Transforms } from 'slate'
import { useTranslation } from 'react-i18next'
import Colors from '@common/constants/Colors'
import { useEffect } from 'react'
import { useRef } from 'react'
import { validURL } from '@helpers/utils'
import { isEqual } from 'lodash'

const minWidth = 70
const useStyles = makeStyles((theme) => ({
  container: {},
  innerContainer: {
    position: 'relative',
    margin: 0
  },
  image: {
    display: 'inline-block',
    height: '100%',
    minWidth: minWidth,
    userSelect: 'none',
    transition: 'all ease 0.1s',
  },
  delete: {
    position: 'absolute',
    top: '0.5em',
    left: '0.5em',
    backgroundColor: 'white !important'
  },
  input: {
    marginTop: 2,
    textAlign: 'center',
    outline: 'none',
    fontSize: 14,
    width: '100%',
    resize: 'none',
    padding: '4px 0px',
    '&:focus': {
      backgroundColor: Colors.secondaryStroke
    }
  },
  imageResizeable: {
    position: 'relative',
    height: '100%',
    flexShrink: 0,
    minWidth: minWidth,
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
  },
  resize: {
    cursor: 'col-resize',
    position: 'absolute',
    height: '100%',
    width: 5,
    top: 0,
    '&::before': {
      position: 'absolute',
      margin: 'auto 0',
      top: 0,
      bottom: 0,
      content: '""',
      width: 5,
      backgroundColor: Colors.greyLabel,
      borderRadius: 12,
      height: '50%',
    }
  },
  resizeL: {
    left: -10,
    '&::before': {
      left: 0,
    }
  },
  resizeR: {
    right: -10,
    '&::before': {
      right: 0,
    }
  }
}))

const Image = ({ attributes, children, element, readOnly }) => {
  const editor = useSlateStatic()
  const classes = useStyles()
  const { t } = useTranslation()
  const selected = useSelected()
  const focus = useRef<'caption' | 'image' | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const path = ReactEditor.findPath(editor as ReactEditor, element)
  const x = useRef<number>(0)
  const handlerId = useRef<string>('')

  const onCaptionChange = (e) => {
    Transforms.setNodes(editor, {
      ...element,
      caption: e.target.value,
      children: [{ text: e.target.value }]
    }, { at: path })
  }

  const onImageLoad = (e) => {
    Transforms.setNodes(editor, {
      ...element,
      width: e.currentTarget.clientWidth
    }, { at: path })
  }

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    x.current = e.clientX
    handlerId.current = e.currentTarget.id
    document.addEventListener('mousemove', onResize)
    document.addEventListener('mouseup', onMouseUp)
  }

  const onMouseUp = (e) => {
    document.removeEventListener('mousemove', onResize)
  }

  const onResize = (e) => {
    const dx = e.clientX - x.current
    const width = handlerId.current === 'left' ? element.width - dx : element.width + dx
    if (width <= minWidth) return
    Transforms.setNodes(editor, { ...element, width }, { at: path })
  }

  useEffect(() => {
    return () => { document.removeEventListener('mousemove', onResize) }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selected && isEqual(editor.selection?.focus, editor.selection?.anchor)) {
      focus.current = 'image'
      // inputRef.current?.focus()
      document.addEventListener('keydown', onKeyDown)
    }
    else {
      focus.current = null
      document.removeEventListener('keydown', onKeyDown)
    }
    return () => document.removeEventListener('keydown', onKeyDown)
    // eslint-disable-next-line
  }, [selected])

  const onKeyDown = (e) => {
    if (['ArrowUp'].includes(e.key)) {
      if (focus.current === 'caption') {
        e.preventDefault()
        focus.current = 'image'
        inputRef.current?.blur()
        ReactEditor.focus(editor as ReactEditor)
        Transforms.select(editor, {
          focus: { path: editor.selection?.focus.path!, offset: 0 },
          anchor: { path: editor.selection?.anchor.path!, offset: 0 },
          path: editor.selection?.focus.path!,
          offset: 0
        })
      }
    }
    else if (['ArrowDown'].includes(e.key)) {
      if (focus.current === 'image') {
        e.preventDefault()
        focus.current = 'caption'
        inputRef.current?.focus()
      }
      else if (focus.current === 'caption') {
        e.preventDefault()
        if (!editor.selection) return
        ReactEditor.focus(editor as ReactEditor)
        const nextPath = Path.next(Path.parent(editor.selection.anchor.path))
        if (Editor.hasPath(editor, nextPath)) Transforms.select(editor, { path: nextPath.concat(0), offset: 0 })
      }
    }
    else if (['Enter'].includes(e.key)) {
      inputRef.current?.blur()
      e.preventDefault()
      if (!editor.selection) return
      ReactEditor.focus(editor as ReactEditor)
      Editor.insertBreak(editor)
    }
  }

  return (
    <div {...attributes} style={{ textAlign: 'center' }} className={classes.container}>
      {children}
      {!element.url && <Typography variant={'body2'}>{t('common.uploading', 'Uploading...')}</Typography>}
      {validURL(element.url) &&
        <figure contentEditable={false} className={classes.innerContainer}>
          <div className={classes.imageResizeable} style={{ width: element.width ?? '40%' }}>
            <img
              src={element.url}
              className={classes.image}
              alt={element.caption ?? element.value?.title}
              onLoad={onImageLoad}
              style={{
                width: element.width ?? '100%',
                boxShadow: selected ? `0 0 0 3px ${Colors.primary}` : 'none'
              }}
            />
            {!readOnly &&
              <>
                <div className={`${classes.resize} ${classes.resizeL}`} id='left' onMouseDown={onMouseDown}></div>
                <div className={`${classes.resize} ${classes.resizeR}`} id='right' onMouseDown={onMouseDown}></div>
              </>
            }
          </div>
          <figcaption className={classes.input}>
            {selected && isEqual(editor.selection?.focus, editor.selection?.anchor) ?
              <input
                ref={inputRef as RefObject<HTMLInputElement>}
                className={classes.input}
                defaultValue={element.caption ?? ''}
                placeholder={t('common.write_a_caption', 'Write a caption')}
                onChange={onCaptionChange}
              />
              :
              <span>{element.caption ?? ''}</span>
            }
          </figcaption>
        </figure>
      }
    </div>
  )
}

export default Image
