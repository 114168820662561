// eslint-disable-next-line
import React, { useCallback } from 'react'
import { contentType } from '@common/constants/Constants'
import { VertexType, VisualisationMode } from '@gloow/navimator'
import { iActiveVertex, iDomainData } from '@store/domain/domain.interfaces'
import { useAppSelector } from '@store/hooks'
import { useLocation } from 'react-router-dom'
import { createNodeList, getResourcesForActiveNode, getConnectedNodesForActiveNode } from '@selectors/index'
import { RouteURI } from '@common/constants/Routes'

const useDomain = () => {
  const location = useLocation()
  const id = new URLSearchParams(location.search).get('id') ?? ''
  const type = new URLSearchParams(location.search).get('type') ?? ''
  const [nodes, labels, resources, groupByLabel] = useAppSelector(state => [
    createNodeList(state),
    state.labels.NodeLabels,
    state.resources.resources,
    location.pathname.includes(RouteURI.FOCUS_MODE) ? state.filters.groupByLabelFocusMode : state.filters.groupByLabelMindMaps
  ])

  const getActiveVertex = useCallback(({ nodes, resources, labels }): iActiveVertex => {
    const mode = groupByLabel ? VisualisationMode.LABEL_TREE : VisualisationMode.DEFAULT
    if (type === contentType.RESOURCE) {
      const data = resources.find(d => d.id.toString() === id)
      return { data, type: VertexType.Resource, mode }
    } else if (type === contentType.NODE) {
      const data = nodes.find(d => d.id.toString() === id) ?? (nodes.length > 0 ? nodes[0] : undefined)
      return { data, type: VertexType.Node, mode }
    } else if (mode === VisualisationMode.LABEL_TREE && type === contentType.LABEL && labels.length > 0) {
      const data = labels.find(d => d.id.toString() === id) ?? labels[0]
      return { data, type: VertexType.Label, mode }
    } else {
      // case when domain is the center of the navimator, set label[0] or nodes[0] as activeVertex if exist
      return {
        data: labels.length > 0 && groupByLabel ? labels[0] : (nodes.length > 0 ? nodes[0] : undefined),
        type: labels.length > 0 && groupByLabel ? VertexType.Domain : VertexType.Node,
        mode
      }
    }
    // eslint-disable-next-line
  }, [nodes, labels, resources, id, type, groupByLabel])

  const activeVertex = getActiveVertex({ nodes, labels, resources })

  const domainData: iDomainData = useAppSelector(state => {
    return {
      nodes,
      labels,
      activeVertex,
      connectedNodes: getConnectedNodesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: activeVertex.type === VertexType.Node ? activeVertex.data : undefined } }),
      connectedResources: getResourcesForActiveNode({ ...state, nodes: { ...state.nodes, activeNode: activeVertex.type === VertexType.Node ? activeVertex.data : undefined } }),
      resources: state.resources.resources,
      relations: state.relations.relations,
      resourceMappings: state.resourceMappings.resourceMappings,
      openDomain: state.domain.openDomain,
      domainPermissions: state.domain.openDomainPermissions,
      // nodeLabels: state.labels.NodeLabels,
      lastUpdate: state.domain.lastUpdate,
      groupByLabel
    }
  })

  return domainData
}

export default useDomain
