import React, { ReactElement, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { APPLICATION_NAME } from "@env/constants";
import { Ator, syncUserOnboarding } from '@services/AuthService';
import { useAppDispatch } from '@store/hooks';
import { authenticate } from '@store/session/sessionSlice';
import { TheLoader } from '@common/components'
import { Collaboration } from "@gloow/apiconsumer";
import { setLoading } from '@store/helpers/helpersSlice';
import AnalyticsService from "@services/AnalyticsService";
import { IOService } from '@services/IOService';
import { useTranslation } from 'react-i18next';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    // callback uses hash params.. probably not the best way to handle this?
    // return new URLSearchParams(useLocation().hash.substring(1));
    return new URLSearchParams(useLocation().search);
}

function AuthCallback(): ReactElement {
    let query = useQuery()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        // handle possible errors
        if (query.has('error')) {
            // console.log(query.get('error_description'));
            // todo --> provide some user feedback + log errors
            history.push('/auth', { error: query.get('error_description') });
        }

        if (!query.has('code')) {
            console.log("missing code");
            // todo --> provide some user feedback + log errors
            history.push('/auth', { error: t('auth.missing_code') });
        }

        // exchange code for tokens using backend
        Ator.exchangeCode(
            query.get('code'),
            `${window.location.origin}/auth/callback`,
            APPLICATION_NAME
        ).then(async resp => {
            dispatch(authenticate(resp))

            IOService.instance.syncAuthenticationToken(resp)

            await syncUserOnboarding()

            // if sign up via domain invitation code
            if (query.get('invitationCode')) {
                try {
                    dispatch(setLoading(true))
                    const CS = new Collaboration()
                    const result = await CS.join(query.get('invitationCode')!)

                    if (result?.length > 0 && result[0].domainUuid) {
                        // @TODO:change to slug
                        return history.push(`/${result[0].domainUuid}/mind-maps`)
                    }
                    else if (result.err) {
                        return history.push('/domains', { error: result?.message ?? t('collaboration.collaboration_not_found_or_you_already_joined') })
                    }
                    dispatch(setLoading(false))
                } catch (error) {
                    dispatch(setLoading(false))
                    AnalyticsService.logError('collaboration-code-error', { error });

                    return history.push('/domains', { error: t('collaboration.try_invitation_link_later') })
                }
            }

            if (query.get('refererUrl')) {
                return history.push(query.get('refererUrl')!)
            }

            history.push('/domains');
        }).catch(e => {
            console.log('error exchange code');
            AnalyticsService.logError('authentication-exchange-code-error', { e });

            console.log(e);
            history.push('/auth', { error: t('auth.error_exchange_code') });
        });
        // eslint-disable-next-line
    }, [dispatch, history, query]);

    return (
        <TheLoader />
    )
}

export default AuthCallback
