import React from 'react'
import './ThemeToggle.scss'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import { toggleTheme } from '@store/theme/themeSlice'

const ThemeToggle = ({
  toggleTheme,
  darkMode
}) => {
  return (
    <IconButton onClick={() => toggleTheme()} color="primary">
      <input
        checked={!darkMode}
        id="toggle"
        className="toggle"
        type="checkbox"
        onChange={() => undefined}
      />
    </IconButton>
  );
}

const mapStateToProps = state => ({
  darkMode: state.theme.darkMode
})

const mapDispatchToProps = dispatch => ({
  toggleTheme: () => dispatch(toggleTheme()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemeToggle)