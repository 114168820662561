import React, { useState, useCallback, useEffect } from 'react'
import useStyles from './RelationsPicker.styles'
import { debounce } from 'lodash'
import { iLabel, iNode, Label, iLabelType } from "@gloow/apiconsumer"
import { Autocomplete, Skeleton } from '@material-ui/lab'
import { Popover, TextField, Typography } from '@material-ui/core'
import { useAppSelector } from '@store/hooks'
import { getUniqueRelationLabels } from '@selectors/index'
import { useTranslation } from 'react-i18next';
import Avatar from '@common/components/Avatar/Avatar';
import { iConnectedNode } from '@store/nodes/nodes.interfaces'
import { useRef } from 'react'

interface IRelationsPicker {
  activeNode: iNode
  targetNodes: iNode[]
  relationLabel?: iLabel
  onRelationLabelAdded?: (nodes: iConnectedNode[], label: iLabel) => void
  readOnly?: boolean,
  onClick?: () => void
}

const RelationsPicker = ({
  activeNode,
  targetNodes,
  relationLabel,
  onRelationLabelAdded,
  readOnly = false,
  onClick = () => { }
}: IRelationsPicker) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState<any>([])
  const [label, setLabel] = useState<iLabel>();
  const [busy, setBusy] = useState(false);
  const divRef = useRef<any>()

  // eslint-disable-next-line
  const searchCb = useCallback(debounce(text => {
    if (text.length >= 2) searchRelations(text)
  }, 500), [])

  const suggestions = useAppSelector((state) => getUniqueRelationLabels(state));
  const domain = useAppSelector(state => state.domain.openDomain);

  useEffect(() => {
    if (showSearch) setSearchResult(suggestions.filter(s => s.id !== relationLabel?.id))
    return () => { }
    // eslint-disable-next-line
  }, [showSearch])

  useEffect(() => {
    setLabel(relationLabel);
    return () => { }
    // eslint-disable-next-line
  }, [relationLabel])

  const searchRelations = async value => {
    setLoading(true)

    if (value.trim() !== '') {
      const autoCompleteParams: any = {
        q: value?.toLowerCase(),
        labelType: iLabelType.RELATION,
        domainUuid: domain?.uuid
      }

      const LS = new Label()
      const searchResults = await LS.getLabelAutomplete(autoCompleteParams)

      const found = searchResults.find(s => s.name?.toLowerCase() === value?.toLowerCase())

      if (found) return setSearchResult(searchResults);

      setSearchResult([{ id: null, name: value }, ...searchResults]);
    }

    setLoading(false)
  }

  const _getImage = () => {
    if (busy) {
      return <Skeleton variant="circle" width={24} height={24} />
    }
    return <Avatar
      name={activeNode.name}
      src={activeNode.thumbnailUrl}
      size={'24'}
      textSize={'xs'}
      color={activeNode.color}
    />
  }

  const onChange = async (val) => {
    if (onRelationLabelAdded && val !== null) {
      setShowSearch(false)
      setBusy(true)
      await onRelationLabelAdded(targetNodes, val)
      setBusy(false)
    }
  }

  const renderPopover = () => {
    return <Popover
      open={showSearch}
      classes={{ paper: classes.menuPaper }}
      anchorEl={divRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <div className={`${classes.relationInput} relation-input`}>
        <Autocomplete
          classes={{
            listbox: classes.listBox
          }}
          onBlur={() => setShowSearch(false)}
          value={label}
          loading={loading}
          id="tags-standard"
          options={searchResult}
          noOptionsText={t('common.no_labels')}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          onChange={async (e, val) => onChange(val)}
          onInputChange={(e, text) => { if (label?.name !== text) searchCb(text) }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={t('common.start_typing')}
              className={classes.labelsInput}
              autoFocus
            />
          )}
        />
      </div>
    </Popover>
  }

  const _getContent = () => {
    return (
      <div ref={divRef} className={`${classes.relationLabel} relation-label`} onClick={
        !readOnly ? (e) => {
          setShowSearch(true)
          onClick()
        } : undefined
      }
      >
        {busy ? <Skeleton width={100} height={24} /> :
          <>
            {label && (
              <Typography variant='body2' className={classes.label}>
                {typeof label === 'string' ? label : label?.name}
              </Typography>
            )}
            {!label && readOnly && <Typography variant='body2'>{t('common.no_label', 'No label')}</Typography>}
            {!label && !readOnly && <Typography variant='body2' className={classes.label}>{t('common.set_relation')} </Typography>}
          </>
        }
      </div>
    )
  }

  return (
    <div className={`${classes.relationsPicker} relation-picker`}>
      {(!readOnly || label) &&
        <div className={classes.imageContainer}>
          {_getImage()}
        </div>
      }
      {_getContent()}
      {renderPopover()}
    </div>
  );
}

export default RelationsPicker;
