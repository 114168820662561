import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ButtonBase,
  // Tab, Tabs,
  Typography,
} from "@material-ui/core";
import { useAppSelector } from "@store/hooks";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import useStyles from "./index.styles";
// import { TabPanel } from "@material-ui/lab";
import {
  iSearchResult,
  Search as SearchApi,
  SearchResultType,
} from "@gloow/apiconsumer";
import { GridNodeCard, GridResourceCard, Spinner } from "@common/components";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { RouteURI } from "@common/constants/Routes";
import { generateDetailURL } from "@helpers/utils";
import { contentType } from "@common/constants/Constants";
import GridLabelCard from "@common/components/GridView/GridLabelCard";
import emptyNodeIcon from "@assets/images/empty-node.png";

interface iTabState {
  type: string;
  values: any[];
}

const Search = () => {
  // const [index, setIndex] = useState(0);
  const { slug, searchType } = useParams<any>();
  const { search } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [
    // searchText,
    labels, nodes, resources] = useAppSelector((state) => [
    // state.filters.searchText, 
    state.labels.NodeLabels, 
    state.nodes, 
    state.resources.resources,
  ]);


  const [loading, setLoading] = useState<boolean>(false);

  const [searchResult, setSearchResult] = useState<Map<
    string,
    iSearchResult[]
  > | null>(null);
  const [tabState, setTabState] = useState<iTabState | null>(
    searchType
      ? {
        type: searchType,
        values: [],
      }
      : null
  );

  let _searchInstance: SearchApi;

  const getSearchQuery = useMemo(() => {
    const q = new URLSearchParams(search);
    return q.get("q") || undefined;
  }, [search]);

  const transformSearchResult = (searchResults: any) => {
    return _.chain(searchResults).map(d => {
      switch (d.type) {
        case SearchResultType.Node:
          const node = nodes.nodes.find(n => n.id === d.node?.id)

          /**
           * It is possible that the node is not in the store, but phaeton returns the id?
           */
          if (!d.node || !node) {
            return null
          }

          return {
            ...d,
            node: nodes.nodes.find(n => n.id === d.node.id) || null,
          }
        case SearchResultType.Label:
          return {
            ...d,
            // @ts-ignore
            label: labels.find(l => l.id === d.label.id) || null,
          }
        case SearchResultType.Resource:
          return {
            ...d,
            resource: resources.find(r => r.id === d.resource.id) || null,
          }
        default:
          return null;
      }
    }).compact().groupBy('type').value()
  }

  // eslint-disable-next-line
  const performSearch = useCallback(async () => {
    if (!_searchInstance) {
      // eslint-disable-next-line
      _searchInstance = new SearchApi();
    }

    if ((getSearchQuery ?? '').length < 2) {
      return;
    }

    try {
      if (typeof getSearchQuery !== 'undefined') {
        setLoading(true);
        const response = await _searchInstance.globalSearch(getSearchQuery);
        setLoading(false);

        // transformSearchResult(response)

        // @ts-ignore
        const groupedResults = transformSearchResult(response);
        const types = Object.keys(groupedResults);

        if (types.length !== 0) {
          setTabState({
            type: types[0],
            values: groupedResults[types[0]],
          });

          history.replace({
            pathname: `/${slug}/search/${types[0]}`,
            search: search,
          });

          // @ts-ignore
          setSearchResult(groupedResults);
        }
        else {
          setTabState({ type: '', values: [] })
        }
      }
    } catch (e) {
      console.error(e)
      setLoading(false);
    }
  },
    [search]
  );

  useEffect(() => {
    (async () => {
      performSearch();
    })();
    return () => { };
    // eslint-disable-next-line
  }, [getSearchQuery]);

  const _onClick = (item) => {
    // onDone();
    // if (onItemClick) onItemClick(item);
    // if (disableRedirection) return

    switch (tabState?.type) {
      case SearchResultType.Node:
        return history.push(`/${slug}/${RouteURI.FOCUS_MODE}/${item.node?.id}`);
      case SearchResultType.Label:
        return history.push(`/${slug}/${RouteURI.ENTITIES}/${item.label?.id}`);
      case SearchResultType.Resource:
        return history.push(
          `/${slug}/${RouteURI.DOCUMENTS}/${item.resource?.id}`
        );
      default:
        return;
    }
  };

  const renderItemsByType = (item: iSearchResult) => {
    switch (tabState?.type) {
      case SearchResultType.Node:
        return (
          <GridNodeCard
            // @ts-ignore
            // onConnectionFilter={
            //   showConnections ? () => viewConnection(d) : undefined
            // }
            highlight={getSearchQuery}
            // @ts-ignore
            data={item.node}
            key={item.id}
            onClick={() => _onClick(item)}
          // selected={d.id === connectionView?.id}
          />
        );
      case SearchResultType.Resource:
        // Phaeton returning null for resource?
        if (!item.resource) {
          return null;
        }

        return (
          <GridResourceCard
            data={{
              ...item.resource!,
              metaData: {
                ...item.resource!.metaData,
                description: item.longText
              }
            }}
            highlight={getSearchQuery}
            onClick={() =>
              history.push(
                generateDetailURL(`/${slug}/mind-maps`, {
                  id: item.resource!.id,
                  type: contentType.RESOURCE,
                })
              )
            }
            hidePreview
          />
        );
      case SearchResultType.Label:
        return (
          <GridLabelCard
            data={item.label!}
            onClick={() =>
              history.push(
                generateDetailURL(`/${slug}/mind-maps`, {
                  id: item.resource!.id,
                  type: contentType.RESOURCE,
                })
              )
            }
          />
        );
      default:
        return <></>;
    }
  };

  const tabTitle = (d) => {
    switch (d) {
      case SearchResultType.Node:
        return t("common.entities", "Entities");
      default:
        return d;
    }
  };

  const renderSearchResult = () => {
    if (
      searchResult === null ||
      tabState === null ||
      tabState?.values.length === 0
    ) {
      return (
        <div className={classes.noResultContainer}>
          <img src={emptyNodeIcon} alt='no-result'/>
          <Typography variant="body2">{t('common.no_result', 'No result')}</Typography>
        </div>
      );
    }

    const domainSlug = slug ? slug + "/" : "";

    return (
      <>
        <div className={classes.tabs}>
          {Object.keys(searchResult).map((d) => (
            <ButtonBase
              key={d}
              className={
                classes.tab +
                " " +
                (tabState.type === d ? classes.tabActive : "")
              }
              onClick={() => {
                setTabState({ type: d, values: searchResult[d] });
                history.replace({
                  pathname: `/${domainSlug}search/${d}`,
                  search,
                });
              }}
            >
              <span className={classes.tabLabel}>{tabTitle(d)}:</span>&nbsp;
              {searchResult[d].length}
            </ButtonBase>
          ))}
        </div>
        <div className={classes.resultContainer}>
          {(tabState.values ?? []).map(renderItemsByType)}
        </div>
      </>
    );
  };

  const renderLoader = () => (
    <div className={classes.loadingContainer}>
      <Spinner size={"md"} />
    </div>
  )

  return (
    <div className={classes.container}>
      {getSearchQuery !== null && (
        <Typography variant="h4" className={classes.title}>
          {t(
            "common.search_results_for",
            `Search results for "${getSearchQuery ?? ''}"`
          )}
        </Typography>
      )}
      {loading ? renderLoader() : renderSearchResult()}
    </div>
  );
};

export default Search;
