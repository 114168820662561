import React, { useEffect, useMemo, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Tab, Tabs, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Close } from '@material-ui/icons'
import { iLabel, iNode, iResource, Relation, Resource, Node } from '@gloow/apiconsumer'
import { VertexType } from '@gloow/navimator'

import useStyles from './ConnectNodesResourcesDialog.styles'
import { SearchField, TabPanel, NodeCard, ResourceCard } from '@common/components'
import { filteredNodes, filteredResource } from '@helpers/utils'
import { useAppDispatch } from '@store/hooks'
import { addRelations } from '@store/relations/relationsSlice'
import { addResourceMappings } from '@store/resourceMappings/resourceMappingsSlice'
import emptyNodeIcon from '@assets/images/empty-node.png'
import emptyResourceIcon from '@assets/images/empty-resource.png'
import { addExistingItemTypes, iInteractionDialog } from '@common/constants/Constants'
import AnalyticsService from "@services/AnalyticsService";
import { setLastUpdate } from '@store/domain/domainSlice'
import { updateNode } from '@store/nodes/nodesSlice'

interface iConnectNodesResourcesDialog {
  nodes: iNode[],
  resources: iResource[],
  connectedNodes: iNode[],
  connectedResources: iResource[],
  dialogProps: iInteractionDialog,
  handleClose: () => void
}

const ConnectNodesResourcesDialog = ({
  nodes,
  resources,
  connectedNodes,
  connectedResources,
  dialogProps,
  handleClose = () => { }
}: iConnectNodesResourcesDialog) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState<string>('')
  const [index, setIndex] = useState(dialogProps.tab ?? addExistingItemTypes.NODES)
  const [connectToNode, setConnectToNode] = useState<iNode>()
  const [connectToResource, setConnectToResource] = useState<iResource>()
  const [connectToLabel, setConnectToLabel] = useState<iLabel>()
  const [selectedNodes, setSelectedNodes] = useState<iNode[]>([])
  const [selectedResources, setSelectedResources] = useState<iResource[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const connectedNodeIds = dialogProps?.connectedNodes ? dialogProps?.connectedNodes.map(d => d.id) : connectedNodes.map(d => d.id)
  const connectedResourceIds = dialogProps?.connectedResources ? dialogProps?.connectedResources.map(d => d.id) : connectedResources.map(d => d.id)
  const data = {
    nodes: useMemo(() =>
      filteredNodes(nodes, searchText).filter((d: iNode) => {
        if (connectToLabel) return connectedNodeIds.indexOf(d.id) === -1 && !d.labels.some(l => l.id === connectToLabel?.id)
        return connectedNodeIds.indexOf(d.id) === -1 && d.id !== connectToNode?.id
      }),
      [nodes, searchText, connectToLabel, connectedNodeIds, connectToNode?.id]
    ),
    resources: useMemo(() =>
      filteredResource(resources, searchText).filter(d => connectedResourceIds.indexOf(d.id) === -1),
      [resources, searchText, connectedResourceIds]
    ),
  }

  useEffect(() => {
    setConnectToNode(undefined)
    setConnectToResource(undefined)
    setIndex(dialogProps.tab ?? addExistingItemTypes.NODES)
    if (!dialogProps.open) {
      setIndex(addExistingItemTypes.NODES)
      setSearchText('')
      setSelectedNodes([])
      setSelectedResources([])
    }
    if (dialogProps.connectTo?.type === VertexType.Node) setConnectToNode(dialogProps.connectTo?.data as iNode)
    else if (dialogProps.connectTo?.type === VertexType.Resource) setConnectToResource(dialogProps.connectTo?.data as iResource)
    else if (dialogProps.connectTo?.type === VertexType.Label) setConnectToLabel(dialogProps.connectTo?.data as iLabel)
    return () => { }
    // eslint-disable-next-line
  }, [dialogProps.open])

  const onSubmit = async () => {
    try {
      setLoading(true)
      if (selectedNodes.length > 0) {
        //connect node to node
        if (connectToNode) {
          const RS = new Relation()
          const relations = selectedNodes.map((d) => ({
            sourceNode: connectToNode?.id,
            targetNode: d.id
          }))
          const newRelations = await RS.createMany(relations)
          if (newRelations) dispatch(addRelations(newRelations))
        }
        //connect resource to node
        else if (connectToResource) {
          const RS = new Resource()
          const rsMappings = selectedNodes.map(d => ({
            node: d.id,
            resource: connectToResource?.id
          }))
          // @ts-ignore
          const newRsMappings = await RS.createResourceMappings(rsMappings)
          if (newRsMappings) dispatch(addResourceMappings(newRsMappings))
        }
        // connect node to label
        else if (connectToLabel) {
          console.log('connectToLabel', connectToLabel, selectedNodes)
          const NS = new Node()

          const nodes = selectedNodes.map(d => ({
            id: d.id,
            labels: [...d.labels.map(d => d.id), connectToLabel!.id]
          }))

          for (let i = 0; i < nodes.length; i++) {
            const element = nodes[i];
            // @ts-ignore
            const response = await NS.update(element)

            if (response) {
              dispatch(updateNode(response))
            }
          }
        }
      }
      if (selectedResources.length > 0) {
        const RS = new Resource()
        const rsMappings = selectedResources.map(d => ({
          node: connectToNode?.id,
          resource: d.id
        }))
        // @ts-ignore
        const newRsMappings = await RS.createResourceMappings(rsMappings)
        if (newRsMappings) dispatch(addResourceMappings(newRsMappings))
      }
      dispatch(setLastUpdate(Date.now()))
      handleClose()
      setLoading(false)
    } catch (error) {
      AnalyticsService.logError('connect-nodes-resources-error', { error });
      console.log(error)
      setLoading(false)
    }
  }

  const selectNode = (item, index) => {
    const selection = selectedNodes.slice()
    if (index === -1) selection.push(item)
    else selection.splice(index, 1)
    setSelectedNodes(selection)
  }
  const selectResource = (item, index) => {
    const selection = selectedResources.slice()
    if (index === -1) selection.push(item)
    else selection.splice(index, 1)
    setSelectedResources(selection)
  }

  return (
    <Dialog id="connect-nodes-resources-dialog" onClose={handleClose} open={dialogProps.open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3">
          {t('common.link_an_existing_item')}
        </Typography>
        <Close className={classes.dialogBackIcon} onClick={() => handleClose()} />
      </div>
      <SearchField className={classes.search} text={searchText} onChange={(text) => setSearchText(text)} />
      <Tabs
        value={index}
        onChange={(e, val) => setIndex(val)}
        textColor="primary"
        classes={{
          root: classes.tabButtonContainer,
          indicator: classes.tabIndicator,
        }}
      >
        <Tab
          disabled={index !== addExistingItemTypes.NODES && typeof dialogProps.tab !== 'undefined'}
          style={{ display: index !== addExistingItemTypes.NODES && typeof dialogProps.tab !== 'undefined' ? 'none' : 'inline-block', }}
          className={classes.tab}
          label={t('common.nodes')}
        />
        <Tab
          disabled={index !== addExistingItemTypes.RESOURCES && typeof dialogProps.tab !== 'undefined'}
          style={{ display: index !== addExistingItemTypes.RESOURCES && typeof dialogProps.tab !== 'undefined' ? 'none' : 'inline-block', }}
          className={classes.tab}
          label={t('common.resources')}
        />
      </Tabs>
      <DialogContent className={classes.dialogContent}>
        <TabPanel value={index} index={addExistingItemTypes.NODES}>
          {data.nodes.length === 0 && <div className={classes.tabEmpty}><img src={emptyNodeIcon} className={classes.emptyIcon} alt='Gloow' /></div>}
          <div className={classes.tabContainer}>
            {data.nodes?.map((d) => {
              const idx = selectedNodes.findIndex((s) => d.id === s.id)
              return <NodeCard disabled={loading} key={d.id.toString()} node={d} onClick={(item) => selectNode(item, idx)} selected={idx > -1} />
            })}
          </div>
        </TabPanel>
        <TabPanel value={index} index={addExistingItemTypes.RESOURCES}>
          {data.resources.length === 0 && <div className={classes.tabEmpty}><img src={emptyResourceIcon} className={classes.emptyIcon} alt='Gloow' /></div>}
          <div className={classes.tabContainer}>
            {data.resources?.map(d => {
              const idx = selectedResources.findIndex((s) => d.id === s.id)
              return <ResourceCard disabled={loading} key={d.id.toString()} resource={d} onClick={(item) => selectResource(item, idx)} selected={idx > -1} hidePreview={true} />
            })}
          </div>
        </TabPanel>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div className={classes.actions}>
          <Button
            id="connect-nodes-resource-cancel"
            type="button"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
            disabled={loading}
          >
            {t('common.cancel')}
          </Button>
          <Button
            id="connect-nodes-resource-submit"
            type={"submit"}
            fullWidth
            variant="contained"
            color="primary"
            onClick={(e) => onSubmit()}
            disabled={loading || (selectedNodes.length === 0 && selectedResources.length === 0)}
          >
            {t('common.save')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ConnectNodesResourcesDialog
