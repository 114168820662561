import { createSlice } from "@reduxjs/toolkit";

export enum ExplorerVisualisationType {
  Default,
  GroupByNodeLabel,
  GroupByRelationLabel,
}

export interface iExplorerState {
  type: ExplorerVisualisationType;
}

const initialState: iExplorerState = {
  type: ExplorerVisualisationType.Default,
};

export const explorerSlice = createSlice({
  name: "explorer",

  initialState: initialState,

  reducers: {
    setExplorerType: (state, action) => {
      state.type =
        state.type === action.payload
          ? ExplorerVisualisationType.Default
          : action.payload;
    },

    changeExplorerType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { setExplorerType, changeExplorerType } = explorerSlice.actions;

export default explorerSlice.reducer;
