import { addExistingItemTypes, iInteractionDialog, nodeInfoTabTypes } from '@common/constants/Constants'
import { Button, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { iNode, iResource } from "@gloow/apiconsumer"
import { removeConnectedResource } from '@services/DomainDataService'
import { VertexType } from '@gloow/navimator'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from '../Dialog/ConfirmDialog/ConfirmDialog'
import { ResourceButton } from '../ResourceButton/ResourceButton'
import useStyles from './ConnectedResources.styles'
import EmptyResourceIcon from '@assets/images/empty-resource.png'

export const ConnectedResources = ({
  activeNode,
  resources = [],
  removeable = false,
  editable = false,
  size = 'medium',
  onItemClicked = (item) => { },
  setNodeMenu = (props: iInteractionDialog) => { }
}: {
  activeNode: iNode,
  resources: iResource[],
  editable?: boolean,
  removeable?: boolean,
  size?: 'small' | 'medium',
  onItemClicked: (item: iResource) => void,
  setNodeMenu: (props: iInteractionDialog) => void
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteState, setDeleteState] = useState<{ dialog: boolean, data?: iResource }>({
    dialog: false,
    data: undefined
  })

  const removeConnection = async () => {
    if (!deleteState.data) return
    setLoading(true)
    await removeConnectedResource(deleteState.data as iResource)
    setLoading(false)
    setDeleteState({ dialog: false, data: undefined })
  }

  const renderItem = (resource: iResource, idx: number) => {
    return <ResourceButton
      size={size}
      key={idx.toString()}
      disabled={loading}
      resource={resource}
      onClick={onItemClicked}
      onRemove={removeable ? () => setDeleteState({ dialog: true, data: resource }) : undefined}
    />
  }

  return (
    <div className={classes.container}>
      {resources.length === 0 ?
        <div className={classes.tabEmpty}>
          <img src={EmptyResourceIcon} alt='gloow resources' />
          <Typography variant='body2'>{t('common.no_resources_found')}</Typography>
        </div>
        :
        <div
          className={`${size === 'small' ? classes.resourcesContainerSmall : classes.resourcesContainer}`}
          style={{ height: editable ? 'calc(100% - 50px)' : '100%' }}
        >
          {resources?.map(renderItem)}
        </div>
      }
      {editable && (
        <div className={`${size === 'small' ? classes.actionContainerSmall : classes.actionContainer} add-resources-container`}>
          <Button
            id={`add-${nodeInfoTabTypes.CONNECTED_RESOURCES}`}
            type="button"
            className='link-button'
            color='primary'
            startIcon={<Add />}
            onClick={() => setNodeMenu({
              open: true,
              tab: addExistingItemTypes.RESOURCES,
              connectTo: { type: VertexType.Node, data: activeNode, },
              connectedResources: resources,
              specificMenu: true
            })}
          >
            <Typography variant='body2'>{t('common.add_resources')}</Typography>
          </Button>
        </div>
      )}
      <ConfirmDialog
        loading={loading}
        open={deleteState.dialog}
        onClose={() => setDeleteState({ dialog: false, data: undefined })}
        onConfirm={() => removeConnection()}
        text={t('common.are_you_sure_want_to_delete_this_connection', 'Are you sure want to delete this connection?')}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}
      />
    </div>
  )
}
