import Colors from '@common/constants/Colors';
import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  searchContainer: {
    height: '36px !important',
  },
  search: {
    position: 'relative',
    borderRadius: 12,
    backgroundColor: Colors.lightBlue2,
    height: 36
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '36px !important',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 20
    }
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    height: 20,
    fontSize: 14
  },
}))