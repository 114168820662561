import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { TextField, Button, InputAdornment, IconButton, Snackbar, Grow } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { changePassword, login } from '@services/AuthService'
import { useAppSelector, useAppDispatch } from '@store/hooks'
import { setLoading } from '@store/helpers/helpersSlice'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    dialogTitle: {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 24,
      width: '80%'
    },
    dialogPaper: {
      borderRadius: 12,
      padding: 30,
      minWidth: 420,
      maxWidth: 420
    },
    dialogBackIcon: {
      cursor: 'pointer',
      position: 'relative',
      marginTop: 5
    },
    passwordForm: {
      marginBottom: 15
    },
    passwordButton: {
      marginTop: 30
    }
  }),
);

export interface SimpleDialogProps {
  open: boolean
  onClose: (isSuccessChangePassword) => void
}

function ChangePasswordDialog(props: SimpleDialogProps) {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { onClose, open } = props;
  const [showPassword, setShowPassword] = useState(false)
  const user = useAppSelector(state => state.user.user)
  const handleClose = (isSuccessChangePassword = false) => {
    onClose(isSuccessChangePassword);
  };

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required(t('auth.current_password_is_required')),
    newPassword: Yup.string().required(t('auth.new_password_is_required')),
    confirmNewPassword: Yup.string().required(t('auth.confirm_new_password_is_required')).when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        t('auth.password_mismatch')
      )
    }),
  })

  const [snackBar, setSnackBar] = useState<{ open: boolean, message: string, severity: Color | undefined }>({
    open: false,
    message: '',
    severity: 'success'
  })

  const onSubmit = (values) => {
    dispatch(setLoading(true))
    login(user.email, values.password).then(res => {
      if (res) {
        changePassword(values.newPassword).then(res => {
          if (res) {
            dispatch(setLoading(false))
            handleClose(true)
          }
        })
      } else {
        dispatch(setLoading(false))
        setSnackBar({
          open: true,
          message: t('auth.wrong_current_password'),
          severity: 'error'
        })
      }
    })
  }

  return (
    <Dialog onClose={() => handleClose()} open={open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle} variant="h3">
          {t('auth.change_password')}
        </Typography>
        <CloseIcon className={classes.dialogBackIcon} onClick={() => handleClose()} />
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={{
          password: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        onSubmit={onSubmit}
        validationSchema={changePasswordSchema}>
        {({ values, handleChange, handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              className={classes.passwordForm}
              error={Boolean(errors.password)}
              helperText={errors.password}
              fullWidth
              label={t('auth.current_password')}
              name="password"
              onChange={(e) => handleChange(e)}
              autoFocus
              value={values['password']}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOutlined style={{ opacity: 0.4 }} /> : <VisibilityOffOutlined style={{ opacity: 0.4 }} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              className={classes.passwordForm}
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword}
              fullWidth
              label={t('auth.new_password')}
              name="newPassword"
              onChange={(e) => handleChange(e)}
              value={values['newPassword']}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOutlined style={{ opacity: 0.4 }} /> : <VisibilityOffOutlined style={{ opacity: 0.4 }} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              className={classes.passwordForm}
              error={Boolean(errors.confirmNewPassword)}
              helperText={errors.confirmNewPassword}
              fullWidth
              label={t('auth.confirm_new_password')}
              name="confirmNewPassword"
              onChange={(e) => handleChange(e)}
              value={values['confirmNewPassword']}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOutlined style={{ opacity: 0.4 }} /> : <VisibilityOffOutlined style={{ opacity: 0.4 }} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              className={classes.passwordButton}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit()}
            >
              {t('common.submit')}
            </Button>
          </form>
        )}
      </Formik>

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackBar.open} TransitionComponent={Grow} onClose={() => setSnackBar({ ...snackBar, open: false })} autoHideDuration={3000} >
        <Alert variant={'filled'} onClose={() => setSnackBar({ ...snackBar, open: false })} severity={snackBar.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default ChangePasswordDialog;
