import React, { ReactElement, useState } from 'react'
import useStyles from './AppLayout.styles'
import { Resizable } from 're-resizable'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setLeftContentWidth, setRightContentWidth } from '@store/filters/filtersSlice'
import { IconButton } from '@material-ui/core'
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons'

const AppLayout = ({
  allowMinimizeLeftContent = false,
  leftContentClassName = '',
  rightContentClassName = '',
  renderLeftContent,
  renderContent,
  renderRightContent,
  className = '',
  contentClassName = ''
}: {
  className?: string,
  allowMinimizeLeftContent?: boolean,
  leftContentClassName?: string,
  rightContentClassName?: string,
  renderLeftContent?: ReactElement | JSX.Element,
  renderContent?: ReactElement | JSX.Element,
  renderRightContent?: ReactElement | JSX.Element,
  contentClassName?: string
}) => {
  const dispatch = useAppDispatch()
  const [leftContentWidth, rightContentWidth] = useAppSelector(state => [state.filters.leftContentWidth, state.filters.rightContentWidth])
  const classes = useStyles()
  const [resizing, setResizing] = useState<boolean>(false)
  const enableLeftContent = renderLeftContent?.props?.children ? renderLeftContent?.props?.children?.some(d => !!d) : Boolean(renderLeftContent)
  const enableRightContent = renderRightContent?.props?.children ? renderRightContent?.props?.children?.some(d => !!d) : Boolean(renderRightContent)

  return (
    <div className={classes.container}>
      <div className={`${classes.appContainer} ${className}`}>
        <div className={`${classes.leftContainer} ${!enableLeftContent ? classes.panelHidden : ''} ${leftContentClassName}`} style={{ width: allowMinimizeLeftContent ? leftContentWidth : 223 }}>
          {renderLeftContent}
        </div>
        {enableLeftContent && allowMinimizeLeftContent && (
          <IconButton
            className={classes.leftSidebarButton}
            style={{ transform: leftContentWidth < 223 ? 'translateX(-163px)' : 'unset' }}
            onClick={() => dispatch(setLeftContentWidth(leftContentWidth === 223 ? 60 : 223))}
          >
            {leftContentWidth === 223
              ? <ChevronLeftOutlined fontSize="inherit" color="primary" />
              : <ChevronRightOutlined fontSize="inherit" color="primary" />
            }
          </IconButton>
        )}
        <div className={`${classes.contentContainer} ${contentClassName}`}>
          {renderContent}
        </div>
        <Resizable
          style={{ transition: !resizing ? 'width 0.2s ease-in-out' : 'unset' }}
          className={`${classes.rightContainer} ${rightContentClassName}`}
          size={{ width: enableRightContent ? rightContentWidth : 0, height: '100%' }}
          onResizeStart={() => setResizing(true)}
          onResizeStop={(e, direction, ref, d) => {
            dispatch(setRightContentWidth(rightContentWidth + d.width))
            setResizing(false)
          }}
          enable={{ left: true }}
          handleStyles={{ left: { left: -3 } }}
          handleClasses={{ left: classes.resizeAnchor }}
        >
          {renderRightContent}
        </Resizable>
      </div>
    </div >
  )
}

export default AppLayout