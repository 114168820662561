import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../Spinner/Spinner';

const useStyles = makeStyles((theme) => ({
  callbackLoader: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2000,
    background: 'rgba(255, 255, 255, 0.9)'
  }
}));

const TheLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.callbackLoader}>
      <Spinner />
    </div>
  );
}

export default TheLoader;