import { makeStyles } from '@material-ui/core/styles'
export default makeStyles((theme) => ({
  container: {
    padding: '24px 32px',
  },
  settingHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  accountTitle: {
    fontWeight: 600,
    flex: 1,
  },
  tabIndicator: {
    display: 'none',
  },
  containerCard: {
    padding: 32,
    borderRadius: 12,
    boxShadow: '0 4px 12px 0 rgb(6 10 34 / 10%) !important',
  },
  tabs: {
    display: 'flex',
    flex: 1,
    gap: 16,
    marginBottom: 24,
  },
  tab: {
    padding: '8px 16px',
    borderRadius: 10,
    fontSize: 18,
    opacity: 0.4,
  },
  tabActive: {
    fontWeight: 600,
    opacity: 1,
  },
  deleteBtn: {
    color: theme.palette.error.main,
    borderBottom: '1px solid ' + theme.palette.error.main,
  },
}))
