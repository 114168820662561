import React from 'react'
import { useSlate } from 'slate-react'
import { FormatBoldOutlined, FormatUnderlinedOutlined, FormatItalicOutlined, FormatQuoteOutlined, LooksOneOutlined, LooksTwoOutlined, FormatListBulletedOutlined, FormatListNumberedOutlined, FormatAlignLeftOutlined, FormatAlignCenterOutlined, FormatAlignRightOutlined, FormatAlignJustifyOutlined, CodeOutlined, ImageOutlined, CheckRounded, Looks3Outlined, Looks4Outlined, Looks5Outlined, Looks6Outlined } from '@material-ui/icons'
import useStyles from '../NodeContent.styles'
import ButtonMark from './ButtonMark'
import ButtonFormat from './ButtonFormat'
import { insertImageEvent } from '../hooks/withImage'
import { IconButton, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'


const Toolbar = ({ className, loading = false, nodeId }: { className?: string, loading?: boolean, nodeId?: number }) => {
  const classes = useStyles()
  const editor = useSlate()
  const { t } = useTranslation()

  const marks = [
    { mark: 'bold', icon: <FormatBoldOutlined className={classes.toolbarIcon} /> },
    { mark: 'italic', icon: <FormatItalicOutlined className={classes.toolbarIcon} /> },
    { mark: 'underline', icon: <FormatUnderlinedOutlined className={classes.toolbarIcon} /> },
  ]

  const format = [
    { format: 'code', icon: <CodeOutlined className={classes.toolbarIcon} /> },
    { format: 'heading-one', icon: <LooksOneOutlined className={classes.toolbarIcon} /> },
    { format: 'heading-two', icon: <LooksTwoOutlined className={classes.toolbarIcon} /> },
    { format: 'heading-three', icon: <Looks3Outlined className={classes.toolbarIcon} /> },
    { format: 'heading-four', icon: <Looks4Outlined className={classes.toolbarIcon} /> },
    { format: 'heading-five', icon: <Looks5Outlined className={classes.toolbarIcon} /> },
    { format: 'heading-six', icon: <Looks6Outlined className={classes.toolbarIcon} /> },
    { format: 'block-quote', icon: <FormatQuoteOutlined className={classes.toolbarIcon} /> },
    { format: 'bulleted-list', icon: <FormatListBulletedOutlined className={classes.toolbarIcon} /> },
    { format: 'numbered-list', icon: <FormatListNumberedOutlined className={classes.toolbarIcon} /> },
    { format: 'left', icon: <FormatAlignLeftOutlined className={classes.toolbarIcon} /> },
    { format: 'center', icon: <FormatAlignCenterOutlined className={classes.toolbarIcon} /> },
    { format: 'right', icon: <FormatAlignRightOutlined className={classes.toolbarIcon} /> },
    { format: 'justify', icon: <FormatAlignJustifyOutlined className={classes.toolbarIcon} /> },
  ]
  return (
    <div className={`${classes.toolbarContainer} ${className} contents-toolbar`}>
      <div className={classes.toolbarButtons}>
        {marks.map((d, i) => <ButtonMark key={i.toString()} editor={editor} mark={d.mark} icon={d.icon} />)}
        {/* <div className={classes.spacer}></div> */}
        {format.map((d, i) => <ButtonFormat key={i.toString()} editor={editor} format={d.format} icon={d.icon} />)}
        {/* <div className={classes.spacer}></div> */}
        <IconButton size='small' className={classes.toolbarButton} onClick={(e) => insertImageEvent(editor, nodeId)}><ImageOutlined className={classes.toolbarIcon} /></IconButton>
      </div>
      <div className={classes.saved}>
        {loading ?
          <Typography className={classes.savingText} variant='body2' color={'primary'}>{t('common.saving', 'Saving')}...</Typography> :
          <CheckRounded color='primary' fontSize='small' />
        }
      </div>
    </div>
  )
}

export default Toolbar