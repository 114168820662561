import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

const headerHeight = 80
export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    top: headerHeight + 18,
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'fixed',
    zIndex: 200,
    padding: '10px 24px',
    backgroundColor: 'white',
    boxShadow: `0 8px 24px 0 ${Colors.shadowColor} !important`,
    borderRadius: 12,
    [theme.breakpoints.down('sm')]: {
      padding: 12,
      flexWrap: 'wrap',
      width: 450
    }
  },
  text: {
    marginBottom: 10
  },
  confirm: {
    marginLeft: 18,
    height: 43,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginBottom: 5
    }
  }
}))