import { makeStyles } from '@material-ui/core';
import Colors from '@common/constants/Colors';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 32px',
    borderRadius: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    // minHeight: 80,
    overflow: 'hidden'
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    transition: 'all ease 0.3s',
  },
  name: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  type: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    textTransform: "capitalize",
  },
  stat: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  statItem: {
    marginRight: 10
  },
  statCount: {
    color: Colors.primary,
    marginRight: 3
  },
  avatarWrapper: {
    marginRight: 10
  },
  hideContent: {
    opacity: 0,
  }
}))