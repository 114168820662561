import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 30,
    paddingBottom: 0,
  },
  dialogTitle: {
    marginBottom: 10,
    fontWeight: 600,
    fontSize: 24,
    width: '80%'
  },
  dialogPaper: {
    borderRadius: 12,
    minWidth: 420,
    maxWidth: 420,
    paddingBottom: 15,
  },
  dialogBackIcon: {
    cursor: 'pointer',
    position: 'relative',
    marginTop: 5
  },
  dialogContent: {
    padding: 0,
    margin: 0
  },
  action: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '12px 32px',
    fontSize: 16,
    borderRadius: 0,
    boxShadow: 'none !important',
    '& img': {
      width: 20,
      height: 20,
      marginRight: 8
    }
  }
}))