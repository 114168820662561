import React, { ReactElement, useEffect } from 'react'
import { Explorer, ResourceInfoForm, LabelInfoForm, AppLayout, NodeListV2, NodeSidePanel } from '@common/components'
import { iLabel, iNode, iResource } from "@gloow/apiconsumer"
import { contentType } from '@common/constants/Constants'
import { useHistory } from 'react-router'
import { generateDetailURL } from '@helpers/utils'
import { VertexType } from '@gloow/navimator'
import useDomain from '@hooks/useDomain'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { Typography } from '@material-ui/core'
import useStyles from './index.styles'
import IconEmptyNode from '@assets/images/empty-node.png'
import { useTranslation } from 'react-i18next'
import { setGroupByLabelMindMaps } from '@store/filters/filtersSlice'

function MindMaps({
  setAddExistingItemDialog = (props) => { },
  setNodeMenu = (props) => { }
}: {
  setAddExistingItemDialog: (props) => void
  setNodeMenu: (props) => void
}): ReactElement {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [visualisation, groupByLabel, leftContentWidth, rightContentWidth] = useAppSelector(state => [state.helpers.visualisation, state.filters.groupByLabelMindMaps, state.filters.leftContentWidth, state.filters.rightContentWidth])
  const id = new URLSearchParams(history.location.search).get('id') ?? ''
  const ref = new URLSearchParams(history.location.search).get('ref') ?? ''
  const domainData = useDomain()
  const { t } = useTranslation()

  const activeNode: iNode | undefined = domainData.activeVertex.type === VertexType.Node ? domainData.activeVertex.data as iNode : undefined
  const activeLabel: iLabel | undefined = [VertexType.Label, VertexType.Domain].includes(domainData.activeVertex.type) && domainData.labels.length > 0 ? domainData.activeVertex.data as iLabel : undefined
  const activeResource: iResource | undefined = domainData.activeVertex.type === VertexType.Resource ? domainData.activeVertex.data as iResource : undefined

  const recenterMap = () => {
    const currentVertex = visualisation?.getCurrentActiveVertex()
    const currentMode = visualisation?.config.mode
    if (currentVertex && typeof currentMode !== 'undefined' && currentMode >= 0) {
      setTimeout(() => visualisation?.switchVisualisationType(currentMode!, currentVertex), 500)
    }
  }

  // special case on mind map, when history go back called, reset the state (there are ignoreRecenter state being used to determine action between navimator and react side)
  useEffect(() => {
    const listenHistory = history.listen((location, action) => {
      if (action === 'POP') return history.replace(`${location.pathname}${location.search}`);
    })
    return listenHistory
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    recenterMap()
    return () => { }
    // eslint-disable-next-line
  }, [leftContentWidth, rightContentWidth])

  return <AppLayout
    renderLeftContent={
      <NodeListV2
        nodes={domainData.nodes}
        activeNodeId={activeNode?.id}
        allowAdd={domainData.domainPermissions?.modify}
        onAdded={(node) => history.replace(`${history.location.pathname}?id=${node.id}&type=${contentType.NODE}`)}
        onClick={(node) => history.push(`${history.location.pathname}?id=${node.id}&type=${contentType.NODE}`)}
        allowMultipleCollapse={false}
        onLabelClick={(labelId) => {
          if (!labelId) return history.replace(history.location.pathname)
          history.replace(`${history.location.pathname}?id=${labelId}&type=${contentType.LABEL}`)
        }}
        groupByLabel={groupByLabel}
        onGroupByLabelChange={(checked) => dispatch(setGroupByLabelMindMaps(checked))}
      />
    }
    renderContent={
      <Explorer
        labels={domainData.labels}
        nodes={domainData.nodes}
        relations={domainData.relations}
        resourceMappings={domainData.resourceMappings}
        activeVertex={domainData.activeVertex}
        domainPermissions={domainData.domainPermissions}
        setNodeMenu={setNodeMenu}
        lastUpdate={domainData.lastUpdate}
        domain={domainData.openDomain!}
      />
    }
    renderRightContent={
      <>
        {!activeNode && !activeResource && !activeLabel && <div className={classes.emptyContainer}>
          <img alt='empty-nodes' src={IconEmptyNode} />
          <Typography variant='body2'>{t('common.no_nodes_yet')}</Typography>
        </div>}
        {activeNode && (
          <NodeSidePanel
            node={activeNode}
            editable={domainData.domainPermissions?.modify}
            removeable={domainData.domainPermissions?.remove}
            domainUuid={domainData.openDomain?.uuid!}
            connectedNodes={domainData.connectedNodes}
            connectedResources={domainData.connectedResources}
            onLabelClick={(labelId) => {
              if (!groupByLabel) return
              history.push(generateDetailURL(history.location.pathname, {
                id: labelId,
                type: contentType.LABEL,
                ref: id ?? ref
              }))
            }}
            onConnectedNodeClick={(nodeId) => history.push(generateDetailURL(history.location.pathname, {
              id: nodeId,
              type: contentType.NODE,
              ref: id ?? ref
            }))}
            onConnectedResourceClick={(resourceId) => history.push(generateDetailURL(history.location.pathname, {
              id: resourceId,
              type: contentType.RESOURCE,
              ref: id ?? ref
            }))}
            setNodeMenu={setNodeMenu}
          />
        )}
        {activeResource && (
          <ResourceInfoForm
            activeResource={activeResource}
            onConnectedNodeClicked={(item) => {
              history.push(generateDetailURL(history.location.pathname, {
                id: item.id,
                type: contentType.NODE,
                ref: ref.length ? ref : item.id.toString()
              }))
            }}
            setAddExistingItemDialog={setAddExistingItemDialog}
          />
        )}
        {activeLabel && (
          <LabelInfoForm
            activeLabel={activeLabel}
            domainPermissions={domainData.domainPermissions}
            onConnectedNodeClicked={(item) => {
              history.push(generateDetailURL(history.location.pathname, {
                id: item.id,
                type: contentType.NODE,
                ref: ref.length ? ref : item.id.toString()
              }), {
                ignoreRecenter: false
              })
            }}
            setNodeMenu={setNodeMenu}
          />
        )}
      </>
    }
  />
}

export default MindMaps
