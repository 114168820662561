import {updateUserMetaData} from "@services/AuthService";
import AnalyticsService from "@services/AnalyticsService";
import {analyticsEventCategory, iUserIdJwtDecoded} from "@gloow/apiconsumer";
import jwtDecode from "jwt-decode";


export const authentication = storeAPI => next => action => {
    if ( action.type === 'session/authenticate' ) {
        updateUserMetaData({
            hasUsedGravity: true,
            gravityLastAuthenticated: new Date()
        });

        AnalyticsService.createUserEvent(analyticsEventCategory.USER_EVENT, 'authentication', {});

        const decodedToken: iUserIdJwtDecoded = jwtDecode(action.payload.id_token)

        if (decodedToken) {
            // @ts-ignore
            if ( typeof window.customerlySettings === 'object') {
                // @ts-ignore
                window.customerlySettings = {
                    // @ts-ignore
                    ...window.customerlySettings,
                    name: decodedToken.name,
                    email: decodedToken.email,
                    attributes: {
                        user_uuid: decodedToken["https://gloow.io/users/uuid"],
                        gravityVersion: process.env.REACT_APP_GRAVITY_VERSION,
                        subscriber: decodedToken["https://gloow.io/users/subscribed"]
                    }
                };
            }
        }
    }

    if (action.type === 'session/fetchUserByToken') {
        // set customerly config
        try {
            const tokens = JSON.parse(action.payload) ?? null
            if (tokens) {
                const decodedToken: iUserIdJwtDecoded = jwtDecode(tokens.id_token)
                if (decodedToken) {
                    // @ts-ignore
                    if ( typeof window.customerlySettings === 'object') {
                        // @ts-ignore
                        window.customerlySettings = {
                            // @ts-ignore
                            ...window.customerlySettings,
                            name: decodedToken.name,
                            email: decodedToken.email,
                            attributes: {
                                user_uuid: decodedToken["https://gloow.io/users/uuid"],
                                gravityVersion: process.env.REACT_APP_GRAVITY_VERSION,
                                subscriber: decodedToken["https://gloow.io/users/subscribed"]
                            }
                        };
                    }
                }
            }
        } catch (e) {
            console.log('problem setting customerly config')
        }
    }

    return next(action)
}
