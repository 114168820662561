import { createSlice } from '@reduxjs/toolkit'
import { iLabel, iNode, iResource } from "@gloow/apiconsumer"
import { createMigrate, PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { migrations } from './filters.migration'
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { EntityDisplayMode } from '@store/entities/entities.interfaces'

interface iFiltersState {
  searchText: string,
  labels: iLabel[],
  connectionView?: iNode | iResource,
  groupByLabelMindMaps?: boolean,
  groupByLabelFocusMode?: boolean,
  showProperties?: boolean,
  showResources?: boolean,
  leftContentWidth: number,
  rightContentWidth: number,
  darkMode?: boolean,
  entityDisplayMode?: EntityDisplayMode
}

const initialState: iFiltersState = {
  searchText: '',
  labels: [],
  connectionView: undefined,
  groupByLabelMindMaps: true,
  groupByLabelFocusMode: false,
  showProperties: false,
  showResources: false,
  leftContentWidth: 223,
  rightContentWidth: 400,
  darkMode: false,
  entityDisplayMode: EntityDisplayMode.table
}

const key = 'filters'
const persistConfig: PersistConfig<any> = {
  key,
  version: 1,
  storage,
  stateReconciler: autoMergeLevel1,
  migrate: createMigrate(migrations, { debug: !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development' }),
  blacklist: ['searchText', 'labels', 'connectionView'],
}

export const filtersSlice = createSlice({
  name: key,
  initialState: initialState,
  reducers: {
    resetFilters: (state) => {
      state.searchText = ''
      state.labels = []
      state.connectionView = undefined
    },
    updateSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setLabelsFilter: (state, action) => {
      state.labels = action.payload
    },
    setConnectionView: (state, action) => {
      state.connectionView = action.payload
    },
    setGroupByLabelMindMaps: (state, action) => {
      state.groupByLabelMindMaps = action.payload
    },
    setGroupByLabelFocusMode: (state, action) => {
      state.groupByLabelFocusMode = action.payload
    },
    setShowProperties: (state, action) => {
      state.showProperties = action.payload
    },
    setShowResources: (state, action) => {
      state.showResources = action.payload
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload
    },
    setRightContentWidth: (state, action) => {
      state.rightContentWidth = action.payload
    },
    setLeftContentWidth: (state, action) => {
      state.leftContentWidth = action.payload
    },
    setEntityDisplayMode: (state, action) => {
      state.entityDisplayMode = action.payload
    }
  }
})

export const { resetFilters, updateSearchText, setLabelsFilter, setConnectionView, setGroupByLabelMindMaps, setGroupByLabelFocusMode, setShowProperties, setShowResources, setDarkMode, setRightContentWidth, setLeftContentWidth, setEntityDisplayMode } = filtersSlice.actions

export default persistReducer<iFiltersState, any>(persistConfig, filtersSlice.reducer)
