import { createSlice } from '@reduxjs/toolkit'
import { iDomainState } from './domain.interfaces'
import { iDomainNew, iLabel, iNode, iRelation, iResource, iResourceMapping } from "@gloow/apiconsumer"
import { IOService } from '@services/IOService'

export interface iDomainPermissions {
  domainUuid: string,
  exp: number,
  expand: boolean,
  iat: number,
  modify: boolean,
  owner: boolean,
  remove: boolean,
  the_rest_of_the_world: boolean,
  view: boolean
}

export interface iDomainData {
  nodes: iNode[],
  resources: iResource[],
  relations: iRelation[],
  resourceMappings: iResourceMapping[],
  activeNode: iNode | undefined,
  activeResource: iResource | undefined,
  activeLabel: iLabel | undefined,
  openDomain: iDomainNew | undefined,
  domainPermissions: iDomainPermissions | undefined,
  connectedNodes: iNode[],
  connectedResources: iResource[],
  nodeLabels: iLabel[],
  lastUpdate: number,
  labels: iLabel[],
}


const initialState: iDomainState = {
  open: false,
  openDomain: undefined,
  domains: [],
  openDomainPermissions: undefined,
  publicDomains: [],
  collaborationDomains: [],
  lastUpdate: Date.now()
}

export const domainSlice = createSlice({
  name: 'domain',
  initialState: initialState,
  reducers: {
    openDomain: (state, action) => {
      state.open = true
      state.openDomain = action.payload
      // @robby maybe this should happen on middleware?
      IOService.instance.syncActiveDomain(action.payload)
    },
    closeDomain: (state) => {
      state.open = false
      state.openDomain = undefined
      state.openDomainPermissions = undefined
    },
    setDomains: (state, action) => {
      state.domains = action.payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    },
    setPublicDomains: (state, action) => {
      state.publicDomains = action.payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    },
    setCollaborationDomains: (state, action) => {
      state.collaborationDomains = action.payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    },
    addDomain: (state, action) => {
      state.domains = [action.payload, ...state.domains]
    },
    removeDomain: (state, action) => {
      const i = state.domains.findIndex(d => d.uuid === action.payload.uuid)
      if (i !== -1) state.domains = [...state.domains.slice(0, i), ...state.domains.slice(i + 1)]
    },
    addCollaborationDomain: (state, action) => {
      state.collaborationDomains = [action.payload, ...state.collaborationDomains]
    },
    removeCollaborationDomain: (state, action) => {
      const i = state.collaborationDomains.findIndex(d => d.uuid === action.payload.uuid)
      if (i !== -1) state.collaborationDomains = [...state.collaborationDomains.slice(0, i), ...state.collaborationDomains.slice(i + 1)]
    },
    setOpenDomainPermissions: (state, action) => {
      state.openDomainPermissions = action.payload
    },
    setLastUpdate: (state, action) => {
      state.lastUpdate = action.payload
    },
    updateDomain: (state, action) => {
      const updatedDomains = state.domains.map(d => {
        if (d.uuid === action.payload) return action.payload
        return d
      })
      state.domains = updatedDomains
    }
  }
})

export const { openDomain, closeDomain, addDomain, removeDomain, setDomains, setOpenDomainPermissions, setPublicDomains, setCollaborationDomains, addCollaborationDomain, removeCollaborationDomain, setLastUpdate, updateDomain } = domainSlice.actions

export default domainSlice.reducer
