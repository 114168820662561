import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  image: { objectFit: 'cover', borderRadius: '50%' },
  reactAvatar: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        fontSize: '1rem',
      }
    }
  },
  reactAvatarSM: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        fontSize: 12,
      }
    }
  },
  reactAvatarXS: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        fontSize: 10,
      }
    }
  },
  '@supports ( -moz-appearance:none )': {
    reactAvatar: {
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontSize: '1rem',
          paddingTop: 2
        }
      }
    },
    reactAvatarSM: {
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontSize: 12,
          paddingTop: 2
        }
      }
    },
    reactAvatarXS: {
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontSize: 10,
          paddingTop: 2
        }
      }
    }
  },
}))