import React, { ReactElement } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core'
import useStyles from './ConfirmDialog.styles'
interface iConfirmDialog {
  open: boolean,
  loading?: boolean,
  onConfirm: () => void,
  onClose: () => void,
  confirmText: string,
  cancelText: string,
  text?: string,
  textComponent?: ReactElement
}

const ConfirmDialog = ({ open, loading = false, onConfirm, onClose, confirmText, text = '', textComponent = <></>, cancelText = 'Cancel' }: iConfirmDialog) => {
  const classes = useStyles()
  return (
    <Dialog id="confirm-dialog" onClose={onClose} open={open} classes={{ paper: classes.paper }}>
      <DialogTitle>
        {textComponent}
        {text && <Typography variant={'body1'} align={'center'}>{text}</Typography>}
      </DialogTitle>
      <DialogActions className={classes.actions}>
        <Button id="confirm-dialog-cancel" disabled={loading} className={classes.cancelButton} onClick={onClose} variant="contained" color="secondary" fullWidth>{cancelText}</Button>
        <Button id="confirm-dialog-confirm" disabled={loading} onClick={onConfirm} variant="contained" className={classes.confirm} fullWidth>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
