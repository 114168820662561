import React from 'react'
import { Button } from '@material-ui/core'
import useStyles from './FilterForm.styles'
import { iLabel } from "@gloow/apiconsumer"
import LabelCheckbox from './LabelCheckbox'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { resetFilters, setConnectionView, setLabelsFilter } from '@store/filters/filtersSlice'
import { instanceOfNode, instanceOfResource } from '@helpers/utils'
import { NodeAutocomplete, NodeCard, ResourceCard } from '@common/components'
import { useTranslation } from 'react-i18next'
import useDomain from '@hooks/useDomain'

const FilterForm = ({
  onClose,
  labels = []
}: {
  onClose?: () => void,
  labels: iLabel[]
}) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { nodes } = useDomain()
  const filterLabels = useAppSelector(state => state.filters.labels)
  const connectionView = useAppSelector(state => state.filters.connectionView)
  const filterCount = filterLabels.length + (connectionView ? 1 : 0)
  const ids = filterLabels.map(d => d.id)

  const filter = (label: iLabel) => {
    const newFilterLabels: iLabel[] = [...filterLabels]
    const index = filterLabels.findIndex(d => d.id === label.id)
    if (index > -1) newFilterLabels.splice(index, 1)
    else newFilterLabels.push(label)
    dispatch(setLabelsFilter(newFilterLabels))
  }

  const renderConnectionView = () => {
    if (connectionView) {
      if (instanceOfNode(connectionView)) {
        return <NodeCard disabled={true} node={connectionView} onRemove={() => dispatch(setConnectionView(undefined))} />
      }
      else if (instanceOfResource(connectionView)) {
        return <ResourceCard disabled={true} resource={connectionView} hidePreview={true} onRemove={() => dispatch(setConnectionView(undefined))} />
      }
    }
    return <div className={classes.emptyConnection + ' ' + classes.emptyText}>{t('common.no_active_connection')}</div>
  }

  return (
    <div className={classes.container + ' filter-form'}>
      <div className={classes.connectionContainer}>
        <label className={classes.filterTitle + ' filterTitle'}>{t('common.active_connection')}</label>
        {!connectionView ?
          <NodeAutocomplete autoHighlight options={nodes} onChange={(e, node) => dispatch(setConnectionView(node))} />
          :
          renderConnectionView()
        }
      </div>
      <div className={classes.labelContainer}>
        <label className={classes.filterTitle}>{t('common.labels')}</label>
        {labels.map(d => <LabelCheckbox key={d.id} label={d} active={ids.includes(d.id)} onClick={() => filter(d)} />)}
        {!labels.length && <div className={classes.emptyText}>{t('common.no_labels')}</div>}
      </div>
      {filterCount > 0 && (
        <Button className={classes.clearAll} onClick={() => dispatch(resetFilters())}>{t('common.clear_all')}</Button>
      )}
    </div>
  )
}

export default FilterForm
