import GridResourceCard from '@common/components/GridView/GridResourceCard'
import { Card, TextField, Typography } from '@material-ui/core'
import { AnswerPublic, Question } from '@gloow/apiconsumer'
import { useAppSelector } from '@store/hooks'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './Assistant.styles'
import * as yup from 'yup'
import InfoIcon from '@assets/images/icons-info.svg'
import { Skeleton } from '@material-ui/lab'
import { useHistory, useParams } from 'react-router-dom'
import { generateDetailURL } from '@helpers/utils'
import { contentType } from '@common/constants/Constants'
import Carousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css'
const carouselSetting = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

const Assistant = ({
  currentQuestion,
  data,
  loading = false,
  error,
  onSubmit = (values) => { }
}: {
  currentQuestion?: Question,
  data?: AnswerPublic,
  loading?: boolean,
  error?: { message: string, status: number | null },
  onSubmit: (values) => void
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const resources = useAppSelector(state => state.resources.resources)
  const resourceIds = data?.selectedResources?.map(d => d.resourceUuid)
  const selectedResources = resources.filter(d => resourceIds?.includes(d.uuid))
  // @ts-ignore
  const { slug } = useParams()
  const history = useHistory()
  const form = useRef<any>(null)
  const schema = yup.object({
    question: yup.string().required(t('assistant.question_is_required', 'Question is required')),
  })

  const initialValues = {
    question: '',
  }

  useEffect(() => {
    if (currentQuestion) form.current?.resetForm()
    return () => { }
    // eslint-disable-next-line
  }, [currentQuestion?.id])


  const renderLoading = useCallback(() => {
    return <>
      <div className={`${classes.innerContainer} ${classes.answerContainer}`}>
        <Skeleton style={{ width: '30%', height: 25 }}></Skeleton>
        <Skeleton style={{ width: '100%', height: 25 }}></Skeleton>
        <Skeleton style={{ width: '100%', height: 25 }}></Skeleton>
        <Skeleton style={{ width: '80%', height: 25 }}></Skeleton>
      </div>
      <div className={classes.gridContainer}>
        {[0, 1, 2].map(d =>
          <Card className={classes.gridCardLoading} key={d.toString()}>
            <Skeleton style={{ width: '40%', height: 25 }}></Skeleton>
            <Skeleton style={{ width: '100%', height: 25 }}></Skeleton>
            <Skeleton style={{ width: '100%', height: 25 }}></Skeleton>
            <Skeleton style={{ width: '80%', height: 25 }}></Skeleton>
          </Card>
        )}
      </div>
    </>
    // eslint-disable-next-line
  }, [])
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      // onMove,
      // index,
      active,
      // carouselState: {
      //   currentSlide,
      //   deviceType
      // }
    } = rest;
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <button
        type='button'
        className={`${classes.sliderDot} ${active ? "active" : "inactive"}`}
        onClick={() => onClick()}
      />
    );
  };

  const renderError = useCallback(() => {
    if (!error) return <></>
    switch (error?.status) {
      case 500:
      case 501:
      case 503:
        return <div className={classes.errorContainer}>
          <Typography variant='body1'>There is a problem with your domain, our engineers are notified and we are working on it. Please contact support (<a href='mailto:info@gloow.io' className={classes.email}>info@gloow.io</a>) for more information</Typography>
        </div>
      default:
        return <div className={classes.errorContainer}>
          <Typography variant='body1'>{error?.message ?? 'Something wrong, please try again later'}</Typography>
        </div>
    }
    // eslint-disable-next-line
  }, [error])

  return (
    <Formik innerRef={form} initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ handleSubmit, errors, handleChange, values }) => (
        <form onSubmit={handleSubmit} className={classes.container}>
          <Typography variant={'h4'} className={classes.title}>{t('assistant.assistant', 'Assistant')}</Typography>
          <div className={classes.contentContainer}>
            {(Boolean(!data) || loading) && (
              <div className={classes.innerContainer}>
                <TextField
                  disabled={loading || error?.status === 401 || error?.status === 404}
                  fullWidth
                  className={classes.textField}
                  value={values.question}
                  name={'question'}
                  label={t('assistant.question', 'Question')}
                  error={Boolean(errors.question)}
                  helperText={errors.question}
                  onChange={handleChange}
                />
              </div>
            )}
            {renderError()}
            {loading ?
              renderLoading()
              : (data && <>
                <div className={classes.innerContainer}>
                  <label className={classes.questionLabel}>{t('assistant.question', 'Question')}</label>
                  <Typography variant='h3' className={classes.question}>{currentQuestion?.question}</Typography>
                </div>
                <div className={`${classes.innerContainer} ${classes.answerContainer}`}>
                  <Typography variant='subtitle1' className={classes.answerTitle}>{t('assistant.answer', 'Answer')}</Typography>
                  <Typography variant='body1' className={classes.answerContent}>{data?.answers?.join(' ')}</Typography>
                </div>
                {selectedResources?.length > 0 && (
                  <>
                    <div className={classes.innerContainer}>
                      <Typography variant={'subtitle2'} className={classes.suggestionTitle}>
                        {t('assistant.you_may_find_more_information', 'You may find more information in these resources')}
                      </Typography>
                    </div>
                    <Carousel
                      swipeable={true}
                      autoPlay={false}
                      keyBoardControl={false}
                      showDots={true}
                      containerClass={classes.horizontalSlider}
                      responsive={carouselSetting}
                      shouldResetAutoplay={false}
                      itemClass={classes.sliderItem}
                      // @ts-ignore
                      customDot={<CustomDot />}
                    >
                      {selectedResources.map((d, index) =>
                        <GridResourceCard
                          key={index.toString()}
                          data={d}
                          onClick={() => history.push(generateDetailURL(`/${slug}/grid/resources`, { id: d.id, type: contentType.RESOURCE }))}
                        />
                      )}
                    </Carousel>
                  </>
                )}
                <div className={classes.innerContainer}>
                  <TextField
                    disabled={loading}
                    fullWidth
                    className={classes.textField}
                    value={values.question}
                    name='question'
                    label={t('assistant.what_is_your_next_question', 'What is your next question?')}
                    error={Boolean(errors.question)}
                    helperText={errors.question}
                    onChange={handleChange}
                  />
                </div>
              </>)}
            <div className={`${classes.innerContainer} ${classes.info}`}>
              <img alt='info' src={InfoIcon} className={classes.infoIcon} />
              <Typography variant='body2' className={classes.infoText}>
                {t('assistant.ask_a_free_form_question', 'Ask a free-form question about what is contained in the notes and the system will find an answer for you if there is one.')}
              </Typography>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default Assistant