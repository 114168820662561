import Colors from "@common/constants/Colors";
import { createTheme } from "@material-ui/core";
import { store } from './store'

export const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
  palette: {
    type: store.getState().filters.darkMode ? 'dark' : 'light',
    primary: {
      main: Colors.primary,
      dark: Colors.bgDark,
    },
    info: {
      main: '#c7ccf5'
    },
    secondary: {
      main: store.getState().filters.darkMode ? '#424242' : Colors.white
    },
    background: {
      default: store.getState().filters.darkMode ? '#303030' : Colors.greyBackground
    },
    text: { primary: store.getState().filters.darkMode ? '#fafafa' : '#060a22' }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          scrollbarColor: store.getState().filters.darkMode ? 'rgba(255,255,255,0.2) transparent' : `${Colors.scrollBarColor} transparent`,
          scrollbarWidth: 'thin'
        },
        '*::-webkit-scrollbar': {
          width: '0.45em',
          height: '0.45em'
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
          borderRadius: 12
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: store.getState().filters.darkMode ? 'rgba(255,255,255,0.2)' : Colors.scrollBarColor,
          borderRadius: 12
        },
        '.link-button': {
          backgroundColor: 'transparent',
          boxShadow: 'none !important'
        },
        '.underline': {
          textDecoration: 'underline !important'
        },
        '.select-none': {
          WebkitUserSelect: 'none',
          userSelect: 'none',
          MozUserSelect: 'none',
        }
      }
    },
    MuiButton: {
      root: {
        fontSize: 16,
        textTransform: 'none',
        fontWeight: 'normal',
        boxShadow: `0 6px 12px 0 ${Colors.shadowColor} !important`,
        borderRadius: 12,
        padding: '15px',
        lineHeight: 1,
        '&:hover': {
          backgroundColor: Colors.lightBlue2
        }
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: Colors.bgDark
        }
      },
      containedSecondary: {
        color: Colors.primary,
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)'
        }
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: Colors.lightBlue2
        }
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiInput: {
      underline: {
        '&:hover': {
          '&::before': {
            borderBottom: '1px solid #4354dd !important'
          }
        },
        '&::before': {
          borderBottom: '1px solid #d0d0da'
        }
      }
    },
    MuiCard: {
      root: {
        background: store.getState().filters.darkMode ? '#424242' : Colors.white,
        boxShadow: `0 4px 12px 0 ${Colors.shadowColor} !important`,
      }
    },
    MuiMenu: {
      paper: {
        boxShadow: `0 4px 12px 0 ${Colors.shadowColor} !important`,
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: `0 4px 12px 0 ${Colors.shadowColor} !important`,
      }
    },
    MuiChip: {
      colorSecondary: {
        backgroundColor: Colors.primaryFaded,
        color: Colors.white
      },
      clickableColorSecondary: {
        backgroundColor: Colors.primaryFaded,
        color: Colors.white,
        '&:hover,&:visited,&:active,&:focus': {
          backgroundColor: `${Colors.primary} !important`,
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 6,
        padding: '4px 8px',
        backgroundColor: Colors.secondaryToast
      }
    },
    MuiTableContainer: {
      root: {
        height: '100%',
        overflowY: 'auto',
        '& .MuiTable-root': {
          '& .MuiTableCell-root': {
            padding: '12px 8px',
            minWidth: 170,
            '&.column-docs': {
              minWidth: 60
            },
            '&.column-selection': {
              minWidth: 'unset',
              width: 60,
              margin: 0
            }
          },
          '& .MuiTableCell-sizeSmall': {
            minWidth: 'unset'
          },
          '& .MuiTableCell-sizeMedium': {
            minWidth: 'unset'
          },
        },
        backgroundColor: 'transparent',
      }
    },
    MuiTableHead: {
      root: {
        '& .MuiTableCell-head': {
          fontWeight: 600
        },
      },
    },
    MuiTableBody: {
      root: {
        '& tr': {
          '& td': {
            border: 'none'
          }
        }
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: 'white'
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: Colors.lightBlue2
        },
        '&:active': {
          backgroundColor: Colors.lightBlue2
        },
        '&:focus': {
          backgroundColor: Colors.lightBlue2
        }
      }
    }
  },
});