import { saveResource, uploadFile } from "@helpers/media"
import { getResourceType } from "@helpers/resource"
import { iResource } from "@gloow/apiconsumer"
import {
  Editor,
  Node,
  Transforms,
  Element
} from "slate"
import { ReactEditor } from "slate-react"
import {
  // defaultEntityContents,
  imageMimes
} from "@common/constants/Constants"
import { store } from "@store/index"

export const withImages = (editor: ReactEditor) => {
  const { isVoid, normalizeNode, isInline } = editor

  editor.isInline = (element: any) => {
    return element.type === 'image' ? false : isInline(element)
  }

  editor.isVoid = (element: any) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = async (data) => {
    try {
      const { files } = data
      await uploadImages(editor, files)
    } catch (error) {
    }
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry
    // @ts-ignore
    if (Element.isElement(node) && node.type === 'image') {
      // @ts-ignore
      if (typeof node?.caption === 'undefined') Transforms.setNodes(editor, { ...node, caption: '' }, { at: path })
      for (const [child, childPath] of Node.children(node, [])) {
        // @ts-ignore
        if (!!child?.children) Transforms.delete(editor, { at: childPath })
      }
    }
    normalizeNode(entry)
  }
  return editor
}

const insertLoader = (editor, fileName) => {
  const loaderEl = { type: 'image', value: {}, caption: fileName, url: '', children: [{ text: fileName }] }
  Transforms.insertNodes(editor, loaderEl)
  return loaderEl
}

const insertImage = (editor, path, url, resource?: iResource) => {
  const text = { text: resource?.title ?? '' }
  const image = {
    type: 'image',
    value: resource ? {
      id: resource?.id,
      uuid: resource?.uuid,
    } : null,
    caption: resource?.title ?? '',
    url,
    children: [text]
  }
  ReactEditor.focus(editor)
  if (Editor.hasPath(editor, path)) Transforms.setNodes(editor, { ...image }, { at: path })
  else Transforms.insertNodes(editor, [image])
}

export const insertImageEvent = (editor, nodeId?: number) => {
  const input = document.createElement("input");
  input.setAttribute('type', 'file');
  input.setAttribute('accept', imageMimes.join(','));
  input.onchange = async (e) => {
    // @ts-ignore
    await uploadImages(editor, e.target.files, nodeId)
    input.remove()
  }
  input.click()
}

export const uploadImages = async (editor, files: FileList | File[], nodeId?: number) => {
  const node = store.getState().nodes.nodes.find(d => d.id === nodeId)
  for (const file of files) {
    if (!imageMimes.includes(file?.type ?? '')) {
      console.log('allowed only for', imageMimes)
      continue
    }
    const loaderNode = insertLoader(editor, file.name)
    const url = await uploadFile(file)
    const image = { originalFileName: file.name, location: url }
    const type = getResourceType(file.type)
    const rs = await saveResource(image, type, file.type, node)
    try {
      ReactEditor.focus(editor)
      const loaderPath = ReactEditor.findPath(editor, loaderNode)
      if (rs) await insertImage(editor, loaderPath, url, rs)
      else if (Editor.hasPath(editor, loaderPath)) Transforms.removeNodes(editor, { at: loaderPath })
    } catch (error) {
      console.log(error, 'upload images')
    }
  }
}

export const onDropImage = async (event, editor, nodeId) => {
  event.preventDefault();
  const files = Array.from(event.dataTransfer.items).map((item: any) => item.getAsFile() as File)
  uploadImages(editor, files, nodeId)
}