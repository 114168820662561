import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, Typography } from '@material-ui/core'
import { Domain, iNode, iOntology } from '@gloow/apiconsumer'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Indicator } from '@common/components'
import { createDomain, createManyNodes, updateDomain as _updateDomain } from '@services/DomainDataService'
import { useAppDispatch } from '@store/hooks'
import { assignRandomColor } from '@common/constants/Colors'
import useStyles from './OnboardingV2.styles'
import { applyOntologiesToDomain, applyOntologiesToNodes, getDomainRoot, getOntologiesMembers } from '@services/LabelService'
import { updateDomain } from '@store/domain/domainSlice'
import CreateDomain from './CreateDomain'
import ChooseMember from './ChooseMember'
import CreateNode from './CreateNode'
import { completeOnboardingEvent } from '@store/user/userSlice'
import { OnboardingEvents } from '@common/constants/Constants'

export interface SimpleDialogProps {
  open: boolean
  onClose: () => void
}

function BusinessOnboarding(props: SimpleDialogProps) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { onClose, open } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedNodes, setSelectedNodes] = useState<Partial<iNode>[]>([])
  const [domain, setDomain] = useState<any>({
    name: "",
    description: "",
    id: undefined,
    uuid: "",
    labels: []
  })
  const [active, setActive] = useState(1)
  const [questionActive, setQuestionActive] = useState(0)
  const [error, setError] = useState('')

  const [members, setMembers] = useState<iOntology[]>([])
  const [selectedMembers, setSelectedMembers] = useState<iOntology[]>([])
  const [currentMember, setCurrentMember] = useState<iOntology | null>(null)


  const getBusinessMembers = async () => {
    try {
      setLoading(true)
      const businessLabel = domain?.labels?.find(d => d.name.toLowerCase().includes('business') && d.ontologyClass)
      if (!businessLabel) return setMembers([])
      const businessMembers = await getOntologiesMembers([businessLabel.ontologyClass])
      if (!businessMembers) return setMembers([])
      setMembers(businessMembers)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (active === 2 && domain.labels.length > 0) getBusinessMembers()
    return () => { }
    // eslint-disable-next-line
  }, [active, domain.labels])


  const onSubmit = async (e) => {
    e.preventDefault()
    if (active === 1) {
      if (!domain.name) return setError(t('common.name_is_required'))
      if (!domain.id) await onCreateDomain()
      else {
        setLoading(true)
        const updatedDomain = await _updateDomain({
          ...domain,
          labels: domain?.labels?.map(d => d.id)
        })
        setDomain(updatedDomain)
      }
      setActive(active + 1)
      setLoading(false)
    } else if (active === 2) {
      if (!selectedMembers.length) return
      setCurrentMember(selectedMembers[0])
      return setActive(active + 1)
    } else {
      setLoading(true)
      if (selectedNodes.length > 0) {
        const result = await createManyNodes(domain, selectedNodes)
        if (result) {
          const ds = new Domain()
          await ds.openDomain(domain.uuid)
          await applyOntologiesToNodes(result.map(d => d.id), [currentMember!])
        }
        dispatch(completeOnboardingEvent(OnboardingEvents.CreateNode))
      }
      const idx = selectedMembers.findIndex(d => d.id === currentMember?.id)
      setLoading(false)
      setSelectedNodes([])
      if (selectedMembers[idx + 1]) return setCurrentMember(selectedMembers[idx + 1])
      return history.push(`/${domain.slug ?? domain.uuid}/mind-maps`)
    }
  }

  const onCreateDomain = async () => {
    try {
      setLoading(true)
      let newDomain = await createDomain(domain)
      const domainRoot = await getDomainRoot()
      if (domainRoot.length > 0) {
        const business = domainRoot.find(d => d.name.toLowerCase().includes('business'))
        if (business) {
          const businessLabel = await applyOntologiesToDomain(newDomain.uuid, [business])
          newDomain = { ...newDomain, labels: [...newDomain.labels, ...businessLabel] }
          dispatch(updateDomain(newDomain))
        }
      }
      setActive(active + 1)
      setDomain(newDomain)
      setLoading(false)
      return newDomain
    } catch (error) {
      console.log(error)
      setLoading(false)
      return false
    }
  }

  const onSelected = (node) => {
    setSelectedNodes([{
      name: node.name,
      info: "",
      labels: [],
      color: assignRandomColor()
    }, ...selectedNodes])
  }

  const onDeleted = (node) => {
    const updatedSelected = selectedNodes.filter(d => d.name !== node.name)
    setSelectedNodes(updatedSelected)
  }

  const memberOnClick = (member) => {
    const exist = selectedMembers.find(d => d.id === member.id)
    if (exist) return setSelectedMembers(selectedMembers.filter(d => d.id !== member.id))
    return setSelectedMembers([...selectedMembers, member])
  }

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.dialog}>
        <Indicator active={active} steps={3} />
        <form onSubmit={onSubmit} noValidate className={classes.dialogForm}>
          <div className={classes.dialogContent}>
            <CloseIcon className={classes.dialogBackIcon} onClick={() => onClose()} />
            {active === 1 && <CreateDomain
              title={t('onboarding.what_kind_of_data')}
              description={t('onboarding.what_business_industry')}
              domain={domain}
              setDomain={setDomain}
              error={error}
            />}
            {active === 2 && <ChooseMember
              title={t('onboarding.what_kind_of_data')}
              description={t('onboarding.choose_labels')}
              loading={loading}
              ontologies={members}
              selected={selectedMembers}
              onClick={memberOnClick}
            />}
            {active === 3 && <CreateNode
              title={t('onboarding.question_index', { index: selectedMembers.findIndex(d => d.id === currentMember?.id) + 1, length: selectedMembers.length })}
              // @ts-ignore
              description={t('onboarding.type_in_names_of_plural_name', { name: selectedMembers?.pluralName ? selectedMembers?.pluralName : selectedMembers?.name })}
              selectedNodes={selectedNodes}
              onSelected={onSelected}
              onDeleted={onDeleted}
              member={currentMember}
            />}
          </div>
          <div className={classes.dialogActions}>
            {active !== 1 && !loading ? (
              <Typography
                className={classes.dialogActionsBack}
                onClick={() => {
                  if (questionActive <= members.length - 1 && questionActive !== 0)
                    setQuestionActive(questionActive - 1)
                  else setActive(active - 1)
                }}
              >
                {t('common.go_back')}
              </Typography>
            ) : (<div></div>)}
            <Button
              type="submit"
              className={classes.dialogActionsButton}
              variant="contained"
              color="primary"
              disabled={(active > 1 && !selectedMembers?.length) || loading}
            >
              {t('common.next')}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

export default BusinessOnboarding;
