import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {},
  inputContainer: { position: 'relative' },
  textField: {
    padding: 4,
    paddingLeft: 42,
    backgroundColor: Colors.lightBlue2,
    width: '100%',
    borderRadius: 12,
    fontSize: 14,
  },
  icon: { height: 34, width: 34, position: 'absolute', margin: 'auto 0', paddingLeft: 16, top: 0, bottom: 0, zIndex: 100 },
  autoCompletePopper: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '& li': {
      fontSize: 14,
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: `${Colors.lightBlue2} !important`
      },
      '&.MuiAutocomplete-option[data-focus="true"]': {
        backgroundColor: `${Colors.lightBlue2} !important`
      },
      '&:active': {
        backgroundColor: `${Colors.lightBlue2} !important`
      },
      '&:hover': {
        backgroundColor: `${Colors.lightBlue2} !important`
      },
      '&:focus': {
        backgroundColor: `${Colors.lightBlue2} !important`
      },
      '&:visited': {
        backgroundColor: `${Colors.lightBlue2} !important`
      }
    }
  },
  autoCompleteNoOptions: {
    fontSize: 14,
    padding: '6px 12px !important'
  },
}))