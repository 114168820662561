import React from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import Colors from '@common/constants/Colors'
import CheckIcon from '@material-ui/icons/Check'
import { decamelCase } from '@helpers/utils'
import useStyles from './ColorList.styles'

const ColorList = ({ onSelected, selectedValue }: { onSelected: (value: string) => any, selectedValue: string }): React.ReactElement => {
  const classes = useStyles()
  const handleListItemClick = (value: string) => {
    onSelected(value);
  };

  return (
    <List>
      {Object.keys(Colors.newColors).map((color, i) => (
        <ListItem id={color} className={classes.colorItem} button onClick={() => handleListItemClick(Colors.newColors[color])} key={color}>
          <div
            className={classes.color}
            style={{
              backgroundColor: Colors.newColors[color]
            }}
          ></div>
          <ListItemText className={classes.colorName} primary={decamelCase(color)} />
          {selectedValue === Colors.newColors[color] && <CheckIcon color="primary" />}
        </ListItem>
      ))}
    </List>
  )
}

export default ColorList
