import React from 'react'
import { CardActionArea, IconButton } from '@material-ui/core'
import { HighLightedText } from '@common/components'
// import { assignRandomColor } from '@common/constants/Colors'
import { iNode } from "@gloow/apiconsumer"
import useStyles from './NodeCard.styles'
import deleteIcon from '@assets/images/icons-unlink.svg'
// import { getAvatarURL } from '@helpers/utils'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@common/components/Avatar/Avatar'
interface INodeCard {
  node: iNode,
  hideStat?: boolean,
  hideContent?: boolean,
  onClick?: (node: iNode) => void,
  onRemove?: (node: iNode) => void,
  highlight?: string,
  selected?: boolean,
  disabled?: boolean,
}

const NodeCard = ({
  node,
  hideStat = false,
  hideContent = false,
  highlight = "",
  selected = false,
  disabled = false,
  onClick = (node: iNode) => { },
  onRemove = undefined
}: INodeCard) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const _getImage = () => {
    return (
      <Avatar
        name={node.name}
        src={node.thumbnailUrl}
        alt={node.name}
        color={node.color}
      />
    )

    // @deprecated
    // if (node.thumbnailUrl) {
    //   return (
    //     <img className={classes.imageItem} src={node.thumbnailUrl} alt="node" />
    //   )
    // } else {
    //   const color = node.color
    //     ? node.color.replace('#', '')
    //     : assignRandomColor().replace('#', '')


    //   const uri = getAvatarURL({ name: node.name, background: color, size: 128 })
    //   return <img className={classes.imageItem} src={uri} alt="node" />
    // }
  }

  const getStatValue = key => {
    // @ts-ignore
    if (typeof node[key] === Array) return node[key].length ?? 0
    return node && node[key] ? node[key] : 0
  }

  const renderLabel = () => {
    const labelText = (node.labels ?? []).map(l => l.name).join(', ')

    return (
      <div className={`${classes.labels} labels`}>
        {node.labels?.length !== 0 ? <HighLightedText text={labelText} highlight={highlight} ></HighLightedText> : t('common.no_labels')}
      </div>
    )
  }

  return (
    <div className={`${classes.outerContainer} node-card-wrapper`}>
      <CardActionArea disabled={disabled} className={classes.container + ' node-card ' + (selected ? classes.selected : '')} onClick={() => onClick(node)}>
        {/* Left side, node avatar */}
        <div className={classes.imageContainer}>
          {_getImage()}
        </div>

        <div className={`${classes.textWrapper} textWrapper ${hideContent ? classes.hideContent : ''}`}>
          {/* Node Title */}
          <div className={classes.nodeName}>
            {node.name}
          </div>

          {/* Labels */}
          {renderLabel()}

          {/* Stats */}
          {!hideStat && (
            <div className={classes.stat}>
              {['relations', 'resources'].map((stat, key) => (
                <div className={classes.statItem} key={key}>
                  <span className={classes.statCount}>{getStatValue(stat)}</span>
                  {t(`common.${stat}`).toLowerCase()}
                </div>
              ))}
            </div>
          )}
        </div>
      </CardActionArea>
      {onRemove &&
        <IconButton size='small' id="remove-node" className={classes.removeButton} onClick={() => onRemove(node)}>
          <img src={deleteIcon} alt='delete' />
        </IconButton>
      }
    </div>
  );
}

export default NodeCard;
