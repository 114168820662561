import React from 'react';
import moment from 'moment';
import { CardActionArea, IconButton } from '@material-ui/core';
import { iResource, eResourceType } from "@gloow/apiconsumer";
import useStyles from './ResourceCard.styles'
import deleteIcon from '@assets/images/icons-unlink.svg'
import { getResourceInfo, getResourceThumbnail, getResourceIcon } from '@helpers/resource'
import getVideoId from 'get-video-id'
import { useTranslation } from 'react-i18next'
interface IResourceCard {
  resource?: any,
  onClick?: (resource: iResource) => void,
  onRemove?: (resource: iResource) => void,
  selected?: boolean
  hidePreview?: boolean
  hideContent?: boolean,
  disabled?: boolean
}

const ResourceCard = ({
  resource,
  onClick = (resource) => { },
  onRemove = undefined,
  selected = false,
  hidePreview = false,
  hideContent = false,
  disabled = false
}: IResourceCard) => {
  const classes = useStyles()
  const info = getResourceInfo(resource)
  const { t, i18n } = useTranslation()
  const isHasThumbnail = getResourceThumbnail(resource, false)

  const renderContent = () => {
    if ([eResourceType.YOUTUBE, eResourceType.VIMEO].indexOf(resource?.type!) > -1) {
      const video = getVideoId(resource?.url!)
      const iframeURL = video.service === 'youtube' ? 'https://youtube.com/embed' : 'https://player.vimeo.com/video'
      return <div className={`${classes.imageContainer} ${classes.imageContainerFull}`}>
        <iframe
          className={classes.iframe}
          src={`${iframeURL}/${video?.id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={resource?.title}
          width="200"
          height="200"
        />
      </div>
    }
    else if (resource?.type === eResourceType.VIDEO_FILE) {
      return <div className={`${classes.imageContainer} ${classes.imageContainerFull}`}>
        <video className={classes.iframe} controls>
          <source src={resource?.url} type={resource?.mimeType} />
        </video>
      </div>
    }
    return (
      <>
        {isHasThumbnail &&
          <div className={`${classes.imageContainer} ${classes.imageContainerFull}`}>
            <img className={classes.imageItem} src={getResourceThumbnail(resource)} alt={resource.title} />
          </div>
        }
      </>
    )
  }

  return (
    <div className='relative resource-card-wrapper'>
      <CardActionArea disabled={disabled} className={'resource-card ' + (selected ? classes.selected : '')} onClick={() => onClick(resource)}>
        <div className={classes.container + ' ' + (hideContent ? classes.hideContainer : '')}>
          <div className={classes.imageContainer}>
            <img
              className={classes.imageItem}
              src={isHasThumbnail && !hidePreview ? getResourceIcon(resource?.type) : getResourceThumbnail(resource)}
              alt={t('common.resources')}
            />
          </div>

          <div className={`${classes.textWrapper} textWrapper ${hideContent ? classes.hideContent : ''}`}>
            {/* Resource Title */}
            <div className={classes.resourceName}>
              {resource.metaData?.title?.length > 0
                ? resource.metaData?.title
                : resource.title ?? t('common.picture_without_description')}
            </div>

            {/* Resource Desc */}
            <div className={`${classes.resourceDesc} description`}>
              {info.length > 60 ? info.slice(0, 60) : info}
            </div>

            {/* Resource Date */}
            <div className={classes.resourceDate}>{moment(resource.updatedAt).locale(i18n.language).fromNow()}</div>
          </div>
        </div>

        {!hidePreview ? renderContent() : null}
      </CardActionArea>
      {onRemove &&
        <IconButton id="remove-resource" className={classes.removeButton} onClick={() => onRemove(resource)}>
          <img src={deleteIcon} alt={t('common.delete')} />
        </IconButton>
      }
    </div>
  );
}

export default ResourceCard;
