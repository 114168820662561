import React from 'react'
import useStyles from './TryGloowDialog.styles'
import { Button, IconButton, Typography } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const TryGloowDialog = ({ open, onClose, onConfirm }: { open: boolean, onClose: () => void, onConfirm: () => void }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={`${classes.container} ${open ? classes.open : classes.closed}`}>
      <div className={classes.header} >
        <Typography variant={'h3'} className={classes.title}>{t('subscription.try_gloow_for_free')}</Typography>
        <IconButton onClick={onClose} className={classes.closeButton}><CloseOutlined className={classes.closeIcon} /></IconButton>
      </div>
      <div className={classes.content}>
        <Typography variant={'body1'} className={classes.contentText}>
          {t('subscription.easily_find_what_you_are_looking_for')}<br />
          {t('auth.sign_up_to_edit_or_create')}
        </Typography>
        <Button variant="contained" color="primary" className={classes.confirm} onClick={onConfirm}>{t('auth.sign_up_for_free')}</Button>
      </div>
    </div>
  )
}

export default TryGloowDialog
