import React, { useRef, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import iconAddPictureBlue from '@assets/images/icons-add-picture-blue.png';
import { useTranslation } from 'react-i18next';
import iconPencilWhite from '@assets/images/icons-pencil-white.svg'
import iconDelete from '@assets/images/delete.svg'
import Colors from '@common/constants/Colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarWrapper: {
      borderRadius: 40,
      width: 64,
      height: 64,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      marginRight: 16,
      cursor: 'pointer'
    },
    avatar: {
      borderRadius: 40,
      height: 56,
      width: 56,
      borderWidth: 2,
      borderColor: 'white',
      objectFit: 'cover',
      position: 'absolute'
    },
    avatarInput: {
      display: 'none'
    },
    editIcon: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      margin: 'auto 0',
      backgroundColor: 'transparent !important',
      borderRadius: '50%',
      width: 20,
      height: 20
    },
    menuItem: {
      display: 'flex',
      gap: 8,
      padding: '8px 16px',
    },
    icon: {
      width: 20,
      height: 20
    },
    nodeIconColor: {
      width: 20,
      height: 20,
      borderRadius: '50%'
    },
    nodeColorWrap: {
      width: 64,
      height: 64,
      borderRadius: '50%'
    }
  }),
);


interface IAvatarField {
  url?: string,
  editable: boolean,
  color?: string,
  onChangeFiles: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeColor: () => void,
  onRemove: () => void,
}

const AvatarField = ({
  url,
  color = Colors.default(),
  editable = false,
  onChangeFiles,
  onChangeColor,
  onRemove = () => { },
}: IAvatarField) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const fileRef = useRef<any>(null);
  const divRef = useRef<any>(null)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const allowedFileExt = 'image/jpg,image/jpeg,image/png';
  const renderMenu = () => {
    return <Menu
      open={openMenu}
      anchorEl={divRef.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
      transformOrigin={{ horizontal: 'left', vertical: 'center' }}
      getContentAnchorEl={null}
      onClose={() => setOpenMenu(false)}
    >
      <MenuItem className={classes.menuItem} onClick={() => {
        setOpenMenu(false)
        onChangeColor()
      }}>
        <div
          className={`${classes.nodeIconColor}`}
          style={{
            backgroundColor:
              Colors.newColors[color]
                ? Colors.newColors[color]
                : color
          }}
        />
        <Typography variant="body2">{t('common.change_color', 'Change color')}</Typography>
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={() => {
        fileRef.current.click()
        setOpenMenu(false)
      }}>
        <img alt='change' src={iconAddPictureBlue} className={classes.icon} />
        <Typography variant="body2">{t('common.change_picture', 'Change picture')}</Typography>
      </MenuItem>
      {url && <MenuItem className={classes.menuItem} disabled={!editable} onClick={() => {
        setOpenMenu(false)
        onRemove()
      }}>
        <img alt='delete' src={iconDelete} className={classes.icon} />
        <Typography variant="body2">{t('common.remove_picture', 'Remove picture')}</Typography>
      </MenuItem>
      }
    </Menu>
  }

  return (
    <>
      <div onClick={() => editable ? setOpenMenu(true) : {}}>
        <input id="avatar-input" className={classes.avatarInput} accept={allowedFileExt} ref={fileRef} type='file' onChange={(e) => {
          e.preventDefault()
          onChangeFiles(e)
        }} />
        <div ref={divRef} className={classes.avatarWrapper} key={url}>
          <div
            className={`${classes.nodeColorWrap}`}
            style={{
              backgroundColor: Colors.newColors[color]
                ? Colors.newColors[color]
                : !!color ? color : Colors.default()
            }}
          />
          {url && <img className={classes.avatar} src={url} alt="avatar" />}
          {editable && <img src={iconPencilWhite} className={classes.editIcon} alt="edit-icon" />}
        </div>
      </div>
      {editable && renderMenu()}
    </>
  );
}

export default AvatarField;