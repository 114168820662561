import { makeStyles } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'

export default makeStyles({
  dialogTitle: {
    paddingTop: 20,
    paddingBottom: 5
  },
  dialogPaper: {
    borderRadius: 12
  },
  dialogBackIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: -2,
    marginRight: 3
  },
  color: {
    width: 24,
    height: 24,
    borderRadius: 8,
    marginRight: 10
  },
  colorName: {
    textTransform: 'capitalize',
  },
  colorItem: {
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,

    '& + &': {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 20,
        right: 20,
        top: 0,
        borderTop: '1px solid',
        borderColor: grey[300]
      }
    }
  }
});