import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  appbar: {
    display: 'flex',
    minHeight: 80,
    padding: '0px 16px',
    paddingRight: 32,
    backgroundColor: Colors.greyBackground,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconMenu: {
    height: 32,
    width: 32,
  },
  iconLogoButton: {
    height: 64,
    width: 64,
    borderRadius: '50%',
    background: 'transparent',
    marginRight: 20
  },
  iconLogo: {
    height: 50,
    width: 50,
  },
  domainEditButton: {
    marginRight: 5
  },
  avatarButton: {
  },
  avatarThumbnail: {
    width: 30,
    height: 30,
    borderRadius: 16,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: 'white',
    backgroundColor: Colors.random()
  },
  avatarThumbnailImage: {
    minHeight: 30,
    minWidth: 30,
    maxWidth: 'initial',
    objectFit: 'cover'
  },
  menu: {
    minWidth: 200,
    '& .MuiListItem-root': {
      paddingTop: 10,
      paddingBottom: 10,

      '& img': {
        marginRight: 15,
        '&.your-domain': {
          marginRight: 10
        }
      }
    }
  },
  searchBar: {
    borderRadius: 12,
    [theme.breakpoints.up('lg')]: {
      minWidth: 400,
    },
    '& .searchContainer': {
      backgroundColor: 'white',
      borderRadius: 12,
      borderWidth: 1,
      borderColor: Colors.greyBorderBottom,
      height: 40
    }
  },
  domainMenu: {

  },
  limitNodes: {
    fontSize: 11,
    maxWidth: 180,
    whiteSpace: 'break-spaces',
    '& p': {
      fontSize: '11px !important',
      marginBottom: 4
    },
    '& a': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  limitNodesProgress: {
    marginTop: 10,
    borderRadius: 10
  },
  subLimit: {
    backgroundColor: theme.palette.background.default,
    maxWidth: 220,
    padding: '5px 10px',
    borderRadius: 12,
    fontSize: 10,
    marginRight: 25,

    '& span': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  searchContainer: {
    position: "absolute",
    minWidth: 300,
    [theme.breakpoints.up('lg')]: {
      minWidth: 400,
    },
    top: 50,
    left: 0,
    borderRadius: 12,
    backgroundColor: 'transparent',
    zIndex: 1000,
  },
  rightSide: {
    display: 'flex',
    gap: 16
  },
  appVersion: {
    color: Colors.greyBorderBottom,
    maxWidth: 100,
    marginLeft: 16,
    fontSize: 10
  }
}))