import { createSlice } from '@reduxjs/toolkit'
import { iResource } from "@gloow/apiconsumer"

interface iResourcesState {
  resources: iResource[],
}
const initialState: iResourcesState = {
  resources: [],
}

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState: initialState,
  reducers: {
    setResources: (state, action) => {
      state.resources = action.payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    },
    addResource: (state, action) => {
      state.resources = [action.payload, ...state.resources]
    },
    addResources: (state, action) => {
      state.resources = [...action.payload, ...state.resources]
    },
    removeResource: (state, action) => {
      const i = state.resources.findIndex(r => r.id === action.payload)
      state.resources = i === -1 ? [...state.resources] : [...state.resources.slice(0, i), ...state.resources.slice(i + 1)]
    },
    updateResource: (state, action) => {
      state.resources = [...state.resources
        .map(n => {
          return n.id !== action.payload.id
            ? n
            : {
              ...action.payload
            }
        })]
    },
  }
})

export const { addResource, addResources, removeResource, setResources, updateResource } = resourcesSlice.actions

export default resourcesSlice.reducer
