import React from 'react'
import { makeStyles, Card, Typography, IconButton, Tooltip } from '@material-ui/core'
import Colors from '@common/constants/Colors'
import HighLightedText from '../HighlightedText/HighlightedText'
import { iNode } from "@gloow/apiconsumer"
// import DOMPurify from "dompurify"
import { contentType } from '@common/constants/Constants'
import { useTranslation } from 'react-i18next'
import IconConnection from '@assets/images/icons-connection.svg'
import IconConnectionActive from '@assets/images/icons-connection-active.svg'
import Avatar from '../Avatar/Avatar'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    width: '100%',
    wordBreak: 'break-word',
    borderRadius: 12,
  },
  card: {
    width: '100%',
    borderRadius: 12,
    padding: 24,
    boxShadow: 'none !important'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 'normal'
  },
  subtitle: {
    color: Colors.darkGrey,
    lineHeight: 1,
    marginTop: 4,
    fontWeight: 400
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  info: {
    lineHeight: 'normal',
    marginTop: 12,
    fontSize: '1rem',
    maxHeight: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  extraInfo: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 12,
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stats: {
    color: Colors.darkGrey,
    lineHeight: 'normal',
    marginRight: 8,
    fontWeight: 400
  },
  value: {
    color: Colors.darkGrey,
    marginRight: 4,
    fontWeight: 400
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  imageContainer: {
    marginRight: 10,
  },
  imageItem: {
    width: 48,
    height: 48,
    objectFit: 'cover'
  },
  connections: {
    marginTop: 14,
    paddingTop: 12,
    borderTop: '1px solid',
    borderColor: Colors.greyBorderBottom,
  },
  connectionImg: {
    width: 20,
    height: 20,
    padding: 2
  },
  connectedNodesResources: {
    fontSize: 14
  }
}));

interface iGridNodeCard {
  className?: string,
  data: iNode,
  onClick: (data: iNode) => void,
  highlight?: string,
  onConnectionFilter?: (data: iNode) => void,
  selected?: boolean
}

const GridNodeCard = ({ className, data, onClick = (node) => { }, highlight = "", onConnectionFilter, selected = false }: iGridNodeCard) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // prevent cross site scripting(xss)
  // const safeHTMLInfo = DOMPurify.sanitize(data.info)
  return (
    <div
      className={`grid-card ${classes.container} ${className ?? ''}`}
      data-id={data.id}
      data-title={data.name?.toLowerCase() ?? ''}
      // data-description={safeHTMLInfo.toLowerCase() ?? ''}
      data-labels={data.labels?.map(d => d.name)?.join('|').toLowerCase() ?? ''}
      data-type={contentType.NODE}
      onClick={() => onClick(data)}
    >
      <Card className={`${classes.card}`}>
        <div className={classes.header}>
          <Avatar name={data.name} src={data.thumbnailUrl ?? ''} size={'48'} className={classes.imageContainer} color={data.color ?? Colors.default()} />
          <div className={`${classes.titleContainer} ${onConnectionFilter ? 'mr-8' : ''}`}>
            <Typography className={classes.title} variant={'h6'}>{data.name}</Typography>
            <Typography className={classes.subtitle} variant={'subtitle2'}>
              <HighLightedText text={data?.labels.map((d) => d.name).join(', ')} highlight={highlight}></HighLightedText>
            </Typography>
          </div>
        </div>
        {/* @todo serialize contents into html and print out */}
        {/* {safeHTMLInfo && <div dangerouslySetInnerHTML={{ __html: safeHTMLInfo }} className={classes.info} />} */}
        <div className={classes.extraInfo}>
          <div className={classes.row}>
            {/* @ts-ignore */}
            <Typography variant={'subtitle2'} className={classes.value}>{data?.relations ?? 0}</Typography><Typography variant={'subtitle2'} className={classes.stats}> {t('common.connections').toLowerCase()}</Typography>
            {/* @ts-ignore */}
            <Typography variant={'subtitle2'} className={classes.value}>{data?.resources ?? 0}</Typography><Typography variant={'subtitle2'} className={classes.stats}> {t('common.resources').toLowerCase()}</Typography>
          </div>
          {onConnectionFilter &&
            <Tooltip title={`${t('common.show_connections', 'Show connections')}`}>
              <IconButton onClick={(e) => {
                e.stopPropagation()
                onConnectionFilter(data)
              }} size={'small'}>
                <img alt='connection-toggle' src={selected ? IconConnectionActive : IconConnection} className={classes.connectionImg} />
              </IconButton>
            </Tooltip>
          }
        </div>
      </Card>
    </div>
  )
}

export default GridNodeCard
