import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 600
  }
})
)

export default function TheTitle(props) {
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h6" noWrap>
      {props?.title ? props.title : 'Gloow'}
    </Typography>
  );
}