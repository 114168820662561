import { makeStyles } from '@material-ui/core/styles'
export default makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'radial-gradient(circle at 0 0, rgba(67, 84, 221, 0.64), #4e5edf);',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    overflow: 'hidden'
  },
  feature: {
    width: '90%'
  },
  tagline: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 48,
    width: '75%',
    textAlign: 'center',
    lineHeight: '80px',
    paddingBottom: 30
  },
  highlight: {
    background: 'white',
    borderRadius: 24,
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: '10px 15px'
  },
  welcome: {
    fontWeight: 600,
    fontSize: 40,
    marginBottom: 24,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 16,
    opacity: 0.7,
    textAlign: 'center',
    padding: theme.spacing(0, 3)
  },
  or: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 14.8, 0, 14.8),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(6, 2),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
  },
  themeToggle: {
    position: 'absolute',
    top: 15,
    right: 15,
    zIndex: 9
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
    "& .MuiTextField-root ": {
      marginTop: theme.spacing(0)
    },
    paddingBottom: theme.spacing(17.5),
    position: 'relative',
    overflow: 'hidden'
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  social: {
    marginTop: 12,
    width: '100%',
    "& .MuiButton-root": {
      marginTop: 16,
      color: theme.palette.text.primary,
      "& img": {
        marginRight: 8
      }
    }
  },
  signup: {
    marginTop: 0
  },
  visible: {
    transform: 'translateX(0px)',
    position: 'absolute',
    transition: 'all 500ms ease-in-out 0s'
  },
  notVisible: {
    transform: 'translateX(1000px)',
    position: 'absolute',
    transition: 'all 500ms ease-in-out 0s'
  },
  languageDropdown: {
    position: 'absolute',
    top: 24,
    right: 24,
    [theme.breakpoints.down('md')]: {
      right: 12
    },
  }
}));
