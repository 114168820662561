import Colors from "@common/constants/Colors";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    fontWeight: 600,
    color: Colors.textPrimary,
    backgroundColor: 'white',
    borderWidth: 1,
    borderTopColor: Colors.white,
    borderRightColor: Colors.white,
    borderLeftColor: Colors.white,
    '& .drag-controller': {
      opacity: 0
    }
  },
  over: {
    borderRadius: '12px 12px 0px 0px',
    backgroundColor: `${Colors.newBgLightBlue} !important`,
    borderColor: Colors.primary
  },
  editable: {
    '&:hover': {
      borderRadius: '12px 12px 0px 0px',
      backgroundColor: `${Colors.newBgLightBlue} !important`,
      borderColor: Colors.primary,
      '& .drag-controller': {
        opacity: 1
      }
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  delete: {
    marginRight: 8
  },
  icon: {
    width: 15,
    height: 15
  },
  action: {
    width: 20,
    height: 20,
    color: Colors.primary
  },
  micon: {
  },
  textfield: {
    minWidth: 150,
    marginLeft: 8,
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
      fontSize: '1rem'
    }
  },
  save: {
    height: 35,
    marginLeft: 8,
    marginRight: 8,
    fontSize: 14,
  },
  saveIcon: {
    maxWidth: 20,
    maxHeight: 20,
  },
  name: {
    cursor: 'pointer'
  },
  error: {
    color: Colors.redError
  },
  errorIcon: {
    width: 18,
    height: 18,
    marginLeft: 4
  },
  dragContainer: {
    position: 'absolute',
    top: 0,
    paddingTop: 4,
    cursor: 'move',
    width: 20,
    height: 15,
    left: 'calc(50% - 7px)',
  }
}))